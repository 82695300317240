import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { decodeToken, isExpired } from 'react-jwt';

import { AuthLoginDocument, AuthSendDocument } from '../generated/graphql';
import { createApolloClient } from '../utils/apolloClient';

export interface ContextProps {
	token: string | null;
	setToken: Dispatch<SetStateAction<string | null>>;
	user?: AuthUser;
	loginWithEmail: (email: string, navigate: (path: string) => void) => Promise<void>;
	loginWithProvider: (loginType: string, credentialResponse: any, navigate: (path: string) => void) => Promise<void>;
}

const AuthContext = React.createContext<ContextProps>({
	user: undefined,
	token: null,
	setToken: () => {},
	loginWithEmail: async () => {},
	loginWithProvider: async () => {}
});

export interface AuthUser {
	email: string;
	name: string;
	sub?: string;
	picture?: string;
	user_id: string;
}

interface Props {
	children: ReactNode;
	authToken: string | null;
}

export const AuthProvider = ({ authToken, children }: Props) => {
	const [token, setToken] = React.useState<string | null>(authToken);
	const [user, setUser] = React.useState<AuthUser | undefined>();
	const [authSend] = useMutation(AuthSendDocument);
	const [authLogin] = useMutation(AuthLoginDocument);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (token) {
			localStorage.setItem('authToken', token);
			if (!isExpired(token)) {
				const decoded = decodeToken<AuthUser>(token);
				if (decoded) {
					setUser(decoded);
				}
			}
		} else {
			localStorage.removeItem('authToken');
			setUser(undefined);
		}
	}, [token]);

	const resetApolloClient = () => {
		// This will reset the Apollo Client, which ensures that the client uses the updated token
		const client = createApolloClient();
		client.clearStore();
	};

	const loginWithEmail = async (email: string, navigate: (path: string) => void) => {
		console.log('loginWithEmail called', { email });
		try {
			const { data, errors } = await authSend({ variables: { email } });
			if (!data || errors || !data?.authSend?.status) {
				enqueueSnackbar('Login Email Send Failed', { variant: 'error' });
			} else {
				enqueueSnackbar('Login Email Sent!', { variant: 'success' });
			}
			resetApolloClient();
		} catch (e) {
			enqueueSnackbar('Login Email Send Failed', { variant: 'error' });
		}
	};

	const loginWithProvider = async (loginType: string, credentialResponse: any, navigate: (path: string) => void) => {
		console.log('loginWithProvider called', { loginType, credentialResponse });
		if (credentialResponse.error) {
			enqueueSnackbar('Login Failed', { variant: 'error' });
			return;
		}

		const result = await authLogin({
			variables: {
				loginType: loginType,
				credentialResponse: JSON.stringify(credentialResponse)
			}
		});

		if (result.data?.authLogin && !result.data.authLogin.error && result.data.authLogin.token) {
			setToken(result.data.authLogin.token);
			enqueueSnackbar('You are now signed in.', { variant: 'success' });
			resetApolloClient();
			navigate('/');
		} else {
			enqueueSnackbar('Login Failed', { variant: 'error' });
		}
	};

	return (
		<AuthContext.Provider value={{ user, token, setToken, loginWithEmail, loginWithProvider }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => React.useContext(AuthContext);
