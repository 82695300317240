import { Grid } from '@mui/material';
import React from 'react';

import SupportForm from '../components/SupportForm';
import BodyWrapper from '../core/components/BodyWrapper';
import BreadcrumbConnectedWrapper from '../core/components/BreadcrumbConnectedWrapper';

const CustomerSupport: React.FC = () => {
	return (
		<>
			<BreadcrumbConnectedWrapper />
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12}>
						<h3>Customer Support</h3>
					</Grid>

					<Grid item xs={12} md={8}>
						<h4>Contact Us for Order Support</h4>
						<p>
							If you need assistance with your order or have any questions, our customer support team is here to
							help. You can reach us through the following methods:
						</p>
						<h5>Via Email</h5>
						<p>
							For general inquiries or non-urgent issues, please email us at{' '}
							<a href={'mailto:support@breakmark.com'}>support@breakmark.com</a>. We aim to respond to all emails
							within 24 hours during business days.
						</p>

						<p>
							When contacting us about an order, please have your order number ready. This will help us assist you
							more efficiently. You can find your order number in the confirmation email you received after placing
							your order.
						</p>
						<h5>Via Online Form</h5>
					</Grid>

					<Grid item xs={12}>
						<SupportForm />
					</Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
};

export default CustomerSupport;
