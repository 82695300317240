import React from 'react';
import { Outlet } from 'react-router-dom';

import Banner from '../components/Banner';
import Page from './Page';

const AppContainer: React.FC = () => {
	return (
		<Page>
			<Outlet />
		</Page>
	);
};

export default AppContainer;
