import { useMutation } from '@apollo/client';
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { InsertSupportDocument } from '../generated/graphql';
import { useAuth } from '../providers/authProvider';
import { findEnvAttr } from '../utils/process';

interface SupportDialogProps {
	onSubmit?: (formData: SupportFormData) => void;
}

export interface SupportFormData {
	reason: string;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	edits?: string;
	orderId?: string;
	orderItemId?: string;
	details: string;
}

const defaultFormData = {
	reason: 'questions',
	firstName: '',
	lastName: '',
	phone: '',
	email: '',
	orderId: '',
	orderItemId: '',
	edits: '',
	details: ''
};

const SupportForm: React.FC<SupportDialogProps> = ({ onSubmit }) => {
	const [formData, setFormData] = useState<SupportFormData>(defaultFormData);
	const captchaRef = useRef<ReCAPTCHA>(null);
	const { user } = useAuth();

	const [insertSupport] = useMutation(InsertSupportDocument);
	const loading = false;
	const { enqueueSnackbar } = useSnackbar();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | Date | null, fieldName?: string) => {
		if (event && typeof event === 'object' && 'target' in event) {
			const { name, value } = event.target;
			setFormData((prevData) => ({ ...prevData, [name]: value }));
		}
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const token = await captchaRef.current?.executeAsync();
		captchaRef.current?.reset();

		try {
			await insertSupport({
				variables: {
					reason: formData.reason,
					email: formData.email,
					first_name: formData.firstName,
					last_name: formData.lastName,
					phone: formData.phone,
					edits: formData.edits,
					details: formData.details,
					order_id: formData.orderId || null,
					order_item_id: formData.orderItemId || null,
					user_id: user?.user_id || null,
					captcha_token: token
				}
			});
			if (onSubmit) {
				onSubmit(formData);
			} else {
				enqueueSnackbar('Support Request submitted', { variant: 'info' });
			}
			setFormData(defaultFormData);
		} catch (err) {
			console.error('Error creating support entry:', err);
			enqueueSnackbar('Error submitting inquiry. Please try again.', { variant: 'error' });
		}
	};

	// New function to check if all required fields are filled
	const isFormValid = (): boolean => {
		if (formData.reason !== 'service') {
			return (
				formData.firstName.trim() !== '' &&
				formData.lastName.trim() !== '' &&
				formData.email.trim() !== '' &&
				formData.orderId !== undefined &&
				formData.orderId.trim() !== ''
			);
		} else {
			return formData.firstName.trim() !== '' && formData.lastName.trim() !== '' && formData.email.trim() !== '';
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">Contact Reason</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={formData.reason}
							label="Contact Reason"
							onChange={(v) => setFormData({ ...formData, reason: v.target.value })}
						>
							<MenuItem value={'questions'}>Questions on My Order</MenuItem>
							<MenuItem value={'orders'}>Team Orders</MenuItem>
							<MenuItem value={'service'}>Customer Service</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<TextField
						required
						fullWidth
						label="First Name"
						name="firstName"
						value={formData.firstName}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						required
						fullWidth
						label="Last Name"
						name="lastName"
						value={formData.lastName}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						required
						fullWidth
						type="email"
						label="Email"
						name="email"
						value={formData.email}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField fullWidth label="Phone" name="phone" value={formData.phone} onChange={handleChange} />
				</Grid>
				{formData.reason !== 'service' ? (
					<>
						<Grid item xs={6}>
							<TextField
								required={formData.reason !== 'service'}
								fullWidth
								label="Order Number"
								name="orderId"
								value={formData.orderId}
								onChange={handleChange}
							/>
						</Grid>
						{/*<Grid item xs={6}>*/}
						{/*	<FormControl fullWidth>*/}
						{/*		<InputLabel>Product</InputLabel>*/}
						{/*		<Select*/}
						{/*			id="product"*/}
						{/*			value={formData.orderItemId}*/}
						{/*			label="Product"*/}
						{/*			onChange={(v) => setFormData({ ...formData, orderItemId: v.target.value })}*/}
						{/*		>*/}
						{/*			<MenuItem value={'questions'}>Questions on My Order</MenuItem>*/}
						{/*			<MenuItem value={'orders'}>Team Orders</MenuItem>*/}
						{/*			<MenuItem value={'service'}>Customer Service</MenuItem>*/}
						{/*		</Select>*/}
						{/*	</FormControl>*/}
						{/*</Grid>*/}
						<Grid item xs={6}>
							<TextField fullWidth label="Edits" name="edits" value={formData.edits} onChange={handleChange} />
						</Grid>
					</>
				) : null}

				<Grid item xs={12}>
					<TextField
						fullWidth
						multiline
						rows={formData.reason !== 'service' ? 4 : 10}
						label={formData.reason !== 'service' ? 'Additional Details' : 'Details'}
						name="details"
						value={formData.details}
						onChange={handleChange}
						placeholder="Any additional information or special requests?"
					/>
				</Grid>
				<Grid item xs={12}>
					<ReCAPTCHA sitekey={findEnvAttr('REACT_APP_GOOGLE_CAPTCHA_PUBLIC_KEY')} size="invisible" ref={captchaRef} />
				</Grid>
				<Grid item xs={12}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={!isFormValid() || loading}
						startIcon={loading ? <CircularProgress size={20} /> : null}
					>
						{loading ? 'Submitting...' : 'Submit'}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default SupportForm;
