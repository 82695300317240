import styled from '@emotion/styled';
import { Avatar } from '@mui/material';
import React, { useState } from 'react';

import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import { AuthUser } from '../../../providers/authProvider';

const ProfileCard = styled('div')`
	padding: 24px;
`;

const ProfileContent = styled('div')`
	display: flex;
	gap: 32px;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 600px) {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
`;

const LargeAvatar = styled(Avatar)`
	width: 120px;
	height: 120px;
	flex-shrink: 0;
	border: 4px solid #ffffff;

	/* Card/Hover */
	filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
`;

const ProfileInfo = styled('div')`
	flex: 1;
`;

const ProfileName = styled('div')`
	font-style: normal;
	font-weight: 600;
	font-size: 23px;
	line-height: 30px;

	/* Gray Shade */
	color: #302e28;

	/* Inside auto layout */
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
`;

const ProfileEmail = styled('div')`
	/* P3 */
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;

	/* Light Gray */
	color: #a9a59c;
`;

const StyledInputText = styled('div')`
	margin-bottom: 16px;
`;

interface UserProfileProps {
	user: AuthUser;
}

export function UserProfile({ user }: UserProfileProps) {
	// const [isEditing, setIsEditing] = useState(false);
	// const [editedUser, setEditedUser] = useState({
	// 	name: user.name,
	// 	email: user.email
	// });

	// const handleSave = async () => {
	// 	await onSave(editedUser);
	// 	setIsEditing(false);
	// };

	// const handleCancel = () => {
	// 	setEditedUser({ name: user.name, email: user.email });
	// 	setIsEditing(false);
	// };

	return (
		<ProfileCard>
			<ProfileContent>
				<LargeAvatar src={user.picture || undefined} alt={user.name}>
					{!user.picture && user.name?.[0]?.toUpperCase()}
				</LargeAvatar>
				<ProfileInfo>
					<ProfileName>{user.name || 'No Name'}</ProfileName>

					<ProfileEmail>{user.email}</ProfileEmail>
				</ProfileInfo>
				{/* {isEditing ? (
					<>
						<Button variant="white" onClick={handleCancel}>
							Cancel
						</Button>
						<Button variant="white" onClick={handleSave}>
							Save
						</Button>
					</>
				) : (
					<Button variant="white" onClick={() => setIsEditing(true)}>
						Edit
					</Button>
				)} */}
			</ProfileContent>
		</ProfileCard>
	);
}
