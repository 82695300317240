import styled from '@emotion/styled';
import { Avatar } from '@mui/material';
import React from 'react';

type ImageProps = {
	src: string;
	size?: number; // Optional size prop
	alt?: string;
};

const LargeAvatar = styled(Avatar)`
	flex-shrink: 0;
	border: 4px solid #ffffff;

	/* Card/Hover */
	filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.12));
`;

interface AvatarComponentProps {
	user: { picture?: string; name?: string };
	size?: number;
}

const AvatarComponent: React.FC<AvatarComponentProps> = ({ user, size }) => {
	return (
		<LargeAvatar src={user.picture || undefined} alt={user.name}>
			{!user.picture && user.name?.[0]?.toUpperCase()}
		</LargeAvatar>
	);
};

export default AvatarComponent;
