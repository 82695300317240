import './index.css';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import ApolloWrapper from './providers/apolloWrapper';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import { findEnvAttr } from './utils/process';

const shouldInitSentry = () => {
	const environment = findEnvAttr('REACT_APP_ENVIRONMENT');
	const isLocalhost = window.location.hostname === 'localhost';
	return !isLocalhost && environment !== 'development';
};

if (shouldInitSentry()) {
	Sentry.init({
		dsn: 'https://6f211f5da890b8317b4c1342b7ae9415@o1038180.ingest.us.sentry.io/4508242509496320',
		environment: findEnvAttr('REACT_APP_ENVIRONMENT'),
		release: findEnvAttr('REACT_APP_SENTRY_RELEASE'),
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		tracesSampleRate: 1.0
	});
}

export const muiCache = createCache({
	key: 'mui',
	prepend: true
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<CacheProvider value={muiCache}>
			<ThemeProvider theme={theme}>
				<GoogleOAuthProvider clientId={findEnvAttr('REACT_APP_GOOGLE_CLIENT_ID')}>
					<SnackbarProvider
						maxSnack={3}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						preventDuplicate={true}
						autoHideDuration={2000}
						className={'snackbar-snack'}
					>
						<ApolloWrapper>
							<App />
						</ApolloWrapper>
					</SnackbarProvider>
				</GoogleOAuthProvider>
			</ThemeProvider>
		</CacheProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
