import React from 'react';

import Banner from '../components/Banner';
import FeaturedDealsSection from '../components/FeaturedDealsSection';
import GenericHeroImage from '../components/GenericHeroImage';
import Hero from '../components/Hero';
import PartnerStoreSection from '../components/PartnerStoreSection';
import PartnerStrip from '../components/PartnerStrip';
import { RouteDefinitions } from '../routeDefinitions';

function Home() {
	return (
		<>
			<Hero />
			<FeaturedDealsSection />
			<PartnerStoreSection />
		</>
	);
}

export default Home;
