import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import * as Icons from 'react-feather';
import { useNavigate } from 'react-router-dom';

import Avatar from '../../core/components/Avatar';
import { AuthUser } from '../../providers/authProvider';
import { useCartStore } from '../../stores/updatedCart/cartStore';

interface UserMenuProps {
	user: AuthUser | undefined;
}

function UserMenu({ user }: UserMenuProps) {
	const navigate = useNavigate();
	const storeData = useCartStore((state) => state.storeData);

	// const cartItemCount = Object.keys(storeData).reduce((acc, key) => {
	// 	return acc + storeData[key].length;
	// }, 0);

	const openLink = (url: string) => {
		navigate(url);
	};

	return (
		<>
			{/* <Box component={'div'} sx={{ display: { md: 'none', xs: 'block' } }}>
				<IconButton onClick={() => openLink('/Search')}>
					<Tooltip title={'Search for Products'}>
						<Icons.Search />
					</Tooltip>
				</IconButton>
			</Box>
			<IconButton onClick={() => openLink('/Cart')}>
				<Tooltip title={'Shopping Cart'}>
					<Badge badgeContent={cartItemCount} color="secondary">
						<Icons.ShoppingCart />
					</Badge>
				</Tooltip>
			</IconButton> */}
			{!user ? (
				<IconButton onClick={() => openLink('/Login')}>
					<Tooltip title={'Login'}>
						<Icons.User />
					</Tooltip>
				</IconButton>
			) : (
				<IconButton onClick={() => openLink('/My/Account')}>
					<Tooltip title={'My Account'}>
						<Avatar user={user} />
					</Tooltip>
				</IconButton>
			)}
		</>
	);
}

export default UserMenu;
