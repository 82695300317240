import styled from '@emotion/styled';
import { Breadcrumbs, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAuth } from '../../providers/authProvider';

const BreadcrumbsWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 95%;
	max-width: 1240px;
	margin: auto;
`;

const Separator = styled('div')`
	width: 100%;
	border-bottom: 1px solid var(--separator);
	margin: 0 0 24px;
`;

const BodyWrapper = styled('div')`
	display: block;
	width: 95%;
	max-width: 1240px;
	margin: 24px auto;
	background-color: var(--grey);
	border-radius: 15px;
	padding: 45px;
	@media (min-width: 568px) {
		max-width: 538px;
	}
`;
function LoginCallback() {
	const { token } = useParams();
	const [search] = useSearchParams();
	const navigate = useNavigate();
	const { user, loginWithProvider } = useAuth();
	const next = search.get('next');

	useEffect(() => {
		if (token) {
			loginWithProvider('email', { token }, navigate).then(() => {
				navigate(next || '/');
			});
			return;
		}
	}, [token]);

	if (user) {
		navigate('/My/Account');
	}

	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/Login'}>
						<p className={'no-margin'}>
							<strong>Login</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<p>Verifying your login...</p>
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default LoginCallback;
