import React from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../providers/authProvider';

interface ProtectedRouteProps {
	children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
	const { user } = useAuth();
	const location = useLocation();

	console.log('user', user);

	if (!user) {
		return (
			<>
				<div className={'padded centered'}>
					<img src={'/images/NotAuthorized.png'} width={150} alt={'Sad Browser'} />
					<div>
						<h3>Offsides!</h3>
						<p>You are not authorized to view this page without an account.</p>
						<p>
							<a href={`/Login?next=${encodeURI(location.pathname)}`}>Login</a>
						</p>
					</div>
				</div>
			</>
		);
	}

	return children;
};

export default ProtectedRoute;
