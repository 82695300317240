import { Box, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { menuItems } from '../../config/menuItems';
import Logo from '../../core/components/Logo';
import MenuLink from '../../core/components/MenuLink';
import MenuWrapper from '../../core/components/MenuWrapper';
import { useAuth } from '../../providers/authProvider';
import { getImageUrl } from '../../utils/imageHelper';
import MobileMenu from './MobileMenu';
import UserMenu from './UserMenu';

function MenuBar() {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { user } = useAuth();
	const location = useLocation();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const queryParam = new URLSearchParams(location.search).get('q');
		if (queryParam) {
			localStorage.setItem('menuBarPreference', queryParam);
		}
	}, [location.search]);

	const getMenuBarPreference = () => {
		const queryParam = new URLSearchParams(location.search).get('q');
		return queryParam || localStorage.getItem('menuBarPreference');
	};

	const showMenuLinks = getMenuBarPreference() === 'menuBar';

	return (
		<MenuWrapper>
			<MobileMenu anchorEl={anchorEl} open={open} handleClick={handleClick} handleClose={handleClose} />
			<NavLink to={'/'}>
				<Logo src={getImageUrl('logo.jpg')} />
			</NavLink>
			<Box component={'div'} sx={{ display: { md: 'flex', xs: 'none' } }}>
				{menuItems.map((item, index) => (
					<MenuLink key={index} to={item.to} target={item.target}>
						{item.label}
					</MenuLink>
				))}
			</Box>
			{/* <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' }, flex: 'max-content' }}>
				<SearchBox />
			</Box> */}

			{showMenuLinks && (
				<Toolbar>
					<UserMenu user={user} />
				</Toolbar>
			)}
		</MenuWrapper>
	);
}

export default MenuBar;
