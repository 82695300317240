import { LoaderFunction, redirect } from 'react-router-dom';

import { GetMyStoreOrdersDocument, GetMyStoreOrdersQuery } from '../generated/graphql';
import { RouteDefinitions } from '../routeDefinitions';
import { createApolloClient } from '../utils/apolloClient';

// Utility function to check for authentication
function isAuthenticated(): boolean {
	// Example: Check for a token in local storage
	return !!localStorage.getItem('authToken');
}

const apolloClient = createApolloClient();

interface LoaderParams {
	bundleId?: string;
}

export interface StoreOrderReturn {
	id: string;
	customer_name: string;
	customer_email: string;
	created_at: string;
	checkout_type: string;
	order_readable_column: number;
	status: string;
	stripe_tax_charged: number;
	stripe_total_charged?: number | null;
	total: number;
	updated_at: string;
	order_items: Array<{ id: string; image_url: string }>;
}

export interface MyAccountLoaderReturn {
	storeOrders: StoreOrderReturn[];
}

const MyAccountLoader: LoaderFunction = async ({ params }: { params: LoaderParams }) => {
	try {
		// Use the utility function to check authentication
		if (!isAuthenticated()) {
			return redirect(RouteDefinitions.LOGIN());
		}

		const { data: storeOrders } = await apolloClient.query<GetMyStoreOrdersQuery>({
			query: GetMyStoreOrdersDocument
		});

		if (!storeOrders) {
			throw new Error('Failed to store orders');
		}

		return { storeOrders: storeOrders.order };
	} catch (error) {
		console.error(error);
		throw new Error(`Loader error:`);
	}
};

export default MyAccountLoader;
