import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	date: any;
	jsonb: any;
	timestamptz: any;
	uuid: any;
};

export type AsyncActionReturn = {
	__typename?: 'AsyncActionReturn';
	error?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	status: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['Boolean']>;
	_gt?: InputMaybe<Scalars['Boolean']>;
	_gte?: InputMaybe<Scalars['Boolean']>;
	_in?: InputMaybe<Array<Scalars['Boolean']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['Boolean']>;
	_lte?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Scalars['Boolean']>;
	_nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CreateReturn = {
	__typename?: 'CreateReturn';
	error?: Maybe<Scalars['String']>;
	status: Scalars['Boolean'];
};

export type CustomLinkReturn = {
	__typename?: 'CustomLinkReturn';
	url: Scalars['String'];
};

export type DuplicateTeamStoreReturn = {
	__typename?: 'DuplicateTeamStoreReturn';
	error?: Maybe<Scalars['String']>;
	newStoreId?: Maybe<Scalars['uuid']>;
};

export type ExportUrl = {
	__typename?: 'ExportURL';
	error?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['Int']>;
	_gt?: InputMaybe<Scalars['Int']>;
	_gte?: InputMaybe<Scalars['Int']>;
	_in?: InputMaybe<Array<Scalars['Int']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['Int']>;
	_lte?: InputMaybe<Scalars['Int']>;
	_neq?: InputMaybe<Scalars['Int']>;
	_nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LeadInput = {
	captcha_token: Scalars['String'];
	comments?: InputMaybe<Scalars['String']>;
	design_file_name_1: Scalars['String'];
	design_file_name_2: Scalars['String'];
	design_file_type_1: Scalars['String'];
	design_file_type_2: Scalars['String'];
	email: Scalars['String'];
	first_name: Scalars['String'];
	last_name: Scalars['String'];
	need_by_date: Scalars['String'];
	phone?: InputMaybe<Scalars['String']>;
	product_id: Scalars['String'];
	team_affiliation: Scalars['String'];
};

export type LeadOutput = {
	__typename?: 'LeadOutput';
	design_file_signed_url?: Maybe<Scalars['String']>;
	design_file_two_signed_url?: Maybe<Scalars['String']>;
	error?: Maybe<Scalars['String']>;
	status: Scalars['Boolean'];
};

export type LoginOutput = {
	__typename?: 'LoginOutput';
	error?: Maybe<Scalars['String']>;
	token?: Maybe<Scalars['String']>;
};

export type OrderItem = {
	__typename?: 'OrderItem';
	id: Scalars['String'];
	image_url: Scalars['String'];
};

export type SampleOutput = {
	__typename?: 'SampleOutput';
	storeProductId: Scalars['String'];
};

export type SavedUrlOutput = {
	__typename?: 'SavedUrlOutput';
	error?: Maybe<Scalars['String']>;
	status: Scalars['Boolean'];
	url: Scalars['String'];
};

export type SendOutput = {
	__typename?: 'SendOutput';
	message?: Maybe<Scalars['String']>;
	status: Scalars['Boolean'];
};

export type ShippingLabelOutput = {
	__typename?: 'ShippingLabelOutput';
	error?: Maybe<Scalars['String']>;
	shippingLabelUrl: Scalars['String'];
	status: Scalars['Boolean'];
};

export type SignedUrlData = {
	__typename?: 'SignedUrlData';
	error?: Maybe<Scalars['String']>;
	signedRequest?: Maybe<Scalars['String']>;
	success: Scalars['Boolean'];
	url?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['String']>;
	_gt?: InputMaybe<Scalars['String']>;
	_gte?: InputMaybe<Scalars['String']>;
	/** does the column match the given case-insensitive pattern */
	_ilike?: InputMaybe<Scalars['String']>;
	_in?: InputMaybe<Array<Scalars['String']>>;
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: InputMaybe<Scalars['String']>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	/** does the column match the given pattern */
	_like?: InputMaybe<Scalars['String']>;
	_lt?: InputMaybe<Scalars['String']>;
	_lte?: InputMaybe<Scalars['String']>;
	_neq?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: InputMaybe<Scalars['String']>;
	_nin?: InputMaybe<Array<Scalars['String']>>;
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given pattern */
	_nlike?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: InputMaybe<Scalars['String']>;
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: InputMaybe<Scalars['String']>;
	/** does the column match the given SQL regular expression */
	_similar?: InputMaybe<Scalars['String']>;
};

export type StripeProduct = {
	__typename?: 'StripeProduct';
	description?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	updated?: Maybe<Scalars['Int']>;
};

export type UrlOutput = {
	__typename?: 'UrlOutput';
	error?: Maybe<Scalars['String']>;
	urlRedirect: Scalars['String'];
};

export type UserOrder = {
	__typename?: 'UserOrder';
	checkout_type: Scalars['String'];
	created_at: Scalars['String'];
	customer_email: Scalars['String'];
	customer_name: Scalars['String'];
	id: Scalars['String'];
	order_items: Array<OrderItem>;
	order_readable_column: Scalars['Int'];
	status: Scalars['String'];
	stripe_tax_charged: Scalars['Int'];
	stripe_total_charged?: Maybe<Scalars['Int']>;
	total: Scalars['Int'];
	updated_at: Scalars['String'];
};

export type Add_User_Role_By_Name_Args = {
	_role_name?: InputMaybe<Scalars['String']>;
	_user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "async_action" */
export type Async_Action = {
	__typename?: 'async_action';
	created_at: Scalars['timestamptz'];
	data?: Maybe<Scalars['jsonb']>;
	id: Scalars['uuid'];
	label: Scalars['String'];
	status: Scalars['String'];
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "async_action" */
export type Async_ActionDataArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "async_action" */
export type Async_Action_Aggregate = {
	__typename?: 'async_action_aggregate';
	aggregate?: Maybe<Async_Action_Aggregate_Fields>;
	nodes: Array<Async_Action>;
};

/** aggregate fields of "async_action" */
export type Async_Action_Aggregate_Fields = {
	__typename?: 'async_action_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<Async_Action_Max_Fields>;
	min?: Maybe<Async_Action_Min_Fields>;
};

/** aggregate fields of "async_action" */
export type Async_Action_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Async_Action_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Async_Action_Append_Input = {
	data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "async_action". All fields are combined with a logical 'AND'. */
export type Async_Action_Bool_Exp = {
	_and?: InputMaybe<Array<Async_Action_Bool_Exp>>;
	_not?: InputMaybe<Async_Action_Bool_Exp>;
	_or?: InputMaybe<Array<Async_Action_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	data?: InputMaybe<Jsonb_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	label?: InputMaybe<String_Comparison_Exp>;
	status?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "async_action" */
export enum Async_Action_Constraint {
	/** unique or primary key constraint on columns "id" */
	AsyncActionPkey = 'async_action_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Async_Action_Delete_At_Path_Input = {
	data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Async_Action_Delete_Elem_Input = {
	data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Async_Action_Delete_Key_Input = {
	data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "async_action" */
export type Async_Action_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	data?: InputMaybe<Scalars['jsonb']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Async_Action_Max_Fields = {
	__typename?: 'async_action_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Async_Action_Min_Fields = {
	__typename?: 'async_action_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "async_action" */
export type Async_Action_Mutation_Response = {
	__typename?: 'async_action_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Async_Action>;
};

/** on_conflict condition type for table "async_action" */
export type Async_Action_On_Conflict = {
	constraint: Async_Action_Constraint;
	update_columns?: Array<Async_Action_Update_Column>;
	where?: InputMaybe<Async_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "async_action". */
export type Async_Action_Order_By = {
	created_at?: InputMaybe<Order_By>;
	data?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: async_action */
export type Async_Action_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Async_Action_Prepend_Input = {
	data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "async_action" */
export enum Async_Action_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Data = 'data',
	/** column name */
	Id = 'id',
	/** column name */
	Label = 'label',
	/** column name */
	Status = 'status',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "async_action" */
export type Async_Action_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	data?: InputMaybe<Scalars['jsonb']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "async_action" */
export type Async_Action_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Async_Action_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Async_Action_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	data?: InputMaybe<Scalars['jsonb']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "async_action" */
export enum Async_Action_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Data = 'data',
	/** column name */
	Id = 'id',
	/** column name */
	Label = 'label',
	/** column name */
	Status = 'status',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Async_Action_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Async_Action_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Async_Action_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Async_Action_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Async_Action_Delete_Key_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Async_Action_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Async_Action_Set_Input>;
	/** filter the rows which have to be updated */
	where: Async_Action_Bool_Exp;
};

/** Various options a product could have. */
export type Available_Option = {
	__typename?: 'available_option';
	/** An array relationship */
	available_option_values: Array<Available_Option_Value>;
	/** An aggregate relationship */
	available_option_values_aggregate: Available_Option_Value_Aggregate;
	created_at: Scalars['timestamptz'];
	csv_key: Scalars['String'];
	description?: Maybe<Scalars['String']>;
	display_name?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	name: Scalars['String'];
	/** An array relationship */
	order_item_options: Array<Order_Item_Option>;
	/** An aggregate relationship */
	order_item_options_aggregate: Order_Item_Option_Aggregate;
	/** An array relationship */
	product_has_available_options: Array<Product_Has_Available_Option>;
	/** An aggregate relationship */
	product_has_available_options_aggregate: Product_Has_Available_Option_Aggregate;
	/** An array relationship */
	store_product_has_options: Array<Store_Product_Has_Options>;
	/** An aggregate relationship */
	store_product_has_options_aggregate: Store_Product_Has_Options_Aggregate;
	ui_test_id?: Maybe<Scalars['String']>;
	updated_at: Scalars['timestamptz'];
};

/** Various options a product could have. */
export type Available_OptionAvailable_Option_ValuesArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Value_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Value_Order_By>>;
	where?: InputMaybe<Available_Option_Value_Bool_Exp>;
};

/** Various options a product could have. */
export type Available_OptionAvailable_Option_Values_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Value_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Value_Order_By>>;
	where?: InputMaybe<Available_Option_Value_Bool_Exp>;
};

/** Various options a product could have. */
export type Available_OptionOrder_Item_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

/** Various options a product could have. */
export type Available_OptionOrder_Item_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

/** Various options a product could have. */
export type Available_OptionProduct_Has_Available_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Has_Available_Option_Order_By>>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

/** Various options a product could have. */
export type Available_OptionProduct_Has_Available_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Has_Available_Option_Order_By>>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

/** Various options a product could have. */
export type Available_OptionStore_Product_Has_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

/** Various options a product could have. */
export type Available_OptionStore_Product_Has_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

/** aggregated selection of "available_option" */
export type Available_Option_Aggregate = {
	__typename?: 'available_option_aggregate';
	aggregate?: Maybe<Available_Option_Aggregate_Fields>;
	nodes: Array<Available_Option>;
};

/** aggregate fields of "available_option" */
export type Available_Option_Aggregate_Fields = {
	__typename?: 'available_option_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<Available_Option_Max_Fields>;
	min?: Maybe<Available_Option_Min_Fields>;
};

/** aggregate fields of "available_option" */
export type Available_Option_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Available_Option_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "available_option". All fields are combined with a logical 'AND'. */
export type Available_Option_Bool_Exp = {
	_and?: InputMaybe<Array<Available_Option_Bool_Exp>>;
	_not?: InputMaybe<Available_Option_Bool_Exp>;
	_or?: InputMaybe<Array<Available_Option_Bool_Exp>>;
	available_option_values?: InputMaybe<Available_Option_Value_Bool_Exp>;
	available_option_values_aggregate?: InputMaybe<Available_Option_Value_Aggregate_Bool_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	csv_key?: InputMaybe<String_Comparison_Exp>;
	description?: InputMaybe<String_Comparison_Exp>;
	display_name?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	order_item_options?: InputMaybe<Order_Item_Option_Bool_Exp>;
	order_item_options_aggregate?: InputMaybe<Order_Item_Option_Aggregate_Bool_Exp>;
	product_has_available_options?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
	product_has_available_options_aggregate?: InputMaybe<Product_Has_Available_Option_Aggregate_Bool_Exp>;
	store_product_has_options?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
	store_product_has_options_aggregate?: InputMaybe<Store_Product_Has_Options_Aggregate_Bool_Exp>;
	ui_test_id?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "available_option" */
export enum Available_Option_Constraint {
	/** unique or primary key constraint on columns "id" */
	AvailableOptionPkey = 'available_option_pkey'
}

/** input type for inserting data into table "available_option" */
export type Available_Option_Insert_Input = {
	available_option_values?: InputMaybe<Available_Option_Value_Arr_Rel_Insert_Input>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	csv_key?: InputMaybe<Scalars['String']>;
	description?: InputMaybe<Scalars['String']>;
	display_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	order_item_options?: InputMaybe<Order_Item_Option_Arr_Rel_Insert_Input>;
	product_has_available_options?: InputMaybe<Product_Has_Available_Option_Arr_Rel_Insert_Input>;
	store_product_has_options?: InputMaybe<Store_Product_Has_Options_Arr_Rel_Insert_Input>;
	ui_test_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Available_Option_Max_Fields = {
	__typename?: 'available_option_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	csv_key?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	display_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	ui_test_id?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Available_Option_Min_Fields = {
	__typename?: 'available_option_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	csv_key?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	display_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	ui_test_id?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "available_option" */
export type Available_Option_Mutation_Response = {
	__typename?: 'available_option_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Available_Option>;
};

/** input type for inserting object relation for remote table "available_option" */
export type Available_Option_Obj_Rel_Insert_Input = {
	data: Available_Option_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Available_Option_On_Conflict>;
};

/** on_conflict condition type for table "available_option" */
export type Available_Option_On_Conflict = {
	constraint: Available_Option_Constraint;
	update_columns?: Array<Available_Option_Update_Column>;
	where?: InputMaybe<Available_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "available_option". */
export type Available_Option_Order_By = {
	available_option_values_aggregate?: InputMaybe<Available_Option_Value_Aggregate_Order_By>;
	created_at?: InputMaybe<Order_By>;
	csv_key?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	display_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	order_item_options_aggregate?: InputMaybe<Order_Item_Option_Aggregate_Order_By>;
	product_has_available_options_aggregate?: InputMaybe<Product_Has_Available_Option_Aggregate_Order_By>;
	store_product_has_options_aggregate?: InputMaybe<Store_Product_Has_Options_Aggregate_Order_By>;
	ui_test_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: available_option */
export type Available_Option_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "available_option" */
export enum Available_Option_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CsvKey = 'csv_key',
	/** column name */
	Description = 'description',
	/** column name */
	DisplayName = 'display_name',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	UiTestId = 'ui_test_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "available_option" */
export type Available_Option_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	csv_key?: InputMaybe<Scalars['String']>;
	description?: InputMaybe<Scalars['String']>;
	display_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	ui_test_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "available_option" */
export type Available_Option_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Available_Option_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Available_Option_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	csv_key?: InputMaybe<Scalars['String']>;
	description?: InputMaybe<Scalars['String']>;
	display_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	ui_test_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "available_option" */
export enum Available_Option_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CsvKey = 'csv_key',
	/** column name */
	Description = 'description',
	/** column name */
	DisplayName = 'display_name',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	UiTestId = 'ui_test_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Available_Option_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Available_Option_Set_Input>;
	/** filter the rows which have to be updated */
	where: Available_Option_Bool_Exp;
};

/** columns and relationships of "available_option_value" */
export type Available_Option_Value = {
	__typename?: 'available_option_value';
	archived?: Maybe<Scalars['Boolean']>;
	/** An object relationship */
	available_option: Available_Option;
	available_option_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	enabled: Scalars['Boolean'];
	id: Scalars['uuid'];
	label: Scalars['String'];
	order: Scalars['Int'];
	/** An array relationship */
	order_item_options: Array<Order_Item_Option>;
	/** An aggregate relationship */
	order_item_options_aggregate: Order_Item_Option_Aggregate;
	price_modifier: Scalars['Int'];
	price_modifier_production: Scalars['Int'];
	store_default_enabled: Scalars['Boolean'];
	/** An array relationship */
	store_product_has_options: Array<Store_Product_Has_Options>;
	/** An aggregate relationship */
	store_product_has_options_aggregate: Store_Product_Has_Options_Aggregate;
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "available_option_value" */
export type Available_Option_ValueOrder_Item_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

/** columns and relationships of "available_option_value" */
export type Available_Option_ValueOrder_Item_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

/** columns and relationships of "available_option_value" */
export type Available_Option_ValueStore_Product_Has_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

/** columns and relationships of "available_option_value" */
export type Available_Option_ValueStore_Product_Has_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

/** aggregated selection of "available_option_value" */
export type Available_Option_Value_Aggregate = {
	__typename?: 'available_option_value_aggregate';
	aggregate?: Maybe<Available_Option_Value_Aggregate_Fields>;
	nodes: Array<Available_Option_Value>;
};

export type Available_Option_Value_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Available_Option_Value_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Available_Option_Value_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Available_Option_Value_Aggregate_Bool_Exp_Count>;
};

export type Available_Option_Value_Aggregate_Bool_Exp_Bool_And = {
	arguments: Available_Option_Value_Select_Column_Available_Option_Value_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Available_Option_Value_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Available_Option_Value_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Available_Option_Value_Select_Column_Available_Option_Value_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Available_Option_Value_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Available_Option_Value_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Available_Option_Value_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Available_Option_Value_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "available_option_value" */
export type Available_Option_Value_Aggregate_Fields = {
	__typename?: 'available_option_value_aggregate_fields';
	avg?: Maybe<Available_Option_Value_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Available_Option_Value_Max_Fields>;
	min?: Maybe<Available_Option_Value_Min_Fields>;
	stddev?: Maybe<Available_Option_Value_Stddev_Fields>;
	stddev_pop?: Maybe<Available_Option_Value_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Available_Option_Value_Stddev_Samp_Fields>;
	sum?: Maybe<Available_Option_Value_Sum_Fields>;
	var_pop?: Maybe<Available_Option_Value_Var_Pop_Fields>;
	var_samp?: Maybe<Available_Option_Value_Var_Samp_Fields>;
	variance?: Maybe<Available_Option_Value_Variance_Fields>;
};

/** aggregate fields of "available_option_value" */
export type Available_Option_Value_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Available_Option_Value_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "available_option_value" */
export type Available_Option_Value_Aggregate_Order_By = {
	avg?: InputMaybe<Available_Option_Value_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Available_Option_Value_Max_Order_By>;
	min?: InputMaybe<Available_Option_Value_Min_Order_By>;
	stddev?: InputMaybe<Available_Option_Value_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Available_Option_Value_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Available_Option_Value_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Available_Option_Value_Sum_Order_By>;
	var_pop?: InputMaybe<Available_Option_Value_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Available_Option_Value_Var_Samp_Order_By>;
	variance?: InputMaybe<Available_Option_Value_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "available_option_value" */
export type Available_Option_Value_Arr_Rel_Insert_Input = {
	data: Array<Available_Option_Value_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Available_Option_Value_On_Conflict>;
};

/** aggregate avg on columns */
export type Available_Option_Value_Avg_Fields = {
	__typename?: 'available_option_value_avg_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier?: Maybe<Scalars['Float']>;
	price_modifier_production?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "available_option_value" */
export type Available_Option_Value_Avg_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "available_option_value". All fields are combined with a logical 'AND'. */
export type Available_Option_Value_Bool_Exp = {
	_and?: InputMaybe<Array<Available_Option_Value_Bool_Exp>>;
	_not?: InputMaybe<Available_Option_Value_Bool_Exp>;
	_or?: InputMaybe<Array<Available_Option_Value_Bool_Exp>>;
	archived?: InputMaybe<Boolean_Comparison_Exp>;
	available_option?: InputMaybe<Available_Option_Bool_Exp>;
	available_option_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	enabled?: InputMaybe<Boolean_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	label?: InputMaybe<String_Comparison_Exp>;
	order?: InputMaybe<Int_Comparison_Exp>;
	order_item_options?: InputMaybe<Order_Item_Option_Bool_Exp>;
	order_item_options_aggregate?: InputMaybe<Order_Item_Option_Aggregate_Bool_Exp>;
	price_modifier?: InputMaybe<Int_Comparison_Exp>;
	price_modifier_production?: InputMaybe<Int_Comparison_Exp>;
	store_default_enabled?: InputMaybe<Boolean_Comparison_Exp>;
	store_product_has_options?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
	store_product_has_options_aggregate?: InputMaybe<Store_Product_Has_Options_Aggregate_Bool_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "available_option_value" */
export enum Available_Option_Value_Constraint {
	/** unique or primary key constraint on columns "id" */
	AvailableOptionValuePkey = 'available_option_value_pkey'
}

/** input type for incrementing numeric columns in table "available_option_value" */
export type Available_Option_Value_Inc_Input = {
	order?: InputMaybe<Scalars['Int']>;
	price_modifier?: InputMaybe<Scalars['Int']>;
	price_modifier_production?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "available_option_value" */
export type Available_Option_Value_Insert_Input = {
	archived?: InputMaybe<Scalars['Boolean']>;
	available_option?: InputMaybe<Available_Option_Obj_Rel_Insert_Input>;
	available_option_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	order_item_options?: InputMaybe<Order_Item_Option_Arr_Rel_Insert_Input>;
	price_modifier?: InputMaybe<Scalars['Int']>;
	price_modifier_production?: InputMaybe<Scalars['Int']>;
	store_default_enabled?: InputMaybe<Scalars['Boolean']>;
	store_product_has_options?: InputMaybe<Store_Product_Has_Options_Arr_Rel_Insert_Input>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Available_Option_Value_Max_Fields = {
	__typename?: 'available_option_value_max_fields';
	available_option_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	price_modifier?: Maybe<Scalars['Int']>;
	price_modifier_production?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "available_option_value" */
export type Available_Option_Value_Max_Order_By = {
	available_option_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Available_Option_Value_Min_Fields = {
	__typename?: 'available_option_value_min_fields';
	available_option_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	price_modifier?: Maybe<Scalars['Int']>;
	price_modifier_production?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "available_option_value" */
export type Available_Option_Value_Min_Order_By = {
	available_option_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "available_option_value" */
export type Available_Option_Value_Mutation_Response = {
	__typename?: 'available_option_value_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Available_Option_Value>;
};

/** input type for inserting object relation for remote table "available_option_value" */
export type Available_Option_Value_Obj_Rel_Insert_Input = {
	data: Available_Option_Value_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Available_Option_Value_On_Conflict>;
};

/** on_conflict condition type for table "available_option_value" */
export type Available_Option_Value_On_Conflict = {
	constraint: Available_Option_Value_Constraint;
	update_columns?: Array<Available_Option_Value_Update_Column>;
	where?: InputMaybe<Available_Option_Value_Bool_Exp>;
};

/** Ordering options when selecting data from "available_option_value". */
export type Available_Option_Value_Order_By = {
	archived?: InputMaybe<Order_By>;
	available_option?: InputMaybe<Available_Option_Order_By>;
	available_option_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	enabled?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	order_item_options_aggregate?: InputMaybe<Order_Item_Option_Aggregate_Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
	store_default_enabled?: InputMaybe<Order_By>;
	store_product_has_options_aggregate?: InputMaybe<Store_Product_Has_Options_Aggregate_Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: available_option_value */
export type Available_Option_Value_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "available_option_value" */
export enum Available_Option_Value_Select_Column {
	/** column name */
	Archived = 'archived',
	/** column name */
	AvailableOptionId = 'available_option_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	Id = 'id',
	/** column name */
	Label = 'label',
	/** column name */
	Order = 'order',
	/** column name */
	PriceModifier = 'price_modifier',
	/** column name */
	PriceModifierProduction = 'price_modifier_production',
	/** column name */
	StoreDefaultEnabled = 'store_default_enabled',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** select "available_option_value_aggregate_bool_exp_bool_and_arguments_columns" columns of table "available_option_value" */
export enum Available_Option_Value_Select_Column_Available_Option_Value_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	Archived = 'archived',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	StoreDefaultEnabled = 'store_default_enabled'
}

/** select "available_option_value_aggregate_bool_exp_bool_or_arguments_columns" columns of table "available_option_value" */
export enum Available_Option_Value_Select_Column_Available_Option_Value_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	Archived = 'archived',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	StoreDefaultEnabled = 'store_default_enabled'
}

/** input type for updating data in table "available_option_value" */
export type Available_Option_Value_Set_Input = {
	archived?: InputMaybe<Scalars['Boolean']>;
	available_option_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	price_modifier?: InputMaybe<Scalars['Int']>;
	price_modifier_production?: InputMaybe<Scalars['Int']>;
	store_default_enabled?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Available_Option_Value_Stddev_Fields = {
	__typename?: 'available_option_value_stddev_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier?: Maybe<Scalars['Float']>;
	price_modifier_production?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "available_option_value" */
export type Available_Option_Value_Stddev_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Available_Option_Value_Stddev_Pop_Fields = {
	__typename?: 'available_option_value_stddev_pop_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier?: Maybe<Scalars['Float']>;
	price_modifier_production?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "available_option_value" */
export type Available_Option_Value_Stddev_Pop_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Available_Option_Value_Stddev_Samp_Fields = {
	__typename?: 'available_option_value_stddev_samp_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier?: Maybe<Scalars['Float']>;
	price_modifier_production?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "available_option_value" */
export type Available_Option_Value_Stddev_Samp_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "available_option_value" */
export type Available_Option_Value_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Available_Option_Value_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Available_Option_Value_Stream_Cursor_Value_Input = {
	archived?: InputMaybe<Scalars['Boolean']>;
	available_option_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	price_modifier?: InputMaybe<Scalars['Int']>;
	price_modifier_production?: InputMaybe<Scalars['Int']>;
	store_default_enabled?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Available_Option_Value_Sum_Fields = {
	__typename?: 'available_option_value_sum_fields';
	order?: Maybe<Scalars['Int']>;
	price_modifier?: Maybe<Scalars['Int']>;
	price_modifier_production?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "available_option_value" */
export type Available_Option_Value_Sum_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
};

/** update columns of table "available_option_value" */
export enum Available_Option_Value_Update_Column {
	/** column name */
	Archived = 'archived',
	/** column name */
	AvailableOptionId = 'available_option_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	Id = 'id',
	/** column name */
	Label = 'label',
	/** column name */
	Order = 'order',
	/** column name */
	PriceModifier = 'price_modifier',
	/** column name */
	PriceModifierProduction = 'price_modifier_production',
	/** column name */
	StoreDefaultEnabled = 'store_default_enabled',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Available_Option_Value_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Available_Option_Value_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Available_Option_Value_Set_Input>;
	/** filter the rows which have to be updated */
	where: Available_Option_Value_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Available_Option_Value_Var_Pop_Fields = {
	__typename?: 'available_option_value_var_pop_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier?: Maybe<Scalars['Float']>;
	price_modifier_production?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "available_option_value" */
export type Available_Option_Value_Var_Pop_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Available_Option_Value_Var_Samp_Fields = {
	__typename?: 'available_option_value_var_samp_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier?: Maybe<Scalars['Float']>;
	price_modifier_production?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "available_option_value" */
export type Available_Option_Value_Var_Samp_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Available_Option_Value_Variance_Fields = {
	__typename?: 'available_option_value_variance_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier?: Maybe<Scalars['Float']>;
	price_modifier_production?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "available_option_value" */
export type Available_Option_Value_Variance_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	price_modifier_production?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
	/** ascending ordering of the cursor */
	Asc = 'ASC',
	/** descending ordering of the cursor */
	Desc = 'DESC'
}

/** columns and relationships of "custom_link_purchase" */
export type Custom_Link_Purchase = {
	__typename?: 'custom_link_purchase';
	created_at: Scalars['timestamptz'];
	customer_email: Scalars['String'];
	customer_name: Scalars['String'];
	id: Scalars['uuid'];
	purchase_price: Scalars['Int'];
	stripe_product_id: Scalars['String'];
	stripe_transaction_id: Scalars['String'];
	updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "custom_link_purchase" */
export type Custom_Link_Purchase_Aggregate = {
	__typename?: 'custom_link_purchase_aggregate';
	aggregate?: Maybe<Custom_Link_Purchase_Aggregate_Fields>;
	nodes: Array<Custom_Link_Purchase>;
};

/** aggregate fields of "custom_link_purchase" */
export type Custom_Link_Purchase_Aggregate_Fields = {
	__typename?: 'custom_link_purchase_aggregate_fields';
	avg?: Maybe<Custom_Link_Purchase_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Custom_Link_Purchase_Max_Fields>;
	min?: Maybe<Custom_Link_Purchase_Min_Fields>;
	stddev?: Maybe<Custom_Link_Purchase_Stddev_Fields>;
	stddev_pop?: Maybe<Custom_Link_Purchase_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Custom_Link_Purchase_Stddev_Samp_Fields>;
	sum?: Maybe<Custom_Link_Purchase_Sum_Fields>;
	var_pop?: Maybe<Custom_Link_Purchase_Var_Pop_Fields>;
	var_samp?: Maybe<Custom_Link_Purchase_Var_Samp_Fields>;
	variance?: Maybe<Custom_Link_Purchase_Variance_Fields>;
};

/** aggregate fields of "custom_link_purchase" */
export type Custom_Link_Purchase_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Custom_Link_Purchase_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Custom_Link_Purchase_Avg_Fields = {
	__typename?: 'custom_link_purchase_avg_fields';
	purchase_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "custom_link_purchase". All fields are combined with a logical 'AND'. */
export type Custom_Link_Purchase_Bool_Exp = {
	_and?: InputMaybe<Array<Custom_Link_Purchase_Bool_Exp>>;
	_not?: InputMaybe<Custom_Link_Purchase_Bool_Exp>;
	_or?: InputMaybe<Array<Custom_Link_Purchase_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	customer_email?: InputMaybe<String_Comparison_Exp>;
	customer_name?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	purchase_price?: InputMaybe<Int_Comparison_Exp>;
	stripe_product_id?: InputMaybe<String_Comparison_Exp>;
	stripe_transaction_id?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "custom_link_purchase" */
export enum Custom_Link_Purchase_Constraint {
	/** unique or primary key constraint on columns "id" */
	CustomLinkPurchasePkey = 'custom_link_purchase_pkey'
}

/** input type for incrementing numeric columns in table "custom_link_purchase" */
export type Custom_Link_Purchase_Inc_Input = {
	purchase_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "custom_link_purchase" */
export type Custom_Link_Purchase_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	customer_email?: InputMaybe<Scalars['String']>;
	customer_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	purchase_price?: InputMaybe<Scalars['Int']>;
	stripe_product_id?: InputMaybe<Scalars['String']>;
	stripe_transaction_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Custom_Link_Purchase_Max_Fields = {
	__typename?: 'custom_link_purchase_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	customer_email?: Maybe<Scalars['String']>;
	customer_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	purchase_price?: Maybe<Scalars['Int']>;
	stripe_product_id?: Maybe<Scalars['String']>;
	stripe_transaction_id?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Custom_Link_Purchase_Min_Fields = {
	__typename?: 'custom_link_purchase_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	customer_email?: Maybe<Scalars['String']>;
	customer_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	purchase_price?: Maybe<Scalars['Int']>;
	stripe_product_id?: Maybe<Scalars['String']>;
	stripe_transaction_id?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "custom_link_purchase" */
export type Custom_Link_Purchase_Mutation_Response = {
	__typename?: 'custom_link_purchase_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Custom_Link_Purchase>;
};

/** on_conflict condition type for table "custom_link_purchase" */
export type Custom_Link_Purchase_On_Conflict = {
	constraint: Custom_Link_Purchase_Constraint;
	update_columns?: Array<Custom_Link_Purchase_Update_Column>;
	where?: InputMaybe<Custom_Link_Purchase_Bool_Exp>;
};

/** Ordering options when selecting data from "custom_link_purchase". */
export type Custom_Link_Purchase_Order_By = {
	created_at?: InputMaybe<Order_By>;
	customer_email?: InputMaybe<Order_By>;
	customer_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	purchase_price?: InputMaybe<Order_By>;
	stripe_product_id?: InputMaybe<Order_By>;
	stripe_transaction_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: custom_link_purchase */
export type Custom_Link_Purchase_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "custom_link_purchase" */
export enum Custom_Link_Purchase_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomerEmail = 'customer_email',
	/** column name */
	CustomerName = 'customer_name',
	/** column name */
	Id = 'id',
	/** column name */
	PurchasePrice = 'purchase_price',
	/** column name */
	StripeProductId = 'stripe_product_id',
	/** column name */
	StripeTransactionId = 'stripe_transaction_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "custom_link_purchase" */
export type Custom_Link_Purchase_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	customer_email?: InputMaybe<Scalars['String']>;
	customer_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	purchase_price?: InputMaybe<Scalars['Int']>;
	stripe_product_id?: InputMaybe<Scalars['String']>;
	stripe_transaction_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Custom_Link_Purchase_Stddev_Fields = {
	__typename?: 'custom_link_purchase_stddev_fields';
	purchase_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Custom_Link_Purchase_Stddev_Pop_Fields = {
	__typename?: 'custom_link_purchase_stddev_pop_fields';
	purchase_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Custom_Link_Purchase_Stddev_Samp_Fields = {
	__typename?: 'custom_link_purchase_stddev_samp_fields';
	purchase_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "custom_link_purchase" */
export type Custom_Link_Purchase_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Custom_Link_Purchase_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Link_Purchase_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	customer_email?: InputMaybe<Scalars['String']>;
	customer_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	purchase_price?: InputMaybe<Scalars['Int']>;
	stripe_product_id?: InputMaybe<Scalars['String']>;
	stripe_transaction_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Custom_Link_Purchase_Sum_Fields = {
	__typename?: 'custom_link_purchase_sum_fields';
	purchase_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "custom_link_purchase" */
export enum Custom_Link_Purchase_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomerEmail = 'customer_email',
	/** column name */
	CustomerName = 'customer_name',
	/** column name */
	Id = 'id',
	/** column name */
	PurchasePrice = 'purchase_price',
	/** column name */
	StripeProductId = 'stripe_product_id',
	/** column name */
	StripeTransactionId = 'stripe_transaction_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Custom_Link_Purchase_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Custom_Link_Purchase_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Custom_Link_Purchase_Set_Input>;
	/** filter the rows which have to be updated */
	where: Custom_Link_Purchase_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Custom_Link_Purchase_Var_Pop_Fields = {
	__typename?: 'custom_link_purchase_var_pop_fields';
	purchase_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Custom_Link_Purchase_Var_Samp_Fields = {
	__typename?: 'custom_link_purchase_var_samp_fields';
	purchase_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Custom_Link_Purchase_Variance_Fields = {
	__typename?: 'custom_link_purchase_variance_fields';
	purchase_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['date']>;
	_gt?: InputMaybe<Scalars['date']>;
	_gte?: InputMaybe<Scalars['date']>;
	_in?: InputMaybe<Array<Scalars['date']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['date']>;
	_lte?: InputMaybe<Scalars['date']>;
	_neq?: InputMaybe<Scalars['date']>;
	_nin?: InputMaybe<Array<Scalars['date']>>;
};

export type Jsonb_Cast_Exp = {
	String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
	_cast?: InputMaybe<Jsonb_Cast_Exp>;
	/** is the column contained in the given json value */
	_contained_in?: InputMaybe<Scalars['jsonb']>;
	/** does the column contain the given json value at the top level */
	_contains?: InputMaybe<Scalars['jsonb']>;
	_eq?: InputMaybe<Scalars['jsonb']>;
	_gt?: InputMaybe<Scalars['jsonb']>;
	_gte?: InputMaybe<Scalars['jsonb']>;
	/** does the string exist as a top-level key in the column */
	_has_key?: InputMaybe<Scalars['String']>;
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?: InputMaybe<Array<Scalars['String']>>;
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?: InputMaybe<Array<Scalars['String']>>;
	_in?: InputMaybe<Array<Scalars['jsonb']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['jsonb']>;
	_lte?: InputMaybe<Scalars['jsonb']>;
	_neq?: InputMaybe<Scalars['jsonb']>;
	_nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "leads" */
export type Leads = {
	__typename?: 'leads';
	comments?: Maybe<Scalars['String']>;
	created_at: Scalars['timestamptz'];
	design_url?: Maybe<Scalars['String']>;
	email: Scalars['String'];
	first_name: Scalars['String'];
	id: Scalars['uuid'];
	last_name: Scalars['String'];
	needed_by?: Maybe<Scalars['date']>;
	phone?: Maybe<Scalars['String']>;
	product_id: Scalars['String'];
	secondary_design_url?: Maybe<Scalars['String']>;
	team_affiliation: Scalars['String'];
	updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "leads" */
export type Leads_Aggregate = {
	__typename?: 'leads_aggregate';
	aggregate?: Maybe<Leads_Aggregate_Fields>;
	nodes: Array<Leads>;
};

/** aggregate fields of "leads" */
export type Leads_Aggregate_Fields = {
	__typename?: 'leads_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<Leads_Max_Fields>;
	min?: Maybe<Leads_Min_Fields>;
};

/** aggregate fields of "leads" */
export type Leads_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Leads_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "leads". All fields are combined with a logical 'AND'. */
export type Leads_Bool_Exp = {
	_and?: InputMaybe<Array<Leads_Bool_Exp>>;
	_not?: InputMaybe<Leads_Bool_Exp>;
	_or?: InputMaybe<Array<Leads_Bool_Exp>>;
	comments?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	design_url?: InputMaybe<String_Comparison_Exp>;
	email?: InputMaybe<String_Comparison_Exp>;
	first_name?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	last_name?: InputMaybe<String_Comparison_Exp>;
	needed_by?: InputMaybe<Date_Comparison_Exp>;
	phone?: InputMaybe<String_Comparison_Exp>;
	product_id?: InputMaybe<String_Comparison_Exp>;
	secondary_design_url?: InputMaybe<String_Comparison_Exp>;
	team_affiliation?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "leads" */
export enum Leads_Constraint {
	/** unique or primary key constraint on columns "id" */
	LeadsPkey = 'leads_pkey'
}

/** input type for inserting data into table "leads" */
export type Leads_Insert_Input = {
	comments?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	design_url?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	last_name?: InputMaybe<Scalars['String']>;
	needed_by?: InputMaybe<Scalars['date']>;
	phone?: InputMaybe<Scalars['String']>;
	product_id?: InputMaybe<Scalars['String']>;
	secondary_design_url?: InputMaybe<Scalars['String']>;
	team_affiliation?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Leads_Max_Fields = {
	__typename?: 'leads_max_fields';
	comments?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	design_url?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	first_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	last_name?: Maybe<Scalars['String']>;
	needed_by?: Maybe<Scalars['date']>;
	phone?: Maybe<Scalars['String']>;
	product_id?: Maybe<Scalars['String']>;
	secondary_design_url?: Maybe<Scalars['String']>;
	team_affiliation?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Leads_Min_Fields = {
	__typename?: 'leads_min_fields';
	comments?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	design_url?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	first_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	last_name?: Maybe<Scalars['String']>;
	needed_by?: Maybe<Scalars['date']>;
	phone?: Maybe<Scalars['String']>;
	product_id?: Maybe<Scalars['String']>;
	secondary_design_url?: Maybe<Scalars['String']>;
	team_affiliation?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "leads" */
export type Leads_Mutation_Response = {
	__typename?: 'leads_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Leads>;
};

/** on_conflict condition type for table "leads" */
export type Leads_On_Conflict = {
	constraint: Leads_Constraint;
	update_columns?: Array<Leads_Update_Column>;
	where?: InputMaybe<Leads_Bool_Exp>;
};

/** Ordering options when selecting data from "leads". */
export type Leads_Order_By = {
	comments?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	design_url?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	first_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	last_name?: InputMaybe<Order_By>;
	needed_by?: InputMaybe<Order_By>;
	phone?: InputMaybe<Order_By>;
	product_id?: InputMaybe<Order_By>;
	secondary_design_url?: InputMaybe<Order_By>;
	team_affiliation?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leads */
export type Leads_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "leads" */
export enum Leads_Select_Column {
	/** column name */
	Comments = 'comments',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DesignUrl = 'design_url',
	/** column name */
	Email = 'email',
	/** column name */
	FirstName = 'first_name',
	/** column name */
	Id = 'id',
	/** column name */
	LastName = 'last_name',
	/** column name */
	NeededBy = 'needed_by',
	/** column name */
	Phone = 'phone',
	/** column name */
	ProductId = 'product_id',
	/** column name */
	SecondaryDesignUrl = 'secondary_design_url',
	/** column name */
	TeamAffiliation = 'team_affiliation',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "leads" */
export type Leads_Set_Input = {
	comments?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	design_url?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	last_name?: InputMaybe<Scalars['String']>;
	needed_by?: InputMaybe<Scalars['date']>;
	phone?: InputMaybe<Scalars['String']>;
	product_id?: InputMaybe<Scalars['String']>;
	secondary_design_url?: InputMaybe<Scalars['String']>;
	team_affiliation?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "leads" */
export type Leads_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Leads_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leads_Stream_Cursor_Value_Input = {
	comments?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	design_url?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	last_name?: InputMaybe<Scalars['String']>;
	needed_by?: InputMaybe<Scalars['date']>;
	phone?: InputMaybe<Scalars['String']>;
	product_id?: InputMaybe<Scalars['String']>;
	secondary_design_url?: InputMaybe<Scalars['String']>;
	team_affiliation?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "leads" */
export enum Leads_Update_Column {
	/** column name */
	Comments = 'comments',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DesignUrl = 'design_url',
	/** column name */
	Email = 'email',
	/** column name */
	FirstName = 'first_name',
	/** column name */
	Id = 'id',
	/** column name */
	LastName = 'last_name',
	/** column name */
	NeededBy = 'needed_by',
	/** column name */
	Phone = 'phone',
	/** column name */
	ProductId = 'product_id',
	/** column name */
	SecondaryDesignUrl = 'secondary_design_url',
	/** column name */
	TeamAffiliation = 'team_affiliation',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Leads_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Leads_Set_Input>;
	/** filter the rows which have to be updated */
	where: Leads_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
	__typename?: 'mutation_root';
	addStoreProduct?: Maybe<SampleOutput>;
	/** execute VOLATILE function "add_user_role_by_name" which returns "user_roles" */
	add_user_role_by_name: Array<User_Roles>;
	authLogin?: Maybe<LoginOutput>;
	/** send the passwordless login token to the email */
	authSend?: Maybe<SendOutput>;
	cancelOrderItem?: Maybe<CreateReturn>;
	createCheckout?: Maybe<UrlOutput>;
	createCustomLink?: Maybe<CustomLinkReturn>;
	/** Create Demo Store */
	createDemoStore?: Maybe<UrlOutput>;
	createMultipleShippingLabels?: Maybe<AsyncActionReturn>;
	createNewLead?: Maybe<LeadOutput>;
	/** createNewReceiptForOrder */
	createNewReceiptForOrder?: Maybe<SavedUrlOutput>;
	createNewShippingLabel?: Maybe<ShippingLabelOutput>;
	createProductFromSignedURL?: Maybe<SignedUrlData>;
	createSignedURL?: Maybe<SignedUrlData>;
	createStripeProduct?: Maybe<CreateReturn>;
	/** Create a batch email for a team store. */
	createTeamStoreEmail?: Maybe<CreateReturn>;
	/** delete data from the table: "async_action" */
	delete_async_action?: Maybe<Async_Action_Mutation_Response>;
	/** delete single row from the table: "async_action" */
	delete_async_action_by_pk?: Maybe<Async_Action>;
	/** delete data from the table: "available_option" */
	delete_available_option?: Maybe<Available_Option_Mutation_Response>;
	/** delete single row from the table: "available_option" */
	delete_available_option_by_pk?: Maybe<Available_Option>;
	/** delete data from the table: "available_option_value" */
	delete_available_option_value?: Maybe<Available_Option_Value_Mutation_Response>;
	/** delete single row from the table: "available_option_value" */
	delete_available_option_value_by_pk?: Maybe<Available_Option_Value>;
	/** delete data from the table: "custom_link_purchase" */
	delete_custom_link_purchase?: Maybe<Custom_Link_Purchase_Mutation_Response>;
	/** delete single row from the table: "custom_link_purchase" */
	delete_custom_link_purchase_by_pk?: Maybe<Custom_Link_Purchase>;
	/** delete data from the table: "leads" */
	delete_leads?: Maybe<Leads_Mutation_Response>;
	/** delete single row from the table: "leads" */
	delete_leads_by_pk?: Maybe<Leads>;
	/** delete data from the table: "order" */
	delete_order?: Maybe<Order_Mutation_Response>;
	/** delete single row from the table: "order" */
	delete_order_by_pk?: Maybe<Order>;
	/** delete data from the table: "order_item" */
	delete_order_item?: Maybe<Order_Item_Mutation_Response>;
	/** delete single row from the table: "order_item" */
	delete_order_item_by_pk?: Maybe<Order_Item>;
	/** delete data from the table: "order_item_option" */
	delete_order_item_option?: Maybe<Order_Item_Option_Mutation_Response>;
	/** delete single row from the table: "order_item_option" */
	delete_order_item_option_by_pk?: Maybe<Order_Item_Option>;
	/** delete data from the table: "order_package" */
	delete_order_package?: Maybe<Order_Package_Mutation_Response>;
	/** delete single row from the table: "order_package" */
	delete_order_package_by_pk?: Maybe<Order_Package>;
	/** delete data from the table: "order_shipping_details" */
	delete_order_shipping_details?: Maybe<Order_Shipping_Details_Mutation_Response>;
	/** delete single row from the table: "order_shipping_details" */
	delete_order_shipping_details_by_pk?: Maybe<Order_Shipping_Details>;
	/** delete data from the table: "order_status_history" */
	delete_order_status_history?: Maybe<Order_Status_History_Mutation_Response>;
	/** delete single row from the table: "order_status_history" */
	delete_order_status_history_by_pk?: Maybe<Order_Status_History>;
	/** delete data from the table: "product" */
	delete_product?: Maybe<Product_Mutation_Response>;
	/** delete single row from the table: "product" */
	delete_product_by_pk?: Maybe<Product>;
	/** delete data from the table: "product_has_available_option" */
	delete_product_has_available_option?: Maybe<Product_Has_Available_Option_Mutation_Response>;
	/** delete single row from the table: "product_has_available_option" */
	delete_product_has_available_option_by_pk?: Maybe<Product_Has_Available_Option>;
	/** delete data from the table: "roles" */
	delete_roles?: Maybe<Roles_Mutation_Response>;
	/** delete single row from the table: "roles" */
	delete_roles_by_pk?: Maybe<Roles>;
	/** delete data from the table: "showcase" */
	delete_showcase?: Maybe<Showcase_Mutation_Response>;
	/** delete single row from the table: "showcase" */
	delete_showcase_by_pk?: Maybe<Showcase>;
	/** delete data from the table: "showcase_category" */
	delete_showcase_category?: Maybe<Showcase_Category_Mutation_Response>;
	/** delete single row from the table: "showcase_category" */
	delete_showcase_category_by_pk?: Maybe<Showcase_Category>;
	/** delete data from the table: "store_package" */
	delete_store_package?: Maybe<Store_Package_Mutation_Response>;
	/** delete single row from the table: "store_package" */
	delete_store_package_by_pk?: Maybe<Store_Package>;
	/** delete data from the table: "store_product" */
	delete_store_product?: Maybe<Store_Product_Mutation_Response>;
	/** delete single row from the table: "store_product" */
	delete_store_product_by_pk?: Maybe<Store_Product>;
	/** delete data from the table: "store_product_has_options" */
	delete_store_product_has_options?: Maybe<Store_Product_Has_Options_Mutation_Response>;
	/** delete single row from the table: "store_product_has_options" */
	delete_store_product_has_options_by_pk?: Maybe<Store_Product_Has_Options>;
	/** delete data from the table: "support" */
	delete_support?: Maybe<Support_Mutation_Response>;
	/** delete single row from the table: "support" */
	delete_support_by_pk?: Maybe<Support>;
	/** delete data from the table: "team" */
	delete_team?: Maybe<Team_Mutation_Response>;
	/** delete single row from the table: "team" */
	delete_team_by_pk?: Maybe<Team>;
	/** delete data from the table: "team_store" */
	delete_team_store?: Maybe<Team_Store_Mutation_Response>;
	/** delete single row from the table: "team_store" */
	delete_team_store_by_pk?: Maybe<Team_Store>;
	/** delete data from the table: "team_store_email_log" */
	delete_team_store_email_log?: Maybe<Team_Store_Email_Log_Mutation_Response>;
	/** delete single row from the table: "team_store_email_log" */
	delete_team_store_email_log_by_pk?: Maybe<Team_Store_Email_Log>;
	/** delete data from the table: "team_store_order_email_log" */
	delete_team_store_order_email_log?: Maybe<Team_Store_Order_Email_Log_Mutation_Response>;
	/** delete single row from the table: "team_store_order_email_log" */
	delete_team_store_order_email_log_by_pk?: Maybe<Team_Store_Order_Email_Log>;
	/** delete data from the table: "user" */
	delete_user?: Maybe<User_Mutation_Response>;
	/** delete single row from the table: "user" */
	delete_user_by_pk?: Maybe<User>;
	/** delete data from the table: "user_roles" */
	delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
	/** delete single row from the table: "user_roles" */
	delete_user_roles_by_pk?: Maybe<User_Roles>;
	/** delete data from the table: "user_token" */
	delete_user_token?: Maybe<User_Token_Mutation_Response>;
	/** delete single row from the table: "user_token" */
	delete_user_token_by_pk?: Maybe<User_Token>;
	duplicateTeamStore?: Maybe<DuplicateTeamStoreReturn>;
	/** insert data into the table: "async_action" */
	insert_async_action?: Maybe<Async_Action_Mutation_Response>;
	/** insert a single row into the table: "async_action" */
	insert_async_action_one?: Maybe<Async_Action>;
	/** insert data into the table: "available_option" */
	insert_available_option?: Maybe<Available_Option_Mutation_Response>;
	/** insert a single row into the table: "available_option" */
	insert_available_option_one?: Maybe<Available_Option>;
	/** insert data into the table: "available_option_value" */
	insert_available_option_value?: Maybe<Available_Option_Value_Mutation_Response>;
	/** insert a single row into the table: "available_option_value" */
	insert_available_option_value_one?: Maybe<Available_Option_Value>;
	/** insert data into the table: "custom_link_purchase" */
	insert_custom_link_purchase?: Maybe<Custom_Link_Purchase_Mutation_Response>;
	/** insert a single row into the table: "custom_link_purchase" */
	insert_custom_link_purchase_one?: Maybe<Custom_Link_Purchase>;
	/** insert data into the table: "leads" */
	insert_leads?: Maybe<Leads_Mutation_Response>;
	/** insert a single row into the table: "leads" */
	insert_leads_one?: Maybe<Leads>;
	/** insert data into the table: "order" */
	insert_order?: Maybe<Order_Mutation_Response>;
	/** insert data into the table: "order_item" */
	insert_order_item?: Maybe<Order_Item_Mutation_Response>;
	/** insert a single row into the table: "order_item" */
	insert_order_item_one?: Maybe<Order_Item>;
	/** insert data into the table: "order_item_option" */
	insert_order_item_option?: Maybe<Order_Item_Option_Mutation_Response>;
	/** insert a single row into the table: "order_item_option" */
	insert_order_item_option_one?: Maybe<Order_Item_Option>;
	/** insert a single row into the table: "order" */
	insert_order_one?: Maybe<Order>;
	/** insert data into the table: "order_package" */
	insert_order_package?: Maybe<Order_Package_Mutation_Response>;
	/** insert a single row into the table: "order_package" */
	insert_order_package_one?: Maybe<Order_Package>;
	/** insert data into the table: "order_shipping_details" */
	insert_order_shipping_details?: Maybe<Order_Shipping_Details_Mutation_Response>;
	/** insert a single row into the table: "order_shipping_details" */
	insert_order_shipping_details_one?: Maybe<Order_Shipping_Details>;
	/** insert data into the table: "order_status_history" */
	insert_order_status_history?: Maybe<Order_Status_History_Mutation_Response>;
	/** insert a single row into the table: "order_status_history" */
	insert_order_status_history_one?: Maybe<Order_Status_History>;
	/** insert data into the table: "product" */
	insert_product?: Maybe<Product_Mutation_Response>;
	/** insert data into the table: "product_has_available_option" */
	insert_product_has_available_option?: Maybe<Product_Has_Available_Option_Mutation_Response>;
	/** insert a single row into the table: "product_has_available_option" */
	insert_product_has_available_option_one?: Maybe<Product_Has_Available_Option>;
	/** insert a single row into the table: "product" */
	insert_product_one?: Maybe<Product>;
	/** insert data into the table: "roles" */
	insert_roles?: Maybe<Roles_Mutation_Response>;
	/** insert a single row into the table: "roles" */
	insert_roles_one?: Maybe<Roles>;
	/** insert data into the table: "showcase" */
	insert_showcase?: Maybe<Showcase_Mutation_Response>;
	/** insert data into the table: "showcase_category" */
	insert_showcase_category?: Maybe<Showcase_Category_Mutation_Response>;
	/** insert a single row into the table: "showcase_category" */
	insert_showcase_category_one?: Maybe<Showcase_Category>;
	/** insert a single row into the table: "showcase" */
	insert_showcase_one?: Maybe<Showcase>;
	/** insert data into the table: "store_package" */
	insert_store_package?: Maybe<Store_Package_Mutation_Response>;
	/** insert a single row into the table: "store_package" */
	insert_store_package_one?: Maybe<Store_Package>;
	/** insert data into the table: "store_product" */
	insert_store_product?: Maybe<Store_Product_Mutation_Response>;
	/** insert data into the table: "store_product_has_options" */
	insert_store_product_has_options?: Maybe<Store_Product_Has_Options_Mutation_Response>;
	/** insert a single row into the table: "store_product_has_options" */
	insert_store_product_has_options_one?: Maybe<Store_Product_Has_Options>;
	/** insert a single row into the table: "store_product" */
	insert_store_product_one?: Maybe<Store_Product>;
	/** insert data into the table: "support" */
	insert_support?: Maybe<Support_Mutation_Response>;
	/** insert a single row into the table: "support" */
	insert_support_one?: Maybe<Support>;
	/** insert data into the table: "team" */
	insert_team?: Maybe<Team_Mutation_Response>;
	/** insert a single row into the table: "team" */
	insert_team_one?: Maybe<Team>;
	/** insert data into the table: "team_store" */
	insert_team_store?: Maybe<Team_Store_Mutation_Response>;
	/** insert data into the table: "team_store_email_log" */
	insert_team_store_email_log?: Maybe<Team_Store_Email_Log_Mutation_Response>;
	/** insert a single row into the table: "team_store_email_log" */
	insert_team_store_email_log_one?: Maybe<Team_Store_Email_Log>;
	/** insert a single row into the table: "team_store" */
	insert_team_store_one?: Maybe<Team_Store>;
	/** insert data into the table: "team_store_order_email_log" */
	insert_team_store_order_email_log?: Maybe<Team_Store_Order_Email_Log_Mutation_Response>;
	/** insert a single row into the table: "team_store_order_email_log" */
	insert_team_store_order_email_log_one?: Maybe<Team_Store_Order_Email_Log>;
	/** insert data into the table: "user" */
	insert_user?: Maybe<User_Mutation_Response>;
	/** insert a single row into the table: "user" */
	insert_user_one?: Maybe<User>;
	/** insert data into the table: "user_roles" */
	insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
	/** insert a single row into the table: "user_roles" */
	insert_user_roles_one?: Maybe<User_Roles>;
	/** insert data into the table: "user_token" */
	insert_user_token?: Maybe<User_Token_Mutation_Response>;
	/** insert a single row into the table: "user_token" */
	insert_user_token_one?: Maybe<User_Token>;
	/** update data of the table: "async_action" */
	update_async_action?: Maybe<Async_Action_Mutation_Response>;
	/** update single row of the table: "async_action" */
	update_async_action_by_pk?: Maybe<Async_Action>;
	/** update multiples rows of table: "async_action" */
	update_async_action_many?: Maybe<Array<Maybe<Async_Action_Mutation_Response>>>;
	/** update data of the table: "available_option" */
	update_available_option?: Maybe<Available_Option_Mutation_Response>;
	/** update single row of the table: "available_option" */
	update_available_option_by_pk?: Maybe<Available_Option>;
	/** update multiples rows of table: "available_option" */
	update_available_option_many?: Maybe<Array<Maybe<Available_Option_Mutation_Response>>>;
	/** update data of the table: "available_option_value" */
	update_available_option_value?: Maybe<Available_Option_Value_Mutation_Response>;
	/** update single row of the table: "available_option_value" */
	update_available_option_value_by_pk?: Maybe<Available_Option_Value>;
	/** update multiples rows of table: "available_option_value" */
	update_available_option_value_many?: Maybe<Array<Maybe<Available_Option_Value_Mutation_Response>>>;
	/** update data of the table: "custom_link_purchase" */
	update_custom_link_purchase?: Maybe<Custom_Link_Purchase_Mutation_Response>;
	/** update single row of the table: "custom_link_purchase" */
	update_custom_link_purchase_by_pk?: Maybe<Custom_Link_Purchase>;
	/** update multiples rows of table: "custom_link_purchase" */
	update_custom_link_purchase_many?: Maybe<Array<Maybe<Custom_Link_Purchase_Mutation_Response>>>;
	/** update data of the table: "leads" */
	update_leads?: Maybe<Leads_Mutation_Response>;
	/** update single row of the table: "leads" */
	update_leads_by_pk?: Maybe<Leads>;
	/** update multiples rows of table: "leads" */
	update_leads_many?: Maybe<Array<Maybe<Leads_Mutation_Response>>>;
	/** update data of the table: "order" */
	update_order?: Maybe<Order_Mutation_Response>;
	/** update single row of the table: "order" */
	update_order_by_pk?: Maybe<Order>;
	/** update data of the table: "order_item" */
	update_order_item?: Maybe<Order_Item_Mutation_Response>;
	/** update single row of the table: "order_item" */
	update_order_item_by_pk?: Maybe<Order_Item>;
	/** update multiples rows of table: "order_item" */
	update_order_item_many?: Maybe<Array<Maybe<Order_Item_Mutation_Response>>>;
	/** update data of the table: "order_item_option" */
	update_order_item_option?: Maybe<Order_Item_Option_Mutation_Response>;
	/** update single row of the table: "order_item_option" */
	update_order_item_option_by_pk?: Maybe<Order_Item_Option>;
	/** update multiples rows of table: "order_item_option" */
	update_order_item_option_many?: Maybe<Array<Maybe<Order_Item_Option_Mutation_Response>>>;
	/** update multiples rows of table: "order" */
	update_order_many?: Maybe<Array<Maybe<Order_Mutation_Response>>>;
	/** update data of the table: "order_package" */
	update_order_package?: Maybe<Order_Package_Mutation_Response>;
	/** update single row of the table: "order_package" */
	update_order_package_by_pk?: Maybe<Order_Package>;
	/** update multiples rows of table: "order_package" */
	update_order_package_many?: Maybe<Array<Maybe<Order_Package_Mutation_Response>>>;
	/** update data of the table: "order_shipping_details" */
	update_order_shipping_details?: Maybe<Order_Shipping_Details_Mutation_Response>;
	/** update single row of the table: "order_shipping_details" */
	update_order_shipping_details_by_pk?: Maybe<Order_Shipping_Details>;
	/** update multiples rows of table: "order_shipping_details" */
	update_order_shipping_details_many?: Maybe<Array<Maybe<Order_Shipping_Details_Mutation_Response>>>;
	/** update data of the table: "order_status_history" */
	update_order_status_history?: Maybe<Order_Status_History_Mutation_Response>;
	/** update single row of the table: "order_status_history" */
	update_order_status_history_by_pk?: Maybe<Order_Status_History>;
	/** update multiples rows of table: "order_status_history" */
	update_order_status_history_many?: Maybe<Array<Maybe<Order_Status_History_Mutation_Response>>>;
	/** update data of the table: "product" */
	update_product?: Maybe<Product_Mutation_Response>;
	/** update single row of the table: "product" */
	update_product_by_pk?: Maybe<Product>;
	/** update data of the table: "product_has_available_option" */
	update_product_has_available_option?: Maybe<Product_Has_Available_Option_Mutation_Response>;
	/** update single row of the table: "product_has_available_option" */
	update_product_has_available_option_by_pk?: Maybe<Product_Has_Available_Option>;
	/** update multiples rows of table: "product_has_available_option" */
	update_product_has_available_option_many?: Maybe<Array<Maybe<Product_Has_Available_Option_Mutation_Response>>>;
	/** update multiples rows of table: "product" */
	update_product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>;
	/** update data of the table: "roles" */
	update_roles?: Maybe<Roles_Mutation_Response>;
	/** update single row of the table: "roles" */
	update_roles_by_pk?: Maybe<Roles>;
	/** update multiples rows of table: "roles" */
	update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
	/** update data of the table: "showcase" */
	update_showcase?: Maybe<Showcase_Mutation_Response>;
	/** update single row of the table: "showcase" */
	update_showcase_by_pk?: Maybe<Showcase>;
	/** update data of the table: "showcase_category" */
	update_showcase_category?: Maybe<Showcase_Category_Mutation_Response>;
	/** update single row of the table: "showcase_category" */
	update_showcase_category_by_pk?: Maybe<Showcase_Category>;
	/** update multiples rows of table: "showcase_category" */
	update_showcase_category_many?: Maybe<Array<Maybe<Showcase_Category_Mutation_Response>>>;
	/** update multiples rows of table: "showcase" */
	update_showcase_many?: Maybe<Array<Maybe<Showcase_Mutation_Response>>>;
	/** update data of the table: "store_package" */
	update_store_package?: Maybe<Store_Package_Mutation_Response>;
	/** update single row of the table: "store_package" */
	update_store_package_by_pk?: Maybe<Store_Package>;
	/** update multiples rows of table: "store_package" */
	update_store_package_many?: Maybe<Array<Maybe<Store_Package_Mutation_Response>>>;
	/** update data of the table: "store_product" */
	update_store_product?: Maybe<Store_Product_Mutation_Response>;
	/** update single row of the table: "store_product" */
	update_store_product_by_pk?: Maybe<Store_Product>;
	/** update data of the table: "store_product_has_options" */
	update_store_product_has_options?: Maybe<Store_Product_Has_Options_Mutation_Response>;
	/** update single row of the table: "store_product_has_options" */
	update_store_product_has_options_by_pk?: Maybe<Store_Product_Has_Options>;
	/** update multiples rows of table: "store_product_has_options" */
	update_store_product_has_options_many?: Maybe<Array<Maybe<Store_Product_Has_Options_Mutation_Response>>>;
	/** update multiples rows of table: "store_product" */
	update_store_product_many?: Maybe<Array<Maybe<Store_Product_Mutation_Response>>>;
	/** update data of the table: "support" */
	update_support?: Maybe<Support_Mutation_Response>;
	/** update single row of the table: "support" */
	update_support_by_pk?: Maybe<Support>;
	/** update multiples rows of table: "support" */
	update_support_many?: Maybe<Array<Maybe<Support_Mutation_Response>>>;
	/** update data of the table: "team" */
	update_team?: Maybe<Team_Mutation_Response>;
	/** update single row of the table: "team" */
	update_team_by_pk?: Maybe<Team>;
	/** update multiples rows of table: "team" */
	update_team_many?: Maybe<Array<Maybe<Team_Mutation_Response>>>;
	/** update data of the table: "team_store" */
	update_team_store?: Maybe<Team_Store_Mutation_Response>;
	/** update single row of the table: "team_store" */
	update_team_store_by_pk?: Maybe<Team_Store>;
	/** update data of the table: "team_store_email_log" */
	update_team_store_email_log?: Maybe<Team_Store_Email_Log_Mutation_Response>;
	/** update single row of the table: "team_store_email_log" */
	update_team_store_email_log_by_pk?: Maybe<Team_Store_Email_Log>;
	/** update multiples rows of table: "team_store_email_log" */
	update_team_store_email_log_many?: Maybe<Array<Maybe<Team_Store_Email_Log_Mutation_Response>>>;
	/** update multiples rows of table: "team_store" */
	update_team_store_many?: Maybe<Array<Maybe<Team_Store_Mutation_Response>>>;
	/** update data of the table: "team_store_order_email_log" */
	update_team_store_order_email_log?: Maybe<Team_Store_Order_Email_Log_Mutation_Response>;
	/** update single row of the table: "team_store_order_email_log" */
	update_team_store_order_email_log_by_pk?: Maybe<Team_Store_Order_Email_Log>;
	/** update multiples rows of table: "team_store_order_email_log" */
	update_team_store_order_email_log_many?: Maybe<Array<Maybe<Team_Store_Order_Email_Log_Mutation_Response>>>;
	/** update data of the table: "user" */
	update_user?: Maybe<User_Mutation_Response>;
	/** update single row of the table: "user" */
	update_user_by_pk?: Maybe<User>;
	/** update multiples rows of table: "user" */
	update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
	/** update data of the table: "user_roles" */
	update_user_roles?: Maybe<User_Roles_Mutation_Response>;
	/** update single row of the table: "user_roles" */
	update_user_roles_by_pk?: Maybe<User_Roles>;
	/** update multiples rows of table: "user_roles" */
	update_user_roles_many?: Maybe<Array<Maybe<User_Roles_Mutation_Response>>>;
	/** update data of the table: "user_token" */
	update_user_token?: Maybe<User_Token_Mutation_Response>;
	/** update single row of the table: "user_token" */
	update_user_token_by_pk?: Maybe<User_Token>;
	/** update multiples rows of table: "user_token" */
	update_user_token_many?: Maybe<Array<Maybe<User_Token_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootAddStoreProductArgs = {
	productId: Scalars['String'];
	storeId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootAdd_User_Role_By_NameArgs = {
	args: Add_User_Role_By_Name_Args;
	distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Roles_Order_By>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** mutation root */
export type Mutation_RootAuthLoginArgs = {
	credentialResponse: Scalars['String'];
	loginType: Scalars['String'];
};

/** mutation root */
export type Mutation_RootAuthSendArgs = {
	email: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCancelOrderItemArgs = {
	orderId: Scalars['uuid'];
	orderItemId: Scalars['uuid'];
	orderItemType: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateCheckoutArgs = {
	attributeTo?: InputMaybe<Scalars['String']>;
	checkoutType: Scalars['String'];
	email: Scalars['String'];
	name: Scalars['String'];
	products: Scalars['String'];
	storeId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateCustomLinkArgs = {
	productId: Scalars['String'];
	productPrice: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootCreateDemoStoreArgs = {
	bundleIds: Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>;
	storeLabel?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootCreateMultipleShippingLabelsArgs = {
	orderIds: Array<Scalars['uuid']>;
	teamStoreId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootCreateNewLeadArgs = {
	leadInput: LeadInput;
};

/** mutation root */
export type Mutation_RootCreateNewReceiptForOrderArgs = {
	orderId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootCreateNewShippingLabelArgs = {
	city?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	orderId: Scalars['uuid'];
	overrideAddress: Scalars['Boolean'];
	state?: InputMaybe<Scalars['String']>;
	street1?: InputMaybe<Scalars['String']>;
	street2?: InputMaybe<Scalars['String']>;
	zip?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootCreateProductFromSignedUrlArgs = {
	fileName: Scalars['String'];
	fileType: Scalars['String'];
	storeId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootCreateSignedUrlArgs = {
	fileName: Scalars['String'];
	fileType: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateStripeProductArgs = {
	description: Scalars['String'];
	name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateTeamStoreEmailArgs = {
	storeId: Scalars['uuid'];
	workflowId?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDelete_Async_ActionArgs = {
	where: Async_Action_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Async_Action_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Available_OptionArgs = {
	where: Available_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Available_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Available_Option_ValueArgs = {
	where: Available_Option_Value_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Available_Option_Value_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Custom_Link_PurchaseArgs = {
	where: Custom_Link_Purchase_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Custom_Link_Purchase_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_LeadsArgs = {
	where: Leads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Leads_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_OrderArgs = {
	where: Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Order_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Order_ItemArgs = {
	where: Order_Item_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Order_Item_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Order_Item_OptionArgs = {
	where: Order_Item_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Order_Item_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Order_PackageArgs = {
	where: Order_Package_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Order_Package_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Order_Shipping_DetailsArgs = {
	where: Order_Shipping_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Order_Shipping_Details_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Order_Status_HistoryArgs = {
	where: Order_Status_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Order_Status_History_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
	where: Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Product_Has_Available_OptionArgs = {
	where: Product_Has_Available_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Has_Available_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
	where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ShowcaseArgs = {
	where: Showcase_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Showcase_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Showcase_CategoryArgs = {
	where: Showcase_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Showcase_Category_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Store_PackageArgs = {
	where: Store_Package_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Store_Package_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Store_ProductArgs = {
	where: Store_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Store_Product_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Store_Product_Has_OptionsArgs = {
	where: Store_Product_Has_Options_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Store_Product_Has_Options_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SupportArgs = {
	where: Support_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Support_By_PkArgs = {
	id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_TeamArgs = {
	where: Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Team_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Team_StoreArgs = {
	where: Team_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Team_Store_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Team_Store_Email_LogArgs = {
	where: Team_Store_Email_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Team_Store_Email_Log_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Team_Store_Order_Email_LogArgs = {
	where: Team_Store_Order_Email_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Team_Store_Order_Email_Log_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
	where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
	where: User_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_TokenArgs = {
	where: User_Token_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Token_By_PkArgs = {
	id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDuplicateTeamStoreArgs = {
	teamStoreId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootInsert_Async_ActionArgs = {
	objects: Array<Async_Action_Insert_Input>;
	on_conflict?: InputMaybe<Async_Action_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Async_Action_OneArgs = {
	object: Async_Action_Insert_Input;
	on_conflict?: InputMaybe<Async_Action_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Available_OptionArgs = {
	objects: Array<Available_Option_Insert_Input>;
	on_conflict?: InputMaybe<Available_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Available_Option_OneArgs = {
	object: Available_Option_Insert_Input;
	on_conflict?: InputMaybe<Available_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Available_Option_ValueArgs = {
	objects: Array<Available_Option_Value_Insert_Input>;
	on_conflict?: InputMaybe<Available_Option_Value_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Available_Option_Value_OneArgs = {
	object: Available_Option_Value_Insert_Input;
	on_conflict?: InputMaybe<Available_Option_Value_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Custom_Link_PurchaseArgs = {
	objects: Array<Custom_Link_Purchase_Insert_Input>;
	on_conflict?: InputMaybe<Custom_Link_Purchase_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Custom_Link_Purchase_OneArgs = {
	object: Custom_Link_Purchase_Insert_Input;
	on_conflict?: InputMaybe<Custom_Link_Purchase_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LeadsArgs = {
	objects: Array<Leads_Insert_Input>;
	on_conflict?: InputMaybe<Leads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Leads_OneArgs = {
	object: Leads_Insert_Input;
	on_conflict?: InputMaybe<Leads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OrderArgs = {
	objects: Array<Order_Insert_Input>;
	on_conflict?: InputMaybe<Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_ItemArgs = {
	objects: Array<Order_Item_Insert_Input>;
	on_conflict?: InputMaybe<Order_Item_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_Item_OneArgs = {
	object: Order_Item_Insert_Input;
	on_conflict?: InputMaybe<Order_Item_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_Item_OptionArgs = {
	objects: Array<Order_Item_Option_Insert_Input>;
	on_conflict?: InputMaybe<Order_Item_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_Item_Option_OneArgs = {
	object: Order_Item_Option_Insert_Input;
	on_conflict?: InputMaybe<Order_Item_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_OneArgs = {
	object: Order_Insert_Input;
	on_conflict?: InputMaybe<Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_PackageArgs = {
	objects: Array<Order_Package_Insert_Input>;
	on_conflict?: InputMaybe<Order_Package_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_Package_OneArgs = {
	object: Order_Package_Insert_Input;
	on_conflict?: InputMaybe<Order_Package_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_Shipping_DetailsArgs = {
	objects: Array<Order_Shipping_Details_Insert_Input>;
	on_conflict?: InputMaybe<Order_Shipping_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_Shipping_Details_OneArgs = {
	object: Order_Shipping_Details_Insert_Input;
	on_conflict?: InputMaybe<Order_Shipping_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_Status_HistoryArgs = {
	objects: Array<Order_Status_History_Insert_Input>;
	on_conflict?: InputMaybe<Order_Status_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Order_Status_History_OneArgs = {
	object: Order_Status_History_Insert_Input;
	on_conflict?: InputMaybe<Order_Status_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
	objects: Array<Product_Insert_Input>;
	on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Has_Available_OptionArgs = {
	objects: Array<Product_Has_Available_Option_Insert_Input>;
	on_conflict?: InputMaybe<Product_Has_Available_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Has_Available_Option_OneArgs = {
	object: Product_Has_Available_Option_Insert_Input;
	on_conflict?: InputMaybe<Product_Has_Available_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
	object: Product_Insert_Input;
	on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
	objects: Array<Roles_Insert_Input>;
	on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
	object: Roles_Insert_Input;
	on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ShowcaseArgs = {
	objects: Array<Showcase_Insert_Input>;
	on_conflict?: InputMaybe<Showcase_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Showcase_CategoryArgs = {
	objects: Array<Showcase_Category_Insert_Input>;
	on_conflict?: InputMaybe<Showcase_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Showcase_Category_OneArgs = {
	object: Showcase_Category_Insert_Input;
	on_conflict?: InputMaybe<Showcase_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Showcase_OneArgs = {
	object: Showcase_Insert_Input;
	on_conflict?: InputMaybe<Showcase_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Store_PackageArgs = {
	objects: Array<Store_Package_Insert_Input>;
	on_conflict?: InputMaybe<Store_Package_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Store_Package_OneArgs = {
	object: Store_Package_Insert_Input;
	on_conflict?: InputMaybe<Store_Package_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Store_ProductArgs = {
	objects: Array<Store_Product_Insert_Input>;
	on_conflict?: InputMaybe<Store_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Store_Product_Has_OptionsArgs = {
	objects: Array<Store_Product_Has_Options_Insert_Input>;
	on_conflict?: InputMaybe<Store_Product_Has_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Store_Product_Has_Options_OneArgs = {
	object: Store_Product_Has_Options_Insert_Input;
	on_conflict?: InputMaybe<Store_Product_Has_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Store_Product_OneArgs = {
	object: Store_Product_Insert_Input;
	on_conflict?: InputMaybe<Store_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SupportArgs = {
	objects: Array<Support_Insert_Input>;
	on_conflict?: InputMaybe<Support_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Support_OneArgs = {
	object: Support_Insert_Input;
	on_conflict?: InputMaybe<Support_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TeamArgs = {
	objects: Array<Team_Insert_Input>;
	on_conflict?: InputMaybe<Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Team_OneArgs = {
	object: Team_Insert_Input;
	on_conflict?: InputMaybe<Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Team_StoreArgs = {
	objects: Array<Team_Store_Insert_Input>;
	on_conflict?: InputMaybe<Team_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Team_Store_Email_LogArgs = {
	objects: Array<Team_Store_Email_Log_Insert_Input>;
	on_conflict?: InputMaybe<Team_Store_Email_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Team_Store_Email_Log_OneArgs = {
	object: Team_Store_Email_Log_Insert_Input;
	on_conflict?: InputMaybe<Team_Store_Email_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Team_Store_OneArgs = {
	object: Team_Store_Insert_Input;
	on_conflict?: InputMaybe<Team_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Team_Store_Order_Email_LogArgs = {
	objects: Array<Team_Store_Order_Email_Log_Insert_Input>;
	on_conflict?: InputMaybe<Team_Store_Order_Email_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Team_Store_Order_Email_Log_OneArgs = {
	object: Team_Store_Order_Email_Log_Insert_Input;
	on_conflict?: InputMaybe<Team_Store_Order_Email_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
	objects: Array<User_Insert_Input>;
	on_conflict?: InputMaybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
	object: User_Insert_Input;
	on_conflict?: InputMaybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
	objects: Array<User_Roles_Insert_Input>;
	on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
	object: User_Roles_Insert_Input;
	on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_TokenArgs = {
	objects: Array<User_Token_Insert_Input>;
	on_conflict?: InputMaybe<User_Token_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Token_OneArgs = {
	object: User_Token_Insert_Input;
	on_conflict?: InputMaybe<User_Token_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Async_ActionArgs = {
	_append?: InputMaybe<Async_Action_Append_Input>;
	_delete_at_path?: InputMaybe<Async_Action_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Async_Action_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Async_Action_Delete_Key_Input>;
	_prepend?: InputMaybe<Async_Action_Prepend_Input>;
	_set?: InputMaybe<Async_Action_Set_Input>;
	where: Async_Action_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Async_Action_By_PkArgs = {
	_append?: InputMaybe<Async_Action_Append_Input>;
	_delete_at_path?: InputMaybe<Async_Action_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Async_Action_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Async_Action_Delete_Key_Input>;
	_prepend?: InputMaybe<Async_Action_Prepend_Input>;
	_set?: InputMaybe<Async_Action_Set_Input>;
	pk_columns: Async_Action_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Async_Action_ManyArgs = {
	updates: Array<Async_Action_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Available_OptionArgs = {
	_set?: InputMaybe<Available_Option_Set_Input>;
	where: Available_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Available_Option_By_PkArgs = {
	_set?: InputMaybe<Available_Option_Set_Input>;
	pk_columns: Available_Option_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Available_Option_ManyArgs = {
	updates: Array<Available_Option_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Available_Option_ValueArgs = {
	_inc?: InputMaybe<Available_Option_Value_Inc_Input>;
	_set?: InputMaybe<Available_Option_Value_Set_Input>;
	where: Available_Option_Value_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Available_Option_Value_By_PkArgs = {
	_inc?: InputMaybe<Available_Option_Value_Inc_Input>;
	_set?: InputMaybe<Available_Option_Value_Set_Input>;
	pk_columns: Available_Option_Value_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Available_Option_Value_ManyArgs = {
	updates: Array<Available_Option_Value_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Link_PurchaseArgs = {
	_inc?: InputMaybe<Custom_Link_Purchase_Inc_Input>;
	_set?: InputMaybe<Custom_Link_Purchase_Set_Input>;
	where: Custom_Link_Purchase_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Link_Purchase_By_PkArgs = {
	_inc?: InputMaybe<Custom_Link_Purchase_Inc_Input>;
	_set?: InputMaybe<Custom_Link_Purchase_Set_Input>;
	pk_columns: Custom_Link_Purchase_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Link_Purchase_ManyArgs = {
	updates: Array<Custom_Link_Purchase_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LeadsArgs = {
	_set?: InputMaybe<Leads_Set_Input>;
	where: Leads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Leads_By_PkArgs = {
	_set?: InputMaybe<Leads_Set_Input>;
	pk_columns: Leads_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Leads_ManyArgs = {
	updates: Array<Leads_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
	_append?: InputMaybe<Order_Append_Input>;
	_delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Order_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Order_Delete_Key_Input>;
	_inc?: InputMaybe<Order_Inc_Input>;
	_prepend?: InputMaybe<Order_Prepend_Input>;
	_set?: InputMaybe<Order_Set_Input>;
	where: Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Order_By_PkArgs = {
	_append?: InputMaybe<Order_Append_Input>;
	_delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Order_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Order_Delete_Key_Input>;
	_inc?: InputMaybe<Order_Inc_Input>;
	_prepend?: InputMaybe<Order_Prepend_Input>;
	_set?: InputMaybe<Order_Set_Input>;
	pk_columns: Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Order_ItemArgs = {
	_inc?: InputMaybe<Order_Item_Inc_Input>;
	_set?: InputMaybe<Order_Item_Set_Input>;
	where: Order_Item_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Item_By_PkArgs = {
	_inc?: InputMaybe<Order_Item_Inc_Input>;
	_set?: InputMaybe<Order_Item_Set_Input>;
	pk_columns: Order_Item_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Item_ManyArgs = {
	updates: Array<Order_Item_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Item_OptionArgs = {
	_inc?: InputMaybe<Order_Item_Option_Inc_Input>;
	_set?: InputMaybe<Order_Item_Option_Set_Input>;
	where: Order_Item_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Item_Option_By_PkArgs = {
	_inc?: InputMaybe<Order_Item_Option_Inc_Input>;
	_set?: InputMaybe<Order_Item_Option_Set_Input>;
	pk_columns: Order_Item_Option_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Item_Option_ManyArgs = {
	updates: Array<Order_Item_Option_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Order_ManyArgs = {
	updates: Array<Order_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Order_PackageArgs = {
	_inc?: InputMaybe<Order_Package_Inc_Input>;
	_set?: InputMaybe<Order_Package_Set_Input>;
	where: Order_Package_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Package_By_PkArgs = {
	_inc?: InputMaybe<Order_Package_Inc_Input>;
	_set?: InputMaybe<Order_Package_Set_Input>;
	pk_columns: Order_Package_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Package_ManyArgs = {
	updates: Array<Order_Package_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Shipping_DetailsArgs = {
	_append?: InputMaybe<Order_Shipping_Details_Append_Input>;
	_delete_at_path?: InputMaybe<Order_Shipping_Details_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Order_Shipping_Details_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Order_Shipping_Details_Delete_Key_Input>;
	_prepend?: InputMaybe<Order_Shipping_Details_Prepend_Input>;
	_set?: InputMaybe<Order_Shipping_Details_Set_Input>;
	where: Order_Shipping_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Shipping_Details_By_PkArgs = {
	_append?: InputMaybe<Order_Shipping_Details_Append_Input>;
	_delete_at_path?: InputMaybe<Order_Shipping_Details_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Order_Shipping_Details_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Order_Shipping_Details_Delete_Key_Input>;
	_prepend?: InputMaybe<Order_Shipping_Details_Prepend_Input>;
	_set?: InputMaybe<Order_Shipping_Details_Set_Input>;
	pk_columns: Order_Shipping_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Shipping_Details_ManyArgs = {
	updates: Array<Order_Shipping_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Status_HistoryArgs = {
	_set?: InputMaybe<Order_Status_History_Set_Input>;
	where: Order_Status_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Status_History_By_PkArgs = {
	_set?: InputMaybe<Order_Status_History_Set_Input>;
	pk_columns: Order_Status_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Order_Status_History_ManyArgs = {
	updates: Array<Order_Status_History_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
	_append?: InputMaybe<Product_Append_Input>;
	_delete_at_path?: InputMaybe<Product_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Product_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Product_Delete_Key_Input>;
	_inc?: InputMaybe<Product_Inc_Input>;
	_prepend?: InputMaybe<Product_Prepend_Input>;
	_set?: InputMaybe<Product_Set_Input>;
	where: Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
	_append?: InputMaybe<Product_Append_Input>;
	_delete_at_path?: InputMaybe<Product_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Product_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Product_Delete_Key_Input>;
	_inc?: InputMaybe<Product_Inc_Input>;
	_prepend?: InputMaybe<Product_Prepend_Input>;
	_set?: InputMaybe<Product_Set_Input>;
	pk_columns: Product_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Has_Available_OptionArgs = {
	_inc?: InputMaybe<Product_Has_Available_Option_Inc_Input>;
	_set?: InputMaybe<Product_Has_Available_Option_Set_Input>;
	where: Product_Has_Available_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Has_Available_Option_By_PkArgs = {
	_inc?: InputMaybe<Product_Has_Available_Option_Inc_Input>;
	_set?: InputMaybe<Product_Has_Available_Option_Set_Input>;
	pk_columns: Product_Has_Available_Option_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Has_Available_Option_ManyArgs = {
	updates: Array<Product_Has_Available_Option_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
	updates: Array<Product_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
	_set?: InputMaybe<Roles_Set_Input>;
	where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
	_set?: InputMaybe<Roles_Set_Input>;
	pk_columns: Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
	updates: Array<Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ShowcaseArgs = {
	_append?: InputMaybe<Showcase_Append_Input>;
	_delete_at_path?: InputMaybe<Showcase_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Showcase_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Showcase_Delete_Key_Input>;
	_inc?: InputMaybe<Showcase_Inc_Input>;
	_prepend?: InputMaybe<Showcase_Prepend_Input>;
	_set?: InputMaybe<Showcase_Set_Input>;
	where: Showcase_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Showcase_By_PkArgs = {
	_append?: InputMaybe<Showcase_Append_Input>;
	_delete_at_path?: InputMaybe<Showcase_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Showcase_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Showcase_Delete_Key_Input>;
	_inc?: InputMaybe<Showcase_Inc_Input>;
	_prepend?: InputMaybe<Showcase_Prepend_Input>;
	_set?: InputMaybe<Showcase_Set_Input>;
	pk_columns: Showcase_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Showcase_CategoryArgs = {
	_inc?: InputMaybe<Showcase_Category_Inc_Input>;
	_set?: InputMaybe<Showcase_Category_Set_Input>;
	where: Showcase_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Showcase_Category_By_PkArgs = {
	_inc?: InputMaybe<Showcase_Category_Inc_Input>;
	_set?: InputMaybe<Showcase_Category_Set_Input>;
	pk_columns: Showcase_Category_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Showcase_Category_ManyArgs = {
	updates: Array<Showcase_Category_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Showcase_ManyArgs = {
	updates: Array<Showcase_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Store_PackageArgs = {
	_append?: InputMaybe<Store_Package_Append_Input>;
	_delete_at_path?: InputMaybe<Store_Package_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Store_Package_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Store_Package_Delete_Key_Input>;
	_inc?: InputMaybe<Store_Package_Inc_Input>;
	_prepend?: InputMaybe<Store_Package_Prepend_Input>;
	_set?: InputMaybe<Store_Package_Set_Input>;
	where: Store_Package_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Store_Package_By_PkArgs = {
	_append?: InputMaybe<Store_Package_Append_Input>;
	_delete_at_path?: InputMaybe<Store_Package_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Store_Package_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Store_Package_Delete_Key_Input>;
	_inc?: InputMaybe<Store_Package_Inc_Input>;
	_prepend?: InputMaybe<Store_Package_Prepend_Input>;
	_set?: InputMaybe<Store_Package_Set_Input>;
	pk_columns: Store_Package_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Store_Package_ManyArgs = {
	updates: Array<Store_Package_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Store_ProductArgs = {
	_append?: InputMaybe<Store_Product_Append_Input>;
	_delete_at_path?: InputMaybe<Store_Product_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Store_Product_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Store_Product_Delete_Key_Input>;
	_inc?: InputMaybe<Store_Product_Inc_Input>;
	_prepend?: InputMaybe<Store_Product_Prepend_Input>;
	_set?: InputMaybe<Store_Product_Set_Input>;
	where: Store_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Store_Product_By_PkArgs = {
	_append?: InputMaybe<Store_Product_Append_Input>;
	_delete_at_path?: InputMaybe<Store_Product_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Store_Product_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Store_Product_Delete_Key_Input>;
	_inc?: InputMaybe<Store_Product_Inc_Input>;
	_prepend?: InputMaybe<Store_Product_Prepend_Input>;
	_set?: InputMaybe<Store_Product_Set_Input>;
	pk_columns: Store_Product_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Store_Product_Has_OptionsArgs = {
	_inc?: InputMaybe<Store_Product_Has_Options_Inc_Input>;
	_set?: InputMaybe<Store_Product_Has_Options_Set_Input>;
	where: Store_Product_Has_Options_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Store_Product_Has_Options_By_PkArgs = {
	_inc?: InputMaybe<Store_Product_Has_Options_Inc_Input>;
	_set?: InputMaybe<Store_Product_Has_Options_Set_Input>;
	pk_columns: Store_Product_Has_Options_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Store_Product_Has_Options_ManyArgs = {
	updates: Array<Store_Product_Has_Options_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Store_Product_ManyArgs = {
	updates: Array<Store_Product_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SupportArgs = {
	_inc?: InputMaybe<Support_Inc_Input>;
	_set?: InputMaybe<Support_Set_Input>;
	where: Support_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Support_By_PkArgs = {
	_inc?: InputMaybe<Support_Inc_Input>;
	_set?: InputMaybe<Support_Set_Input>;
	pk_columns: Support_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Support_ManyArgs = {
	updates: Array<Support_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TeamArgs = {
	_set?: InputMaybe<Team_Set_Input>;
	where: Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Team_By_PkArgs = {
	_set?: InputMaybe<Team_Set_Input>;
	pk_columns: Team_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Team_ManyArgs = {
	updates: Array<Team_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Team_StoreArgs = {
	_append?: InputMaybe<Team_Store_Append_Input>;
	_delete_at_path?: InputMaybe<Team_Store_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Team_Store_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Team_Store_Delete_Key_Input>;
	_inc?: InputMaybe<Team_Store_Inc_Input>;
	_prepend?: InputMaybe<Team_Store_Prepend_Input>;
	_set?: InputMaybe<Team_Store_Set_Input>;
	where: Team_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Team_Store_By_PkArgs = {
	_append?: InputMaybe<Team_Store_Append_Input>;
	_delete_at_path?: InputMaybe<Team_Store_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Team_Store_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Team_Store_Delete_Key_Input>;
	_inc?: InputMaybe<Team_Store_Inc_Input>;
	_prepend?: InputMaybe<Team_Store_Prepend_Input>;
	_set?: InputMaybe<Team_Store_Set_Input>;
	pk_columns: Team_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Team_Store_Email_LogArgs = {
	_append?: InputMaybe<Team_Store_Email_Log_Append_Input>;
	_delete_at_path?: InputMaybe<Team_Store_Email_Log_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Team_Store_Email_Log_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Team_Store_Email_Log_Delete_Key_Input>;
	_prepend?: InputMaybe<Team_Store_Email_Log_Prepend_Input>;
	_set?: InputMaybe<Team_Store_Email_Log_Set_Input>;
	where: Team_Store_Email_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Team_Store_Email_Log_By_PkArgs = {
	_append?: InputMaybe<Team_Store_Email_Log_Append_Input>;
	_delete_at_path?: InputMaybe<Team_Store_Email_Log_Delete_At_Path_Input>;
	_delete_elem?: InputMaybe<Team_Store_Email_Log_Delete_Elem_Input>;
	_delete_key?: InputMaybe<Team_Store_Email_Log_Delete_Key_Input>;
	_prepend?: InputMaybe<Team_Store_Email_Log_Prepend_Input>;
	_set?: InputMaybe<Team_Store_Email_Log_Set_Input>;
	pk_columns: Team_Store_Email_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Team_Store_Email_Log_ManyArgs = {
	updates: Array<Team_Store_Email_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Team_Store_ManyArgs = {
	updates: Array<Team_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Team_Store_Order_Email_LogArgs = {
	_set?: InputMaybe<Team_Store_Order_Email_Log_Set_Input>;
	where: Team_Store_Order_Email_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Team_Store_Order_Email_Log_By_PkArgs = {
	_set?: InputMaybe<Team_Store_Order_Email_Log_Set_Input>;
	pk_columns: Team_Store_Order_Email_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Team_Store_Order_Email_Log_ManyArgs = {
	updates: Array<Team_Store_Order_Email_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
	_set?: InputMaybe<User_Set_Input>;
	where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
	_set?: InputMaybe<User_Set_Input>;
	pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
	updates: Array<User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
	_set?: InputMaybe<User_Roles_Set_Input>;
	where: User_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
	_set?: InputMaybe<User_Roles_Set_Input>;
	pk_columns: User_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Roles_ManyArgs = {
	updates: Array<User_Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_TokenArgs = {
	_inc?: InputMaybe<User_Token_Inc_Input>;
	_set?: InputMaybe<User_Token_Set_Input>;
	where: User_Token_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Token_By_PkArgs = {
	_inc?: InputMaybe<User_Token_Inc_Input>;
	_set?: InputMaybe<User_Token_Set_Input>;
	pk_columns: User_Token_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Token_ManyArgs = {
	updates: Array<User_Token_Updates>;
};

/** columns and relationships of "order" */
export type Order = {
	__typename?: 'order';
	additional_origin_cart_id?: Maybe<Scalars['uuid']>;
	aftership_id?: Maybe<Scalars['String']>;
	aftership_status_tag?: Maybe<Scalars['String']>;
	attributed_to?: Maybe<Scalars['String']>;
	checkout_type?: Maybe<Scalars['String']>;
	created_at: Scalars['timestamptz'];
	customer_email?: Maybe<Scalars['String']>;
	customer_name?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	/** An array relationship */
	order_items: Array<Order_Item>;
	/** An aggregate relationship */
	order_items_aggregate: Order_Item_Aggregate;
	order_readable_column: Scalars['Int'];
	/** An array relationship */
	order_shipping_details: Array<Order_Shipping_Details>;
	/** An aggregate relationship */
	order_shipping_details_aggregate: Order_Shipping_Details_Aggregate;
	/** An array relationship */
	order_status_histories: Array<Order_Status_History>;
	/** An aggregate relationship */
	order_status_histories_aggregate: Order_Status_History_Aggregate;
	ordered_by_user_id?: Maybe<Scalars['uuid']>;
	original_order_price?: Maybe<Scalars['Int']>;
	receipt_url?: Maybe<Scalars['String']>;
	shipping_charged: Scalars['Int'];
	shipping_label_url?: Maybe<Scalars['String']>;
	shipping_origin: Scalars['String'];
	shipstation_response_data?: Maybe<Scalars['jsonb']>;
	shipstation_ship_price?: Maybe<Scalars['String']>;
	shipstation_shipment_id?: Maybe<Scalars['String']>;
	status: Scalars['String'];
	stripe_customer_id?: Maybe<Scalars['String']>;
	stripe_payment_id?: Maybe<Scalars['String']>;
	stripe_session_id?: Maybe<Scalars['String']>;
	stripe_tax_charged: Scalars['Int'];
	stripe_total_charged?: Maybe<Scalars['Int']>;
	/** An object relationship */
	team_store: Team_Store;
	team_store_id: Scalars['uuid'];
	/** An array relationship */
	team_store_order_email_logs: Array<Team_Store_Order_Email_Log>;
	/** An aggregate relationship */
	team_store_order_email_logs_aggregate: Team_Store_Order_Email_Log_Aggregate;
	total?: Maybe<Scalars['Int']>;
	updated_at: Scalars['timestamptz'];
	use_stripe_customer_shipping_data: Scalars['Boolean'];
};

/** columns and relationships of "order" */
export type OrderOrder_ItemsArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

/** columns and relationships of "order" */
export type OrderOrder_Items_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

/** columns and relationships of "order" */
export type OrderOrder_Shipping_DetailsArgs = {
	distinct_on?: InputMaybe<Array<Order_Shipping_Details_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Shipping_Details_Order_By>>;
	where?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
};

/** columns and relationships of "order" */
export type OrderOrder_Shipping_Details_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Shipping_Details_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Shipping_Details_Order_By>>;
	where?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
};

/** columns and relationships of "order" */
export type OrderOrder_Status_HistoriesArgs = {
	distinct_on?: InputMaybe<Array<Order_Status_History_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Status_History_Order_By>>;
	where?: InputMaybe<Order_Status_History_Bool_Exp>;
};

/** columns and relationships of "order" */
export type OrderOrder_Status_Histories_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Status_History_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Status_History_Order_By>>;
	where?: InputMaybe<Order_Status_History_Bool_Exp>;
};

/** columns and relationships of "order" */
export type OrderShipstation_Response_DataArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "order" */
export type OrderTeam_Store_Order_Email_LogsArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

/** columns and relationships of "order" */
export type OrderTeam_Store_Order_Email_Logs_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

/** aggregated selection of "order" */
export type Order_Aggregate = {
	__typename?: 'order_aggregate';
	aggregate?: Maybe<Order_Aggregate_Fields>;
	nodes: Array<Order>;
};

export type Order_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Order_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Order_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Order_Aggregate_Bool_Exp_Count>;
};

export type Order_Aggregate_Bool_Exp_Bool_And = {
	arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Order_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order" */
export type Order_Aggregate_Fields = {
	__typename?: 'order_aggregate_fields';
	avg?: Maybe<Order_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Order_Max_Fields>;
	min?: Maybe<Order_Min_Fields>;
	stddev?: Maybe<Order_Stddev_Fields>;
	stddev_pop?: Maybe<Order_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Order_Stddev_Samp_Fields>;
	sum?: Maybe<Order_Sum_Fields>;
	var_pop?: Maybe<Order_Var_Pop_Fields>;
	var_samp?: Maybe<Order_Var_Samp_Fields>;
	variance?: Maybe<Order_Variance_Fields>;
};

/** aggregate fields of "order" */
export type Order_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Order_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order" */
export type Order_Aggregate_Order_By = {
	avg?: InputMaybe<Order_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Order_Max_Order_By>;
	min?: InputMaybe<Order_Min_Order_By>;
	stddev?: InputMaybe<Order_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Order_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Order_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Order_Sum_Order_By>;
	var_pop?: InputMaybe<Order_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Order_Var_Samp_Order_By>;
	variance?: InputMaybe<Order_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Append_Input = {
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "order" */
export type Order_Arr_Rel_Insert_Input = {
	data: Array<Order_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Avg_Fields = {
	__typename?: 'order_avg_fields';
	order_readable_column?: Maybe<Scalars['Float']>;
	original_order_price?: Maybe<Scalars['Float']>;
	shipping_charged?: Maybe<Scalars['Float']>;
	stripe_tax_charged?: Maybe<Scalars['Float']>;
	stripe_total_charged?: Maybe<Scalars['Float']>;
	total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order" */
export type Order_Avg_Order_By = {
	order_readable_column?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
	_and?: InputMaybe<Array<Order_Bool_Exp>>;
	_not?: InputMaybe<Order_Bool_Exp>;
	_or?: InputMaybe<Array<Order_Bool_Exp>>;
	additional_origin_cart_id?: InputMaybe<Uuid_Comparison_Exp>;
	aftership_id?: InputMaybe<String_Comparison_Exp>;
	aftership_status_tag?: InputMaybe<String_Comparison_Exp>;
	attributed_to?: InputMaybe<String_Comparison_Exp>;
	checkout_type?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	customer_email?: InputMaybe<String_Comparison_Exp>;
	customer_name?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	order_items?: InputMaybe<Order_Item_Bool_Exp>;
	order_items_aggregate?: InputMaybe<Order_Item_Aggregate_Bool_Exp>;
	order_readable_column?: InputMaybe<Int_Comparison_Exp>;
	order_shipping_details?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
	order_shipping_details_aggregate?: InputMaybe<Order_Shipping_Details_Aggregate_Bool_Exp>;
	order_status_histories?: InputMaybe<Order_Status_History_Bool_Exp>;
	order_status_histories_aggregate?: InputMaybe<Order_Status_History_Aggregate_Bool_Exp>;
	ordered_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
	original_order_price?: InputMaybe<Int_Comparison_Exp>;
	receipt_url?: InputMaybe<String_Comparison_Exp>;
	shipping_charged?: InputMaybe<Int_Comparison_Exp>;
	shipping_label_url?: InputMaybe<String_Comparison_Exp>;
	shipping_origin?: InputMaybe<String_Comparison_Exp>;
	shipstation_response_data?: InputMaybe<Jsonb_Comparison_Exp>;
	shipstation_ship_price?: InputMaybe<String_Comparison_Exp>;
	shipstation_shipment_id?: InputMaybe<String_Comparison_Exp>;
	status?: InputMaybe<String_Comparison_Exp>;
	stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
	stripe_payment_id?: InputMaybe<String_Comparison_Exp>;
	stripe_session_id?: InputMaybe<String_Comparison_Exp>;
	stripe_tax_charged?: InputMaybe<Int_Comparison_Exp>;
	stripe_total_charged?: InputMaybe<Int_Comparison_Exp>;
	team_store?: InputMaybe<Team_Store_Bool_Exp>;
	team_store_id?: InputMaybe<Uuid_Comparison_Exp>;
	team_store_order_email_logs?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
	team_store_order_email_logs_aggregate?: InputMaybe<Team_Store_Order_Email_Log_Aggregate_Bool_Exp>;
	total?: InputMaybe<Int_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	use_stripe_customer_shipping_data?: InputMaybe<Boolean_Comparison_Exp>;
};

/** column ordering options */
export enum Order_By {
	/** in ascending order, nulls last */
	Asc = 'asc',
	/** in ascending order, nulls first */
	AscNullsFirst = 'asc_nulls_first',
	/** in ascending order, nulls last */
	AscNullsLast = 'asc_nulls_last',
	/** in descending order, nulls first */
	Desc = 'desc',
	/** in descending order, nulls first */
	DescNullsFirst = 'desc_nulls_first',
	/** in descending order, nulls last */
	DescNullsLast = 'desc_nulls_last'
}

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
	/** unique or primary key constraint on columns "id" */
	OrderPkey = 'order_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Delete_At_Path_Input = {
	shipstation_response_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Delete_Elem_Input = {
	shipstation_response_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Delete_Key_Input = {
	shipstation_response_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "order" */
export type Order_Inc_Input = {
	order_readable_column?: InputMaybe<Scalars['Int']>;
	original_order_price?: InputMaybe<Scalars['Int']>;
	shipping_charged?: InputMaybe<Scalars['Int']>;
	stripe_tax_charged?: InputMaybe<Scalars['Int']>;
	stripe_total_charged?: InputMaybe<Scalars['Int']>;
	total?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order" */
export type Order_Insert_Input = {
	additional_origin_cart_id?: InputMaybe<Scalars['uuid']>;
	aftership_id?: InputMaybe<Scalars['String']>;
	aftership_status_tag?: InputMaybe<Scalars['String']>;
	attributed_to?: InputMaybe<Scalars['String']>;
	checkout_type?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	customer_email?: InputMaybe<Scalars['String']>;
	customer_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	order_items?: InputMaybe<Order_Item_Arr_Rel_Insert_Input>;
	order_readable_column?: InputMaybe<Scalars['Int']>;
	order_shipping_details?: InputMaybe<Order_Shipping_Details_Arr_Rel_Insert_Input>;
	order_status_histories?: InputMaybe<Order_Status_History_Arr_Rel_Insert_Input>;
	ordered_by_user_id?: InputMaybe<Scalars['uuid']>;
	original_order_price?: InputMaybe<Scalars['Int']>;
	receipt_url?: InputMaybe<Scalars['String']>;
	shipping_charged?: InputMaybe<Scalars['Int']>;
	shipping_label_url?: InputMaybe<Scalars['String']>;
	shipping_origin?: InputMaybe<Scalars['String']>;
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
	shipstation_ship_price?: InputMaybe<Scalars['String']>;
	shipstation_shipment_id?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	stripe_customer_id?: InputMaybe<Scalars['String']>;
	stripe_payment_id?: InputMaybe<Scalars['String']>;
	stripe_session_id?: InputMaybe<Scalars['String']>;
	stripe_tax_charged?: InputMaybe<Scalars['Int']>;
	stripe_total_charged?: InputMaybe<Scalars['Int']>;
	team_store?: InputMaybe<Team_Store_Obj_Rel_Insert_Input>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
	team_store_order_email_logs?: InputMaybe<Team_Store_Order_Email_Log_Arr_Rel_Insert_Input>;
	total?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	use_stripe_customer_shipping_data?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "order_item" */
export type Order_Item = {
	__typename?: 'order_item';
	cancelled: Scalars['Boolean'];
	created_at: Scalars['timestamptz'];
	custom_name?: Maybe<Scalars['String']>;
	custom_number?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	image_url?: Maybe<Scalars['String']>;
	/** An object relationship */
	order: Order;
	order_for?: Maybe<Scalars['String']>;
	order_id: Scalars['uuid'];
	/** An array relationship */
	order_item_options: Array<Order_Item_Option>;
	/** An aggregate relationship */
	order_item_options_aggregate: Order_Item_Option_Aggregate;
	/** An object relationship */
	order_package?: Maybe<Order_Package>;
	order_package_id?: Maybe<Scalars['uuid']>;
	order_shipping_details_id?: Maybe<Scalars['uuid']>;
	quantity: Scalars['Int'];
	/** An object relationship */
	store_product: Store_Product;
	store_product_id: Scalars['uuid'];
	total_price: Scalars['Int'];
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "order_item" */
export type Order_ItemOrder_Item_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

/** columns and relationships of "order_item" */
export type Order_ItemOrder_Item_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

/** aggregated selection of "order_item" */
export type Order_Item_Aggregate = {
	__typename?: 'order_item_aggregate';
	aggregate?: Maybe<Order_Item_Aggregate_Fields>;
	nodes: Array<Order_Item>;
};

export type Order_Item_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Order_Item_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Order_Item_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Order_Item_Aggregate_Bool_Exp_Count>;
};

export type Order_Item_Aggregate_Bool_Exp_Bool_And = {
	arguments: Order_Item_Select_Column_Order_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Item_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Order_Item_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Order_Item_Select_Column_Order_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Item_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Order_Item_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Order_Item_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Item_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_item" */
export type Order_Item_Aggregate_Fields = {
	__typename?: 'order_item_aggregate_fields';
	avg?: Maybe<Order_Item_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Order_Item_Max_Fields>;
	min?: Maybe<Order_Item_Min_Fields>;
	stddev?: Maybe<Order_Item_Stddev_Fields>;
	stddev_pop?: Maybe<Order_Item_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Order_Item_Stddev_Samp_Fields>;
	sum?: Maybe<Order_Item_Sum_Fields>;
	var_pop?: Maybe<Order_Item_Var_Pop_Fields>;
	var_samp?: Maybe<Order_Item_Var_Samp_Fields>;
	variance?: Maybe<Order_Item_Variance_Fields>;
};

/** aggregate fields of "order_item" */
export type Order_Item_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Order_Item_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_item" */
export type Order_Item_Aggregate_Order_By = {
	avg?: InputMaybe<Order_Item_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Order_Item_Max_Order_By>;
	min?: InputMaybe<Order_Item_Min_Order_By>;
	stddev?: InputMaybe<Order_Item_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Order_Item_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Order_Item_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Order_Item_Sum_Order_By>;
	var_pop?: InputMaybe<Order_Item_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Order_Item_Var_Samp_Order_By>;
	variance?: InputMaybe<Order_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_item" */
export type Order_Item_Arr_Rel_Insert_Input = {
	data: Array<Order_Item_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Order_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Item_Avg_Fields = {
	__typename?: 'order_item_avg_fields';
	quantity?: Maybe<Scalars['Float']>;
	total_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_item" */
export type Order_Item_Avg_Order_By = {
	quantity?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_item". All fields are combined with a logical 'AND'. */
export type Order_Item_Bool_Exp = {
	_and?: InputMaybe<Array<Order_Item_Bool_Exp>>;
	_not?: InputMaybe<Order_Item_Bool_Exp>;
	_or?: InputMaybe<Array<Order_Item_Bool_Exp>>;
	cancelled?: InputMaybe<Boolean_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	custom_name?: InputMaybe<String_Comparison_Exp>;
	custom_number?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	image_url?: InputMaybe<String_Comparison_Exp>;
	order?: InputMaybe<Order_Bool_Exp>;
	order_for?: InputMaybe<String_Comparison_Exp>;
	order_id?: InputMaybe<Uuid_Comparison_Exp>;
	order_item_options?: InputMaybe<Order_Item_Option_Bool_Exp>;
	order_item_options_aggregate?: InputMaybe<Order_Item_Option_Aggregate_Bool_Exp>;
	order_package?: InputMaybe<Order_Package_Bool_Exp>;
	order_package_id?: InputMaybe<Uuid_Comparison_Exp>;
	order_shipping_details_id?: InputMaybe<Uuid_Comparison_Exp>;
	quantity?: InputMaybe<Int_Comparison_Exp>;
	store_product?: InputMaybe<Store_Product_Bool_Exp>;
	store_product_id?: InputMaybe<Uuid_Comparison_Exp>;
	total_price?: InputMaybe<Int_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_item" */
export enum Order_Item_Constraint {
	/** unique or primary key constraint on columns "id" */
	OrderItemPkey = 'order_item_pkey'
}

/** input type for incrementing numeric columns in table "order_item" */
export type Order_Item_Inc_Input = {
	quantity?: InputMaybe<Scalars['Int']>;
	total_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_item" */
export type Order_Item_Insert_Input = {
	cancelled?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_name?: InputMaybe<Scalars['String']>;
	custom_number?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
	order_for?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['uuid']>;
	order_item_options?: InputMaybe<Order_Item_Option_Arr_Rel_Insert_Input>;
	order_package?: InputMaybe<Order_Package_Obj_Rel_Insert_Input>;
	order_package_id?: InputMaybe<Scalars['uuid']>;
	order_shipping_details_id?: InputMaybe<Scalars['uuid']>;
	quantity?: InputMaybe<Scalars['Int']>;
	store_product?: InputMaybe<Store_Product_Obj_Rel_Insert_Input>;
	store_product_id?: InputMaybe<Scalars['uuid']>;
	total_price?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Item_Max_Fields = {
	__typename?: 'order_item_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	custom_name?: Maybe<Scalars['String']>;
	custom_number?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	order_for?: Maybe<Scalars['String']>;
	order_id?: Maybe<Scalars['uuid']>;
	order_package_id?: Maybe<Scalars['uuid']>;
	order_shipping_details_id?: Maybe<Scalars['uuid']>;
	quantity?: Maybe<Scalars['Int']>;
	store_product_id?: Maybe<Scalars['uuid']>;
	total_price?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_item" */
export type Order_Item_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	custom_name?: InputMaybe<Order_By>;
	custom_number?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	order_for?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	order_package_id?: InputMaybe<Order_By>;
	order_shipping_details_id?: InputMaybe<Order_By>;
	quantity?: InputMaybe<Order_By>;
	store_product_id?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Item_Min_Fields = {
	__typename?: 'order_item_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	custom_name?: Maybe<Scalars['String']>;
	custom_number?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	order_for?: Maybe<Scalars['String']>;
	order_id?: Maybe<Scalars['uuid']>;
	order_package_id?: Maybe<Scalars['uuid']>;
	order_shipping_details_id?: Maybe<Scalars['uuid']>;
	quantity?: Maybe<Scalars['Int']>;
	store_product_id?: Maybe<Scalars['uuid']>;
	total_price?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_item" */
export type Order_Item_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	custom_name?: InputMaybe<Order_By>;
	custom_number?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	order_for?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	order_package_id?: InputMaybe<Order_By>;
	order_shipping_details_id?: InputMaybe<Order_By>;
	quantity?: InputMaybe<Order_By>;
	store_product_id?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_item" */
export type Order_Item_Mutation_Response = {
	__typename?: 'order_item_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Order_Item>;
};

/** input type for inserting object relation for remote table "order_item" */
export type Order_Item_Obj_Rel_Insert_Input = {
	data: Order_Item_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Order_Item_On_Conflict>;
};

/** on_conflict condition type for table "order_item" */
export type Order_Item_On_Conflict = {
	constraint: Order_Item_Constraint;
	update_columns?: Array<Order_Item_Update_Column>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

/** columns and relationships of "order_item_option" */
export type Order_Item_Option = {
	__typename?: 'order_item_option';
	/** An object relationship */
	available_option: Available_Option;
	available_option_id: Scalars['uuid'];
	/** An object relationship */
	available_option_value: Available_Option_Value;
	available_option_value_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	image_url?: Maybe<Scalars['String']>;
	/** An object relationship */
	order_item: Order_Item;
	order_item_id: Scalars['uuid'];
	price_modifier: Scalars['Int'];
	updated_at: Scalars['timestamptz'];
	value: Scalars['String'];
};

/** aggregated selection of "order_item_option" */
export type Order_Item_Option_Aggregate = {
	__typename?: 'order_item_option_aggregate';
	aggregate?: Maybe<Order_Item_Option_Aggregate_Fields>;
	nodes: Array<Order_Item_Option>;
};

export type Order_Item_Option_Aggregate_Bool_Exp = {
	count?: InputMaybe<Order_Item_Option_Aggregate_Bool_Exp_Count>;
};

export type Order_Item_Option_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Item_Option_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_item_option" */
export type Order_Item_Option_Aggregate_Fields = {
	__typename?: 'order_item_option_aggregate_fields';
	avg?: Maybe<Order_Item_Option_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Order_Item_Option_Max_Fields>;
	min?: Maybe<Order_Item_Option_Min_Fields>;
	stddev?: Maybe<Order_Item_Option_Stddev_Fields>;
	stddev_pop?: Maybe<Order_Item_Option_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Order_Item_Option_Stddev_Samp_Fields>;
	sum?: Maybe<Order_Item_Option_Sum_Fields>;
	var_pop?: Maybe<Order_Item_Option_Var_Pop_Fields>;
	var_samp?: Maybe<Order_Item_Option_Var_Samp_Fields>;
	variance?: Maybe<Order_Item_Option_Variance_Fields>;
};

/** aggregate fields of "order_item_option" */
export type Order_Item_Option_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_item_option" */
export type Order_Item_Option_Aggregate_Order_By = {
	avg?: InputMaybe<Order_Item_Option_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Order_Item_Option_Max_Order_By>;
	min?: InputMaybe<Order_Item_Option_Min_Order_By>;
	stddev?: InputMaybe<Order_Item_Option_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Order_Item_Option_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Order_Item_Option_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Order_Item_Option_Sum_Order_By>;
	var_pop?: InputMaybe<Order_Item_Option_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Order_Item_Option_Var_Samp_Order_By>;
	variance?: InputMaybe<Order_Item_Option_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_item_option" */
export type Order_Item_Option_Arr_Rel_Insert_Input = {
	data: Array<Order_Item_Option_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Order_Item_Option_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Item_Option_Avg_Fields = {
	__typename?: 'order_item_option_avg_fields';
	price_modifier?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_item_option" */
export type Order_Item_Option_Avg_Order_By = {
	price_modifier?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_item_option". All fields are combined with a logical 'AND'. */
export type Order_Item_Option_Bool_Exp = {
	_and?: InputMaybe<Array<Order_Item_Option_Bool_Exp>>;
	_not?: InputMaybe<Order_Item_Option_Bool_Exp>;
	_or?: InputMaybe<Array<Order_Item_Option_Bool_Exp>>;
	available_option?: InputMaybe<Available_Option_Bool_Exp>;
	available_option_id?: InputMaybe<Uuid_Comparison_Exp>;
	available_option_value?: InputMaybe<Available_Option_Value_Bool_Exp>;
	available_option_value_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	image_url?: InputMaybe<String_Comparison_Exp>;
	order_item?: InputMaybe<Order_Item_Bool_Exp>;
	order_item_id?: InputMaybe<Uuid_Comparison_Exp>;
	price_modifier?: InputMaybe<Int_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_item_option" */
export enum Order_Item_Option_Constraint {
	/** unique or primary key constraint on columns "id" */
	OrderItemOptionPkey = 'order_item_option_pkey'
}

/** input type for incrementing numeric columns in table "order_item_option" */
export type Order_Item_Option_Inc_Input = {
	price_modifier?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_item_option" */
export type Order_Item_Option_Insert_Input = {
	available_option?: InputMaybe<Available_Option_Obj_Rel_Insert_Input>;
	available_option_id?: InputMaybe<Scalars['uuid']>;
	available_option_value?: InputMaybe<Available_Option_Value_Obj_Rel_Insert_Input>;
	available_option_value_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	order_item?: InputMaybe<Order_Item_Obj_Rel_Insert_Input>;
	order_item_id?: InputMaybe<Scalars['uuid']>;
	price_modifier?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Item_Option_Max_Fields = {
	__typename?: 'order_item_option_max_fields';
	available_option_id?: Maybe<Scalars['uuid']>;
	available_option_value_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	order_item_id?: Maybe<Scalars['uuid']>;
	price_modifier?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "order_item_option" */
export type Order_Item_Option_Max_Order_By = {
	available_option_id?: InputMaybe<Order_By>;
	available_option_value_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	order_item_id?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Item_Option_Min_Fields = {
	__typename?: 'order_item_option_min_fields';
	available_option_id?: Maybe<Scalars['uuid']>;
	available_option_value_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	order_item_id?: Maybe<Scalars['uuid']>;
	price_modifier?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "order_item_option" */
export type Order_Item_Option_Min_Order_By = {
	available_option_id?: InputMaybe<Order_By>;
	available_option_value_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	order_item_id?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_item_option" */
export type Order_Item_Option_Mutation_Response = {
	__typename?: 'order_item_option_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Order_Item_Option>;
};

/** on_conflict condition type for table "order_item_option" */
export type Order_Item_Option_On_Conflict = {
	constraint: Order_Item_Option_Constraint;
	update_columns?: Array<Order_Item_Option_Update_Column>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "order_item_option". */
export type Order_Item_Option_Order_By = {
	available_option?: InputMaybe<Available_Option_Order_By>;
	available_option_id?: InputMaybe<Order_By>;
	available_option_value?: InputMaybe<Available_Option_Value_Order_By>;
	available_option_value_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	order_item?: InputMaybe<Order_Item_Order_By>;
	order_item_id?: InputMaybe<Order_By>;
	price_modifier?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_item_option */
export type Order_Item_Option_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "order_item_option" */
export enum Order_Item_Option_Select_Column {
	/** column name */
	AvailableOptionId = 'available_option_id',
	/** column name */
	AvailableOptionValueId = 'available_option_value_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	OrderItemId = 'order_item_id',
	/** column name */
	PriceModifier = 'price_modifier',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	Value = 'value'
}

/** input type for updating data in table "order_item_option" */
export type Order_Item_Option_Set_Input = {
	available_option_id?: InputMaybe<Scalars['uuid']>;
	available_option_value_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	order_item_id?: InputMaybe<Scalars['uuid']>;
	price_modifier?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Order_Item_Option_Stddev_Fields = {
	__typename?: 'order_item_option_stddev_fields';
	price_modifier?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_item_option" */
export type Order_Item_Option_Stddev_Order_By = {
	price_modifier?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Item_Option_Stddev_Pop_Fields = {
	__typename?: 'order_item_option_stddev_pop_fields';
	price_modifier?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_item_option" */
export type Order_Item_Option_Stddev_Pop_Order_By = {
	price_modifier?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Item_Option_Stddev_Samp_Fields = {
	__typename?: 'order_item_option_stddev_samp_fields';
	price_modifier?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_item_option" */
export type Order_Item_Option_Stddev_Samp_Order_By = {
	price_modifier?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_item_option" */
export type Order_Item_Option_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Order_Item_Option_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Item_Option_Stream_Cursor_Value_Input = {
	available_option_id?: InputMaybe<Scalars['uuid']>;
	available_option_value_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	order_item_id?: InputMaybe<Scalars['uuid']>;
	price_modifier?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Order_Item_Option_Sum_Fields = {
	__typename?: 'order_item_option_sum_fields';
	price_modifier?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_item_option" */
export type Order_Item_Option_Sum_Order_By = {
	price_modifier?: InputMaybe<Order_By>;
};

/** update columns of table "order_item_option" */
export enum Order_Item_Option_Update_Column {
	/** column name */
	AvailableOptionId = 'available_option_id',
	/** column name */
	AvailableOptionValueId = 'available_option_value_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	OrderItemId = 'order_item_id',
	/** column name */
	PriceModifier = 'price_modifier',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	Value = 'value'
}

export type Order_Item_Option_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Order_Item_Option_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Order_Item_Option_Set_Input>;
	/** filter the rows which have to be updated */
	where: Order_Item_Option_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_Item_Option_Var_Pop_Fields = {
	__typename?: 'order_item_option_var_pop_fields';
	price_modifier?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_item_option" */
export type Order_Item_Option_Var_Pop_Order_By = {
	price_modifier?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Item_Option_Var_Samp_Fields = {
	__typename?: 'order_item_option_var_samp_fields';
	price_modifier?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_item_option" */
export type Order_Item_Option_Var_Samp_Order_By = {
	price_modifier?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Item_Option_Variance_Fields = {
	__typename?: 'order_item_option_variance_fields';
	price_modifier?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_item_option" */
export type Order_Item_Option_Variance_Order_By = {
	price_modifier?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "order_item". */
export type Order_Item_Order_By = {
	cancelled?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	custom_name?: InputMaybe<Order_By>;
	custom_number?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_Order_By>;
	order_for?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	order_item_options_aggregate?: InputMaybe<Order_Item_Option_Aggregate_Order_By>;
	order_package?: InputMaybe<Order_Package_Order_By>;
	order_package_id?: InputMaybe<Order_By>;
	order_shipping_details_id?: InputMaybe<Order_By>;
	quantity?: InputMaybe<Order_By>;
	store_product?: InputMaybe<Store_Product_Order_By>;
	store_product_id?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_item */
export type Order_Item_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "order_item" */
export enum Order_Item_Select_Column {
	/** column name */
	Cancelled = 'cancelled',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomName = 'custom_name',
	/** column name */
	CustomNumber = 'custom_number',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	OrderFor = 'order_for',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	OrderPackageId = 'order_package_id',
	/** column name */
	OrderShippingDetailsId = 'order_shipping_details_id',
	/** column name */
	Quantity = 'quantity',
	/** column name */
	StoreProductId = 'store_product_id',
	/** column name */
	TotalPrice = 'total_price',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** select "order_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "order_item" */
export enum Order_Item_Select_Column_Order_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	Cancelled = 'cancelled'
}

/** select "order_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "order_item" */
export enum Order_Item_Select_Column_Order_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	Cancelled = 'cancelled'
}

/** input type for updating data in table "order_item" */
export type Order_Item_Set_Input = {
	cancelled?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_name?: InputMaybe<Scalars['String']>;
	custom_number?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	order_for?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['uuid']>;
	order_package_id?: InputMaybe<Scalars['uuid']>;
	order_shipping_details_id?: InputMaybe<Scalars['uuid']>;
	quantity?: InputMaybe<Scalars['Int']>;
	store_product_id?: InputMaybe<Scalars['uuid']>;
	total_price?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_Item_Stddev_Fields = {
	__typename?: 'order_item_stddev_fields';
	quantity?: Maybe<Scalars['Float']>;
	total_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_item" */
export type Order_Item_Stddev_Order_By = {
	quantity?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Item_Stddev_Pop_Fields = {
	__typename?: 'order_item_stddev_pop_fields';
	quantity?: Maybe<Scalars['Float']>;
	total_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_item" */
export type Order_Item_Stddev_Pop_Order_By = {
	quantity?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Item_Stddev_Samp_Fields = {
	__typename?: 'order_item_stddev_samp_fields';
	quantity?: Maybe<Scalars['Float']>;
	total_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_item" */
export type Order_Item_Stddev_Samp_Order_By = {
	quantity?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_item" */
export type Order_Item_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Order_Item_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Item_Stream_Cursor_Value_Input = {
	cancelled?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_name?: InputMaybe<Scalars['String']>;
	custom_number?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	order_for?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['uuid']>;
	order_package_id?: InputMaybe<Scalars['uuid']>;
	order_shipping_details_id?: InputMaybe<Scalars['uuid']>;
	quantity?: InputMaybe<Scalars['Int']>;
	store_product_id?: InputMaybe<Scalars['uuid']>;
	total_price?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Order_Item_Sum_Fields = {
	__typename?: 'order_item_sum_fields';
	quantity?: Maybe<Scalars['Int']>;
	total_price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_item" */
export type Order_Item_Sum_Order_By = {
	quantity?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
};

/** update columns of table "order_item" */
export enum Order_Item_Update_Column {
	/** column name */
	Cancelled = 'cancelled',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomName = 'custom_name',
	/** column name */
	CustomNumber = 'custom_number',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	OrderFor = 'order_for',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	OrderPackageId = 'order_package_id',
	/** column name */
	OrderShippingDetailsId = 'order_shipping_details_id',
	/** column name */
	Quantity = 'quantity',
	/** column name */
	StoreProductId = 'store_product_id',
	/** column name */
	TotalPrice = 'total_price',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Order_Item_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Order_Item_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Order_Item_Set_Input>;
	/** filter the rows which have to be updated */
	where: Order_Item_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_Item_Var_Pop_Fields = {
	__typename?: 'order_item_var_pop_fields';
	quantity?: Maybe<Scalars['Float']>;
	total_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_item" */
export type Order_Item_Var_Pop_Order_By = {
	quantity?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Item_Var_Samp_Fields = {
	__typename?: 'order_item_var_samp_fields';
	quantity?: Maybe<Scalars['Float']>;
	total_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_item" */
export type Order_Item_Var_Samp_Order_By = {
	quantity?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Item_Variance_Fields = {
	__typename?: 'order_item_variance_fields';
	quantity?: Maybe<Scalars['Float']>;
	total_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_item" */
export type Order_Item_Variance_Order_By = {
	quantity?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Order_Max_Fields = {
	__typename?: 'order_max_fields';
	additional_origin_cart_id?: Maybe<Scalars['uuid']>;
	aftership_id?: Maybe<Scalars['String']>;
	aftership_status_tag?: Maybe<Scalars['String']>;
	attributed_to?: Maybe<Scalars['String']>;
	checkout_type?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	customer_email?: Maybe<Scalars['String']>;
	customer_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	order_readable_column?: Maybe<Scalars['Int']>;
	ordered_by_user_id?: Maybe<Scalars['uuid']>;
	original_order_price?: Maybe<Scalars['Int']>;
	receipt_url?: Maybe<Scalars['String']>;
	shipping_charged?: Maybe<Scalars['Int']>;
	shipping_label_url?: Maybe<Scalars['String']>;
	shipping_origin?: Maybe<Scalars['String']>;
	shipstation_ship_price?: Maybe<Scalars['String']>;
	shipstation_shipment_id?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	stripe_customer_id?: Maybe<Scalars['String']>;
	stripe_payment_id?: Maybe<Scalars['String']>;
	stripe_session_id?: Maybe<Scalars['String']>;
	stripe_tax_charged?: Maybe<Scalars['Int']>;
	stripe_total_charged?: Maybe<Scalars['Int']>;
	team_store_id?: Maybe<Scalars['uuid']>;
	total?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order" */
export type Order_Max_Order_By = {
	additional_origin_cart_id?: InputMaybe<Order_By>;
	aftership_id?: InputMaybe<Order_By>;
	aftership_status_tag?: InputMaybe<Order_By>;
	attributed_to?: InputMaybe<Order_By>;
	checkout_type?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	customer_email?: InputMaybe<Order_By>;
	customer_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order_readable_column?: InputMaybe<Order_By>;
	ordered_by_user_id?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	receipt_url?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	shipping_label_url?: InputMaybe<Order_By>;
	shipping_origin?: InputMaybe<Order_By>;
	shipstation_ship_price?: InputMaybe<Order_By>;
	shipstation_shipment_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	stripe_customer_id?: InputMaybe<Order_By>;
	stripe_payment_id?: InputMaybe<Order_By>;
	stripe_session_id?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	team_store_id?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Min_Fields = {
	__typename?: 'order_min_fields';
	additional_origin_cart_id?: Maybe<Scalars['uuid']>;
	aftership_id?: Maybe<Scalars['String']>;
	aftership_status_tag?: Maybe<Scalars['String']>;
	attributed_to?: Maybe<Scalars['String']>;
	checkout_type?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	customer_email?: Maybe<Scalars['String']>;
	customer_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	order_readable_column?: Maybe<Scalars['Int']>;
	ordered_by_user_id?: Maybe<Scalars['uuid']>;
	original_order_price?: Maybe<Scalars['Int']>;
	receipt_url?: Maybe<Scalars['String']>;
	shipping_charged?: Maybe<Scalars['Int']>;
	shipping_label_url?: Maybe<Scalars['String']>;
	shipping_origin?: Maybe<Scalars['String']>;
	shipstation_ship_price?: Maybe<Scalars['String']>;
	shipstation_shipment_id?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	stripe_customer_id?: Maybe<Scalars['String']>;
	stripe_payment_id?: Maybe<Scalars['String']>;
	stripe_session_id?: Maybe<Scalars['String']>;
	stripe_tax_charged?: Maybe<Scalars['Int']>;
	stripe_total_charged?: Maybe<Scalars['Int']>;
	team_store_id?: Maybe<Scalars['uuid']>;
	total?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order" */
export type Order_Min_Order_By = {
	additional_origin_cart_id?: InputMaybe<Order_By>;
	aftership_id?: InputMaybe<Order_By>;
	aftership_status_tag?: InputMaybe<Order_By>;
	attributed_to?: InputMaybe<Order_By>;
	checkout_type?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	customer_email?: InputMaybe<Order_By>;
	customer_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order_readable_column?: InputMaybe<Order_By>;
	ordered_by_user_id?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	receipt_url?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	shipping_label_url?: InputMaybe<Order_By>;
	shipping_origin?: InputMaybe<Order_By>;
	shipstation_ship_price?: InputMaybe<Order_By>;
	shipstation_shipment_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	stripe_customer_id?: InputMaybe<Order_By>;
	stripe_payment_id?: InputMaybe<Order_By>;
	stripe_session_id?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	team_store_id?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order" */
export type Order_Mutation_Response = {
	__typename?: 'order_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Order>;
};

/** input type for inserting object relation for remote table "order" */
export type Order_Obj_Rel_Insert_Input = {
	data: Order_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Order_On_Conflict>;
};

/** on_conflict condition type for table "order" */
export type Order_On_Conflict = {
	constraint: Order_Constraint;
	update_columns?: Array<Order_Update_Column>;
	where?: InputMaybe<Order_Bool_Exp>;
};

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
	additional_origin_cart_id?: InputMaybe<Order_By>;
	aftership_id?: InputMaybe<Order_By>;
	aftership_status_tag?: InputMaybe<Order_By>;
	attributed_to?: InputMaybe<Order_By>;
	checkout_type?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	customer_email?: InputMaybe<Order_By>;
	customer_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order_items_aggregate?: InputMaybe<Order_Item_Aggregate_Order_By>;
	order_readable_column?: InputMaybe<Order_By>;
	order_shipping_details_aggregate?: InputMaybe<Order_Shipping_Details_Aggregate_Order_By>;
	order_status_histories_aggregate?: InputMaybe<Order_Status_History_Aggregate_Order_By>;
	ordered_by_user_id?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	receipt_url?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	shipping_label_url?: InputMaybe<Order_By>;
	shipping_origin?: InputMaybe<Order_By>;
	shipstation_response_data?: InputMaybe<Order_By>;
	shipstation_ship_price?: InputMaybe<Order_By>;
	shipstation_shipment_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	stripe_customer_id?: InputMaybe<Order_By>;
	stripe_payment_id?: InputMaybe<Order_By>;
	stripe_session_id?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	team_store?: InputMaybe<Team_Store_Order_By>;
	team_store_id?: InputMaybe<Order_By>;
	team_store_order_email_logs_aggregate?: InputMaybe<Team_Store_Order_Email_Log_Aggregate_Order_By>;
	total?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	use_stripe_customer_shipping_data?: InputMaybe<Order_By>;
};

/** columns and relationships of "order_package" */
export type Order_Package = {
	__typename?: 'order_package';
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	image_url?: Maybe<Scalars['String']>;
	/** An array relationship */
	order_items: Array<Order_Item>;
	/** An aggregate relationship */
	order_items_aggregate: Order_Item_Aggregate;
	/** An object relationship */
	store_package: Store_Package;
	store_package_id: Scalars['uuid'];
	total_price: Scalars['Int'];
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "order_package" */
export type Order_PackageOrder_ItemsArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

/** columns and relationships of "order_package" */
export type Order_PackageOrder_Items_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

/** aggregated selection of "order_package" */
export type Order_Package_Aggregate = {
	__typename?: 'order_package_aggregate';
	aggregate?: Maybe<Order_Package_Aggregate_Fields>;
	nodes: Array<Order_Package>;
};

export type Order_Package_Aggregate_Bool_Exp = {
	count?: InputMaybe<Order_Package_Aggregate_Bool_Exp_Count>;
};

export type Order_Package_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Order_Package_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Package_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_package" */
export type Order_Package_Aggregate_Fields = {
	__typename?: 'order_package_aggregate_fields';
	avg?: Maybe<Order_Package_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Order_Package_Max_Fields>;
	min?: Maybe<Order_Package_Min_Fields>;
	stddev?: Maybe<Order_Package_Stddev_Fields>;
	stddev_pop?: Maybe<Order_Package_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Order_Package_Stddev_Samp_Fields>;
	sum?: Maybe<Order_Package_Sum_Fields>;
	var_pop?: Maybe<Order_Package_Var_Pop_Fields>;
	var_samp?: Maybe<Order_Package_Var_Samp_Fields>;
	variance?: Maybe<Order_Package_Variance_Fields>;
};

/** aggregate fields of "order_package" */
export type Order_Package_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Order_Package_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_package" */
export type Order_Package_Aggregate_Order_By = {
	avg?: InputMaybe<Order_Package_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Order_Package_Max_Order_By>;
	min?: InputMaybe<Order_Package_Min_Order_By>;
	stddev?: InputMaybe<Order_Package_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Order_Package_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Order_Package_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Order_Package_Sum_Order_By>;
	var_pop?: InputMaybe<Order_Package_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Order_Package_Var_Samp_Order_By>;
	variance?: InputMaybe<Order_Package_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_package" */
export type Order_Package_Arr_Rel_Insert_Input = {
	data: Array<Order_Package_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Order_Package_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Package_Avg_Fields = {
	__typename?: 'order_package_avg_fields';
	total_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_package" */
export type Order_Package_Avg_Order_By = {
	total_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_package". All fields are combined with a logical 'AND'. */
export type Order_Package_Bool_Exp = {
	_and?: InputMaybe<Array<Order_Package_Bool_Exp>>;
	_not?: InputMaybe<Order_Package_Bool_Exp>;
	_or?: InputMaybe<Array<Order_Package_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	image_url?: InputMaybe<String_Comparison_Exp>;
	order_items?: InputMaybe<Order_Item_Bool_Exp>;
	order_items_aggregate?: InputMaybe<Order_Item_Aggregate_Bool_Exp>;
	store_package?: InputMaybe<Store_Package_Bool_Exp>;
	store_package_id?: InputMaybe<Uuid_Comparison_Exp>;
	total_price?: InputMaybe<Int_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_package" */
export enum Order_Package_Constraint {
	/** unique or primary key constraint on columns "id" */
	OrderPackagePkey = 'order_package_pkey'
}

/** input type for incrementing numeric columns in table "order_package" */
export type Order_Package_Inc_Input = {
	total_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_package" */
export type Order_Package_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	order_items?: InputMaybe<Order_Item_Arr_Rel_Insert_Input>;
	store_package?: InputMaybe<Store_Package_Obj_Rel_Insert_Input>;
	store_package_id?: InputMaybe<Scalars['uuid']>;
	total_price?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Package_Max_Fields = {
	__typename?: 'order_package_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	store_package_id?: Maybe<Scalars['uuid']>;
	total_price?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_package" */
export type Order_Package_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	store_package_id?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Package_Min_Fields = {
	__typename?: 'order_package_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	store_package_id?: Maybe<Scalars['uuid']>;
	total_price?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_package" */
export type Order_Package_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	store_package_id?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_package" */
export type Order_Package_Mutation_Response = {
	__typename?: 'order_package_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Order_Package>;
};

/** input type for inserting object relation for remote table "order_package" */
export type Order_Package_Obj_Rel_Insert_Input = {
	data: Order_Package_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Order_Package_On_Conflict>;
};

/** on_conflict condition type for table "order_package" */
export type Order_Package_On_Conflict = {
	constraint: Order_Package_Constraint;
	update_columns?: Array<Order_Package_Update_Column>;
	where?: InputMaybe<Order_Package_Bool_Exp>;
};

/** Ordering options when selecting data from "order_package". */
export type Order_Package_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	order_items_aggregate?: InputMaybe<Order_Item_Aggregate_Order_By>;
	store_package?: InputMaybe<Store_Package_Order_By>;
	store_package_id?: InputMaybe<Order_By>;
	total_price?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_package */
export type Order_Package_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "order_package" */
export enum Order_Package_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	StorePackageId = 'store_package_id',
	/** column name */
	TotalPrice = 'total_price',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_package" */
export type Order_Package_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	store_package_id?: InputMaybe<Scalars['uuid']>;
	total_price?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_Package_Stddev_Fields = {
	__typename?: 'order_package_stddev_fields';
	total_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_package" */
export type Order_Package_Stddev_Order_By = {
	total_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Package_Stddev_Pop_Fields = {
	__typename?: 'order_package_stddev_pop_fields';
	total_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_package" */
export type Order_Package_Stddev_Pop_Order_By = {
	total_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Package_Stddev_Samp_Fields = {
	__typename?: 'order_package_stddev_samp_fields';
	total_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_package" */
export type Order_Package_Stddev_Samp_Order_By = {
	total_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_package" */
export type Order_Package_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Order_Package_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Package_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	store_package_id?: InputMaybe<Scalars['uuid']>;
	total_price?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Order_Package_Sum_Fields = {
	__typename?: 'order_package_sum_fields';
	total_price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_package" */
export type Order_Package_Sum_Order_By = {
	total_price?: InputMaybe<Order_By>;
};

/** update columns of table "order_package" */
export enum Order_Package_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	StorePackageId = 'store_package_id',
	/** column name */
	TotalPrice = 'total_price',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Order_Package_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Order_Package_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Order_Package_Set_Input>;
	/** filter the rows which have to be updated */
	where: Order_Package_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_Package_Var_Pop_Fields = {
	__typename?: 'order_package_var_pop_fields';
	total_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_package" */
export type Order_Package_Var_Pop_Order_By = {
	total_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Package_Var_Samp_Fields = {
	__typename?: 'order_package_var_samp_fields';
	total_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_package" */
export type Order_Package_Var_Samp_Order_By = {
	total_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Package_Variance_Fields = {
	__typename?: 'order_package_variance_fields';
	total_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_package" */
export type Order_Package_Variance_Order_By = {
	total_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order */
export type Order_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Prepend_Input = {
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "order" */
export enum Order_Select_Column {
	/** column name */
	AdditionalOriginCartId = 'additional_origin_cart_id',
	/** column name */
	AftershipId = 'aftership_id',
	/** column name */
	AftershipStatusTag = 'aftership_status_tag',
	/** column name */
	AttributedTo = 'attributed_to',
	/** column name */
	CheckoutType = 'checkout_type',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomerEmail = 'customer_email',
	/** column name */
	CustomerName = 'customer_name',
	/** column name */
	Id = 'id',
	/** column name */
	OrderReadableColumn = 'order_readable_column',
	/** column name */
	OrderedByUserId = 'ordered_by_user_id',
	/** column name */
	OriginalOrderPrice = 'original_order_price',
	/** column name */
	ReceiptUrl = 'receipt_url',
	/** column name */
	ShippingCharged = 'shipping_charged',
	/** column name */
	ShippingLabelUrl = 'shipping_label_url',
	/** column name */
	ShippingOrigin = 'shipping_origin',
	/** column name */
	ShipstationResponseData = 'shipstation_response_data',
	/** column name */
	ShipstationShipPrice = 'shipstation_ship_price',
	/** column name */
	ShipstationShipmentId = 'shipstation_shipment_id',
	/** column name */
	Status = 'status',
	/** column name */
	StripeCustomerId = 'stripe_customer_id',
	/** column name */
	StripePaymentId = 'stripe_payment_id',
	/** column name */
	StripeSessionId = 'stripe_session_id',
	/** column name */
	StripeTaxCharged = 'stripe_tax_charged',
	/** column name */
	StripeTotalCharged = 'stripe_total_charged',
	/** column name */
	TeamStoreId = 'team_store_id',
	/** column name */
	Total = 'total',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UseStripeCustomerShippingData = 'use_stripe_customer_shipping_data'
}

/** select "order_aggregate_bool_exp_bool_and_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	UseStripeCustomerShippingData = 'use_stripe_customer_shipping_data'
}

/** select "order_aggregate_bool_exp_bool_or_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	UseStripeCustomerShippingData = 'use_stripe_customer_shipping_data'
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
	additional_origin_cart_id?: InputMaybe<Scalars['uuid']>;
	aftership_id?: InputMaybe<Scalars['String']>;
	aftership_status_tag?: InputMaybe<Scalars['String']>;
	attributed_to?: InputMaybe<Scalars['String']>;
	checkout_type?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	customer_email?: InputMaybe<Scalars['String']>;
	customer_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	order_readable_column?: InputMaybe<Scalars['Int']>;
	ordered_by_user_id?: InputMaybe<Scalars['uuid']>;
	original_order_price?: InputMaybe<Scalars['Int']>;
	receipt_url?: InputMaybe<Scalars['String']>;
	shipping_charged?: InputMaybe<Scalars['Int']>;
	shipping_label_url?: InputMaybe<Scalars['String']>;
	shipping_origin?: InputMaybe<Scalars['String']>;
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
	shipstation_ship_price?: InputMaybe<Scalars['String']>;
	shipstation_shipment_id?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	stripe_customer_id?: InputMaybe<Scalars['String']>;
	stripe_payment_id?: InputMaybe<Scalars['String']>;
	stripe_session_id?: InputMaybe<Scalars['String']>;
	stripe_tax_charged?: InputMaybe<Scalars['Int']>;
	stripe_total_charged?: InputMaybe<Scalars['Int']>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
	total?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	use_stripe_customer_shipping_data?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "order_shipping_details" */
export type Order_Shipping_Details = {
	__typename?: 'order_shipping_details';
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	/** An object relationship */
	order: Order;
	order_id: Scalars['uuid'];
	origin: Scalars['String'];
	shipstation_response_data?: Maybe<Scalars['jsonb']>;
	shipstation_ship_price?: Maybe<Scalars['String']>;
	shipstation_shipment_id?: Maybe<Scalars['String']>;
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "order_shipping_details" */
export type Order_Shipping_DetailsShipstation_Response_DataArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "order_shipping_details" */
export type Order_Shipping_Details_Aggregate = {
	__typename?: 'order_shipping_details_aggregate';
	aggregate?: Maybe<Order_Shipping_Details_Aggregate_Fields>;
	nodes: Array<Order_Shipping_Details>;
};

export type Order_Shipping_Details_Aggregate_Bool_Exp = {
	count?: InputMaybe<Order_Shipping_Details_Aggregate_Bool_Exp_Count>;
};

export type Order_Shipping_Details_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Order_Shipping_Details_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_shipping_details" */
export type Order_Shipping_Details_Aggregate_Fields = {
	__typename?: 'order_shipping_details_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<Order_Shipping_Details_Max_Fields>;
	min?: Maybe<Order_Shipping_Details_Min_Fields>;
};

/** aggregate fields of "order_shipping_details" */
export type Order_Shipping_Details_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Order_Shipping_Details_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_shipping_details" */
export type Order_Shipping_Details_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Order_Shipping_Details_Max_Order_By>;
	min?: InputMaybe<Order_Shipping_Details_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Shipping_Details_Append_Input = {
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "order_shipping_details" */
export type Order_Shipping_Details_Arr_Rel_Insert_Input = {
	data: Array<Order_Shipping_Details_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Order_Shipping_Details_On_Conflict>;
};

/** Boolean expression to filter rows from the table "order_shipping_details". All fields are combined with a logical 'AND'. */
export type Order_Shipping_Details_Bool_Exp = {
	_and?: InputMaybe<Array<Order_Shipping_Details_Bool_Exp>>;
	_not?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
	_or?: InputMaybe<Array<Order_Shipping_Details_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	order?: InputMaybe<Order_Bool_Exp>;
	order_id?: InputMaybe<Uuid_Comparison_Exp>;
	origin?: InputMaybe<String_Comparison_Exp>;
	shipstation_response_data?: InputMaybe<Jsonb_Comparison_Exp>;
	shipstation_ship_price?: InputMaybe<String_Comparison_Exp>;
	shipstation_shipment_id?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_shipping_details" */
export enum Order_Shipping_Details_Constraint {
	/** unique or primary key constraint on columns "id" */
	OrderShippingDetailsPkey = 'order_shipping_details_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Shipping_Details_Delete_At_Path_Input = {
	shipstation_response_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Shipping_Details_Delete_Elem_Input = {
	shipstation_response_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Shipping_Details_Delete_Key_Input = {
	shipstation_response_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "order_shipping_details" */
export type Order_Shipping_Details_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
	order_id?: InputMaybe<Scalars['uuid']>;
	origin?: InputMaybe<Scalars['String']>;
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
	shipstation_ship_price?: InputMaybe<Scalars['String']>;
	shipstation_shipment_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Shipping_Details_Max_Fields = {
	__typename?: 'order_shipping_details_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	order_id?: Maybe<Scalars['uuid']>;
	origin?: Maybe<Scalars['String']>;
	shipstation_ship_price?: Maybe<Scalars['String']>;
	shipstation_shipment_id?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_shipping_details" */
export type Order_Shipping_Details_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	origin?: InputMaybe<Order_By>;
	shipstation_ship_price?: InputMaybe<Order_By>;
	shipstation_shipment_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Shipping_Details_Min_Fields = {
	__typename?: 'order_shipping_details_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	order_id?: Maybe<Scalars['uuid']>;
	origin?: Maybe<Scalars['String']>;
	shipstation_ship_price?: Maybe<Scalars['String']>;
	shipstation_shipment_id?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_shipping_details" */
export type Order_Shipping_Details_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	origin?: InputMaybe<Order_By>;
	shipstation_ship_price?: InputMaybe<Order_By>;
	shipstation_shipment_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_shipping_details" */
export type Order_Shipping_Details_Mutation_Response = {
	__typename?: 'order_shipping_details_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Order_Shipping_Details>;
};

/** on_conflict condition type for table "order_shipping_details" */
export type Order_Shipping_Details_On_Conflict = {
	constraint: Order_Shipping_Details_Constraint;
	update_columns?: Array<Order_Shipping_Details_Update_Column>;
	where?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "order_shipping_details". */
export type Order_Shipping_Details_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_Order_By>;
	order_id?: InputMaybe<Order_By>;
	origin?: InputMaybe<Order_By>;
	shipstation_response_data?: InputMaybe<Order_By>;
	shipstation_ship_price?: InputMaybe<Order_By>;
	shipstation_shipment_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_shipping_details */
export type Order_Shipping_Details_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Shipping_Details_Prepend_Input = {
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "order_shipping_details" */
export enum Order_Shipping_Details_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	Origin = 'origin',
	/** column name */
	ShipstationResponseData = 'shipstation_response_data',
	/** column name */
	ShipstationShipPrice = 'shipstation_ship_price',
	/** column name */
	ShipstationShipmentId = 'shipstation_shipment_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_shipping_details" */
export type Order_Shipping_Details_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	order_id?: InputMaybe<Scalars['uuid']>;
	origin?: InputMaybe<Scalars['String']>;
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
	shipstation_ship_price?: InputMaybe<Scalars['String']>;
	shipstation_shipment_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "order_shipping_details" */
export type Order_Shipping_Details_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Order_Shipping_Details_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Shipping_Details_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	order_id?: InputMaybe<Scalars['uuid']>;
	origin?: InputMaybe<Scalars['String']>;
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
	shipstation_ship_price?: InputMaybe<Scalars['String']>;
	shipstation_shipment_id?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "order_shipping_details" */
export enum Order_Shipping_Details_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	Origin = 'origin',
	/** column name */
	ShipstationResponseData = 'shipstation_response_data',
	/** column name */
	ShipstationShipPrice = 'shipstation_ship_price',
	/** column name */
	ShipstationShipmentId = 'shipstation_shipment_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Order_Shipping_Details_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Order_Shipping_Details_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Order_Shipping_Details_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Order_Shipping_Details_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Order_Shipping_Details_Delete_Key_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Order_Shipping_Details_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Order_Shipping_Details_Set_Input>;
	/** filter the rows which have to be updated */
	where: Order_Shipping_Details_Bool_Exp;
};

/** columns and relationships of "order_status_history" */
export type Order_Status_History = {
	__typename?: 'order_status_history';
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	notes?: Maybe<Scalars['String']>;
	/** An object relationship */
	order: Order;
	order_id: Scalars['uuid'];
	status: Scalars['String'];
	updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "order_status_history" */
export type Order_Status_History_Aggregate = {
	__typename?: 'order_status_history_aggregate';
	aggregate?: Maybe<Order_Status_History_Aggregate_Fields>;
	nodes: Array<Order_Status_History>;
};

export type Order_Status_History_Aggregate_Bool_Exp = {
	count?: InputMaybe<Order_Status_History_Aggregate_Bool_Exp_Count>;
};

export type Order_Status_History_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Order_Status_History_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Order_Status_History_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_status_history" */
export type Order_Status_History_Aggregate_Fields = {
	__typename?: 'order_status_history_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<Order_Status_History_Max_Fields>;
	min?: Maybe<Order_Status_History_Min_Fields>;
};

/** aggregate fields of "order_status_history" */
export type Order_Status_History_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Order_Status_History_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_status_history" */
export type Order_Status_History_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Order_Status_History_Max_Order_By>;
	min?: InputMaybe<Order_Status_History_Min_Order_By>;
};

/** input type for inserting array relation for remote table "order_status_history" */
export type Order_Status_History_Arr_Rel_Insert_Input = {
	data: Array<Order_Status_History_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Order_Status_History_On_Conflict>;
};

/** Boolean expression to filter rows from the table "order_status_history". All fields are combined with a logical 'AND'. */
export type Order_Status_History_Bool_Exp = {
	_and?: InputMaybe<Array<Order_Status_History_Bool_Exp>>;
	_not?: InputMaybe<Order_Status_History_Bool_Exp>;
	_or?: InputMaybe<Array<Order_Status_History_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	notes?: InputMaybe<String_Comparison_Exp>;
	order?: InputMaybe<Order_Bool_Exp>;
	order_id?: InputMaybe<Uuid_Comparison_Exp>;
	status?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_status_history" */
export enum Order_Status_History_Constraint {
	/** unique or primary key constraint on columns "id" */
	OrderProductionStatusPkey = 'order_production_status_pkey',
	/** unique or primary key constraint on columns "id" */
	OrderStatusHistoryPkey = 'order_status_history_pkey'
}

/** input type for inserting data into table "order_status_history" */
export type Order_Status_History_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	notes?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
	order_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Status_History_Max_Fields = {
	__typename?: 'order_status_history_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	notes?: Maybe<Scalars['String']>;
	order_id?: Maybe<Scalars['uuid']>;
	status?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order_status_history" */
export type Order_Status_History_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	notes?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Status_History_Min_Fields = {
	__typename?: 'order_status_history_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	notes?: Maybe<Scalars['String']>;
	order_id?: Maybe<Scalars['uuid']>;
	status?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order_status_history" */
export type Order_Status_History_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	notes?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_status_history" */
export type Order_Status_History_Mutation_Response = {
	__typename?: 'order_status_history_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Order_Status_History>;
};

/** on_conflict condition type for table "order_status_history" */
export type Order_Status_History_On_Conflict = {
	constraint: Order_Status_History_Constraint;
	update_columns?: Array<Order_Status_History_Update_Column>;
	where?: InputMaybe<Order_Status_History_Bool_Exp>;
};

/** Ordering options when selecting data from "order_status_history". */
export type Order_Status_History_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	notes?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_Order_By>;
	order_id?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_status_history */
export type Order_Status_History_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "order_status_history" */
export enum Order_Status_History_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	Notes = 'notes',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	Status = 'status',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_status_history" */
export type Order_Status_History_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	notes?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "order_status_history" */
export type Order_Status_History_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Order_Status_History_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Status_History_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	notes?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['uuid']>;
	status?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "order_status_history" */
export enum Order_Status_History_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	Notes = 'notes',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	Status = 'status',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Order_Status_History_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Order_Status_History_Set_Input>;
	/** filter the rows which have to be updated */
	where: Order_Status_History_Bool_Exp;
};

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
	__typename?: 'order_stddev_fields';
	order_readable_column?: Maybe<Scalars['Float']>;
	original_order_price?: Maybe<Scalars['Float']>;
	shipping_charged?: Maybe<Scalars['Float']>;
	stripe_tax_charged?: Maybe<Scalars['Float']>;
	stripe_total_charged?: Maybe<Scalars['Float']>;
	total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order" */
export type Order_Stddev_Order_By = {
	order_readable_column?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
	__typename?: 'order_stddev_pop_fields';
	order_readable_column?: Maybe<Scalars['Float']>;
	original_order_price?: Maybe<Scalars['Float']>;
	shipping_charged?: Maybe<Scalars['Float']>;
	stripe_tax_charged?: Maybe<Scalars['Float']>;
	stripe_total_charged?: Maybe<Scalars['Float']>;
	total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order" */
export type Order_Stddev_Pop_Order_By = {
	order_readable_column?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
	__typename?: 'order_stddev_samp_fields';
	order_readable_column?: Maybe<Scalars['Float']>;
	original_order_price?: Maybe<Scalars['Float']>;
	shipping_charged?: Maybe<Scalars['Float']>;
	stripe_tax_charged?: Maybe<Scalars['Float']>;
	stripe_total_charged?: Maybe<Scalars['Float']>;
	total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order" */
export type Order_Stddev_Samp_Order_By = {
	order_readable_column?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order" */
export type Order_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Order_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Stream_Cursor_Value_Input = {
	additional_origin_cart_id?: InputMaybe<Scalars['uuid']>;
	aftership_id?: InputMaybe<Scalars['String']>;
	aftership_status_tag?: InputMaybe<Scalars['String']>;
	attributed_to?: InputMaybe<Scalars['String']>;
	checkout_type?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	customer_email?: InputMaybe<Scalars['String']>;
	customer_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	order_readable_column?: InputMaybe<Scalars['Int']>;
	ordered_by_user_id?: InputMaybe<Scalars['uuid']>;
	original_order_price?: InputMaybe<Scalars['Int']>;
	receipt_url?: InputMaybe<Scalars['String']>;
	shipping_charged?: InputMaybe<Scalars['Int']>;
	shipping_label_url?: InputMaybe<Scalars['String']>;
	shipping_origin?: InputMaybe<Scalars['String']>;
	shipstation_response_data?: InputMaybe<Scalars['jsonb']>;
	shipstation_ship_price?: InputMaybe<Scalars['String']>;
	shipstation_shipment_id?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	stripe_customer_id?: InputMaybe<Scalars['String']>;
	stripe_payment_id?: InputMaybe<Scalars['String']>;
	stripe_session_id?: InputMaybe<Scalars['String']>;
	stripe_tax_charged?: InputMaybe<Scalars['Int']>;
	stripe_total_charged?: InputMaybe<Scalars['Int']>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
	total?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	use_stripe_customer_shipping_data?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Order_Sum_Fields = {
	__typename?: 'order_sum_fields';
	order_readable_column?: Maybe<Scalars['Int']>;
	original_order_price?: Maybe<Scalars['Int']>;
	shipping_charged?: Maybe<Scalars['Int']>;
	stripe_tax_charged?: Maybe<Scalars['Int']>;
	stripe_total_charged?: Maybe<Scalars['Int']>;
	total?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order" */
export type Order_Sum_Order_By = {
	order_readable_column?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
};

/** update columns of table "order" */
export enum Order_Update_Column {
	/** column name */
	AdditionalOriginCartId = 'additional_origin_cart_id',
	/** column name */
	AftershipId = 'aftership_id',
	/** column name */
	AftershipStatusTag = 'aftership_status_tag',
	/** column name */
	AttributedTo = 'attributed_to',
	/** column name */
	CheckoutType = 'checkout_type',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomerEmail = 'customer_email',
	/** column name */
	CustomerName = 'customer_name',
	/** column name */
	Id = 'id',
	/** column name */
	OrderReadableColumn = 'order_readable_column',
	/** column name */
	OrderedByUserId = 'ordered_by_user_id',
	/** column name */
	OriginalOrderPrice = 'original_order_price',
	/** column name */
	ReceiptUrl = 'receipt_url',
	/** column name */
	ShippingCharged = 'shipping_charged',
	/** column name */
	ShippingLabelUrl = 'shipping_label_url',
	/** column name */
	ShippingOrigin = 'shipping_origin',
	/** column name */
	ShipstationResponseData = 'shipstation_response_data',
	/** column name */
	ShipstationShipPrice = 'shipstation_ship_price',
	/** column name */
	ShipstationShipmentId = 'shipstation_shipment_id',
	/** column name */
	Status = 'status',
	/** column name */
	StripeCustomerId = 'stripe_customer_id',
	/** column name */
	StripePaymentId = 'stripe_payment_id',
	/** column name */
	StripeSessionId = 'stripe_session_id',
	/** column name */
	StripeTaxCharged = 'stripe_tax_charged',
	/** column name */
	StripeTotalCharged = 'stripe_total_charged',
	/** column name */
	TeamStoreId = 'team_store_id',
	/** column name */
	Total = 'total',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UseStripeCustomerShippingData = 'use_stripe_customer_shipping_data'
}

export type Order_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Order_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Order_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Order_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Order_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Order_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Order_Set_Input>;
	/** filter the rows which have to be updated */
	where: Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
	__typename?: 'order_var_pop_fields';
	order_readable_column?: Maybe<Scalars['Float']>;
	original_order_price?: Maybe<Scalars['Float']>;
	shipping_charged?: Maybe<Scalars['Float']>;
	stripe_tax_charged?: Maybe<Scalars['Float']>;
	stripe_total_charged?: Maybe<Scalars['Float']>;
	total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order" */
export type Order_Var_Pop_Order_By = {
	order_readable_column?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
	__typename?: 'order_var_samp_fields';
	order_readable_column?: Maybe<Scalars['Float']>;
	original_order_price?: Maybe<Scalars['Float']>;
	shipping_charged?: Maybe<Scalars['Float']>;
	stripe_tax_charged?: Maybe<Scalars['Float']>;
	stripe_total_charged?: Maybe<Scalars['Float']>;
	total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order" */
export type Order_Var_Samp_Order_By = {
	order_readable_column?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Variance_Fields = {
	__typename?: 'order_variance_fields';
	order_readable_column?: Maybe<Scalars['Float']>;
	original_order_price?: Maybe<Scalars['Float']>;
	shipping_charged?: Maybe<Scalars['Float']>;
	stripe_tax_charged?: Maybe<Scalars['Float']>;
	stripe_total_charged?: Maybe<Scalars['Float']>;
	total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order" */
export type Order_Variance_Order_By = {
	order_readable_column?: InputMaybe<Order_By>;
	original_order_price?: InputMaybe<Order_By>;
	shipping_charged?: InputMaybe<Order_By>;
	stripe_tax_charged?: InputMaybe<Order_By>;
	stripe_total_charged?: InputMaybe<Order_By>;
	total?: InputMaybe<Order_By>;
};

/** columns and relationships of "product" */
export type Product = {
	__typename?: 'product';
	base_price?: Maybe<Scalars['Int']>;
	bundle_builder_featured: Scalars['Boolean'];
	created_at: Scalars['timestamptz'];
	custom_name_enabled: Scalars['Boolean'];
	custom_name_price?: Maybe<Scalars['Int']>;
	custom_number_enabled: Scalars['Boolean'];
	custom_number_price?: Maybe<Scalars['Int']>;
	description?: Maybe<Scalars['String']>;
	discounted_from_price?: Maybe<Scalars['Int']>;
	fabric?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	is_active: Scalars['Boolean'];
	is_archived: Scalars['Boolean'];
	is_bundle: Scalars['Boolean'];
	label: Scalars['String'];
	options?: Maybe<Scalars['jsonb']>;
	/** An array relationship */
	product_has_available_options: Array<Product_Has_Available_Option>;
	/** An aggregate relationship */
	product_has_available_options_aggregate: Product_Has_Available_Option_Aggregate;
	production_price?: Maybe<Scalars['Int']>;
	public_product: Scalars['Boolean'];
	public_product_description?: Maybe<Scalars['String']>;
	public_product_image_url?: Maybe<Scalars['String']>;
	shipping_origin: Scalars['String'];
	short_size_restriction: Scalars['Boolean'];
	show_in_export: Scalars['Boolean'];
	size_chart_url?: Maybe<Scalars['String']>;
	sku?: Maybe<Scalars['String']>;
	/** An array relationship */
	store_products: Array<Store_Product>;
	/** An aggregate relationship */
	store_products_aggregate: Store_Product_Aggregate;
	stripe_tax_identifier?: Maybe<Scalars['String']>;
	top_size_restriction: Scalars['Boolean'];
	updated_at: Scalars['timestamptz'];
	weight_in_ounces?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "product" */
export type ProductOptionsArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "product" */
export type ProductProduct_Has_Available_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Has_Available_Option_Order_By>>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductProduct_Has_Available_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Has_Available_Option_Order_By>>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductStore_ProductsArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Order_By>>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductStore_Products_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Order_By>>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
	__typename?: 'product_aggregate';
	aggregate?: Maybe<Product_Aggregate_Fields>;
	nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
	__typename?: 'product_aggregate_fields';
	avg?: Maybe<Product_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Product_Max_Fields>;
	min?: Maybe<Product_Min_Fields>;
	stddev?: Maybe<Product_Stddev_Fields>;
	stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
	sum?: Maybe<Product_Sum_Fields>;
	var_pop?: Maybe<Product_Var_Pop_Fields>;
	var_samp?: Maybe<Product_Var_Samp_Fields>;
	variance?: Maybe<Product_Variance_Fields>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Product_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Append_Input = {
	options?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
	__typename?: 'product_avg_fields';
	base_price?: Maybe<Scalars['Float']>;
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	discounted_from_price?: Maybe<Scalars['Float']>;
	production_price?: Maybe<Scalars['Float']>;
	weight_in_ounces?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
	_and?: InputMaybe<Array<Product_Bool_Exp>>;
	_not?: InputMaybe<Product_Bool_Exp>;
	_or?: InputMaybe<Array<Product_Bool_Exp>>;
	base_price?: InputMaybe<Int_Comparison_Exp>;
	bundle_builder_featured?: InputMaybe<Boolean_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	custom_name_enabled?: InputMaybe<Boolean_Comparison_Exp>;
	custom_name_price?: InputMaybe<Int_Comparison_Exp>;
	custom_number_enabled?: InputMaybe<Boolean_Comparison_Exp>;
	custom_number_price?: InputMaybe<Int_Comparison_Exp>;
	description?: InputMaybe<String_Comparison_Exp>;
	discounted_from_price?: InputMaybe<Int_Comparison_Exp>;
	fabric?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_active?: InputMaybe<Boolean_Comparison_Exp>;
	is_archived?: InputMaybe<Boolean_Comparison_Exp>;
	is_bundle?: InputMaybe<Boolean_Comparison_Exp>;
	label?: InputMaybe<String_Comparison_Exp>;
	options?: InputMaybe<Jsonb_Comparison_Exp>;
	product_has_available_options?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
	product_has_available_options_aggregate?: InputMaybe<Product_Has_Available_Option_Aggregate_Bool_Exp>;
	production_price?: InputMaybe<Int_Comparison_Exp>;
	public_product?: InputMaybe<Boolean_Comparison_Exp>;
	public_product_description?: InputMaybe<String_Comparison_Exp>;
	public_product_image_url?: InputMaybe<String_Comparison_Exp>;
	shipping_origin?: InputMaybe<String_Comparison_Exp>;
	short_size_restriction?: InputMaybe<Boolean_Comparison_Exp>;
	show_in_export?: InputMaybe<Boolean_Comparison_Exp>;
	size_chart_url?: InputMaybe<String_Comparison_Exp>;
	sku?: InputMaybe<String_Comparison_Exp>;
	store_products?: InputMaybe<Store_Product_Bool_Exp>;
	store_products_aggregate?: InputMaybe<Store_Product_Aggregate_Bool_Exp>;
	stripe_tax_identifier?: InputMaybe<String_Comparison_Exp>;
	top_size_restriction?: InputMaybe<Boolean_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	weight_in_ounces?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProductPkey = 'product_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Delete_At_Path_Input = {
	options?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Delete_Elem_Input = {
	options?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Delete_Key_Input = {
	options?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "product_has_available_option" */
export type Product_Has_Available_Option = {
	__typename?: 'product_has_available_option';
	/** An object relationship */
	available_option: Available_Option;
	available_option_id: Scalars['uuid'];
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	order: Scalars['Int'];
	/** An object relationship */
	product: Product;
	product_id: Scalars['uuid'];
	updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "product_has_available_option" */
export type Product_Has_Available_Option_Aggregate = {
	__typename?: 'product_has_available_option_aggregate';
	aggregate?: Maybe<Product_Has_Available_Option_Aggregate_Fields>;
	nodes: Array<Product_Has_Available_Option>;
};

export type Product_Has_Available_Option_Aggregate_Bool_Exp = {
	count?: InputMaybe<Product_Has_Available_Option_Aggregate_Bool_Exp_Count>;
};

export type Product_Has_Available_Option_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_has_available_option" */
export type Product_Has_Available_Option_Aggregate_Fields = {
	__typename?: 'product_has_available_option_aggregate_fields';
	avg?: Maybe<Product_Has_Available_Option_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Product_Has_Available_Option_Max_Fields>;
	min?: Maybe<Product_Has_Available_Option_Min_Fields>;
	stddev?: Maybe<Product_Has_Available_Option_Stddev_Fields>;
	stddev_pop?: Maybe<Product_Has_Available_Option_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Product_Has_Available_Option_Stddev_Samp_Fields>;
	sum?: Maybe<Product_Has_Available_Option_Sum_Fields>;
	var_pop?: Maybe<Product_Has_Available_Option_Var_Pop_Fields>;
	var_samp?: Maybe<Product_Has_Available_Option_Var_Samp_Fields>;
	variance?: Maybe<Product_Has_Available_Option_Variance_Fields>;
};

/** aggregate fields of "product_has_available_option" */
export type Product_Has_Available_Option_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_has_available_option" */
export type Product_Has_Available_Option_Aggregate_Order_By = {
	avg?: InputMaybe<Product_Has_Available_Option_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Product_Has_Available_Option_Max_Order_By>;
	min?: InputMaybe<Product_Has_Available_Option_Min_Order_By>;
	stddev?: InputMaybe<Product_Has_Available_Option_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Product_Has_Available_Option_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Product_Has_Available_Option_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Product_Has_Available_Option_Sum_Order_By>;
	var_pop?: InputMaybe<Product_Has_Available_Option_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Product_Has_Available_Option_Var_Samp_Order_By>;
	variance?: InputMaybe<Product_Has_Available_Option_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_has_available_option" */
export type Product_Has_Available_Option_Arr_Rel_Insert_Input = {
	data: Array<Product_Has_Available_Option_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Product_Has_Available_Option_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Has_Available_Option_Avg_Fields = {
	__typename?: 'product_has_available_option_avg_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Avg_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_has_available_option". All fields are combined with a logical 'AND'. */
export type Product_Has_Available_Option_Bool_Exp = {
	_and?: InputMaybe<Array<Product_Has_Available_Option_Bool_Exp>>;
	_not?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
	_or?: InputMaybe<Array<Product_Has_Available_Option_Bool_Exp>>;
	available_option?: InputMaybe<Available_Option_Bool_Exp>;
	available_option_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	order?: InputMaybe<Int_Comparison_Exp>;
	product?: InputMaybe<Product_Bool_Exp>;
	product_id?: InputMaybe<Uuid_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_has_available_option" */
export enum Product_Has_Available_Option_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProductHasAvailableOptionPkey = 'product_has_available_option_pkey'
}

/** input type for incrementing numeric columns in table "product_has_available_option" */
export type Product_Has_Available_Option_Inc_Input = {
	order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "product_has_available_option" */
export type Product_Has_Available_Option_Insert_Input = {
	available_option?: InputMaybe<Available_Option_Obj_Rel_Insert_Input>;
	available_option_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	order?: InputMaybe<Scalars['Int']>;
	product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
	product_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Product_Has_Available_Option_Max_Fields = {
	__typename?: 'product_has_available_option_max_fields';
	available_option_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	order?: Maybe<Scalars['Int']>;
	product_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Max_Order_By = {
	available_option_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	product_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Has_Available_Option_Min_Fields = {
	__typename?: 'product_has_available_option_min_fields';
	available_option_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	order?: Maybe<Scalars['Int']>;
	product_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Min_Order_By = {
	available_option_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	product_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_has_available_option" */
export type Product_Has_Available_Option_Mutation_Response = {
	__typename?: 'product_has_available_option_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Product_Has_Available_Option>;
};

/** on_conflict condition type for table "product_has_available_option" */
export type Product_Has_Available_Option_On_Conflict = {
	constraint: Product_Has_Available_Option_Constraint;
	update_columns?: Array<Product_Has_Available_Option_Update_Column>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "product_has_available_option". */
export type Product_Has_Available_Option_Order_By = {
	available_option?: InputMaybe<Available_Option_Order_By>;
	available_option_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	product?: InputMaybe<Product_Order_By>;
	product_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_has_available_option */
export type Product_Has_Available_Option_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "product_has_available_option" */
export enum Product_Has_Available_Option_Select_Column {
	/** column name */
	AvailableOptionId = 'available_option_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	Order = 'order',
	/** column name */
	ProductId = 'product_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product_has_available_option" */
export type Product_Has_Available_Option_Set_Input = {
	available_option_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	order?: InputMaybe<Scalars['Int']>;
	product_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Product_Has_Available_Option_Stddev_Fields = {
	__typename?: 'product_has_available_option_stddev_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Stddev_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Has_Available_Option_Stddev_Pop_Fields = {
	__typename?: 'product_has_available_option_stddev_pop_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Stddev_Pop_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Has_Available_Option_Stddev_Samp_Fields = {
	__typename?: 'product_has_available_option_stddev_samp_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Stddev_Samp_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product_has_available_option" */
export type Product_Has_Available_Option_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Product_Has_Available_Option_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Has_Available_Option_Stream_Cursor_Value_Input = {
	available_option_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	order?: InputMaybe<Scalars['Int']>;
	product_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Product_Has_Available_Option_Sum_Fields = {
	__typename?: 'product_has_available_option_sum_fields';
	order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Sum_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** update columns of table "product_has_available_option" */
export enum Product_Has_Available_Option_Update_Column {
	/** column name */
	AvailableOptionId = 'available_option_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	Order = 'order',
	/** column name */
	ProductId = 'product_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Product_Has_Available_Option_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Product_Has_Available_Option_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Product_Has_Available_Option_Set_Input>;
	/** filter the rows which have to be updated */
	where: Product_Has_Available_Option_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Has_Available_Option_Var_Pop_Fields = {
	__typename?: 'product_has_available_option_var_pop_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Var_Pop_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Has_Available_Option_Var_Samp_Fields = {
	__typename?: 'product_has_available_option_var_samp_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Var_Samp_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Has_Available_Option_Variance_Fields = {
	__typename?: 'product_has_available_option_variance_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_has_available_option" */
export type Product_Has_Available_Option_Variance_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "product" */
export type Product_Inc_Input = {
	base_price?: InputMaybe<Scalars['Int']>;
	custom_name_price?: InputMaybe<Scalars['Int']>;
	custom_number_price?: InputMaybe<Scalars['Int']>;
	discounted_from_price?: InputMaybe<Scalars['Int']>;
	production_price?: InputMaybe<Scalars['Int']>;
	weight_in_ounces?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
	base_price?: InputMaybe<Scalars['Int']>;
	bundle_builder_featured?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_name_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_name_price?: InputMaybe<Scalars['Int']>;
	custom_number_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_number_price?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	discounted_from_price?: InputMaybe<Scalars['Int']>;
	fabric?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	is_archived?: InputMaybe<Scalars['Boolean']>;
	is_bundle?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	options?: InputMaybe<Scalars['jsonb']>;
	product_has_available_options?: InputMaybe<Product_Has_Available_Option_Arr_Rel_Insert_Input>;
	production_price?: InputMaybe<Scalars['Int']>;
	public_product?: InputMaybe<Scalars['Boolean']>;
	public_product_description?: InputMaybe<Scalars['String']>;
	public_product_image_url?: InputMaybe<Scalars['String']>;
	shipping_origin?: InputMaybe<Scalars['String']>;
	short_size_restriction?: InputMaybe<Scalars['Boolean']>;
	show_in_export?: InputMaybe<Scalars['Boolean']>;
	size_chart_url?: InputMaybe<Scalars['String']>;
	sku?: InputMaybe<Scalars['String']>;
	store_products?: InputMaybe<Store_Product_Arr_Rel_Insert_Input>;
	stripe_tax_identifier?: InputMaybe<Scalars['String']>;
	top_size_restriction?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	weight_in_ounces?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
	__typename?: 'product_max_fields';
	base_price?: Maybe<Scalars['Int']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	custom_name_price?: Maybe<Scalars['Int']>;
	custom_number_price?: Maybe<Scalars['Int']>;
	description?: Maybe<Scalars['String']>;
	discounted_from_price?: Maybe<Scalars['Int']>;
	fabric?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	production_price?: Maybe<Scalars['Int']>;
	public_product_description?: Maybe<Scalars['String']>;
	public_product_image_url?: Maybe<Scalars['String']>;
	shipping_origin?: Maybe<Scalars['String']>;
	size_chart_url?: Maybe<Scalars['String']>;
	sku?: Maybe<Scalars['String']>;
	stripe_tax_identifier?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	weight_in_ounces?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
	__typename?: 'product_min_fields';
	base_price?: Maybe<Scalars['Int']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	custom_name_price?: Maybe<Scalars['Int']>;
	custom_number_price?: Maybe<Scalars['Int']>;
	description?: Maybe<Scalars['String']>;
	discounted_from_price?: Maybe<Scalars['Int']>;
	fabric?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	production_price?: Maybe<Scalars['Int']>;
	public_product_description?: Maybe<Scalars['String']>;
	public_product_image_url?: Maybe<Scalars['String']>;
	shipping_origin?: Maybe<Scalars['String']>;
	size_chart_url?: Maybe<Scalars['String']>;
	sku?: Maybe<Scalars['String']>;
	stripe_tax_identifier?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	weight_in_ounces?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
	__typename?: 'product_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
	data: Product_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** on_conflict condition type for table "product" */
export type Product_On_Conflict = {
	constraint: Product_Constraint;
	update_columns?: Array<Product_Update_Column>;
	where?: InputMaybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
	base_price?: InputMaybe<Order_By>;
	bundle_builder_featured?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	custom_name_enabled?: InputMaybe<Order_By>;
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_enabled?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	discounted_from_price?: InputMaybe<Order_By>;
	fabric?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_active?: InputMaybe<Order_By>;
	is_archived?: InputMaybe<Order_By>;
	is_bundle?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	options?: InputMaybe<Order_By>;
	product_has_available_options_aggregate?: InputMaybe<Product_Has_Available_Option_Aggregate_Order_By>;
	production_price?: InputMaybe<Order_By>;
	public_product?: InputMaybe<Order_By>;
	public_product_description?: InputMaybe<Order_By>;
	public_product_image_url?: InputMaybe<Order_By>;
	shipping_origin?: InputMaybe<Order_By>;
	short_size_restriction?: InputMaybe<Order_By>;
	show_in_export?: InputMaybe<Order_By>;
	size_chart_url?: InputMaybe<Order_By>;
	sku?: InputMaybe<Order_By>;
	store_products_aggregate?: InputMaybe<Store_Product_Aggregate_Order_By>;
	stripe_tax_identifier?: InputMaybe<Order_By>;
	top_size_restriction?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	weight_in_ounces?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Prepend_Input = {
	options?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "product" */
export enum Product_Select_Column {
	/** column name */
	BasePrice = 'base_price',
	/** column name */
	BundleBuilderFeatured = 'bundle_builder_featured',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomNameEnabled = 'custom_name_enabled',
	/** column name */
	CustomNamePrice = 'custom_name_price',
	/** column name */
	CustomNumberEnabled = 'custom_number_enabled',
	/** column name */
	CustomNumberPrice = 'custom_number_price',
	/** column name */
	Description = 'description',
	/** column name */
	DiscountedFromPrice = 'discounted_from_price',
	/** column name */
	Fabric = 'fabric',
	/** column name */
	Id = 'id',
	/** column name */
	IsActive = 'is_active',
	/** column name */
	IsArchived = 'is_archived',
	/** column name */
	IsBundle = 'is_bundle',
	/** column name */
	Label = 'label',
	/** column name */
	Options = 'options',
	/** column name */
	ProductionPrice = 'production_price',
	/** column name */
	PublicProduct = 'public_product',
	/** column name */
	PublicProductDescription = 'public_product_description',
	/** column name */
	PublicProductImageUrl = 'public_product_image_url',
	/** column name */
	ShippingOrigin = 'shipping_origin',
	/** column name */
	ShortSizeRestriction = 'short_size_restriction',
	/** column name */
	ShowInExport = 'show_in_export',
	/** column name */
	SizeChartUrl = 'size_chart_url',
	/** column name */
	Sku = 'sku',
	/** column name */
	StripeTaxIdentifier = 'stripe_tax_identifier',
	/** column name */
	TopSizeRestriction = 'top_size_restriction',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	WeightInOunces = 'weight_in_ounces'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
	base_price?: InputMaybe<Scalars['Int']>;
	bundle_builder_featured?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_name_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_name_price?: InputMaybe<Scalars['Int']>;
	custom_number_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_number_price?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	discounted_from_price?: InputMaybe<Scalars['Int']>;
	fabric?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	is_archived?: InputMaybe<Scalars['Boolean']>;
	is_bundle?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	options?: InputMaybe<Scalars['jsonb']>;
	production_price?: InputMaybe<Scalars['Int']>;
	public_product?: InputMaybe<Scalars['Boolean']>;
	public_product_description?: InputMaybe<Scalars['String']>;
	public_product_image_url?: InputMaybe<Scalars['String']>;
	shipping_origin?: InputMaybe<Scalars['String']>;
	short_size_restriction?: InputMaybe<Scalars['Boolean']>;
	show_in_export?: InputMaybe<Scalars['Boolean']>;
	size_chart_url?: InputMaybe<Scalars['String']>;
	sku?: InputMaybe<Scalars['String']>;
	stripe_tax_identifier?: InputMaybe<Scalars['String']>;
	top_size_restriction?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	weight_in_ounces?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
	__typename?: 'product_stddev_fields';
	base_price?: Maybe<Scalars['Float']>;
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	discounted_from_price?: Maybe<Scalars['Float']>;
	production_price?: Maybe<Scalars['Float']>;
	weight_in_ounces?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
	__typename?: 'product_stddev_pop_fields';
	base_price?: Maybe<Scalars['Float']>;
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	discounted_from_price?: Maybe<Scalars['Float']>;
	production_price?: Maybe<Scalars['Float']>;
	weight_in_ounces?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
	__typename?: 'product_stddev_samp_fields';
	base_price?: Maybe<Scalars['Float']>;
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	discounted_from_price?: Maybe<Scalars['Float']>;
	production_price?: Maybe<Scalars['Float']>;
	weight_in_ounces?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "product" */
export type Product_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Product_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
	base_price?: InputMaybe<Scalars['Int']>;
	bundle_builder_featured?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_name_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_name_price?: InputMaybe<Scalars['Int']>;
	custom_number_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_number_price?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	discounted_from_price?: InputMaybe<Scalars['Int']>;
	fabric?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	is_archived?: InputMaybe<Scalars['Boolean']>;
	is_bundle?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	options?: InputMaybe<Scalars['jsonb']>;
	production_price?: InputMaybe<Scalars['Int']>;
	public_product?: InputMaybe<Scalars['Boolean']>;
	public_product_description?: InputMaybe<Scalars['String']>;
	public_product_image_url?: InputMaybe<Scalars['String']>;
	shipping_origin?: InputMaybe<Scalars['String']>;
	short_size_restriction?: InputMaybe<Scalars['Boolean']>;
	show_in_export?: InputMaybe<Scalars['Boolean']>;
	size_chart_url?: InputMaybe<Scalars['String']>;
	sku?: InputMaybe<Scalars['String']>;
	stripe_tax_identifier?: InputMaybe<Scalars['String']>;
	top_size_restriction?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	weight_in_ounces?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
	__typename?: 'product_sum_fields';
	base_price?: Maybe<Scalars['Int']>;
	custom_name_price?: Maybe<Scalars['Int']>;
	custom_number_price?: Maybe<Scalars['Int']>;
	discounted_from_price?: Maybe<Scalars['Int']>;
	production_price?: Maybe<Scalars['Int']>;
	weight_in_ounces?: Maybe<Scalars['Int']>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
	/** column name */
	BasePrice = 'base_price',
	/** column name */
	BundleBuilderFeatured = 'bundle_builder_featured',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomNameEnabled = 'custom_name_enabled',
	/** column name */
	CustomNamePrice = 'custom_name_price',
	/** column name */
	CustomNumberEnabled = 'custom_number_enabled',
	/** column name */
	CustomNumberPrice = 'custom_number_price',
	/** column name */
	Description = 'description',
	/** column name */
	DiscountedFromPrice = 'discounted_from_price',
	/** column name */
	Fabric = 'fabric',
	/** column name */
	Id = 'id',
	/** column name */
	IsActive = 'is_active',
	/** column name */
	IsArchived = 'is_archived',
	/** column name */
	IsBundle = 'is_bundle',
	/** column name */
	Label = 'label',
	/** column name */
	Options = 'options',
	/** column name */
	ProductionPrice = 'production_price',
	/** column name */
	PublicProduct = 'public_product',
	/** column name */
	PublicProductDescription = 'public_product_description',
	/** column name */
	PublicProductImageUrl = 'public_product_image_url',
	/** column name */
	ShippingOrigin = 'shipping_origin',
	/** column name */
	ShortSizeRestriction = 'short_size_restriction',
	/** column name */
	ShowInExport = 'show_in_export',
	/** column name */
	SizeChartUrl = 'size_chart_url',
	/** column name */
	Sku = 'sku',
	/** column name */
	StripeTaxIdentifier = 'stripe_tax_identifier',
	/** column name */
	TopSizeRestriction = 'top_size_restriction',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	WeightInOunces = 'weight_in_ounces'
}

export type Product_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Product_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Product_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Product_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Product_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Product_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Product_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Product_Set_Input>;
	/** filter the rows which have to be updated */
	where: Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
	__typename?: 'product_var_pop_fields';
	base_price?: Maybe<Scalars['Float']>;
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	discounted_from_price?: Maybe<Scalars['Float']>;
	production_price?: Maybe<Scalars['Float']>;
	weight_in_ounces?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
	__typename?: 'product_var_samp_fields';
	base_price?: Maybe<Scalars['Float']>;
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	discounted_from_price?: Maybe<Scalars['Float']>;
	production_price?: Maybe<Scalars['Float']>;
	weight_in_ounces?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
	__typename?: 'product_variance_fields';
	base_price?: Maybe<Scalars['Float']>;
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	discounted_from_price?: Maybe<Scalars['Float']>;
	production_price?: Maybe<Scalars['Float']>;
	weight_in_ounces?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
	__typename?: 'query_root';
	/** fetch data from the table: "async_action" */
	async_action: Array<Async_Action>;
	/** fetch aggregated fields from the table: "async_action" */
	async_action_aggregate: Async_Action_Aggregate;
	/** fetch data from the table: "async_action" using primary key columns */
	async_action_by_pk?: Maybe<Async_Action>;
	/** fetch data from the table: "available_option" */
	available_option: Array<Available_Option>;
	/** fetch aggregated fields from the table: "available_option" */
	available_option_aggregate: Available_Option_Aggregate;
	/** fetch data from the table: "available_option" using primary key columns */
	available_option_by_pk?: Maybe<Available_Option>;
	/** fetch data from the table: "available_option_value" */
	available_option_value: Array<Available_Option_Value>;
	/** fetch aggregated fields from the table: "available_option_value" */
	available_option_value_aggregate: Available_Option_Value_Aggregate;
	/** fetch data from the table: "available_option_value" using primary key columns */
	available_option_value_by_pk?: Maybe<Available_Option_Value>;
	/** fetch data from the table: "custom_link_purchase" */
	custom_link_purchase: Array<Custom_Link_Purchase>;
	/** fetch aggregated fields from the table: "custom_link_purchase" */
	custom_link_purchase_aggregate: Custom_Link_Purchase_Aggregate;
	/** fetch data from the table: "custom_link_purchase" using primary key columns */
	custom_link_purchase_by_pk?: Maybe<Custom_Link_Purchase>;
	getCustomLinkProducts?: Maybe<Array<Maybe<StripeProduct>>>;
	getDataExportURL?: Maybe<ExportUrl>;
	/** getDataExportUrlPublic */
	getDataExportUrlPublic?: Maybe<ExportUrl>;
	/** Design automation for exporting JSON data out to the client */
	getExportDesignAutomationData?: Maybe<ExportUrl>;
	/** Get User Orders based on their JWT token */
	getMyStoreOrders?: Maybe<Array<Maybe<UserOrder>>>;
	getProdDataExportURL?: Maybe<ExportUrl>;
	getProdDataForDesignExportURL?: Maybe<ExportUrl>;
	getProdShippingDataExportURL?: Maybe<ExportUrl>;
	/** fetch data from the table: "leads" */
	leads: Array<Leads>;
	/** fetch aggregated fields from the table: "leads" */
	leads_aggregate: Leads_Aggregate;
	/** fetch data from the table: "leads" using primary key columns */
	leads_by_pk?: Maybe<Leads>;
	/** fetch data from the table: "order" */
	order: Array<Order>;
	/** fetch aggregated fields from the table: "order" */
	order_aggregate: Order_Aggregate;
	/** fetch data from the table: "order" using primary key columns */
	order_by_pk?: Maybe<Order>;
	/** fetch data from the table: "order_item" */
	order_item: Array<Order_Item>;
	/** fetch aggregated fields from the table: "order_item" */
	order_item_aggregate: Order_Item_Aggregate;
	/** fetch data from the table: "order_item" using primary key columns */
	order_item_by_pk?: Maybe<Order_Item>;
	/** fetch data from the table: "order_item_option" */
	order_item_option: Array<Order_Item_Option>;
	/** fetch aggregated fields from the table: "order_item_option" */
	order_item_option_aggregate: Order_Item_Option_Aggregate;
	/** fetch data from the table: "order_item_option" using primary key columns */
	order_item_option_by_pk?: Maybe<Order_Item_Option>;
	/** fetch data from the table: "order_package" */
	order_package: Array<Order_Package>;
	/** fetch aggregated fields from the table: "order_package" */
	order_package_aggregate: Order_Package_Aggregate;
	/** fetch data from the table: "order_package" using primary key columns */
	order_package_by_pk?: Maybe<Order_Package>;
	/** An array relationship */
	order_shipping_details: Array<Order_Shipping_Details>;
	/** An aggregate relationship */
	order_shipping_details_aggregate: Order_Shipping_Details_Aggregate;
	/** fetch data from the table: "order_shipping_details" using primary key columns */
	order_shipping_details_by_pk?: Maybe<Order_Shipping_Details>;
	/** fetch data from the table: "order_status_history" */
	order_status_history: Array<Order_Status_History>;
	/** fetch aggregated fields from the table: "order_status_history" */
	order_status_history_aggregate: Order_Status_History_Aggregate;
	/** fetch data from the table: "order_status_history" using primary key columns */
	order_status_history_by_pk?: Maybe<Order_Status_History>;
	/** fetch data from the table: "product" */
	product: Array<Product>;
	/** fetch aggregated fields from the table: "product" */
	product_aggregate: Product_Aggregate;
	/** fetch data from the table: "product" using primary key columns */
	product_by_pk?: Maybe<Product>;
	/** fetch data from the table: "product_has_available_option" */
	product_has_available_option: Array<Product_Has_Available_Option>;
	/** fetch aggregated fields from the table: "product_has_available_option" */
	product_has_available_option_aggregate: Product_Has_Available_Option_Aggregate;
	/** fetch data from the table: "product_has_available_option" using primary key columns */
	product_has_available_option_by_pk?: Maybe<Product_Has_Available_Option>;
	/** fetch data from the table: "roles" */
	roles: Array<Roles>;
	/** fetch aggregated fields from the table: "roles" */
	roles_aggregate: Roles_Aggregate;
	/** fetch data from the table: "roles" using primary key columns */
	roles_by_pk?: Maybe<Roles>;
	/** fetch data from the table: "showcase" */
	showcase: Array<Showcase>;
	/** fetch aggregated fields from the table: "showcase" */
	showcase_aggregate: Showcase_Aggregate;
	/** fetch data from the table: "showcase" using primary key columns */
	showcase_by_pk?: Maybe<Showcase>;
	/** fetch data from the table: "showcase_category" */
	showcase_category: Array<Showcase_Category>;
	/** fetch aggregated fields from the table: "showcase_category" */
	showcase_category_aggregate: Showcase_Category_Aggregate;
	/** fetch data from the table: "showcase_category" using primary key columns */
	showcase_category_by_pk?: Maybe<Showcase_Category>;
	/** fetch data from the table: "store_package" */
	store_package: Array<Store_Package>;
	/** fetch aggregated fields from the table: "store_package" */
	store_package_aggregate: Store_Package_Aggregate;
	/** fetch data from the table: "store_package" using primary key columns */
	store_package_by_pk?: Maybe<Store_Package>;
	/** fetch data from the table: "store_product" */
	store_product: Array<Store_Product>;
	/** fetch aggregated fields from the table: "store_product" */
	store_product_aggregate: Store_Product_Aggregate;
	/** fetch data from the table: "store_product" using primary key columns */
	store_product_by_pk?: Maybe<Store_Product>;
	/** An array relationship */
	store_product_has_options: Array<Store_Product_Has_Options>;
	/** An aggregate relationship */
	store_product_has_options_aggregate: Store_Product_Has_Options_Aggregate;
	/** fetch data from the table: "store_product_has_options" using primary key columns */
	store_product_has_options_by_pk?: Maybe<Store_Product_Has_Options>;
	/** fetch data from the table: "support" */
	support: Array<Support>;
	/** fetch aggregated fields from the table: "support" */
	support_aggregate: Support_Aggregate;
	/** fetch data from the table: "support" using primary key columns */
	support_by_pk?: Maybe<Support>;
	/** fetch data from the table: "team" */
	team: Array<Team>;
	/** fetch aggregated fields from the table: "team" */
	team_aggregate: Team_Aggregate;
	/** fetch data from the table: "team" using primary key columns */
	team_by_pk?: Maybe<Team>;
	/** fetch data from the table: "team_store" */
	team_store: Array<Team_Store>;
	/** fetch aggregated fields from the table: "team_store" */
	team_store_aggregate: Team_Store_Aggregate;
	/** fetch data from the table: "team_store" using primary key columns */
	team_store_by_pk?: Maybe<Team_Store>;
	/** fetch data from the table: "team_store_email_log" */
	team_store_email_log: Array<Team_Store_Email_Log>;
	/** fetch aggregated fields from the table: "team_store_email_log" */
	team_store_email_log_aggregate: Team_Store_Email_Log_Aggregate;
	/** fetch data from the table: "team_store_email_log" using primary key columns */
	team_store_email_log_by_pk?: Maybe<Team_Store_Email_Log>;
	/** fetch data from the table: "team_store_order_email_log" */
	team_store_order_email_log: Array<Team_Store_Order_Email_Log>;
	/** fetch aggregated fields from the table: "team_store_order_email_log" */
	team_store_order_email_log_aggregate: Team_Store_Order_Email_Log_Aggregate;
	/** fetch data from the table: "team_store_order_email_log" using primary key columns */
	team_store_order_email_log_by_pk?: Maybe<Team_Store_Order_Email_Log>;
	/** fetch data from the table: "user" */
	user: Array<User>;
	/** fetch aggregated fields from the table: "user" */
	user_aggregate: User_Aggregate;
	/** fetch data from the table: "user" using primary key columns */
	user_by_pk?: Maybe<User>;
	/** An array relationship */
	user_roles: Array<User_Roles>;
	/** An aggregate relationship */
	user_roles_aggregate: User_Roles_Aggregate;
	/** fetch data from the table: "user_roles" using primary key columns */
	user_roles_by_pk?: Maybe<User_Roles>;
	/** fetch data from the table: "user_token" */
	user_token: Array<User_Token>;
	/** fetch aggregated fields from the table: "user_token" */
	user_token_aggregate: User_Token_Aggregate;
	/** fetch data from the table: "user_token" using primary key columns */
	user_token_by_pk?: Maybe<User_Token>;
};

export type Query_RootAsync_ActionArgs = {
	distinct_on?: InputMaybe<Array<Async_Action_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Async_Action_Order_By>>;
	where?: InputMaybe<Async_Action_Bool_Exp>;
};

export type Query_RootAsync_Action_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Async_Action_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Async_Action_Order_By>>;
	where?: InputMaybe<Async_Action_Bool_Exp>;
};

export type Query_RootAsync_Action_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootAvailable_OptionArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Order_By>>;
	where?: InputMaybe<Available_Option_Bool_Exp>;
};

export type Query_RootAvailable_Option_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Order_By>>;
	where?: InputMaybe<Available_Option_Bool_Exp>;
};

export type Query_RootAvailable_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootAvailable_Option_ValueArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Value_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Value_Order_By>>;
	where?: InputMaybe<Available_Option_Value_Bool_Exp>;
};

export type Query_RootAvailable_Option_Value_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Value_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Value_Order_By>>;
	where?: InputMaybe<Available_Option_Value_Bool_Exp>;
};

export type Query_RootAvailable_Option_Value_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootCustom_Link_PurchaseArgs = {
	distinct_on?: InputMaybe<Array<Custom_Link_Purchase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Custom_Link_Purchase_Order_By>>;
	where?: InputMaybe<Custom_Link_Purchase_Bool_Exp>;
};

export type Query_RootCustom_Link_Purchase_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Custom_Link_Purchase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Custom_Link_Purchase_Order_By>>;
	where?: InputMaybe<Custom_Link_Purchase_Bool_Exp>;
};

export type Query_RootCustom_Link_Purchase_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootGetDataExportUrlArgs = {
	groupId?: InputMaybe<Scalars['String']>;
	orderStatusType?: InputMaybe<Scalars['Int']>;
	origin?: InputMaybe<Scalars['String']>;
	storeId: Scalars['String'];
};

export type Query_RootGetDataExportUrlPublicArgs = {
	groupId?: InputMaybe<Scalars['String']>;
	storeId: Scalars['String'];
};

export type Query_RootGetExportDesignAutomationDataArgs = {
	groupId?: InputMaybe<Scalars['String']>;
	orderStatusType?: InputMaybe<Scalars['Int']>;
	origin?: InputMaybe<Scalars['String']>;
	storeId: Scalars['String'];
};

export type Query_RootGetProdDataExportUrlArgs = {
	orderStatusType?: InputMaybe<Scalars['Int']>;
	origin?: InputMaybe<Scalars['String']>;
	storeId: Scalars['String'];
};

export type Query_RootGetProdDataForDesignExportUrlArgs = {
	groupId?: InputMaybe<Scalars['String']>;
	orderStatusType?: InputMaybe<Scalars['Int']>;
	origin?: InputMaybe<Scalars['String']>;
	storeId: Scalars['String'];
};

export type Query_RootGetProdShippingDataExportUrlArgs = {
	orderStatusType?: InputMaybe<Scalars['Int']>;
	origin?: InputMaybe<Scalars['String']>;
	storeId: Scalars['String'];
};

export type Query_RootLeadsArgs = {
	distinct_on?: InputMaybe<Array<Leads_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Leads_Order_By>>;
	where?: InputMaybe<Leads_Bool_Exp>;
};

export type Query_RootLeads_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Leads_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Leads_Order_By>>;
	where?: InputMaybe<Leads_Bool_Exp>;
};

export type Query_RootLeads_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootOrderArgs = {
	distinct_on?: InputMaybe<Array<Order_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Order_By>>;
	where?: InputMaybe<Order_Bool_Exp>;
};

export type Query_RootOrder_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Order_By>>;
	where?: InputMaybe<Order_Bool_Exp>;
};

export type Query_RootOrder_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootOrder_ItemArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

export type Query_RootOrder_Item_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

export type Query_RootOrder_Item_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootOrder_Item_OptionArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

export type Query_RootOrder_Item_Option_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

export type Query_RootOrder_Item_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootOrder_PackageArgs = {
	distinct_on?: InputMaybe<Array<Order_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Package_Order_By>>;
	where?: InputMaybe<Order_Package_Bool_Exp>;
};

export type Query_RootOrder_Package_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Package_Order_By>>;
	where?: InputMaybe<Order_Package_Bool_Exp>;
};

export type Query_RootOrder_Package_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootOrder_Shipping_DetailsArgs = {
	distinct_on?: InputMaybe<Array<Order_Shipping_Details_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Shipping_Details_Order_By>>;
	where?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
};

export type Query_RootOrder_Shipping_Details_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Shipping_Details_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Shipping_Details_Order_By>>;
	where?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
};

export type Query_RootOrder_Shipping_Details_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootOrder_Status_HistoryArgs = {
	distinct_on?: InputMaybe<Array<Order_Status_History_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Status_History_Order_By>>;
	where?: InputMaybe<Order_Status_History_Bool_Exp>;
};

export type Query_RootOrder_Status_History_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Status_History_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Status_History_Order_By>>;
	where?: InputMaybe<Order_Status_History_Bool_Exp>;
};

export type Query_RootOrder_Status_History_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootProductArgs = {
	distinct_on?: InputMaybe<Array<Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Order_By>>;
	where?: InputMaybe<Product_Bool_Exp>;
};

export type Query_RootProduct_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Order_By>>;
	where?: InputMaybe<Product_Bool_Exp>;
};

export type Query_RootProduct_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootProduct_Has_Available_OptionArgs = {
	distinct_on?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Has_Available_Option_Order_By>>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

export type Query_RootProduct_Has_Available_Option_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Has_Available_Option_Order_By>>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

export type Query_RootProduct_Has_Available_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootRolesArgs = {
	distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Roles_Order_By>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Roles_Order_By>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootShowcaseArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

export type Query_RootShowcase_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

export type Query_RootShowcase_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootShowcase_CategoryArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Category_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Category_Order_By>>;
	where?: InputMaybe<Showcase_Category_Bool_Exp>;
};

export type Query_RootShowcase_Category_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Category_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Category_Order_By>>;
	where?: InputMaybe<Showcase_Category_Bool_Exp>;
};

export type Query_RootShowcase_Category_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootStore_PackageArgs = {
	distinct_on?: InputMaybe<Array<Store_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Package_Order_By>>;
	where?: InputMaybe<Store_Package_Bool_Exp>;
};

export type Query_RootStore_Package_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Package_Order_By>>;
	where?: InputMaybe<Store_Package_Bool_Exp>;
};

export type Query_RootStore_Package_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootStore_ProductArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Order_By>>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

export type Query_RootStore_Product_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Order_By>>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

export type Query_RootStore_Product_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootStore_Product_Has_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

export type Query_RootStore_Product_Has_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

export type Query_RootStore_Product_Has_Options_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootSupportArgs = {
	distinct_on?: InputMaybe<Array<Support_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Support_Order_By>>;
	where?: InputMaybe<Support_Bool_Exp>;
};

export type Query_RootSupport_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Support_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Support_Order_By>>;
	where?: InputMaybe<Support_Bool_Exp>;
};

export type Query_RootSupport_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootTeamArgs = {
	distinct_on?: InputMaybe<Array<Team_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Order_By>>;
	where?: InputMaybe<Team_Bool_Exp>;
};

export type Query_RootTeam_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Order_By>>;
	where?: InputMaybe<Team_Bool_Exp>;
};

export type Query_RootTeam_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootTeam_StoreArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_By>>;
	where?: InputMaybe<Team_Store_Bool_Exp>;
};

export type Query_RootTeam_Store_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_By>>;
	where?: InputMaybe<Team_Store_Bool_Exp>;
};

export type Query_RootTeam_Store_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootTeam_Store_Email_LogArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
};

export type Query_RootTeam_Store_Email_Log_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
};

export type Query_RootTeam_Store_Email_Log_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootTeam_Store_Order_Email_LogArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

export type Query_RootTeam_Store_Order_Email_Log_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

export type Query_RootTeam_Store_Order_Email_Log_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootUserArgs = {
	distinct_on?: InputMaybe<Array<User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Order_By>>;
	where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUser_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Order_By>>;
	where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUser_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootUser_RolesArgs = {
	distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Roles_Order_By>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Roles_Order_By>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootUser_TokenArgs = {
	distinct_on?: InputMaybe<Array<User_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Token_Order_By>>;
	where?: InputMaybe<User_Token_Bool_Exp>;
};

export type Query_RootUser_Token_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Token_Order_By>>;
	where?: InputMaybe<User_Token_Bool_Exp>;
};

export type Query_RootUser_Token_By_PkArgs = {
	id: Scalars['Int'];
};

/** roles to track for users */
export type Roles = {
	__typename?: 'roles';
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	role_name: Scalars['String'];
	updated_at: Scalars['timestamptz'];
	/** An array relationship */
	user_roles: Array<User_Roles>;
	/** An aggregate relationship */
	user_roles_aggregate: User_Roles_Aggregate;
};

/** roles to track for users */
export type RolesUser_RolesArgs = {
	distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Roles_Order_By>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** roles to track for users */
export type RolesUser_Roles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Roles_Order_By>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
	__typename?: 'roles_aggregate';
	aggregate?: Maybe<Roles_Aggregate_Fields>;
	nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
	__typename?: 'roles_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<Roles_Max_Fields>;
	min?: Maybe<Roles_Min_Fields>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Roles_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
	_and?: InputMaybe<Array<Roles_Bool_Exp>>;
	_not?: InputMaybe<Roles_Bool_Exp>;
	_or?: InputMaybe<Array<Roles_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	role_name?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user_roles?: InputMaybe<User_Roles_Bool_Exp>;
	user_roles_aggregate?: InputMaybe<User_Roles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
	/** unique or primary key constraint on columns "id" */
	RolesPkey = 'roles_pkey',
	/** unique or primary key constraint on columns "role_name" */
	RolesRoleNameKey = 'roles_role_name_key'
}

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	role_name?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_roles?: InputMaybe<User_Roles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
	__typename?: 'roles_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	role_name?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
	__typename?: 'roles_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	role_name?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
	__typename?: 'roles_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
	data: Roles_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
	constraint: Roles_Constraint;
	update_columns?: Array<Roles_Update_Column>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	role_name?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_roles_aggregate?: InputMaybe<User_Roles_Aggregate_Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	RoleName = 'role_name',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	role_name?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Roles_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	role_name?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	RoleName = 'role_name',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Roles_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Roles_Set_Input>;
	/** filter the rows which have to be updated */
	where: Roles_Bool_Exp;
};

/** columns and relationships of "showcase" */
export type Showcase = {
	__typename?: 'showcase';
	banner_url?: Maybe<Scalars['String']>;
	created_at: Scalars['timestamptz'];
	friendly_url_key?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	image_url: Scalars['String'];
	is_archived: Scalars['Boolean'];
	label: Scalars['String'];
	order: Scalars['Int'];
	search_sets?: Maybe<Scalars['jsonb']>;
	/** An object relationship */
	showcase_category?: Maybe<Showcase_Category>;
	showcase_category_id?: Maybe<Scalars['uuid']>;
	store_owner_id: Scalars['uuid'];
	store_showcasing_id: Scalars['uuid'];
	/** An object relationship */
	teamStoreByStoreShowcasingId: Team_Store;
	/** An object relationship */
	team_store: Team_Store;
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "showcase" */
export type ShowcaseSearch_SetsArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "showcase" */
export type Showcase_Aggregate = {
	__typename?: 'showcase_aggregate';
	aggregate?: Maybe<Showcase_Aggregate_Fields>;
	nodes: Array<Showcase>;
};

export type Showcase_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Showcase_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Showcase_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Showcase_Aggregate_Bool_Exp_Count>;
};

export type Showcase_Aggregate_Bool_Exp_Bool_And = {
	arguments: Showcase_Select_Column_Showcase_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Showcase_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Showcase_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Showcase_Select_Column_Showcase_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Showcase_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Showcase_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Showcase_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Showcase_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showcase" */
export type Showcase_Aggregate_Fields = {
	__typename?: 'showcase_aggregate_fields';
	avg?: Maybe<Showcase_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Showcase_Max_Fields>;
	min?: Maybe<Showcase_Min_Fields>;
	stddev?: Maybe<Showcase_Stddev_Fields>;
	stddev_pop?: Maybe<Showcase_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Showcase_Stddev_Samp_Fields>;
	sum?: Maybe<Showcase_Sum_Fields>;
	var_pop?: Maybe<Showcase_Var_Pop_Fields>;
	var_samp?: Maybe<Showcase_Var_Samp_Fields>;
	variance?: Maybe<Showcase_Variance_Fields>;
};

/** aggregate fields of "showcase" */
export type Showcase_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Showcase_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showcase" */
export type Showcase_Aggregate_Order_By = {
	avg?: InputMaybe<Showcase_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Showcase_Max_Order_By>;
	min?: InputMaybe<Showcase_Min_Order_By>;
	stddev?: InputMaybe<Showcase_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Showcase_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Showcase_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Showcase_Sum_Order_By>;
	var_pop?: InputMaybe<Showcase_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Showcase_Var_Samp_Order_By>;
	variance?: InputMaybe<Showcase_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Showcase_Append_Input = {
	search_sets?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "showcase" */
export type Showcase_Arr_Rel_Insert_Input = {
	data: Array<Showcase_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Showcase_On_Conflict>;
};

/** aggregate avg on columns */
export type Showcase_Avg_Fields = {
	__typename?: 'showcase_avg_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "showcase" */
export type Showcase_Avg_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "showcase". All fields are combined with a logical 'AND'. */
export type Showcase_Bool_Exp = {
	_and?: InputMaybe<Array<Showcase_Bool_Exp>>;
	_not?: InputMaybe<Showcase_Bool_Exp>;
	_or?: InputMaybe<Array<Showcase_Bool_Exp>>;
	banner_url?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	friendly_url_key?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	image_url?: InputMaybe<String_Comparison_Exp>;
	is_archived?: InputMaybe<Boolean_Comparison_Exp>;
	label?: InputMaybe<String_Comparison_Exp>;
	order?: InputMaybe<Int_Comparison_Exp>;
	search_sets?: InputMaybe<Jsonb_Comparison_Exp>;
	showcase_category?: InputMaybe<Showcase_Category_Bool_Exp>;
	showcase_category_id?: InputMaybe<Uuid_Comparison_Exp>;
	store_owner_id?: InputMaybe<Uuid_Comparison_Exp>;
	store_showcasing_id?: InputMaybe<Uuid_Comparison_Exp>;
	teamStoreByStoreShowcasingId?: InputMaybe<Team_Store_Bool_Exp>;
	team_store?: InputMaybe<Team_Store_Bool_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** categories for showcase items */
export type Showcase_Category = {
	__typename?: 'showcase_category';
	created_at: Scalars['timestamptz'];
	display_order: Scalars['Int'];
	id: Scalars['uuid'];
	name: Scalars['String'];
	parent_id?: Maybe<Scalars['uuid']>;
	/** An array relationship */
	showcase_categories: Array<Showcase_Category>;
	/** An aggregate relationship */
	showcase_categories_aggregate: Showcase_Category_Aggregate;
	/** An object relationship */
	showcase_category?: Maybe<Showcase_Category>;
	/** An array relationship */
	showcases: Array<Showcase>;
	/** An aggregate relationship */
	showcases_aggregate: Showcase_Aggregate;
	team_group_store_id: Scalars['uuid'];
	updated_at: Scalars['timestamptz'];
};

/** categories for showcase items */
export type Showcase_CategoryShowcase_CategoriesArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Category_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Category_Order_By>>;
	where?: InputMaybe<Showcase_Category_Bool_Exp>;
};

/** categories for showcase items */
export type Showcase_CategoryShowcase_Categories_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Category_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Category_Order_By>>;
	where?: InputMaybe<Showcase_Category_Bool_Exp>;
};

/** categories for showcase items */
export type Showcase_CategoryShowcasesArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

/** categories for showcase items */
export type Showcase_CategoryShowcases_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

/** aggregated selection of "showcase_category" */
export type Showcase_Category_Aggregate = {
	__typename?: 'showcase_category_aggregate';
	aggregate?: Maybe<Showcase_Category_Aggregate_Fields>;
	nodes: Array<Showcase_Category>;
};

export type Showcase_Category_Aggregate_Bool_Exp = {
	count?: InputMaybe<Showcase_Category_Aggregate_Bool_Exp_Count>;
};

export type Showcase_Category_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Showcase_Category_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Showcase_Category_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "showcase_category" */
export type Showcase_Category_Aggregate_Fields = {
	__typename?: 'showcase_category_aggregate_fields';
	avg?: Maybe<Showcase_Category_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Showcase_Category_Max_Fields>;
	min?: Maybe<Showcase_Category_Min_Fields>;
	stddev?: Maybe<Showcase_Category_Stddev_Fields>;
	stddev_pop?: Maybe<Showcase_Category_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Showcase_Category_Stddev_Samp_Fields>;
	sum?: Maybe<Showcase_Category_Sum_Fields>;
	var_pop?: Maybe<Showcase_Category_Var_Pop_Fields>;
	var_samp?: Maybe<Showcase_Category_Var_Samp_Fields>;
	variance?: Maybe<Showcase_Category_Variance_Fields>;
};

/** aggregate fields of "showcase_category" */
export type Showcase_Category_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Showcase_Category_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "showcase_category" */
export type Showcase_Category_Aggregate_Order_By = {
	avg?: InputMaybe<Showcase_Category_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Showcase_Category_Max_Order_By>;
	min?: InputMaybe<Showcase_Category_Min_Order_By>;
	stddev?: InputMaybe<Showcase_Category_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Showcase_Category_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Showcase_Category_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Showcase_Category_Sum_Order_By>;
	var_pop?: InputMaybe<Showcase_Category_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Showcase_Category_Var_Samp_Order_By>;
	variance?: InputMaybe<Showcase_Category_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "showcase_category" */
export type Showcase_Category_Arr_Rel_Insert_Input = {
	data: Array<Showcase_Category_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Showcase_Category_On_Conflict>;
};

/** aggregate avg on columns */
export type Showcase_Category_Avg_Fields = {
	__typename?: 'showcase_category_avg_fields';
	display_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "showcase_category" */
export type Showcase_Category_Avg_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "showcase_category". All fields are combined with a logical 'AND'. */
export type Showcase_Category_Bool_Exp = {
	_and?: InputMaybe<Array<Showcase_Category_Bool_Exp>>;
	_not?: InputMaybe<Showcase_Category_Bool_Exp>;
	_or?: InputMaybe<Array<Showcase_Category_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	display_order?: InputMaybe<Int_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	parent_id?: InputMaybe<Uuid_Comparison_Exp>;
	showcase_categories?: InputMaybe<Showcase_Category_Bool_Exp>;
	showcase_categories_aggregate?: InputMaybe<Showcase_Category_Aggregate_Bool_Exp>;
	showcase_category?: InputMaybe<Showcase_Category_Bool_Exp>;
	showcases?: InputMaybe<Showcase_Bool_Exp>;
	showcases_aggregate?: InputMaybe<Showcase_Aggregate_Bool_Exp>;
	team_group_store_id?: InputMaybe<Uuid_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "showcase_category" */
export enum Showcase_Category_Constraint {
	/** unique or primary key constraint on columns "id" */
	ShowcaseCategoryPkey = 'showcase_category_pkey'
}

/** input type for incrementing numeric columns in table "showcase_category" */
export type Showcase_Category_Inc_Input = {
	display_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "showcase_category" */
export type Showcase_Category_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	parent_id?: InputMaybe<Scalars['uuid']>;
	showcase_categories?: InputMaybe<Showcase_Category_Arr_Rel_Insert_Input>;
	showcase_category?: InputMaybe<Showcase_Category_Obj_Rel_Insert_Input>;
	showcases?: InputMaybe<Showcase_Arr_Rel_Insert_Input>;
	team_group_store_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Showcase_Category_Max_Fields = {
	__typename?: 'showcase_category_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	parent_id?: Maybe<Scalars['uuid']>;
	team_group_store_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "showcase_category" */
export type Showcase_Category_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	parent_id?: InputMaybe<Order_By>;
	team_group_store_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Showcase_Category_Min_Fields = {
	__typename?: 'showcase_category_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	display_order?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	parent_id?: Maybe<Scalars['uuid']>;
	team_group_store_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "showcase_category" */
export type Showcase_Category_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	parent_id?: InputMaybe<Order_By>;
	team_group_store_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "showcase_category" */
export type Showcase_Category_Mutation_Response = {
	__typename?: 'showcase_category_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Showcase_Category>;
};

/** input type for inserting object relation for remote table "showcase_category" */
export type Showcase_Category_Obj_Rel_Insert_Input = {
	data: Showcase_Category_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Showcase_Category_On_Conflict>;
};

/** on_conflict condition type for table "showcase_category" */
export type Showcase_Category_On_Conflict = {
	constraint: Showcase_Category_Constraint;
	update_columns?: Array<Showcase_Category_Update_Column>;
	where?: InputMaybe<Showcase_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "showcase_category". */
export type Showcase_Category_Order_By = {
	created_at?: InputMaybe<Order_By>;
	display_order?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	parent_id?: InputMaybe<Order_By>;
	showcase_categories_aggregate?: InputMaybe<Showcase_Category_Aggregate_Order_By>;
	showcase_category?: InputMaybe<Showcase_Category_Order_By>;
	showcases_aggregate?: InputMaybe<Showcase_Aggregate_Order_By>;
	team_group_store_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: showcase_category */
export type Showcase_Category_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "showcase_category" */
export enum Showcase_Category_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	ParentId = 'parent_id',
	/** column name */
	TeamGroupStoreId = 'team_group_store_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "showcase_category" */
export type Showcase_Category_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	parent_id?: InputMaybe<Scalars['uuid']>;
	team_group_store_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Showcase_Category_Stddev_Fields = {
	__typename?: 'showcase_category_stddev_fields';
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "showcase_category" */
export type Showcase_Category_Stddev_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Showcase_Category_Stddev_Pop_Fields = {
	__typename?: 'showcase_category_stddev_pop_fields';
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "showcase_category" */
export type Showcase_Category_Stddev_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Showcase_Category_Stddev_Samp_Fields = {
	__typename?: 'showcase_category_stddev_samp_fields';
	display_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "showcase_category" */
export type Showcase_Category_Stddev_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "showcase_category" */
export type Showcase_Category_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Showcase_Category_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showcase_Category_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	display_order?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	parent_id?: InputMaybe<Scalars['uuid']>;
	team_group_store_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Showcase_Category_Sum_Fields = {
	__typename?: 'showcase_category_sum_fields';
	display_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "showcase_category" */
export type Showcase_Category_Sum_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** update columns of table "showcase_category" */
export enum Showcase_Category_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DisplayOrder = 'display_order',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	ParentId = 'parent_id',
	/** column name */
	TeamGroupStoreId = 'team_group_store_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Showcase_Category_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Showcase_Category_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Showcase_Category_Set_Input>;
	/** filter the rows which have to be updated */
	where: Showcase_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Showcase_Category_Var_Pop_Fields = {
	__typename?: 'showcase_category_var_pop_fields';
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "showcase_category" */
export type Showcase_Category_Var_Pop_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Showcase_Category_Var_Samp_Fields = {
	__typename?: 'showcase_category_var_samp_fields';
	display_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "showcase_category" */
export type Showcase_Category_Var_Samp_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Showcase_Category_Variance_Fields = {
	__typename?: 'showcase_category_variance_fields';
	display_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "showcase_category" */
export type Showcase_Category_Variance_Order_By = {
	display_order?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "showcase" */
export enum Showcase_Constraint {
	/** unique or primary key constraint on columns "friendly_url_key" */
	ShowcaseFriendlyUrlKeyKey = 'showcase_friendly_url_key_key',
	/** unique or primary key constraint on columns "id" */
	ShowcasePkey = 'showcase_pkey',
	/** unique or primary key constraint on columns "id" */
	StoreIsShowcasingStoresPkey = 'store_is_showcasing_stores_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Showcase_Delete_At_Path_Input = {
	search_sets?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Showcase_Delete_Elem_Input = {
	search_sets?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Showcase_Delete_Key_Input = {
	search_sets?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "showcase" */
export type Showcase_Inc_Input = {
	order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "showcase" */
export type Showcase_Insert_Input = {
	banner_url?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	friendly_url_key?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	is_archived?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	search_sets?: InputMaybe<Scalars['jsonb']>;
	showcase_category?: InputMaybe<Showcase_Category_Obj_Rel_Insert_Input>;
	showcase_category_id?: InputMaybe<Scalars['uuid']>;
	store_owner_id?: InputMaybe<Scalars['uuid']>;
	store_showcasing_id?: InputMaybe<Scalars['uuid']>;
	teamStoreByStoreShowcasingId?: InputMaybe<Team_Store_Obj_Rel_Insert_Input>;
	team_store?: InputMaybe<Team_Store_Obj_Rel_Insert_Input>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Showcase_Max_Fields = {
	__typename?: 'showcase_max_fields';
	banner_url?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	friendly_url_key?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	label?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	showcase_category_id?: Maybe<Scalars['uuid']>;
	store_owner_id?: Maybe<Scalars['uuid']>;
	store_showcasing_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "showcase" */
export type Showcase_Max_Order_By = {
	banner_url?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	friendly_url_key?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	showcase_category_id?: InputMaybe<Order_By>;
	store_owner_id?: InputMaybe<Order_By>;
	store_showcasing_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Showcase_Min_Fields = {
	__typename?: 'showcase_min_fields';
	banner_url?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	friendly_url_key?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	label?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	showcase_category_id?: Maybe<Scalars['uuid']>;
	store_owner_id?: Maybe<Scalars['uuid']>;
	store_showcasing_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "showcase" */
export type Showcase_Min_Order_By = {
	banner_url?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	friendly_url_key?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	showcase_category_id?: InputMaybe<Order_By>;
	store_owner_id?: InputMaybe<Order_By>;
	store_showcasing_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "showcase" */
export type Showcase_Mutation_Response = {
	__typename?: 'showcase_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Showcase>;
};

/** on_conflict condition type for table "showcase" */
export type Showcase_On_Conflict = {
	constraint: Showcase_Constraint;
	update_columns?: Array<Showcase_Update_Column>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

/** Ordering options when selecting data from "showcase". */
export type Showcase_Order_By = {
	banner_url?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	friendly_url_key?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	is_archived?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	search_sets?: InputMaybe<Order_By>;
	showcase_category?: InputMaybe<Showcase_Category_Order_By>;
	showcase_category_id?: InputMaybe<Order_By>;
	store_owner_id?: InputMaybe<Order_By>;
	store_showcasing_id?: InputMaybe<Order_By>;
	teamStoreByStoreShowcasingId?: InputMaybe<Team_Store_Order_By>;
	team_store?: InputMaybe<Team_Store_Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: showcase */
export type Showcase_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Showcase_Prepend_Input = {
	search_sets?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "showcase" */
export enum Showcase_Select_Column {
	/** column name */
	BannerUrl = 'banner_url',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	FriendlyUrlKey = 'friendly_url_key',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	IsArchived = 'is_archived',
	/** column name */
	Label = 'label',
	/** column name */
	Order = 'order',
	/** column name */
	SearchSets = 'search_sets',
	/** column name */
	ShowcaseCategoryId = 'showcase_category_id',
	/** column name */
	StoreOwnerId = 'store_owner_id',
	/** column name */
	StoreShowcasingId = 'store_showcasing_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** select "showcase_aggregate_bool_exp_bool_and_arguments_columns" columns of table "showcase" */
export enum Showcase_Select_Column_Showcase_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsArchived = 'is_archived'
}

/** select "showcase_aggregate_bool_exp_bool_or_arguments_columns" columns of table "showcase" */
export enum Showcase_Select_Column_Showcase_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsArchived = 'is_archived'
}

/** input type for updating data in table "showcase" */
export type Showcase_Set_Input = {
	banner_url?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	friendly_url_key?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	is_archived?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	search_sets?: InputMaybe<Scalars['jsonb']>;
	showcase_category_id?: InputMaybe<Scalars['uuid']>;
	store_owner_id?: InputMaybe<Scalars['uuid']>;
	store_showcasing_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Showcase_Stddev_Fields = {
	__typename?: 'showcase_stddev_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "showcase" */
export type Showcase_Stddev_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Showcase_Stddev_Pop_Fields = {
	__typename?: 'showcase_stddev_pop_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "showcase" */
export type Showcase_Stddev_Pop_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Showcase_Stddev_Samp_Fields = {
	__typename?: 'showcase_stddev_samp_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "showcase" */
export type Showcase_Stddev_Samp_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "showcase" */
export type Showcase_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Showcase_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Showcase_Stream_Cursor_Value_Input = {
	banner_url?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	friendly_url_key?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	is_archived?: InputMaybe<Scalars['Boolean']>;
	label?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	search_sets?: InputMaybe<Scalars['jsonb']>;
	showcase_category_id?: InputMaybe<Scalars['uuid']>;
	store_owner_id?: InputMaybe<Scalars['uuid']>;
	store_showcasing_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Showcase_Sum_Fields = {
	__typename?: 'showcase_sum_fields';
	order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "showcase" */
export type Showcase_Sum_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** update columns of table "showcase" */
export enum Showcase_Update_Column {
	/** column name */
	BannerUrl = 'banner_url',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	FriendlyUrlKey = 'friendly_url_key',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	IsArchived = 'is_archived',
	/** column name */
	Label = 'label',
	/** column name */
	Order = 'order',
	/** column name */
	SearchSets = 'search_sets',
	/** column name */
	ShowcaseCategoryId = 'showcase_category_id',
	/** column name */
	StoreOwnerId = 'store_owner_id',
	/** column name */
	StoreShowcasingId = 'store_showcasing_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Showcase_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Showcase_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Showcase_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Showcase_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Showcase_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Showcase_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Showcase_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Showcase_Set_Input>;
	/** filter the rows which have to be updated */
	where: Showcase_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Showcase_Var_Pop_Fields = {
	__typename?: 'showcase_var_pop_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "showcase" */
export type Showcase_Var_Pop_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Showcase_Var_Samp_Fields = {
	__typename?: 'showcase_var_samp_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "showcase" */
export type Showcase_Var_Samp_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Showcase_Variance_Fields = {
	__typename?: 'showcase_variance_fields';
	order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "showcase" */
export type Showcase_Variance_Order_By = {
	order?: InputMaybe<Order_By>;
};

/** columns and relationships of "store_package" */
export type Store_Package = {
	__typename?: 'store_package';
	created_at: Scalars['timestamptz'];
	custom_price: Scalars['Int'];
	description?: Maybe<Scalars['String']>;
	enabled: Scalars['Boolean'];
	id: Scalars['uuid'];
	label?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	/** An array relationship */
	order_packages: Array<Order_Package>;
	/** An aggregate relationship */
	order_packages_aggregate: Order_Package_Aggregate;
	photo_url?: Maybe<Scalars['String']>;
	product_ids: Scalars['jsonb'];
	store_id: Scalars['uuid'];
	/** An object relationship */
	team_store: Team_Store;
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "store_package" */
export type Store_PackageOrder_PackagesArgs = {
	distinct_on?: InputMaybe<Array<Order_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Package_Order_By>>;
	where?: InputMaybe<Order_Package_Bool_Exp>;
};

/** columns and relationships of "store_package" */
export type Store_PackageOrder_Packages_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Package_Order_By>>;
	where?: InputMaybe<Order_Package_Bool_Exp>;
};

/** columns and relationships of "store_package" */
export type Store_PackageProduct_IdsArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "store_package" */
export type Store_Package_Aggregate = {
	__typename?: 'store_package_aggregate';
	aggregate?: Maybe<Store_Package_Aggregate_Fields>;
	nodes: Array<Store_Package>;
};

export type Store_Package_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Store_Package_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Store_Package_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Store_Package_Aggregate_Bool_Exp_Count>;
};

export type Store_Package_Aggregate_Bool_Exp_Bool_And = {
	arguments: Store_Package_Select_Column_Store_Package_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Store_Package_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Store_Package_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Store_Package_Select_Column_Store_Package_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Store_Package_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Store_Package_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Store_Package_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Store_Package_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "store_package" */
export type Store_Package_Aggregate_Fields = {
	__typename?: 'store_package_aggregate_fields';
	avg?: Maybe<Store_Package_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Store_Package_Max_Fields>;
	min?: Maybe<Store_Package_Min_Fields>;
	stddev?: Maybe<Store_Package_Stddev_Fields>;
	stddev_pop?: Maybe<Store_Package_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Store_Package_Stddev_Samp_Fields>;
	sum?: Maybe<Store_Package_Sum_Fields>;
	var_pop?: Maybe<Store_Package_Var_Pop_Fields>;
	var_samp?: Maybe<Store_Package_Var_Samp_Fields>;
	variance?: Maybe<Store_Package_Variance_Fields>;
};

/** aggregate fields of "store_package" */
export type Store_Package_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Store_Package_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_package" */
export type Store_Package_Aggregate_Order_By = {
	avg?: InputMaybe<Store_Package_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Store_Package_Max_Order_By>;
	min?: InputMaybe<Store_Package_Min_Order_By>;
	stddev?: InputMaybe<Store_Package_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Store_Package_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Store_Package_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Store_Package_Sum_Order_By>;
	var_pop?: InputMaybe<Store_Package_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Store_Package_Var_Samp_Order_By>;
	variance?: InputMaybe<Store_Package_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Store_Package_Append_Input = {
	product_ids?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "store_package" */
export type Store_Package_Arr_Rel_Insert_Input = {
	data: Array<Store_Package_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Store_Package_On_Conflict>;
};

/** aggregate avg on columns */
export type Store_Package_Avg_Fields = {
	__typename?: 'store_package_avg_fields';
	custom_price?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "store_package" */
export type Store_Package_Avg_Order_By = {
	custom_price?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "store_package". All fields are combined with a logical 'AND'. */
export type Store_Package_Bool_Exp = {
	_and?: InputMaybe<Array<Store_Package_Bool_Exp>>;
	_not?: InputMaybe<Store_Package_Bool_Exp>;
	_or?: InputMaybe<Array<Store_Package_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	custom_price?: InputMaybe<Int_Comparison_Exp>;
	description?: InputMaybe<String_Comparison_Exp>;
	enabled?: InputMaybe<Boolean_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	label?: InputMaybe<String_Comparison_Exp>;
	order?: InputMaybe<Int_Comparison_Exp>;
	order_packages?: InputMaybe<Order_Package_Bool_Exp>;
	order_packages_aggregate?: InputMaybe<Order_Package_Aggregate_Bool_Exp>;
	photo_url?: InputMaybe<String_Comparison_Exp>;
	product_ids?: InputMaybe<Jsonb_Comparison_Exp>;
	store_id?: InputMaybe<Uuid_Comparison_Exp>;
	team_store?: InputMaybe<Team_Store_Bool_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_package" */
export enum Store_Package_Constraint {
	/** unique or primary key constraint on columns "id" */
	StorePackagePkey = 'store_package_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Store_Package_Delete_At_Path_Input = {
	product_ids?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Store_Package_Delete_Elem_Input = {
	product_ids?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Store_Package_Delete_Key_Input = {
	product_ids?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "store_package" */
export type Store_Package_Inc_Input = {
	custom_price?: InputMaybe<Scalars['Int']>;
	order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "store_package" */
export type Store_Package_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_price?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	order_packages?: InputMaybe<Order_Package_Arr_Rel_Insert_Input>;
	photo_url?: InputMaybe<Scalars['String']>;
	product_ids?: InputMaybe<Scalars['jsonb']>;
	store_id?: InputMaybe<Scalars['uuid']>;
	team_store?: InputMaybe<Team_Store_Obj_Rel_Insert_Input>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_Package_Max_Fields = {
	__typename?: 'store_package_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	custom_price?: Maybe<Scalars['Int']>;
	description?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	photo_url?: Maybe<Scalars['String']>;
	store_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_package" */
export type Store_Package_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	custom_price?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	photo_url?: InputMaybe<Order_By>;
	store_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Store_Package_Min_Fields = {
	__typename?: 'store_package_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	custom_price?: Maybe<Scalars['Int']>;
	description?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	photo_url?: Maybe<Scalars['String']>;
	store_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_package" */
export type Store_Package_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	custom_price?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	photo_url?: InputMaybe<Order_By>;
	store_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "store_package" */
export type Store_Package_Mutation_Response = {
	__typename?: 'store_package_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Store_Package>;
};

/** input type for inserting object relation for remote table "store_package" */
export type Store_Package_Obj_Rel_Insert_Input = {
	data: Store_Package_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Store_Package_On_Conflict>;
};

/** on_conflict condition type for table "store_package" */
export type Store_Package_On_Conflict = {
	constraint: Store_Package_Constraint;
	update_columns?: Array<Store_Package_Update_Column>;
	where?: InputMaybe<Store_Package_Bool_Exp>;
};

/** Ordering options when selecting data from "store_package". */
export type Store_Package_Order_By = {
	created_at?: InputMaybe<Order_By>;
	custom_price?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	enabled?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	order_packages_aggregate?: InputMaybe<Order_Package_Aggregate_Order_By>;
	photo_url?: InputMaybe<Order_By>;
	product_ids?: InputMaybe<Order_By>;
	store_id?: InputMaybe<Order_By>;
	team_store?: InputMaybe<Team_Store_Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_package */
export type Store_Package_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Store_Package_Prepend_Input = {
	product_ids?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "store_package" */
export enum Store_Package_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomPrice = 'custom_price',
	/** column name */
	Description = 'description',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	Id = 'id',
	/** column name */
	Label = 'label',
	/** column name */
	Order = 'order',
	/** column name */
	PhotoUrl = 'photo_url',
	/** column name */
	ProductIds = 'product_ids',
	/** column name */
	StoreId = 'store_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** select "store_package_aggregate_bool_exp_bool_and_arguments_columns" columns of table "store_package" */
export enum Store_Package_Select_Column_Store_Package_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	Enabled = 'enabled'
}

/** select "store_package_aggregate_bool_exp_bool_or_arguments_columns" columns of table "store_package" */
export enum Store_Package_Select_Column_Store_Package_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	Enabled = 'enabled'
}

/** input type for updating data in table "store_package" */
export type Store_Package_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_price?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	photo_url?: InputMaybe<Scalars['String']>;
	product_ids?: InputMaybe<Scalars['jsonb']>;
	store_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Store_Package_Stddev_Fields = {
	__typename?: 'store_package_stddev_fields';
	custom_price?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "store_package" */
export type Store_Package_Stddev_Order_By = {
	custom_price?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Store_Package_Stddev_Pop_Fields = {
	__typename?: 'store_package_stddev_pop_fields';
	custom_price?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "store_package" */
export type Store_Package_Stddev_Pop_Order_By = {
	custom_price?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Store_Package_Stddev_Samp_Fields = {
	__typename?: 'store_package_stddev_samp_fields';
	custom_price?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "store_package" */
export type Store_Package_Stddev_Samp_Order_By = {
	custom_price?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "store_package" */
export type Store_Package_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Store_Package_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Package_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_price?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	photo_url?: InputMaybe<Scalars['String']>;
	product_ids?: InputMaybe<Scalars['jsonb']>;
	store_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Store_Package_Sum_Fields = {
	__typename?: 'store_package_sum_fields';
	custom_price?: Maybe<Scalars['Int']>;
	order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "store_package" */
export type Store_Package_Sum_Order_By = {
	custom_price?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
};

/** update columns of table "store_package" */
export enum Store_Package_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomPrice = 'custom_price',
	/** column name */
	Description = 'description',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	Id = 'id',
	/** column name */
	Label = 'label',
	/** column name */
	Order = 'order',
	/** column name */
	PhotoUrl = 'photo_url',
	/** column name */
	ProductIds = 'product_ids',
	/** column name */
	StoreId = 'store_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Store_Package_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Store_Package_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Store_Package_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Store_Package_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Store_Package_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Store_Package_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Store_Package_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Store_Package_Set_Input>;
	/** filter the rows which have to be updated */
	where: Store_Package_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Store_Package_Var_Pop_Fields = {
	__typename?: 'store_package_var_pop_fields';
	custom_price?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "store_package" */
export type Store_Package_Var_Pop_Order_By = {
	custom_price?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Store_Package_Var_Samp_Fields = {
	__typename?: 'store_package_var_samp_fields';
	custom_price?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "store_package" */
export type Store_Package_Var_Samp_Order_By = {
	custom_price?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Store_Package_Variance_Fields = {
	__typename?: 'store_package_variance_fields';
	custom_price?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "store_package" */
export type Store_Package_Variance_Order_By = {
	custom_price?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
};

/** columns and relationships of "store_product" */
export type Store_Product = {
	__typename?: 'store_product';
	additional_description?: Maybe<Scalars['String']>;
	additional_image_list: Scalars['jsonb'];
	bundle_only: Scalars['Boolean'];
	created_at: Scalars['timestamptz'];
	custom_name_enabled: Scalars['Boolean'];
	custom_name_number_dropdown?: Maybe<Scalars['jsonb']>;
	custom_name_price?: Maybe<Scalars['Int']>;
	custom_number_enabled: Scalars['Boolean'];
	custom_number_price?: Maybe<Scalars['Int']>;
	custom_price_override?: Maybe<Scalars['Int']>;
	description?: Maybe<Scalars['String']>;
	display_photo?: Maybe<Scalars['String']>;
	enabled?: Maybe<Scalars['Boolean']>;
	enforce_limit_qty: Scalars['Boolean'];
	friendly_url_key?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	label?: Maybe<Scalars['String']>;
	limit_order_qty: Scalars['Int'];
	meta_key?: Maybe<Scalars['String']>;
	order: Scalars['Int'];
	/** An array relationship */
	order_items: Array<Order_Item>;
	/** An aggregate relationship */
	order_items_aggregate: Order_Item_Aggregate;
	/** An object relationship */
	product: Product;
	product_id: Scalars['uuid'];
	qty_purchased_calc: Scalars['Int'];
	/** An array relationship */
	store_product_has_options: Array<Store_Product_Has_Options>;
	/** An aggregate relationship */
	store_product_has_options_aggregate: Store_Product_Has_Options_Aggregate;
	/** An object relationship */
	team_store: Team_Store;
	team_store_id: Scalars['uuid'];
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "store_product" */
export type Store_ProductAdditional_Image_ListArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "store_product" */
export type Store_ProductCustom_Name_Number_DropdownArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "store_product" */
export type Store_ProductOrder_ItemsArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

/** columns and relationships of "store_product" */
export type Store_ProductOrder_Items_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

/** columns and relationships of "store_product" */
export type Store_ProductStore_Product_Has_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

/** columns and relationships of "store_product" */
export type Store_ProductStore_Product_Has_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

/** aggregated selection of "store_product" */
export type Store_Product_Aggregate = {
	__typename?: 'store_product_aggregate';
	aggregate?: Maybe<Store_Product_Aggregate_Fields>;
	nodes: Array<Store_Product>;
};

export type Store_Product_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Store_Product_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Store_Product_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Store_Product_Aggregate_Bool_Exp_Count>;
};

export type Store_Product_Aggregate_Bool_Exp_Bool_And = {
	arguments: Store_Product_Select_Column_Store_Product_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Store_Product_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Store_Product_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Store_Product_Select_Column_Store_Product_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Store_Product_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Store_Product_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Store_Product_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Store_Product_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "store_product" */
export type Store_Product_Aggregate_Fields = {
	__typename?: 'store_product_aggregate_fields';
	avg?: Maybe<Store_Product_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Store_Product_Max_Fields>;
	min?: Maybe<Store_Product_Min_Fields>;
	stddev?: Maybe<Store_Product_Stddev_Fields>;
	stddev_pop?: Maybe<Store_Product_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Store_Product_Stddev_Samp_Fields>;
	sum?: Maybe<Store_Product_Sum_Fields>;
	var_pop?: Maybe<Store_Product_Var_Pop_Fields>;
	var_samp?: Maybe<Store_Product_Var_Samp_Fields>;
	variance?: Maybe<Store_Product_Variance_Fields>;
};

/** aggregate fields of "store_product" */
export type Store_Product_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Store_Product_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_product" */
export type Store_Product_Aggregate_Order_By = {
	avg?: InputMaybe<Store_Product_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Store_Product_Max_Order_By>;
	min?: InputMaybe<Store_Product_Min_Order_By>;
	stddev?: InputMaybe<Store_Product_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Store_Product_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Store_Product_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Store_Product_Sum_Order_By>;
	var_pop?: InputMaybe<Store_Product_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Store_Product_Var_Samp_Order_By>;
	variance?: InputMaybe<Store_Product_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Store_Product_Append_Input = {
	additional_image_list?: InputMaybe<Scalars['jsonb']>;
	custom_name_number_dropdown?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "store_product" */
export type Store_Product_Arr_Rel_Insert_Input = {
	data: Array<Store_Product_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Store_Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Store_Product_Avg_Fields = {
	__typename?: 'store_product_avg_fields';
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	custom_price_override?: Maybe<Scalars['Float']>;
	limit_order_qty?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
	qty_purchased_calc?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "store_product" */
export type Store_Product_Avg_Order_By = {
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "store_product". All fields are combined with a logical 'AND'. */
export type Store_Product_Bool_Exp = {
	_and?: InputMaybe<Array<Store_Product_Bool_Exp>>;
	_not?: InputMaybe<Store_Product_Bool_Exp>;
	_or?: InputMaybe<Array<Store_Product_Bool_Exp>>;
	additional_description?: InputMaybe<String_Comparison_Exp>;
	additional_image_list?: InputMaybe<Jsonb_Comparison_Exp>;
	bundle_only?: InputMaybe<Boolean_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	custom_name_enabled?: InputMaybe<Boolean_Comparison_Exp>;
	custom_name_number_dropdown?: InputMaybe<Jsonb_Comparison_Exp>;
	custom_name_price?: InputMaybe<Int_Comparison_Exp>;
	custom_number_enabled?: InputMaybe<Boolean_Comparison_Exp>;
	custom_number_price?: InputMaybe<Int_Comparison_Exp>;
	custom_price_override?: InputMaybe<Int_Comparison_Exp>;
	description?: InputMaybe<String_Comparison_Exp>;
	display_photo?: InputMaybe<String_Comparison_Exp>;
	enabled?: InputMaybe<Boolean_Comparison_Exp>;
	enforce_limit_qty?: InputMaybe<Boolean_Comparison_Exp>;
	friendly_url_key?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	label?: InputMaybe<String_Comparison_Exp>;
	limit_order_qty?: InputMaybe<Int_Comparison_Exp>;
	meta_key?: InputMaybe<String_Comparison_Exp>;
	order?: InputMaybe<Int_Comparison_Exp>;
	order_items?: InputMaybe<Order_Item_Bool_Exp>;
	order_items_aggregate?: InputMaybe<Order_Item_Aggregate_Bool_Exp>;
	product?: InputMaybe<Product_Bool_Exp>;
	product_id?: InputMaybe<Uuid_Comparison_Exp>;
	qty_purchased_calc?: InputMaybe<Int_Comparison_Exp>;
	store_product_has_options?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
	store_product_has_options_aggregate?: InputMaybe<Store_Product_Has_Options_Aggregate_Bool_Exp>;
	team_store?: InputMaybe<Team_Store_Bool_Exp>;
	team_store_id?: InputMaybe<Uuid_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_product" */
export enum Store_Product_Constraint {
	/** unique or primary key constraint on columns "friendly_url_key" */
	StoreProductFriendlyUrlKeyKey = 'store_product_friendly_url_key_key',
	/** unique or primary key constraint on columns "id" */
	StoreProductPkey = 'store_product_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Store_Product_Delete_At_Path_Input = {
	additional_image_list?: InputMaybe<Array<Scalars['String']>>;
	custom_name_number_dropdown?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Store_Product_Delete_Elem_Input = {
	additional_image_list?: InputMaybe<Scalars['Int']>;
	custom_name_number_dropdown?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Store_Product_Delete_Key_Input = {
	additional_image_list?: InputMaybe<Scalars['String']>;
	custom_name_number_dropdown?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "store_product_has_options" */
export type Store_Product_Has_Options = {
	__typename?: 'store_product_has_options';
	/** An object relationship */
	available_option: Available_Option;
	available_option_id: Scalars['uuid'];
	/** An object relationship */
	available_option_value?: Maybe<Available_Option_Value>;
	available_option_value_id?: Maybe<Scalars['uuid']>;
	created_at: Scalars['timestamptz'];
	enabled: Scalars['Boolean'];
	id: Scalars['uuid'];
	image_url?: Maybe<Scalars['String']>;
	label_override?: Maybe<Scalars['String']>;
	order: Scalars['Int'];
	price_modifier_override?: Maybe<Scalars['Int']>;
	secondary_image_url?: Maybe<Scalars['String']>;
	/** An object relationship */
	store_product: Store_Product;
	store_product_id: Scalars['uuid'];
	updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "store_product_has_options" */
export type Store_Product_Has_Options_Aggregate = {
	__typename?: 'store_product_has_options_aggregate';
	aggregate?: Maybe<Store_Product_Has_Options_Aggregate_Fields>;
	nodes: Array<Store_Product_Has_Options>;
};

export type Store_Product_Has_Options_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Store_Product_Has_Options_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Store_Product_Has_Options_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Store_Product_Has_Options_Aggregate_Bool_Exp_Count>;
};

export type Store_Product_Has_Options_Aggregate_Bool_Exp_Bool_And = {
	arguments: Store_Product_Has_Options_Select_Column_Store_Product_Has_Options_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Store_Product_Has_Options_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Store_Product_Has_Options_Select_Column_Store_Product_Has_Options_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Store_Product_Has_Options_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "store_product_has_options" */
export type Store_Product_Has_Options_Aggregate_Fields = {
	__typename?: 'store_product_has_options_aggregate_fields';
	avg?: Maybe<Store_Product_Has_Options_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Store_Product_Has_Options_Max_Fields>;
	min?: Maybe<Store_Product_Has_Options_Min_Fields>;
	stddev?: Maybe<Store_Product_Has_Options_Stddev_Fields>;
	stddev_pop?: Maybe<Store_Product_Has_Options_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Store_Product_Has_Options_Stddev_Samp_Fields>;
	sum?: Maybe<Store_Product_Has_Options_Sum_Fields>;
	var_pop?: Maybe<Store_Product_Has_Options_Var_Pop_Fields>;
	var_samp?: Maybe<Store_Product_Has_Options_Var_Samp_Fields>;
	variance?: Maybe<Store_Product_Has_Options_Variance_Fields>;
};

/** aggregate fields of "store_product_has_options" */
export type Store_Product_Has_Options_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_product_has_options" */
export type Store_Product_Has_Options_Aggregate_Order_By = {
	avg?: InputMaybe<Store_Product_Has_Options_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Store_Product_Has_Options_Max_Order_By>;
	min?: InputMaybe<Store_Product_Has_Options_Min_Order_By>;
	stddev?: InputMaybe<Store_Product_Has_Options_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Store_Product_Has_Options_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Store_Product_Has_Options_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Store_Product_Has_Options_Sum_Order_By>;
	var_pop?: InputMaybe<Store_Product_Has_Options_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Store_Product_Has_Options_Var_Samp_Order_By>;
	variance?: InputMaybe<Store_Product_Has_Options_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "store_product_has_options" */
export type Store_Product_Has_Options_Arr_Rel_Insert_Input = {
	data: Array<Store_Product_Has_Options_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Store_Product_Has_Options_On_Conflict>;
};

/** aggregate avg on columns */
export type Store_Product_Has_Options_Avg_Fields = {
	__typename?: 'store_product_has_options_avg_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier_override?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Avg_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "store_product_has_options". All fields are combined with a logical 'AND'. */
export type Store_Product_Has_Options_Bool_Exp = {
	_and?: InputMaybe<Array<Store_Product_Has_Options_Bool_Exp>>;
	_not?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
	_or?: InputMaybe<Array<Store_Product_Has_Options_Bool_Exp>>;
	available_option?: InputMaybe<Available_Option_Bool_Exp>;
	available_option_id?: InputMaybe<Uuid_Comparison_Exp>;
	available_option_value?: InputMaybe<Available_Option_Value_Bool_Exp>;
	available_option_value_id?: InputMaybe<Uuid_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	enabled?: InputMaybe<Boolean_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	image_url?: InputMaybe<String_Comparison_Exp>;
	label_override?: InputMaybe<String_Comparison_Exp>;
	order?: InputMaybe<Int_Comparison_Exp>;
	price_modifier_override?: InputMaybe<Int_Comparison_Exp>;
	secondary_image_url?: InputMaybe<String_Comparison_Exp>;
	store_product?: InputMaybe<Store_Product_Bool_Exp>;
	store_product_id?: InputMaybe<Uuid_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_product_has_options" */
export enum Store_Product_Has_Options_Constraint {
	/** unique or primary key constraint on columns "id" */
	StoreProductHasOptionsPkey = 'store_product_has_options_pkey'
}

/** input type for incrementing numeric columns in table "store_product_has_options" */
export type Store_Product_Has_Options_Inc_Input = {
	order?: InputMaybe<Scalars['Int']>;
	price_modifier_override?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "store_product_has_options" */
export type Store_Product_Has_Options_Insert_Input = {
	available_option?: InputMaybe<Available_Option_Obj_Rel_Insert_Input>;
	available_option_id?: InputMaybe<Scalars['uuid']>;
	available_option_value?: InputMaybe<Available_Option_Value_Obj_Rel_Insert_Input>;
	available_option_value_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	label_override?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	price_modifier_override?: InputMaybe<Scalars['Int']>;
	secondary_image_url?: InputMaybe<Scalars['String']>;
	store_product?: InputMaybe<Store_Product_Obj_Rel_Insert_Input>;
	store_product_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_Product_Has_Options_Max_Fields = {
	__typename?: 'store_product_has_options_max_fields';
	available_option_id?: Maybe<Scalars['uuid']>;
	available_option_value_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	label_override?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	price_modifier_override?: Maybe<Scalars['Int']>;
	secondary_image_url?: Maybe<Scalars['String']>;
	store_product_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Max_Order_By = {
	available_option_id?: InputMaybe<Order_By>;
	available_option_value_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	label_override?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
	secondary_image_url?: InputMaybe<Order_By>;
	store_product_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Store_Product_Has_Options_Min_Fields = {
	__typename?: 'store_product_has_options_min_fields';
	available_option_id?: Maybe<Scalars['uuid']>;
	available_option_value_id?: Maybe<Scalars['uuid']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	label_override?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	price_modifier_override?: Maybe<Scalars['Int']>;
	secondary_image_url?: Maybe<Scalars['String']>;
	store_product_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Min_Order_By = {
	available_option_id?: InputMaybe<Order_By>;
	available_option_value_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	label_override?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
	secondary_image_url?: InputMaybe<Order_By>;
	store_product_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "store_product_has_options" */
export type Store_Product_Has_Options_Mutation_Response = {
	__typename?: 'store_product_has_options_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Store_Product_Has_Options>;
};

/** on_conflict condition type for table "store_product_has_options" */
export type Store_Product_Has_Options_On_Conflict = {
	constraint: Store_Product_Has_Options_Constraint;
	update_columns?: Array<Store_Product_Has_Options_Update_Column>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "store_product_has_options". */
export type Store_Product_Has_Options_Order_By = {
	available_option?: InputMaybe<Available_Option_Order_By>;
	available_option_id?: InputMaybe<Order_By>;
	available_option_value?: InputMaybe<Available_Option_Value_Order_By>;
	available_option_value_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	enabled?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	label_override?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
	secondary_image_url?: InputMaybe<Order_By>;
	store_product?: InputMaybe<Store_Product_Order_By>;
	store_product_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_product_has_options */
export type Store_Product_Has_Options_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "store_product_has_options" */
export enum Store_Product_Has_Options_Select_Column {
	/** column name */
	AvailableOptionId = 'available_option_id',
	/** column name */
	AvailableOptionValueId = 'available_option_value_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	LabelOverride = 'label_override',
	/** column name */
	Order = 'order',
	/** column name */
	PriceModifierOverride = 'price_modifier_override',
	/** column name */
	SecondaryImageUrl = 'secondary_image_url',
	/** column name */
	StoreProductId = 'store_product_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** select "store_product_has_options_aggregate_bool_exp_bool_and_arguments_columns" columns of table "store_product_has_options" */
export enum Store_Product_Has_Options_Select_Column_Store_Product_Has_Options_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	Enabled = 'enabled'
}

/** select "store_product_has_options_aggregate_bool_exp_bool_or_arguments_columns" columns of table "store_product_has_options" */
export enum Store_Product_Has_Options_Select_Column_Store_Product_Has_Options_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	Enabled = 'enabled'
}

/** input type for updating data in table "store_product_has_options" */
export type Store_Product_Has_Options_Set_Input = {
	available_option_id?: InputMaybe<Scalars['uuid']>;
	available_option_value_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	label_override?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	price_modifier_override?: InputMaybe<Scalars['Int']>;
	secondary_image_url?: InputMaybe<Scalars['String']>;
	store_product_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Store_Product_Has_Options_Stddev_Fields = {
	__typename?: 'store_product_has_options_stddev_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier_override?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Stddev_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Store_Product_Has_Options_Stddev_Pop_Fields = {
	__typename?: 'store_product_has_options_stddev_pop_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier_override?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Stddev_Pop_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Store_Product_Has_Options_Stddev_Samp_Fields = {
	__typename?: 'store_product_has_options_stddev_samp_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier_override?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Stddev_Samp_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "store_product_has_options" */
export type Store_Product_Has_Options_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Store_Product_Has_Options_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Product_Has_Options_Stream_Cursor_Value_Input = {
	available_option_id?: InputMaybe<Scalars['uuid']>;
	available_option_value_id?: InputMaybe<Scalars['uuid']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	label_override?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	price_modifier_override?: InputMaybe<Scalars['Int']>;
	secondary_image_url?: InputMaybe<Scalars['String']>;
	store_product_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Store_Product_Has_Options_Sum_Fields = {
	__typename?: 'store_product_has_options_sum_fields';
	order?: Maybe<Scalars['Int']>;
	price_modifier_override?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Sum_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
};

/** update columns of table "store_product_has_options" */
export enum Store_Product_Has_Options_Update_Column {
	/** column name */
	AvailableOptionId = 'available_option_id',
	/** column name */
	AvailableOptionValueId = 'available_option_value_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	LabelOverride = 'label_override',
	/** column name */
	Order = 'order',
	/** column name */
	PriceModifierOverride = 'price_modifier_override',
	/** column name */
	SecondaryImageUrl = 'secondary_image_url',
	/** column name */
	StoreProductId = 'store_product_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Store_Product_Has_Options_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Store_Product_Has_Options_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Store_Product_Has_Options_Set_Input>;
	/** filter the rows which have to be updated */
	where: Store_Product_Has_Options_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Store_Product_Has_Options_Var_Pop_Fields = {
	__typename?: 'store_product_has_options_var_pop_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier_override?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Var_Pop_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Store_Product_Has_Options_Var_Samp_Fields = {
	__typename?: 'store_product_has_options_var_samp_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier_override?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Var_Samp_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Store_Product_Has_Options_Variance_Fields = {
	__typename?: 'store_product_has_options_variance_fields';
	order?: Maybe<Scalars['Float']>;
	price_modifier_override?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "store_product_has_options" */
export type Store_Product_Has_Options_Variance_Order_By = {
	order?: InputMaybe<Order_By>;
	price_modifier_override?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "store_product" */
export type Store_Product_Inc_Input = {
	custom_name_price?: InputMaybe<Scalars['Int']>;
	custom_number_price?: InputMaybe<Scalars['Int']>;
	custom_price_override?: InputMaybe<Scalars['Int']>;
	limit_order_qty?: InputMaybe<Scalars['Int']>;
	order?: InputMaybe<Scalars['Int']>;
	qty_purchased_calc?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "store_product" */
export type Store_Product_Insert_Input = {
	additional_description?: InputMaybe<Scalars['String']>;
	additional_image_list?: InputMaybe<Scalars['jsonb']>;
	bundle_only?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_name_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_name_number_dropdown?: InputMaybe<Scalars['jsonb']>;
	custom_name_price?: InputMaybe<Scalars['Int']>;
	custom_number_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_number_price?: InputMaybe<Scalars['Int']>;
	custom_price_override?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	display_photo?: InputMaybe<Scalars['String']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	enforce_limit_qty?: InputMaybe<Scalars['Boolean']>;
	friendly_url_key?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	limit_order_qty?: InputMaybe<Scalars['Int']>;
	meta_key?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	order_items?: InputMaybe<Order_Item_Arr_Rel_Insert_Input>;
	product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
	product_id?: InputMaybe<Scalars['uuid']>;
	qty_purchased_calc?: InputMaybe<Scalars['Int']>;
	store_product_has_options?: InputMaybe<Store_Product_Has_Options_Arr_Rel_Insert_Input>;
	team_store?: InputMaybe<Team_Store_Obj_Rel_Insert_Input>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_Product_Max_Fields = {
	__typename?: 'store_product_max_fields';
	additional_description?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	custom_name_price?: Maybe<Scalars['Int']>;
	custom_number_price?: Maybe<Scalars['Int']>;
	custom_price_override?: Maybe<Scalars['Int']>;
	description?: Maybe<Scalars['String']>;
	display_photo?: Maybe<Scalars['String']>;
	friendly_url_key?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	limit_order_qty?: Maybe<Scalars['Int']>;
	meta_key?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	product_id?: Maybe<Scalars['uuid']>;
	qty_purchased_calc?: Maybe<Scalars['Int']>;
	team_store_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_product" */
export type Store_Product_Max_Order_By = {
	additional_description?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	display_photo?: InputMaybe<Order_By>;
	friendly_url_key?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	meta_key?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	product_id?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
	team_store_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Store_Product_Min_Fields = {
	__typename?: 'store_product_min_fields';
	additional_description?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	custom_name_price?: Maybe<Scalars['Int']>;
	custom_number_price?: Maybe<Scalars['Int']>;
	custom_price_override?: Maybe<Scalars['Int']>;
	description?: Maybe<Scalars['String']>;
	display_photo?: Maybe<Scalars['String']>;
	friendly_url_key?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	label?: Maybe<Scalars['String']>;
	limit_order_qty?: Maybe<Scalars['Int']>;
	meta_key?: Maybe<Scalars['String']>;
	order?: Maybe<Scalars['Int']>;
	product_id?: Maybe<Scalars['uuid']>;
	qty_purchased_calc?: Maybe<Scalars['Int']>;
	team_store_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_product" */
export type Store_Product_Min_Order_By = {
	additional_description?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	display_photo?: InputMaybe<Order_By>;
	friendly_url_key?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	meta_key?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	product_id?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
	team_store_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "store_product" */
export type Store_Product_Mutation_Response = {
	__typename?: 'store_product_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Store_Product>;
};

/** input type for inserting object relation for remote table "store_product" */
export type Store_Product_Obj_Rel_Insert_Input = {
	data: Store_Product_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Store_Product_On_Conflict>;
};

/** on_conflict condition type for table "store_product" */
export type Store_Product_On_Conflict = {
	constraint: Store_Product_Constraint;
	update_columns?: Array<Store_Product_Update_Column>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "store_product". */
export type Store_Product_Order_By = {
	additional_description?: InputMaybe<Order_By>;
	additional_image_list?: InputMaybe<Order_By>;
	bundle_only?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	custom_name_enabled?: InputMaybe<Order_By>;
	custom_name_number_dropdown?: InputMaybe<Order_By>;
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_enabled?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	description?: InputMaybe<Order_By>;
	display_photo?: InputMaybe<Order_By>;
	enabled?: InputMaybe<Order_By>;
	enforce_limit_qty?: InputMaybe<Order_By>;
	friendly_url_key?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	label?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	meta_key?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	order_items_aggregate?: InputMaybe<Order_Item_Aggregate_Order_By>;
	product?: InputMaybe<Product_Order_By>;
	product_id?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
	store_product_has_options_aggregate?: InputMaybe<Store_Product_Has_Options_Aggregate_Order_By>;
	team_store?: InputMaybe<Team_Store_Order_By>;
	team_store_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_product */
export type Store_Product_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Store_Product_Prepend_Input = {
	additional_image_list?: InputMaybe<Scalars['jsonb']>;
	custom_name_number_dropdown?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "store_product" */
export enum Store_Product_Select_Column {
	/** column name */
	AdditionalDescription = 'additional_description',
	/** column name */
	AdditionalImageList = 'additional_image_list',
	/** column name */
	BundleOnly = 'bundle_only',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomNameEnabled = 'custom_name_enabled',
	/** column name */
	CustomNameNumberDropdown = 'custom_name_number_dropdown',
	/** column name */
	CustomNamePrice = 'custom_name_price',
	/** column name */
	CustomNumberEnabled = 'custom_number_enabled',
	/** column name */
	CustomNumberPrice = 'custom_number_price',
	/** column name */
	CustomPriceOverride = 'custom_price_override',
	/** column name */
	Description = 'description',
	/** column name */
	DisplayPhoto = 'display_photo',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	EnforceLimitQty = 'enforce_limit_qty',
	/** column name */
	FriendlyUrlKey = 'friendly_url_key',
	/** column name */
	Id = 'id',
	/** column name */
	Label = 'label',
	/** column name */
	LimitOrderQty = 'limit_order_qty',
	/** column name */
	MetaKey = 'meta_key',
	/** column name */
	Order = 'order',
	/** column name */
	ProductId = 'product_id',
	/** column name */
	QtyPurchasedCalc = 'qty_purchased_calc',
	/** column name */
	TeamStoreId = 'team_store_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** select "store_product_aggregate_bool_exp_bool_and_arguments_columns" columns of table "store_product" */
export enum Store_Product_Select_Column_Store_Product_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	BundleOnly = 'bundle_only',
	/** column name */
	CustomNameEnabled = 'custom_name_enabled',
	/** column name */
	CustomNumberEnabled = 'custom_number_enabled',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	EnforceLimitQty = 'enforce_limit_qty'
}

/** select "store_product_aggregate_bool_exp_bool_or_arguments_columns" columns of table "store_product" */
export enum Store_Product_Select_Column_Store_Product_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	BundleOnly = 'bundle_only',
	/** column name */
	CustomNameEnabled = 'custom_name_enabled',
	/** column name */
	CustomNumberEnabled = 'custom_number_enabled',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	EnforceLimitQty = 'enforce_limit_qty'
}

/** input type for updating data in table "store_product" */
export type Store_Product_Set_Input = {
	additional_description?: InputMaybe<Scalars['String']>;
	additional_image_list?: InputMaybe<Scalars['jsonb']>;
	bundle_only?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_name_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_name_number_dropdown?: InputMaybe<Scalars['jsonb']>;
	custom_name_price?: InputMaybe<Scalars['Int']>;
	custom_number_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_number_price?: InputMaybe<Scalars['Int']>;
	custom_price_override?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	display_photo?: InputMaybe<Scalars['String']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	enforce_limit_qty?: InputMaybe<Scalars['Boolean']>;
	friendly_url_key?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	limit_order_qty?: InputMaybe<Scalars['Int']>;
	meta_key?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	product_id?: InputMaybe<Scalars['uuid']>;
	qty_purchased_calc?: InputMaybe<Scalars['Int']>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Store_Product_Stddev_Fields = {
	__typename?: 'store_product_stddev_fields';
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	custom_price_override?: Maybe<Scalars['Float']>;
	limit_order_qty?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
	qty_purchased_calc?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "store_product" */
export type Store_Product_Stddev_Order_By = {
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Store_Product_Stddev_Pop_Fields = {
	__typename?: 'store_product_stddev_pop_fields';
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	custom_price_override?: Maybe<Scalars['Float']>;
	limit_order_qty?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
	qty_purchased_calc?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "store_product" */
export type Store_Product_Stddev_Pop_Order_By = {
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Store_Product_Stddev_Samp_Fields = {
	__typename?: 'store_product_stddev_samp_fields';
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	custom_price_override?: Maybe<Scalars['Float']>;
	limit_order_qty?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
	qty_purchased_calc?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "store_product" */
export type Store_Product_Stddev_Samp_Order_By = {
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "store_product" */
export type Store_Product_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Store_Product_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Product_Stream_Cursor_Value_Input = {
	additional_description?: InputMaybe<Scalars['String']>;
	additional_image_list?: InputMaybe<Scalars['jsonb']>;
	bundle_only?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	custom_name_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_name_number_dropdown?: InputMaybe<Scalars['jsonb']>;
	custom_name_price?: InputMaybe<Scalars['Int']>;
	custom_number_enabled?: InputMaybe<Scalars['Boolean']>;
	custom_number_price?: InputMaybe<Scalars['Int']>;
	custom_price_override?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	display_photo?: InputMaybe<Scalars['String']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	enforce_limit_qty?: InputMaybe<Scalars['Boolean']>;
	friendly_url_key?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	label?: InputMaybe<Scalars['String']>;
	limit_order_qty?: InputMaybe<Scalars['Int']>;
	meta_key?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Int']>;
	product_id?: InputMaybe<Scalars['uuid']>;
	qty_purchased_calc?: InputMaybe<Scalars['Int']>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Store_Product_Sum_Fields = {
	__typename?: 'store_product_sum_fields';
	custom_name_price?: Maybe<Scalars['Int']>;
	custom_number_price?: Maybe<Scalars['Int']>;
	custom_price_override?: Maybe<Scalars['Int']>;
	limit_order_qty?: Maybe<Scalars['Int']>;
	order?: Maybe<Scalars['Int']>;
	qty_purchased_calc?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "store_product" */
export type Store_Product_Sum_Order_By = {
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
};

/** update columns of table "store_product" */
export enum Store_Product_Update_Column {
	/** column name */
	AdditionalDescription = 'additional_description',
	/** column name */
	AdditionalImageList = 'additional_image_list',
	/** column name */
	BundleOnly = 'bundle_only',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CustomNameEnabled = 'custom_name_enabled',
	/** column name */
	CustomNameNumberDropdown = 'custom_name_number_dropdown',
	/** column name */
	CustomNamePrice = 'custom_name_price',
	/** column name */
	CustomNumberEnabled = 'custom_number_enabled',
	/** column name */
	CustomNumberPrice = 'custom_number_price',
	/** column name */
	CustomPriceOverride = 'custom_price_override',
	/** column name */
	Description = 'description',
	/** column name */
	DisplayPhoto = 'display_photo',
	/** column name */
	Enabled = 'enabled',
	/** column name */
	EnforceLimitQty = 'enforce_limit_qty',
	/** column name */
	FriendlyUrlKey = 'friendly_url_key',
	/** column name */
	Id = 'id',
	/** column name */
	Label = 'label',
	/** column name */
	LimitOrderQty = 'limit_order_qty',
	/** column name */
	MetaKey = 'meta_key',
	/** column name */
	Order = 'order',
	/** column name */
	ProductId = 'product_id',
	/** column name */
	QtyPurchasedCalc = 'qty_purchased_calc',
	/** column name */
	TeamStoreId = 'team_store_id',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Store_Product_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Store_Product_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Store_Product_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Store_Product_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Store_Product_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Store_Product_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Store_Product_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Store_Product_Set_Input>;
	/** filter the rows which have to be updated */
	where: Store_Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Store_Product_Var_Pop_Fields = {
	__typename?: 'store_product_var_pop_fields';
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	custom_price_override?: Maybe<Scalars['Float']>;
	limit_order_qty?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
	qty_purchased_calc?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "store_product" */
export type Store_Product_Var_Pop_Order_By = {
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Store_Product_Var_Samp_Fields = {
	__typename?: 'store_product_var_samp_fields';
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	custom_price_override?: Maybe<Scalars['Float']>;
	limit_order_qty?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
	qty_purchased_calc?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "store_product" */
export type Store_Product_Var_Samp_Order_By = {
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Store_Product_Variance_Fields = {
	__typename?: 'store_product_variance_fields';
	custom_name_price?: Maybe<Scalars['Float']>;
	custom_number_price?: Maybe<Scalars['Float']>;
	custom_price_override?: Maybe<Scalars['Float']>;
	limit_order_qty?: Maybe<Scalars['Float']>;
	order?: Maybe<Scalars['Float']>;
	qty_purchased_calc?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "store_product" */
export type Store_Product_Variance_Order_By = {
	custom_name_price?: InputMaybe<Order_By>;
	custom_number_price?: InputMaybe<Order_By>;
	custom_price_override?: InputMaybe<Order_By>;
	limit_order_qty?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_By>;
	qty_purchased_calc?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
	__typename?: 'subscription_root';
	/** fetch data from the table: "async_action" */
	async_action: Array<Async_Action>;
	/** fetch aggregated fields from the table: "async_action" */
	async_action_aggregate: Async_Action_Aggregate;
	/** fetch data from the table: "async_action" using primary key columns */
	async_action_by_pk?: Maybe<Async_Action>;
	/** fetch data from the table in a streaming manner: "async_action" */
	async_action_stream: Array<Async_Action>;
	/** fetch data from the table: "available_option" */
	available_option: Array<Available_Option>;
	/** fetch aggregated fields from the table: "available_option" */
	available_option_aggregate: Available_Option_Aggregate;
	/** fetch data from the table: "available_option" using primary key columns */
	available_option_by_pk?: Maybe<Available_Option>;
	/** fetch data from the table in a streaming manner: "available_option" */
	available_option_stream: Array<Available_Option>;
	/** fetch data from the table: "available_option_value" */
	available_option_value: Array<Available_Option_Value>;
	/** fetch aggregated fields from the table: "available_option_value" */
	available_option_value_aggregate: Available_Option_Value_Aggregate;
	/** fetch data from the table: "available_option_value" using primary key columns */
	available_option_value_by_pk?: Maybe<Available_Option_Value>;
	/** fetch data from the table in a streaming manner: "available_option_value" */
	available_option_value_stream: Array<Available_Option_Value>;
	/** fetch data from the table: "custom_link_purchase" */
	custom_link_purchase: Array<Custom_Link_Purchase>;
	/** fetch aggregated fields from the table: "custom_link_purchase" */
	custom_link_purchase_aggregate: Custom_Link_Purchase_Aggregate;
	/** fetch data from the table: "custom_link_purchase" using primary key columns */
	custom_link_purchase_by_pk?: Maybe<Custom_Link_Purchase>;
	/** fetch data from the table in a streaming manner: "custom_link_purchase" */
	custom_link_purchase_stream: Array<Custom_Link_Purchase>;
	/** fetch data from the table: "leads" */
	leads: Array<Leads>;
	/** fetch aggregated fields from the table: "leads" */
	leads_aggregate: Leads_Aggregate;
	/** fetch data from the table: "leads" using primary key columns */
	leads_by_pk?: Maybe<Leads>;
	/** fetch data from the table in a streaming manner: "leads" */
	leads_stream: Array<Leads>;
	/** fetch data from the table: "order" */
	order: Array<Order>;
	/** fetch aggregated fields from the table: "order" */
	order_aggregate: Order_Aggregate;
	/** fetch data from the table: "order" using primary key columns */
	order_by_pk?: Maybe<Order>;
	/** fetch data from the table: "order_item" */
	order_item: Array<Order_Item>;
	/** fetch aggregated fields from the table: "order_item" */
	order_item_aggregate: Order_Item_Aggregate;
	/** fetch data from the table: "order_item" using primary key columns */
	order_item_by_pk?: Maybe<Order_Item>;
	/** fetch data from the table: "order_item_option" */
	order_item_option: Array<Order_Item_Option>;
	/** fetch aggregated fields from the table: "order_item_option" */
	order_item_option_aggregate: Order_Item_Option_Aggregate;
	/** fetch data from the table: "order_item_option" using primary key columns */
	order_item_option_by_pk?: Maybe<Order_Item_Option>;
	/** fetch data from the table in a streaming manner: "order_item_option" */
	order_item_option_stream: Array<Order_Item_Option>;
	/** fetch data from the table in a streaming manner: "order_item" */
	order_item_stream: Array<Order_Item>;
	/** fetch data from the table: "order_package" */
	order_package: Array<Order_Package>;
	/** fetch aggregated fields from the table: "order_package" */
	order_package_aggregate: Order_Package_Aggregate;
	/** fetch data from the table: "order_package" using primary key columns */
	order_package_by_pk?: Maybe<Order_Package>;
	/** fetch data from the table in a streaming manner: "order_package" */
	order_package_stream: Array<Order_Package>;
	/** An array relationship */
	order_shipping_details: Array<Order_Shipping_Details>;
	/** An aggregate relationship */
	order_shipping_details_aggregate: Order_Shipping_Details_Aggregate;
	/** fetch data from the table: "order_shipping_details" using primary key columns */
	order_shipping_details_by_pk?: Maybe<Order_Shipping_Details>;
	/** fetch data from the table in a streaming manner: "order_shipping_details" */
	order_shipping_details_stream: Array<Order_Shipping_Details>;
	/** fetch data from the table: "order_status_history" */
	order_status_history: Array<Order_Status_History>;
	/** fetch aggregated fields from the table: "order_status_history" */
	order_status_history_aggregate: Order_Status_History_Aggregate;
	/** fetch data from the table: "order_status_history" using primary key columns */
	order_status_history_by_pk?: Maybe<Order_Status_History>;
	/** fetch data from the table in a streaming manner: "order_status_history" */
	order_status_history_stream: Array<Order_Status_History>;
	/** fetch data from the table in a streaming manner: "order" */
	order_stream: Array<Order>;
	/** fetch data from the table: "product" */
	product: Array<Product>;
	/** fetch aggregated fields from the table: "product" */
	product_aggregate: Product_Aggregate;
	/** fetch data from the table: "product" using primary key columns */
	product_by_pk?: Maybe<Product>;
	/** fetch data from the table: "product_has_available_option" */
	product_has_available_option: Array<Product_Has_Available_Option>;
	/** fetch aggregated fields from the table: "product_has_available_option" */
	product_has_available_option_aggregate: Product_Has_Available_Option_Aggregate;
	/** fetch data from the table: "product_has_available_option" using primary key columns */
	product_has_available_option_by_pk?: Maybe<Product_Has_Available_Option>;
	/** fetch data from the table in a streaming manner: "product_has_available_option" */
	product_has_available_option_stream: Array<Product_Has_Available_Option>;
	/** fetch data from the table in a streaming manner: "product" */
	product_stream: Array<Product>;
	/** fetch data from the table: "roles" */
	roles: Array<Roles>;
	/** fetch aggregated fields from the table: "roles" */
	roles_aggregate: Roles_Aggregate;
	/** fetch data from the table: "roles" using primary key columns */
	roles_by_pk?: Maybe<Roles>;
	/** fetch data from the table in a streaming manner: "roles" */
	roles_stream: Array<Roles>;
	/** fetch data from the table: "showcase" */
	showcase: Array<Showcase>;
	/** fetch aggregated fields from the table: "showcase" */
	showcase_aggregate: Showcase_Aggregate;
	/** fetch data from the table: "showcase" using primary key columns */
	showcase_by_pk?: Maybe<Showcase>;
	/** fetch data from the table: "showcase_category" */
	showcase_category: Array<Showcase_Category>;
	/** fetch aggregated fields from the table: "showcase_category" */
	showcase_category_aggregate: Showcase_Category_Aggregate;
	/** fetch data from the table: "showcase_category" using primary key columns */
	showcase_category_by_pk?: Maybe<Showcase_Category>;
	/** fetch data from the table in a streaming manner: "showcase_category" */
	showcase_category_stream: Array<Showcase_Category>;
	/** fetch data from the table in a streaming manner: "showcase" */
	showcase_stream: Array<Showcase>;
	/** fetch data from the table: "store_package" */
	store_package: Array<Store_Package>;
	/** fetch aggregated fields from the table: "store_package" */
	store_package_aggregate: Store_Package_Aggregate;
	/** fetch data from the table: "store_package" using primary key columns */
	store_package_by_pk?: Maybe<Store_Package>;
	/** fetch data from the table in a streaming manner: "store_package" */
	store_package_stream: Array<Store_Package>;
	/** fetch data from the table: "store_product" */
	store_product: Array<Store_Product>;
	/** fetch aggregated fields from the table: "store_product" */
	store_product_aggregate: Store_Product_Aggregate;
	/** fetch data from the table: "store_product" using primary key columns */
	store_product_by_pk?: Maybe<Store_Product>;
	/** An array relationship */
	store_product_has_options: Array<Store_Product_Has_Options>;
	/** An aggregate relationship */
	store_product_has_options_aggregate: Store_Product_Has_Options_Aggregate;
	/** fetch data from the table: "store_product_has_options" using primary key columns */
	store_product_has_options_by_pk?: Maybe<Store_Product_Has_Options>;
	/** fetch data from the table in a streaming manner: "store_product_has_options" */
	store_product_has_options_stream: Array<Store_Product_Has_Options>;
	/** fetch data from the table in a streaming manner: "store_product" */
	store_product_stream: Array<Store_Product>;
	/** fetch data from the table: "support" */
	support: Array<Support>;
	/** fetch aggregated fields from the table: "support" */
	support_aggregate: Support_Aggregate;
	/** fetch data from the table: "support" using primary key columns */
	support_by_pk?: Maybe<Support>;
	/** fetch data from the table in a streaming manner: "support" */
	support_stream: Array<Support>;
	/** fetch data from the table: "team" */
	team: Array<Team>;
	/** fetch aggregated fields from the table: "team" */
	team_aggregate: Team_Aggregate;
	/** fetch data from the table: "team" using primary key columns */
	team_by_pk?: Maybe<Team>;
	/** fetch data from the table: "team_store" */
	team_store: Array<Team_Store>;
	/** fetch aggregated fields from the table: "team_store" */
	team_store_aggregate: Team_Store_Aggregate;
	/** fetch data from the table: "team_store" using primary key columns */
	team_store_by_pk?: Maybe<Team_Store>;
	/** fetch data from the table: "team_store_email_log" */
	team_store_email_log: Array<Team_Store_Email_Log>;
	/** fetch aggregated fields from the table: "team_store_email_log" */
	team_store_email_log_aggregate: Team_Store_Email_Log_Aggregate;
	/** fetch data from the table: "team_store_email_log" using primary key columns */
	team_store_email_log_by_pk?: Maybe<Team_Store_Email_Log>;
	/** fetch data from the table in a streaming manner: "team_store_email_log" */
	team_store_email_log_stream: Array<Team_Store_Email_Log>;
	/** fetch data from the table: "team_store_order_email_log" */
	team_store_order_email_log: Array<Team_Store_Order_Email_Log>;
	/** fetch aggregated fields from the table: "team_store_order_email_log" */
	team_store_order_email_log_aggregate: Team_Store_Order_Email_Log_Aggregate;
	/** fetch data from the table: "team_store_order_email_log" using primary key columns */
	team_store_order_email_log_by_pk?: Maybe<Team_Store_Order_Email_Log>;
	/** fetch data from the table in a streaming manner: "team_store_order_email_log" */
	team_store_order_email_log_stream: Array<Team_Store_Order_Email_Log>;
	/** fetch data from the table in a streaming manner: "team_store" */
	team_store_stream: Array<Team_Store>;
	/** fetch data from the table in a streaming manner: "team" */
	team_stream: Array<Team>;
	/** fetch data from the table: "user" */
	user: Array<User>;
	/** fetch aggregated fields from the table: "user" */
	user_aggregate: User_Aggregate;
	/** fetch data from the table: "user" using primary key columns */
	user_by_pk?: Maybe<User>;
	/** An array relationship */
	user_roles: Array<User_Roles>;
	/** An aggregate relationship */
	user_roles_aggregate: User_Roles_Aggregate;
	/** fetch data from the table: "user_roles" using primary key columns */
	user_roles_by_pk?: Maybe<User_Roles>;
	/** fetch data from the table in a streaming manner: "user_roles" */
	user_roles_stream: Array<User_Roles>;
	/** fetch data from the table in a streaming manner: "user" */
	user_stream: Array<User>;
	/** fetch data from the table: "user_token" */
	user_token: Array<User_Token>;
	/** fetch aggregated fields from the table: "user_token" */
	user_token_aggregate: User_Token_Aggregate;
	/** fetch data from the table: "user_token" using primary key columns */
	user_token_by_pk?: Maybe<User_Token>;
	/** fetch data from the table in a streaming manner: "user_token" */
	user_token_stream: Array<User_Token>;
};

export type Subscription_RootAsync_ActionArgs = {
	distinct_on?: InputMaybe<Array<Async_Action_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Async_Action_Order_By>>;
	where?: InputMaybe<Async_Action_Bool_Exp>;
};

export type Subscription_RootAsync_Action_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Async_Action_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Async_Action_Order_By>>;
	where?: InputMaybe<Async_Action_Bool_Exp>;
};

export type Subscription_RootAsync_Action_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootAsync_Action_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Async_Action_Stream_Cursor_Input>>;
	where?: InputMaybe<Async_Action_Bool_Exp>;
};

export type Subscription_RootAvailable_OptionArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Order_By>>;
	where?: InputMaybe<Available_Option_Bool_Exp>;
};

export type Subscription_RootAvailable_Option_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Order_By>>;
	where?: InputMaybe<Available_Option_Bool_Exp>;
};

export type Subscription_RootAvailable_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootAvailable_Option_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Available_Option_Stream_Cursor_Input>>;
	where?: InputMaybe<Available_Option_Bool_Exp>;
};

export type Subscription_RootAvailable_Option_ValueArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Value_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Value_Order_By>>;
	where?: InputMaybe<Available_Option_Value_Bool_Exp>;
};

export type Subscription_RootAvailable_Option_Value_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Available_Option_Value_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Available_Option_Value_Order_By>>;
	where?: InputMaybe<Available_Option_Value_Bool_Exp>;
};

export type Subscription_RootAvailable_Option_Value_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootAvailable_Option_Value_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Available_Option_Value_Stream_Cursor_Input>>;
	where?: InputMaybe<Available_Option_Value_Bool_Exp>;
};

export type Subscription_RootCustom_Link_PurchaseArgs = {
	distinct_on?: InputMaybe<Array<Custom_Link_Purchase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Custom_Link_Purchase_Order_By>>;
	where?: InputMaybe<Custom_Link_Purchase_Bool_Exp>;
};

export type Subscription_RootCustom_Link_Purchase_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Custom_Link_Purchase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Custom_Link_Purchase_Order_By>>;
	where?: InputMaybe<Custom_Link_Purchase_Bool_Exp>;
};

export type Subscription_RootCustom_Link_Purchase_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootCustom_Link_Purchase_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Custom_Link_Purchase_Stream_Cursor_Input>>;
	where?: InputMaybe<Custom_Link_Purchase_Bool_Exp>;
};

export type Subscription_RootLeadsArgs = {
	distinct_on?: InputMaybe<Array<Leads_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Leads_Order_By>>;
	where?: InputMaybe<Leads_Bool_Exp>;
};

export type Subscription_RootLeads_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Leads_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Leads_Order_By>>;
	where?: InputMaybe<Leads_Bool_Exp>;
};

export type Subscription_RootLeads_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootLeads_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Leads_Stream_Cursor_Input>>;
	where?: InputMaybe<Leads_Bool_Exp>;
};

export type Subscription_RootOrderArgs = {
	distinct_on?: InputMaybe<Array<Order_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Order_By>>;
	where?: InputMaybe<Order_Bool_Exp>;
};

export type Subscription_RootOrder_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Order_By>>;
	where?: InputMaybe<Order_Bool_Exp>;
};

export type Subscription_RootOrder_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootOrder_ItemArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

export type Subscription_RootOrder_Item_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Order_By>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

export type Subscription_RootOrder_Item_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootOrder_Item_OptionArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

export type Subscription_RootOrder_Item_Option_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Item_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Item_Option_Order_By>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

export type Subscription_RootOrder_Item_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootOrder_Item_Option_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Order_Item_Option_Stream_Cursor_Input>>;
	where?: InputMaybe<Order_Item_Option_Bool_Exp>;
};

export type Subscription_RootOrder_Item_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Order_Item_Stream_Cursor_Input>>;
	where?: InputMaybe<Order_Item_Bool_Exp>;
};

export type Subscription_RootOrder_PackageArgs = {
	distinct_on?: InputMaybe<Array<Order_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Package_Order_By>>;
	where?: InputMaybe<Order_Package_Bool_Exp>;
};

export type Subscription_RootOrder_Package_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Package_Order_By>>;
	where?: InputMaybe<Order_Package_Bool_Exp>;
};

export type Subscription_RootOrder_Package_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootOrder_Package_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Order_Package_Stream_Cursor_Input>>;
	where?: InputMaybe<Order_Package_Bool_Exp>;
};

export type Subscription_RootOrder_Shipping_DetailsArgs = {
	distinct_on?: InputMaybe<Array<Order_Shipping_Details_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Shipping_Details_Order_By>>;
	where?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
};

export type Subscription_RootOrder_Shipping_Details_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Shipping_Details_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Shipping_Details_Order_By>>;
	where?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
};

export type Subscription_RootOrder_Shipping_Details_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootOrder_Shipping_Details_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Order_Shipping_Details_Stream_Cursor_Input>>;
	where?: InputMaybe<Order_Shipping_Details_Bool_Exp>;
};

export type Subscription_RootOrder_Status_HistoryArgs = {
	distinct_on?: InputMaybe<Array<Order_Status_History_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Status_History_Order_By>>;
	where?: InputMaybe<Order_Status_History_Bool_Exp>;
};

export type Subscription_RootOrder_Status_History_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Status_History_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Status_History_Order_By>>;
	where?: InputMaybe<Order_Status_History_Bool_Exp>;
};

export type Subscription_RootOrder_Status_History_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootOrder_Status_History_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Order_Status_History_Stream_Cursor_Input>>;
	where?: InputMaybe<Order_Status_History_Bool_Exp>;
};

export type Subscription_RootOrder_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Order_Stream_Cursor_Input>>;
	where?: InputMaybe<Order_Bool_Exp>;
};

export type Subscription_RootProductArgs = {
	distinct_on?: InputMaybe<Array<Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Order_By>>;
	where?: InputMaybe<Product_Bool_Exp>;
};

export type Subscription_RootProduct_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Order_By>>;
	where?: InputMaybe<Product_Bool_Exp>;
};

export type Subscription_RootProduct_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootProduct_Has_Available_OptionArgs = {
	distinct_on?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Has_Available_Option_Order_By>>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

export type Subscription_RootProduct_Has_Available_Option_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Product_Has_Available_Option_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Product_Has_Available_Option_Order_By>>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

export type Subscription_RootProduct_Has_Available_Option_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootProduct_Has_Available_Option_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Product_Has_Available_Option_Stream_Cursor_Input>>;
	where?: InputMaybe<Product_Has_Available_Option_Bool_Exp>;
};

export type Subscription_RootProduct_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Product_Stream_Cursor_Input>>;
	where?: InputMaybe<Product_Bool_Exp>;
};

export type Subscription_RootRolesArgs = {
	distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Roles_Order_By>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Roles_Order_By>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootRoles_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
	where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootShowcaseArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

export type Subscription_RootShowcase_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

export type Subscription_RootShowcase_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootShowcase_CategoryArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Category_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Category_Order_By>>;
	where?: InputMaybe<Showcase_Category_Bool_Exp>;
};

export type Subscription_RootShowcase_Category_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Category_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Category_Order_By>>;
	where?: InputMaybe<Showcase_Category_Bool_Exp>;
};

export type Subscription_RootShowcase_Category_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootShowcase_Category_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Showcase_Category_Stream_Cursor_Input>>;
	where?: InputMaybe<Showcase_Category_Bool_Exp>;
};

export type Subscription_RootShowcase_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Showcase_Stream_Cursor_Input>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

export type Subscription_RootStore_PackageArgs = {
	distinct_on?: InputMaybe<Array<Store_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Package_Order_By>>;
	where?: InputMaybe<Store_Package_Bool_Exp>;
};

export type Subscription_RootStore_Package_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Package_Order_By>>;
	where?: InputMaybe<Store_Package_Bool_Exp>;
};

export type Subscription_RootStore_Package_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootStore_Package_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Store_Package_Stream_Cursor_Input>>;
	where?: InputMaybe<Store_Package_Bool_Exp>;
};

export type Subscription_RootStore_ProductArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Order_By>>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

export type Subscription_RootStore_Product_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Order_By>>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

export type Subscription_RootStore_Product_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootStore_Product_Has_OptionsArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

export type Subscription_RootStore_Product_Has_Options_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Has_Options_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Has_Options_Order_By>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

export type Subscription_RootStore_Product_Has_Options_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootStore_Product_Has_Options_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Store_Product_Has_Options_Stream_Cursor_Input>>;
	where?: InputMaybe<Store_Product_Has_Options_Bool_Exp>;
};

export type Subscription_RootStore_Product_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Store_Product_Stream_Cursor_Input>>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

export type Subscription_RootSupportArgs = {
	distinct_on?: InputMaybe<Array<Support_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Support_Order_By>>;
	where?: InputMaybe<Support_Bool_Exp>;
};

export type Subscription_RootSupport_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Support_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Support_Order_By>>;
	where?: InputMaybe<Support_Bool_Exp>;
};

export type Subscription_RootSupport_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootSupport_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Support_Stream_Cursor_Input>>;
	where?: InputMaybe<Support_Bool_Exp>;
};

export type Subscription_RootTeamArgs = {
	distinct_on?: InputMaybe<Array<Team_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Order_By>>;
	where?: InputMaybe<Team_Bool_Exp>;
};

export type Subscription_RootTeam_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Order_By>>;
	where?: InputMaybe<Team_Bool_Exp>;
};

export type Subscription_RootTeam_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootTeam_StoreArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_By>>;
	where?: InputMaybe<Team_Store_Bool_Exp>;
};

export type Subscription_RootTeam_Store_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_By>>;
	where?: InputMaybe<Team_Store_Bool_Exp>;
};

export type Subscription_RootTeam_Store_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootTeam_Store_Email_LogArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
};

export type Subscription_RootTeam_Store_Email_Log_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
};

export type Subscription_RootTeam_Store_Email_Log_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootTeam_Store_Email_Log_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Team_Store_Email_Log_Stream_Cursor_Input>>;
	where?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
};

export type Subscription_RootTeam_Store_Order_Email_LogArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

export type Subscription_RootTeam_Store_Order_Email_Log_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

export type Subscription_RootTeam_Store_Order_Email_Log_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootTeam_Store_Order_Email_Log_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Team_Store_Order_Email_Log_Stream_Cursor_Input>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

export type Subscription_RootTeam_Store_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Team_Store_Stream_Cursor_Input>>;
	where?: InputMaybe<Team_Store_Bool_Exp>;
};

export type Subscription_RootTeam_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Team_Stream_Cursor_Input>>;
	where?: InputMaybe<Team_Bool_Exp>;
};

export type Subscription_RootUserArgs = {
	distinct_on?: InputMaybe<Array<User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Order_By>>;
	where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Order_By>>;
	where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootUser_RolesArgs = {
	distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Roles_Order_By>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Roles_Order_By>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootUser_Roles_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<User_Roles_Stream_Cursor_Input>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
	where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_TokenArgs = {
	distinct_on?: InputMaybe<Array<User_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Token_Order_By>>;
	where?: InputMaybe<User_Token_Bool_Exp>;
};

export type Subscription_RootUser_Token_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Token_Order_By>>;
	where?: InputMaybe<User_Token_Bool_Exp>;
};

export type Subscription_RootUser_Token_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootUser_Token_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<User_Token_Stream_Cursor_Input>>;
	where?: InputMaybe<User_Token_Bool_Exp>;
};

/** columns and relationships of "support" */
export type Support = {
	__typename?: 'support';
	captcha_token?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	details?: Maybe<Scalars['String']>;
	edits?: Maybe<Scalars['String']>;
	email: Scalars['String'];
	first_name: Scalars['String'];
	id: Scalars['Int'];
	last_name: Scalars['String'];
	order_id?: Maybe<Scalars['String']>;
	order_item_id?: Maybe<Scalars['uuid']>;
	phone?: Maybe<Scalars['String']>;
	reason: Scalars['String'];
	resolved?: Maybe<Scalars['Boolean']>;
	sent?: Maybe<Scalars['Boolean']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "support" */
export type Support_Aggregate = {
	__typename?: 'support_aggregate';
	aggregate?: Maybe<Support_Aggregate_Fields>;
	nodes: Array<Support>;
};

/** aggregate fields of "support" */
export type Support_Aggregate_Fields = {
	__typename?: 'support_aggregate_fields';
	avg?: Maybe<Support_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Support_Max_Fields>;
	min?: Maybe<Support_Min_Fields>;
	stddev?: Maybe<Support_Stddev_Fields>;
	stddev_pop?: Maybe<Support_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Support_Stddev_Samp_Fields>;
	sum?: Maybe<Support_Sum_Fields>;
	var_pop?: Maybe<Support_Var_Pop_Fields>;
	var_samp?: Maybe<Support_Var_Samp_Fields>;
	variance?: Maybe<Support_Variance_Fields>;
};

/** aggregate fields of "support" */
export type Support_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Support_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Support_Avg_Fields = {
	__typename?: 'support_avg_fields';
	id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "support". All fields are combined with a logical 'AND'. */
export type Support_Bool_Exp = {
	_and?: InputMaybe<Array<Support_Bool_Exp>>;
	_not?: InputMaybe<Support_Bool_Exp>;
	_or?: InputMaybe<Array<Support_Bool_Exp>>;
	captcha_token?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	details?: InputMaybe<String_Comparison_Exp>;
	edits?: InputMaybe<String_Comparison_Exp>;
	email?: InputMaybe<String_Comparison_Exp>;
	first_name?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Int_Comparison_Exp>;
	last_name?: InputMaybe<String_Comparison_Exp>;
	order_id?: InputMaybe<String_Comparison_Exp>;
	order_item_id?: InputMaybe<Uuid_Comparison_Exp>;
	phone?: InputMaybe<String_Comparison_Exp>;
	reason?: InputMaybe<String_Comparison_Exp>;
	resolved?: InputMaybe<Boolean_Comparison_Exp>;
	sent?: InputMaybe<Boolean_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "support" */
export enum Support_Constraint {
	/** unique or primary key constraint on columns "id" */
	SupportPkey = 'support_pkey'
}

/** input type for incrementing numeric columns in table "support" */
export type Support_Inc_Input = {
	id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "support" */
export type Support_Insert_Input = {
	captcha_token?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	details?: InputMaybe<Scalars['String']>;
	edits?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Int']>;
	last_name?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['String']>;
	order_item_id?: InputMaybe<Scalars['uuid']>;
	phone?: InputMaybe<Scalars['String']>;
	reason?: InputMaybe<Scalars['String']>;
	resolved?: InputMaybe<Scalars['Boolean']>;
	sent?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Support_Max_Fields = {
	__typename?: 'support_max_fields';
	captcha_token?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	details?: Maybe<Scalars['String']>;
	edits?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	first_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	last_name?: Maybe<Scalars['String']>;
	order_id?: Maybe<Scalars['String']>;
	order_item_id?: Maybe<Scalars['uuid']>;
	phone?: Maybe<Scalars['String']>;
	reason?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Support_Min_Fields = {
	__typename?: 'support_min_fields';
	captcha_token?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	details?: Maybe<Scalars['String']>;
	edits?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	first_name?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	last_name?: Maybe<Scalars['String']>;
	order_id?: Maybe<Scalars['String']>;
	order_item_id?: Maybe<Scalars['uuid']>;
	phone?: Maybe<Scalars['String']>;
	reason?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "support" */
export type Support_Mutation_Response = {
	__typename?: 'support_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Support>;
};

/** on_conflict condition type for table "support" */
export type Support_On_Conflict = {
	constraint: Support_Constraint;
	update_columns?: Array<Support_Update_Column>;
	where?: InputMaybe<Support_Bool_Exp>;
};

/** Ordering options when selecting data from "support". */
export type Support_Order_By = {
	captcha_token?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	details?: InputMaybe<Order_By>;
	edits?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	first_name?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	last_name?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	order_item_id?: InputMaybe<Order_By>;
	phone?: InputMaybe<Order_By>;
	reason?: InputMaybe<Order_By>;
	resolved?: InputMaybe<Order_By>;
	sent?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: support */
export type Support_Pk_Columns_Input = {
	id: Scalars['Int'];
};

/** select columns of table "support" */
export enum Support_Select_Column {
	/** column name */
	CaptchaToken = 'captcha_token',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Details = 'details',
	/** column name */
	Edits = 'edits',
	/** column name */
	Email = 'email',
	/** column name */
	FirstName = 'first_name',
	/** column name */
	Id = 'id',
	/** column name */
	LastName = 'last_name',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	OrderItemId = 'order_item_id',
	/** column name */
	Phone = 'phone',
	/** column name */
	Reason = 'reason',
	/** column name */
	Resolved = 'resolved',
	/** column name */
	Sent = 'sent',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id'
}

/** input type for updating data in table "support" */
export type Support_Set_Input = {
	captcha_token?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	details?: InputMaybe<Scalars['String']>;
	edits?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Int']>;
	last_name?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['String']>;
	order_item_id?: InputMaybe<Scalars['uuid']>;
	phone?: InputMaybe<Scalars['String']>;
	reason?: InputMaybe<Scalars['String']>;
	resolved?: InputMaybe<Scalars['Boolean']>;
	sent?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Support_Stddev_Fields = {
	__typename?: 'support_stddev_fields';
	id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Support_Stddev_Pop_Fields = {
	__typename?: 'support_stddev_pop_fields';
	id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Support_Stddev_Samp_Fields = {
	__typename?: 'support_stddev_samp_fields';
	id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "support" */
export type Support_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Support_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Support_Stream_Cursor_Value_Input = {
	captcha_token?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	details?: InputMaybe<Scalars['String']>;
	edits?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Int']>;
	last_name?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['String']>;
	order_item_id?: InputMaybe<Scalars['uuid']>;
	phone?: InputMaybe<Scalars['String']>;
	reason?: InputMaybe<Scalars['String']>;
	resolved?: InputMaybe<Scalars['Boolean']>;
	sent?: InputMaybe<Scalars['Boolean']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Support_Sum_Fields = {
	__typename?: 'support_sum_fields';
	id?: Maybe<Scalars['Int']>;
};

/** update columns of table "support" */
export enum Support_Update_Column {
	/** column name */
	CaptchaToken = 'captcha_token',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Details = 'details',
	/** column name */
	Edits = 'edits',
	/** column name */
	Email = 'email',
	/** column name */
	FirstName = 'first_name',
	/** column name */
	Id = 'id',
	/** column name */
	LastName = 'last_name',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	OrderItemId = 'order_item_id',
	/** column name */
	Phone = 'phone',
	/** column name */
	Reason = 'reason',
	/** column name */
	Resolved = 'resolved',
	/** column name */
	Sent = 'sent',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id'
}

export type Support_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Support_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Support_Set_Input>;
	/** filter the rows which have to be updated */
	where: Support_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Support_Var_Pop_Fields = {
	__typename?: 'support_var_pop_fields';
	id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Support_Var_Samp_Fields = {
	__typename?: 'support_var_samp_fields';
	id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Support_Variance_Fields = {
	__typename?: 'support_variance_fields';
	id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "team" */
export type Team = {
	__typename?: 'team';
	activity?: Maybe<Scalars['String']>;
	category?: Maybe<Scalars['String']>;
	city: Scalars['String'];
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	is_category_type: Scalars['Boolean'];
	name: Scalars['String'];
	state: Scalars['String'];
	/** An array relationship */
	team_stores: Array<Team_Store>;
	/** An aggregate relationship */
	team_stores_aggregate: Team_Store_Aggregate;
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "team" */
export type TeamTeam_StoresArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_By>>;
	where?: InputMaybe<Team_Store_Bool_Exp>;
};

/** columns and relationships of "team" */
export type TeamTeam_Stores_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_By>>;
	where?: InputMaybe<Team_Store_Bool_Exp>;
};

/** aggregated selection of "team" */
export type Team_Aggregate = {
	__typename?: 'team_aggregate';
	aggregate?: Maybe<Team_Aggregate_Fields>;
	nodes: Array<Team>;
};

/** aggregate fields of "team" */
export type Team_Aggregate_Fields = {
	__typename?: 'team_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<Team_Max_Fields>;
	min?: Maybe<Team_Min_Fields>;
};

/** aggregate fields of "team" */
export type Team_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Team_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team". All fields are combined with a logical 'AND'. */
export type Team_Bool_Exp = {
	_and?: InputMaybe<Array<Team_Bool_Exp>>;
	_not?: InputMaybe<Team_Bool_Exp>;
	_or?: InputMaybe<Array<Team_Bool_Exp>>;
	activity?: InputMaybe<String_Comparison_Exp>;
	category?: InputMaybe<String_Comparison_Exp>;
	city?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_category_type?: InputMaybe<Boolean_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	state?: InputMaybe<String_Comparison_Exp>;
	team_stores?: InputMaybe<Team_Store_Bool_Exp>;
	team_stores_aggregate?: InputMaybe<Team_Store_Aggregate_Bool_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "team" */
export enum Team_Constraint {
	/** unique or primary key constraint on columns "id" */
	TeamPkey = 'team_pkey'
}

/** input type for inserting data into table "team" */
export type Team_Insert_Input = {
	activity?: InputMaybe<Scalars['String']>;
	category?: InputMaybe<Scalars['String']>;
	city?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_category_type?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	state?: InputMaybe<Scalars['String']>;
	team_stores?: InputMaybe<Team_Store_Arr_Rel_Insert_Input>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Team_Max_Fields = {
	__typename?: 'team_max_fields';
	activity?: Maybe<Scalars['String']>;
	category?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Team_Min_Fields = {
	__typename?: 'team_min_fields';
	activity?: Maybe<Scalars['String']>;
	category?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	name?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "team" */
export type Team_Mutation_Response = {
	__typename?: 'team_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Team>;
};

/** input type for inserting object relation for remote table "team" */
export type Team_Obj_Rel_Insert_Input = {
	data: Team_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Team_On_Conflict>;
};

/** on_conflict condition type for table "team" */
export type Team_On_Conflict = {
	constraint: Team_Constraint;
	update_columns?: Array<Team_Update_Column>;
	where?: InputMaybe<Team_Bool_Exp>;
};

/** Ordering options when selecting data from "team". */
export type Team_Order_By = {
	activity?: InputMaybe<Order_By>;
	category?: InputMaybe<Order_By>;
	city?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_category_type?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	state?: InputMaybe<Order_By>;
	team_stores_aggregate?: InputMaybe<Team_Store_Aggregate_Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team */
export type Team_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "team" */
export enum Team_Select_Column {
	/** column name */
	Activity = 'activity',
	/** column name */
	Category = 'category',
	/** column name */
	City = 'city',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	IsCategoryType = 'is_category_type',
	/** column name */
	Name = 'name',
	/** column name */
	State = 'state',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "team" */
export type Team_Set_Input = {
	activity?: InputMaybe<Scalars['String']>;
	category?: InputMaybe<Scalars['String']>;
	city?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_category_type?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	state?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "team_store" */
export type Team_Store = {
	__typename?: 'team_store';
	attribution_list?: Maybe<Scalars['jsonb']>;
	banner_url?: Maybe<Scalars['String']>;
	close_date?: Maybe<Scalars['date']>;
	collect_addresses: Scalars['Boolean'];
	create_shipstation_label_on_order?: Maybe<Scalars['Boolean']>;
	created_at: Scalars['timestamptz'];
	enable_check_payment: Scalars['Boolean'];
	enable_credit_card_payment: Scalars['Boolean'];
	enable_free_shipping: Scalars['Boolean'];
	enable_ship_to_store: Scalars['Boolean'];
	enable_shipping_options: Scalars['Boolean'];
	enable_tax_collection: Scalars['Boolean'];
	friendly_url_key?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	image_url?: Maybe<Scalars['String']>;
	is_archived: Scalars['Boolean'];
	name: Scalars['String'];
	notes?: Maybe<Scalars['String']>;
	open_date?: Maybe<Scalars['date']>;
	/** An array relationship */
	orders: Array<Order>;
	/** An aggregate relationship */
	orders_aggregate: Order_Aggregate;
	rep?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	/** An array relationship */
	storeIsShowcasingStoresByStoreShowcasingId: Array<Showcase>;
	/** An aggregate relationship */
	storeIsShowcasingStoresByStoreShowcasingId_aggregate: Showcase_Aggregate;
	store_contact?: Maybe<Scalars['String']>;
	store_contact_address?: Maybe<Scalars['String']>;
	store_contact_address2?: Maybe<Scalars['String']>;
	store_contact_city?: Maybe<Scalars['String']>;
	store_contact_email?: Maybe<Scalars['String']>;
	store_contact_postal?: Maybe<Scalars['String']>;
	store_contact_state?: Maybe<Scalars['String']>;
	store_discount_rate?: Maybe<Scalars['Int']>;
	store_discount_type?: Maybe<Scalars['String']>;
	/** An array relationship */
	store_is_showcasing_stores: Array<Showcase>;
	/** An aggregate relationship */
	store_is_showcasing_stores_aggregate: Showcase_Aggregate;
	/** An array relationship */
	store_packages: Array<Store_Package>;
	/** An aggregate relationship */
	store_packages_aggregate: Store_Package_Aggregate;
	/** An array relationship */
	store_products: Array<Store_Product>;
	/** An aggregate relationship */
	store_products_aggregate: Store_Product_Aggregate;
	store_tracking_number?: Maybe<Scalars['String']>;
	store_url?: Maybe<Scalars['String']>;
	/** An object relationship */
	team: Team;
	team_id: Scalars['uuid'];
	/** An array relationship */
	team_store_email_logs: Array<Team_Store_Email_Log>;
	/** An aggregate relationship */
	team_store_email_logs_aggregate: Team_Store_Email_Log_Aggregate;
	/** An array relationship */
	team_store_order_email_logs: Array<Team_Store_Order_Email_Log>;
	/** An aggregate relationship */
	team_store_order_email_logs_aggregate: Team_Store_Order_Email_Log_Aggregate;
	updated_at: Scalars['timestamptz'];
	use_as_catalog: Scalars['Boolean'];
	welcome_message?: Maybe<Scalars['String']>;
};

/** columns and relationships of "team_store" */
export type Team_StoreAttribution_ListArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "team_store" */
export type Team_StoreOrdersArgs = {
	distinct_on?: InputMaybe<Array<Order_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Order_By>>;
	where?: InputMaybe<Order_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreOrders_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Order_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Order_By>>;
	where?: InputMaybe<Order_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreStoreIsShowcasingStoresByStoreShowcasingIdArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreStoreIsShowcasingStoresByStoreShowcasingId_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreStore_Is_Showcasing_StoresArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreStore_Is_Showcasing_Stores_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Showcase_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Showcase_Order_By>>;
	where?: InputMaybe<Showcase_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreStore_PackagesArgs = {
	distinct_on?: InputMaybe<Array<Store_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Package_Order_By>>;
	where?: InputMaybe<Store_Package_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreStore_Packages_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Package_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Package_Order_By>>;
	where?: InputMaybe<Store_Package_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreStore_ProductsArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Order_By>>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreStore_Products_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Store_Product_Order_By>>;
	where?: InputMaybe<Store_Product_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreTeam_Store_Email_LogsArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreTeam_Store_Email_Logs_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreTeam_Store_Order_Email_LogsArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

/** columns and relationships of "team_store" */
export type Team_StoreTeam_Store_Order_Email_Logs_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

/** aggregated selection of "team_store" */
export type Team_Store_Aggregate = {
	__typename?: 'team_store_aggregate';
	aggregate?: Maybe<Team_Store_Aggregate_Fields>;
	nodes: Array<Team_Store>;
};

export type Team_Store_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Team_Store_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Team_Store_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Team_Store_Aggregate_Bool_Exp_Count>;
};

export type Team_Store_Aggregate_Bool_Exp_Bool_And = {
	arguments: Team_Store_Select_Column_Team_Store_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Team_Store_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Team_Store_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Team_Store_Select_Column_Team_Store_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Team_Store_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Team_Store_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Team_Store_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Team_Store_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "team_store" */
export type Team_Store_Aggregate_Fields = {
	__typename?: 'team_store_aggregate_fields';
	avg?: Maybe<Team_Store_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Team_Store_Max_Fields>;
	min?: Maybe<Team_Store_Min_Fields>;
	stddev?: Maybe<Team_Store_Stddev_Fields>;
	stddev_pop?: Maybe<Team_Store_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Team_Store_Stddev_Samp_Fields>;
	sum?: Maybe<Team_Store_Sum_Fields>;
	var_pop?: Maybe<Team_Store_Var_Pop_Fields>;
	var_samp?: Maybe<Team_Store_Var_Samp_Fields>;
	variance?: Maybe<Team_Store_Variance_Fields>;
};

/** aggregate fields of "team_store" */
export type Team_Store_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Team_Store_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "team_store" */
export type Team_Store_Aggregate_Order_By = {
	avg?: InputMaybe<Team_Store_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Team_Store_Max_Order_By>;
	min?: InputMaybe<Team_Store_Min_Order_By>;
	stddev?: InputMaybe<Team_Store_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Team_Store_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Team_Store_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Team_Store_Sum_Order_By>;
	var_pop?: InputMaybe<Team_Store_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Team_Store_Var_Samp_Order_By>;
	variance?: InputMaybe<Team_Store_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Team_Store_Append_Input = {
	attribution_list?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "team_store" */
export type Team_Store_Arr_Rel_Insert_Input = {
	data: Array<Team_Store_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Team_Store_On_Conflict>;
};

/** aggregate avg on columns */
export type Team_Store_Avg_Fields = {
	__typename?: 'team_store_avg_fields';
	store_discount_rate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "team_store" */
export type Team_Store_Avg_Order_By = {
	store_discount_rate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "team_store". All fields are combined with a logical 'AND'. */
export type Team_Store_Bool_Exp = {
	_and?: InputMaybe<Array<Team_Store_Bool_Exp>>;
	_not?: InputMaybe<Team_Store_Bool_Exp>;
	_or?: InputMaybe<Array<Team_Store_Bool_Exp>>;
	attribution_list?: InputMaybe<Jsonb_Comparison_Exp>;
	banner_url?: InputMaybe<String_Comparison_Exp>;
	close_date?: InputMaybe<Date_Comparison_Exp>;
	collect_addresses?: InputMaybe<Boolean_Comparison_Exp>;
	create_shipstation_label_on_order?: InputMaybe<Boolean_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	enable_check_payment?: InputMaybe<Boolean_Comparison_Exp>;
	enable_credit_card_payment?: InputMaybe<Boolean_Comparison_Exp>;
	enable_free_shipping?: InputMaybe<Boolean_Comparison_Exp>;
	enable_ship_to_store?: InputMaybe<Boolean_Comparison_Exp>;
	enable_shipping_options?: InputMaybe<Boolean_Comparison_Exp>;
	enable_tax_collection?: InputMaybe<Boolean_Comparison_Exp>;
	friendly_url_key?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	image_url?: InputMaybe<String_Comparison_Exp>;
	is_archived?: InputMaybe<Boolean_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	notes?: InputMaybe<String_Comparison_Exp>;
	open_date?: InputMaybe<Date_Comparison_Exp>;
	orders?: InputMaybe<Order_Bool_Exp>;
	orders_aggregate?: InputMaybe<Order_Aggregate_Bool_Exp>;
	rep?: InputMaybe<String_Comparison_Exp>;
	status?: InputMaybe<String_Comparison_Exp>;
	storeIsShowcasingStoresByStoreShowcasingId?: InputMaybe<Showcase_Bool_Exp>;
	storeIsShowcasingStoresByStoreShowcasingId_aggregate?: InputMaybe<Showcase_Aggregate_Bool_Exp>;
	store_contact?: InputMaybe<String_Comparison_Exp>;
	store_contact_address?: InputMaybe<String_Comparison_Exp>;
	store_contact_address2?: InputMaybe<String_Comparison_Exp>;
	store_contact_city?: InputMaybe<String_Comparison_Exp>;
	store_contact_email?: InputMaybe<String_Comparison_Exp>;
	store_contact_postal?: InputMaybe<String_Comparison_Exp>;
	store_contact_state?: InputMaybe<String_Comparison_Exp>;
	store_discount_rate?: InputMaybe<Int_Comparison_Exp>;
	store_discount_type?: InputMaybe<String_Comparison_Exp>;
	store_is_showcasing_stores?: InputMaybe<Showcase_Bool_Exp>;
	store_is_showcasing_stores_aggregate?: InputMaybe<Showcase_Aggregate_Bool_Exp>;
	store_packages?: InputMaybe<Store_Package_Bool_Exp>;
	store_packages_aggregate?: InputMaybe<Store_Package_Aggregate_Bool_Exp>;
	store_products?: InputMaybe<Store_Product_Bool_Exp>;
	store_products_aggregate?: InputMaybe<Store_Product_Aggregate_Bool_Exp>;
	store_tracking_number?: InputMaybe<String_Comparison_Exp>;
	store_url?: InputMaybe<String_Comparison_Exp>;
	team?: InputMaybe<Team_Bool_Exp>;
	team_id?: InputMaybe<Uuid_Comparison_Exp>;
	team_store_email_logs?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
	team_store_email_logs_aggregate?: InputMaybe<Team_Store_Email_Log_Aggregate_Bool_Exp>;
	team_store_order_email_logs?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
	team_store_order_email_logs_aggregate?: InputMaybe<Team_Store_Order_Email_Log_Aggregate_Bool_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	use_as_catalog?: InputMaybe<Boolean_Comparison_Exp>;
	welcome_message?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_store" */
export enum Team_Store_Constraint {
	/** unique or primary key constraint on columns "friendly_url_key" */
	TeamStoreFriendlyUrlKeyKey = 'team_store_friendly_url_key_key',
	/** unique or primary key constraint on columns "id" */
	TeamStorePkey = 'team_store_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Team_Store_Delete_At_Path_Input = {
	attribution_list?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Team_Store_Delete_Elem_Input = {
	attribution_list?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Team_Store_Delete_Key_Input = {
	attribution_list?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "team_store_email_log" */
export type Team_Store_Email_Log = {
	__typename?: 'team_store_email_log';
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	returned_data: Scalars['jsonb'];
	scheduled_at?: Maybe<Scalars['timestamptz']>;
	sent_at?: Maybe<Scalars['timestamptz']>;
	status: Scalars['String'];
	/** An object relationship */
	team_store: Team_Store;
	team_store_id: Scalars['uuid'];
	/** An array relationship */
	team_store_order_email_logs: Array<Team_Store_Order_Email_Log>;
	/** An aggregate relationship */
	team_store_order_email_logs_aggregate: Team_Store_Order_Email_Log_Aggregate;
	template_name: Scalars['String'];
	updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "team_store_email_log" */
export type Team_Store_Email_LogReturned_DataArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "team_store_email_log" */
export type Team_Store_Email_LogTeam_Store_Order_Email_LogsArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

/** columns and relationships of "team_store_email_log" */
export type Team_Store_Email_LogTeam_Store_Order_Email_Logs_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Team_Store_Order_Email_Log_Order_By>>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

/** aggregated selection of "team_store_email_log" */
export type Team_Store_Email_Log_Aggregate = {
	__typename?: 'team_store_email_log_aggregate';
	aggregate?: Maybe<Team_Store_Email_Log_Aggregate_Fields>;
	nodes: Array<Team_Store_Email_Log>;
};

export type Team_Store_Email_Log_Aggregate_Bool_Exp = {
	count?: InputMaybe<Team_Store_Email_Log_Aggregate_Bool_Exp_Count>;
};

export type Team_Store_Email_Log_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Team_Store_Email_Log_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "team_store_email_log" */
export type Team_Store_Email_Log_Aggregate_Fields = {
	__typename?: 'team_store_email_log_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<Team_Store_Email_Log_Max_Fields>;
	min?: Maybe<Team_Store_Email_Log_Min_Fields>;
};

/** aggregate fields of "team_store_email_log" */
export type Team_Store_Email_Log_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Team_Store_Email_Log_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "team_store_email_log" */
export type Team_Store_Email_Log_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Team_Store_Email_Log_Max_Order_By>;
	min?: InputMaybe<Team_Store_Email_Log_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Team_Store_Email_Log_Append_Input = {
	returned_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "team_store_email_log" */
export type Team_Store_Email_Log_Arr_Rel_Insert_Input = {
	data: Array<Team_Store_Email_Log_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Team_Store_Email_Log_On_Conflict>;
};

/** Boolean expression to filter rows from the table "team_store_email_log". All fields are combined with a logical 'AND'. */
export type Team_Store_Email_Log_Bool_Exp = {
	_and?: InputMaybe<Array<Team_Store_Email_Log_Bool_Exp>>;
	_not?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
	_or?: InputMaybe<Array<Team_Store_Email_Log_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	returned_data?: InputMaybe<Jsonb_Comparison_Exp>;
	scheduled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	status?: InputMaybe<String_Comparison_Exp>;
	team_store?: InputMaybe<Team_Store_Bool_Exp>;
	team_store_id?: InputMaybe<Uuid_Comparison_Exp>;
	team_store_order_email_logs?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
	team_store_order_email_logs_aggregate?: InputMaybe<Team_Store_Order_Email_Log_Aggregate_Bool_Exp>;
	template_name?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_store_email_log" */
export enum Team_Store_Email_Log_Constraint {
	/** unique or primary key constraint on columns "id" */
	TeamStoreEmailLogPkey = 'team_store_email_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Team_Store_Email_Log_Delete_At_Path_Input = {
	returned_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Team_Store_Email_Log_Delete_Elem_Input = {
	returned_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Team_Store_Email_Log_Delete_Key_Input = {
	returned_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "team_store_email_log" */
export type Team_Store_Email_Log_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	returned_data?: InputMaybe<Scalars['jsonb']>;
	scheduled_at?: InputMaybe<Scalars['timestamptz']>;
	sent_at?: InputMaybe<Scalars['timestamptz']>;
	status?: InputMaybe<Scalars['String']>;
	team_store?: InputMaybe<Team_Store_Obj_Rel_Insert_Input>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
	team_store_order_email_logs?: InputMaybe<Team_Store_Order_Email_Log_Arr_Rel_Insert_Input>;
	template_name?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Team_Store_Email_Log_Max_Fields = {
	__typename?: 'team_store_email_log_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	scheduled_at?: Maybe<Scalars['timestamptz']>;
	sent_at?: Maybe<Scalars['timestamptz']>;
	status?: Maybe<Scalars['String']>;
	team_store_id?: Maybe<Scalars['uuid']>;
	template_name?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "team_store_email_log" */
export type Team_Store_Email_Log_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	scheduled_at?: InputMaybe<Order_By>;
	sent_at?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	team_store_id?: InputMaybe<Order_By>;
	template_name?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Store_Email_Log_Min_Fields = {
	__typename?: 'team_store_email_log_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	scheduled_at?: Maybe<Scalars['timestamptz']>;
	sent_at?: Maybe<Scalars['timestamptz']>;
	status?: Maybe<Scalars['String']>;
	team_store_id?: Maybe<Scalars['uuid']>;
	template_name?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "team_store_email_log" */
export type Team_Store_Email_Log_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	scheduled_at?: InputMaybe<Order_By>;
	sent_at?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	team_store_id?: InputMaybe<Order_By>;
	template_name?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "team_store_email_log" */
export type Team_Store_Email_Log_Mutation_Response = {
	__typename?: 'team_store_email_log_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Team_Store_Email_Log>;
};

/** input type for inserting object relation for remote table "team_store_email_log" */
export type Team_Store_Email_Log_Obj_Rel_Insert_Input = {
	data: Team_Store_Email_Log_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Team_Store_Email_Log_On_Conflict>;
};

/** on_conflict condition type for table "team_store_email_log" */
export type Team_Store_Email_Log_On_Conflict = {
	constraint: Team_Store_Email_Log_Constraint;
	update_columns?: Array<Team_Store_Email_Log_Update_Column>;
	where?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "team_store_email_log". */
export type Team_Store_Email_Log_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	returned_data?: InputMaybe<Order_By>;
	scheduled_at?: InputMaybe<Order_By>;
	sent_at?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	team_store?: InputMaybe<Team_Store_Order_By>;
	team_store_id?: InputMaybe<Order_By>;
	team_store_order_email_logs_aggregate?: InputMaybe<Team_Store_Order_Email_Log_Aggregate_Order_By>;
	template_name?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_store_email_log */
export type Team_Store_Email_Log_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Team_Store_Email_Log_Prepend_Input = {
	returned_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "team_store_email_log" */
export enum Team_Store_Email_Log_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	ReturnedData = 'returned_data',
	/** column name */
	ScheduledAt = 'scheduled_at',
	/** column name */
	SentAt = 'sent_at',
	/** column name */
	Status = 'status',
	/** column name */
	TeamStoreId = 'team_store_id',
	/** column name */
	TemplateName = 'template_name',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "team_store_email_log" */
export type Team_Store_Email_Log_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	returned_data?: InputMaybe<Scalars['jsonb']>;
	scheduled_at?: InputMaybe<Scalars['timestamptz']>;
	sent_at?: InputMaybe<Scalars['timestamptz']>;
	status?: InputMaybe<Scalars['String']>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
	template_name?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "team_store_email_log" */
export type Team_Store_Email_Log_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Team_Store_Email_Log_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Store_Email_Log_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	returned_data?: InputMaybe<Scalars['jsonb']>;
	scheduled_at?: InputMaybe<Scalars['timestamptz']>;
	sent_at?: InputMaybe<Scalars['timestamptz']>;
	status?: InputMaybe<Scalars['String']>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
	template_name?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "team_store_email_log" */
export enum Team_Store_Email_Log_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	ReturnedData = 'returned_data',
	/** column name */
	ScheduledAt = 'scheduled_at',
	/** column name */
	SentAt = 'sent_at',
	/** column name */
	Status = 'status',
	/** column name */
	TeamStoreId = 'team_store_id',
	/** column name */
	TemplateName = 'template_name',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Team_Store_Email_Log_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Team_Store_Email_Log_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Team_Store_Email_Log_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Team_Store_Email_Log_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Team_Store_Email_Log_Delete_Key_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Team_Store_Email_Log_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Team_Store_Email_Log_Set_Input>;
	/** filter the rows which have to be updated */
	where: Team_Store_Email_Log_Bool_Exp;
};

/** input type for incrementing numeric columns in table "team_store" */
export type Team_Store_Inc_Input = {
	store_discount_rate?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "team_store" */
export type Team_Store_Insert_Input = {
	attribution_list?: InputMaybe<Scalars['jsonb']>;
	banner_url?: InputMaybe<Scalars['String']>;
	close_date?: InputMaybe<Scalars['date']>;
	collect_addresses?: InputMaybe<Scalars['Boolean']>;
	create_shipstation_label_on_order?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	enable_check_payment?: InputMaybe<Scalars['Boolean']>;
	enable_credit_card_payment?: InputMaybe<Scalars['Boolean']>;
	enable_free_shipping?: InputMaybe<Scalars['Boolean']>;
	enable_ship_to_store?: InputMaybe<Scalars['Boolean']>;
	enable_shipping_options?: InputMaybe<Scalars['Boolean']>;
	enable_tax_collection?: InputMaybe<Scalars['Boolean']>;
	friendly_url_key?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	is_archived?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	notes?: InputMaybe<Scalars['String']>;
	open_date?: InputMaybe<Scalars['date']>;
	orders?: InputMaybe<Order_Arr_Rel_Insert_Input>;
	rep?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	storeIsShowcasingStoresByStoreShowcasingId?: InputMaybe<Showcase_Arr_Rel_Insert_Input>;
	store_contact?: InputMaybe<Scalars['String']>;
	store_contact_address?: InputMaybe<Scalars['String']>;
	store_contact_address2?: InputMaybe<Scalars['String']>;
	store_contact_city?: InputMaybe<Scalars['String']>;
	store_contact_email?: InputMaybe<Scalars['String']>;
	store_contact_postal?: InputMaybe<Scalars['String']>;
	store_contact_state?: InputMaybe<Scalars['String']>;
	store_discount_rate?: InputMaybe<Scalars['Int']>;
	store_discount_type?: InputMaybe<Scalars['String']>;
	store_is_showcasing_stores?: InputMaybe<Showcase_Arr_Rel_Insert_Input>;
	store_packages?: InputMaybe<Store_Package_Arr_Rel_Insert_Input>;
	store_products?: InputMaybe<Store_Product_Arr_Rel_Insert_Input>;
	store_tracking_number?: InputMaybe<Scalars['String']>;
	store_url?: InputMaybe<Scalars['String']>;
	team?: InputMaybe<Team_Obj_Rel_Insert_Input>;
	team_id?: InputMaybe<Scalars['uuid']>;
	team_store_email_logs?: InputMaybe<Team_Store_Email_Log_Arr_Rel_Insert_Input>;
	team_store_order_email_logs?: InputMaybe<Team_Store_Order_Email_Log_Arr_Rel_Insert_Input>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	use_as_catalog?: InputMaybe<Scalars['Boolean']>;
	welcome_message?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Team_Store_Max_Fields = {
	__typename?: 'team_store_max_fields';
	banner_url?: Maybe<Scalars['String']>;
	close_date?: Maybe<Scalars['date']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	friendly_url_key?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	notes?: Maybe<Scalars['String']>;
	open_date?: Maybe<Scalars['date']>;
	rep?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	store_contact?: Maybe<Scalars['String']>;
	store_contact_address?: Maybe<Scalars['String']>;
	store_contact_address2?: Maybe<Scalars['String']>;
	store_contact_city?: Maybe<Scalars['String']>;
	store_contact_email?: Maybe<Scalars['String']>;
	store_contact_postal?: Maybe<Scalars['String']>;
	store_contact_state?: Maybe<Scalars['String']>;
	store_discount_rate?: Maybe<Scalars['Int']>;
	store_discount_type?: Maybe<Scalars['String']>;
	store_tracking_number?: Maybe<Scalars['String']>;
	store_url?: Maybe<Scalars['String']>;
	team_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	welcome_message?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "team_store" */
export type Team_Store_Max_Order_By = {
	banner_url?: InputMaybe<Order_By>;
	close_date?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	friendly_url_key?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	notes?: InputMaybe<Order_By>;
	open_date?: InputMaybe<Order_By>;
	rep?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	store_contact?: InputMaybe<Order_By>;
	store_contact_address?: InputMaybe<Order_By>;
	store_contact_address2?: InputMaybe<Order_By>;
	store_contact_city?: InputMaybe<Order_By>;
	store_contact_email?: InputMaybe<Order_By>;
	store_contact_postal?: InputMaybe<Order_By>;
	store_contact_state?: InputMaybe<Order_By>;
	store_discount_rate?: InputMaybe<Order_By>;
	store_discount_type?: InputMaybe<Order_By>;
	store_tracking_number?: InputMaybe<Order_By>;
	store_url?: InputMaybe<Order_By>;
	team_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	welcome_message?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Store_Min_Fields = {
	__typename?: 'team_store_min_fields';
	banner_url?: Maybe<Scalars['String']>;
	close_date?: Maybe<Scalars['date']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	friendly_url_key?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	image_url?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	notes?: Maybe<Scalars['String']>;
	open_date?: Maybe<Scalars['date']>;
	rep?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	store_contact?: Maybe<Scalars['String']>;
	store_contact_address?: Maybe<Scalars['String']>;
	store_contact_address2?: Maybe<Scalars['String']>;
	store_contact_city?: Maybe<Scalars['String']>;
	store_contact_email?: Maybe<Scalars['String']>;
	store_contact_postal?: Maybe<Scalars['String']>;
	store_contact_state?: Maybe<Scalars['String']>;
	store_discount_rate?: Maybe<Scalars['Int']>;
	store_discount_type?: Maybe<Scalars['String']>;
	store_tracking_number?: Maybe<Scalars['String']>;
	store_url?: Maybe<Scalars['String']>;
	team_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	welcome_message?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "team_store" */
export type Team_Store_Min_Order_By = {
	banner_url?: InputMaybe<Order_By>;
	close_date?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	friendly_url_key?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	notes?: InputMaybe<Order_By>;
	open_date?: InputMaybe<Order_By>;
	rep?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	store_contact?: InputMaybe<Order_By>;
	store_contact_address?: InputMaybe<Order_By>;
	store_contact_address2?: InputMaybe<Order_By>;
	store_contact_city?: InputMaybe<Order_By>;
	store_contact_email?: InputMaybe<Order_By>;
	store_contact_postal?: InputMaybe<Order_By>;
	store_contact_state?: InputMaybe<Order_By>;
	store_discount_rate?: InputMaybe<Order_By>;
	store_discount_type?: InputMaybe<Order_By>;
	store_tracking_number?: InputMaybe<Order_By>;
	store_url?: InputMaybe<Order_By>;
	team_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	welcome_message?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "team_store" */
export type Team_Store_Mutation_Response = {
	__typename?: 'team_store_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Team_Store>;
};

/** input type for inserting object relation for remote table "team_store" */
export type Team_Store_Obj_Rel_Insert_Input = {
	data: Team_Store_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Team_Store_On_Conflict>;
};

/** on_conflict condition type for table "team_store" */
export type Team_Store_On_Conflict = {
	constraint: Team_Store_Constraint;
	update_columns?: Array<Team_Store_Update_Column>;
	where?: InputMaybe<Team_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "team_store". */
export type Team_Store_Order_By = {
	attribution_list?: InputMaybe<Order_By>;
	banner_url?: InputMaybe<Order_By>;
	close_date?: InputMaybe<Order_By>;
	collect_addresses?: InputMaybe<Order_By>;
	create_shipstation_label_on_order?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	enable_check_payment?: InputMaybe<Order_By>;
	enable_credit_card_payment?: InputMaybe<Order_By>;
	enable_free_shipping?: InputMaybe<Order_By>;
	enable_ship_to_store?: InputMaybe<Order_By>;
	enable_shipping_options?: InputMaybe<Order_By>;
	enable_tax_collection?: InputMaybe<Order_By>;
	friendly_url_key?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	image_url?: InputMaybe<Order_By>;
	is_archived?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	notes?: InputMaybe<Order_By>;
	open_date?: InputMaybe<Order_By>;
	orders_aggregate?: InputMaybe<Order_Aggregate_Order_By>;
	rep?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	storeIsShowcasingStoresByStoreShowcasingId_aggregate?: InputMaybe<Showcase_Aggregate_Order_By>;
	store_contact?: InputMaybe<Order_By>;
	store_contact_address?: InputMaybe<Order_By>;
	store_contact_address2?: InputMaybe<Order_By>;
	store_contact_city?: InputMaybe<Order_By>;
	store_contact_email?: InputMaybe<Order_By>;
	store_contact_postal?: InputMaybe<Order_By>;
	store_contact_state?: InputMaybe<Order_By>;
	store_discount_rate?: InputMaybe<Order_By>;
	store_discount_type?: InputMaybe<Order_By>;
	store_is_showcasing_stores_aggregate?: InputMaybe<Showcase_Aggregate_Order_By>;
	store_packages_aggregate?: InputMaybe<Store_Package_Aggregate_Order_By>;
	store_products_aggregate?: InputMaybe<Store_Product_Aggregate_Order_By>;
	store_tracking_number?: InputMaybe<Order_By>;
	store_url?: InputMaybe<Order_By>;
	team?: InputMaybe<Team_Order_By>;
	team_id?: InputMaybe<Order_By>;
	team_store_email_logs_aggregate?: InputMaybe<Team_Store_Email_Log_Aggregate_Order_By>;
	team_store_order_email_logs_aggregate?: InputMaybe<Team_Store_Order_Email_Log_Aggregate_Order_By>;
	updated_at?: InputMaybe<Order_By>;
	use_as_catalog?: InputMaybe<Order_By>;
	welcome_message?: InputMaybe<Order_By>;
};

/** columns and relationships of "team_store_order_email_log" */
export type Team_Store_Order_Email_Log = {
	__typename?: 'team_store_order_email_log';
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	/** An object relationship */
	order: Order;
	order_id: Scalars['uuid'];
	/** An object relationship */
	team_store: Team_Store;
	/** An object relationship */
	team_store_email_log: Team_Store_Email_Log;
	team_store_email_log_id: Scalars['uuid'];
	team_store_id: Scalars['uuid'];
};

/** aggregated selection of "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Aggregate = {
	__typename?: 'team_store_order_email_log_aggregate';
	aggregate?: Maybe<Team_Store_Order_Email_Log_Aggregate_Fields>;
	nodes: Array<Team_Store_Order_Email_Log>;
};

export type Team_Store_Order_Email_Log_Aggregate_Bool_Exp = {
	count?: InputMaybe<Team_Store_Order_Email_Log_Aggregate_Bool_Exp_Count>;
};

export type Team_Store_Order_Email_Log_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Aggregate_Fields = {
	__typename?: 'team_store_order_email_log_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<Team_Store_Order_Email_Log_Max_Fields>;
	min?: Maybe<Team_Store_Order_Email_Log_Min_Fields>;
};

/** aggregate fields of "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Team_Store_Order_Email_Log_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Team_Store_Order_Email_Log_Max_Order_By>;
	min?: InputMaybe<Team_Store_Order_Email_Log_Min_Order_By>;
};

/** input type for inserting array relation for remote table "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Arr_Rel_Insert_Input = {
	data: Array<Team_Store_Order_Email_Log_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Team_Store_Order_Email_Log_On_Conflict>;
};

/** Boolean expression to filter rows from the table "team_store_order_email_log". All fields are combined with a logical 'AND'. */
export type Team_Store_Order_Email_Log_Bool_Exp = {
	_and?: InputMaybe<Array<Team_Store_Order_Email_Log_Bool_Exp>>;
	_not?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
	_or?: InputMaybe<Array<Team_Store_Order_Email_Log_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	order?: InputMaybe<Order_Bool_Exp>;
	order_id?: InputMaybe<Uuid_Comparison_Exp>;
	team_store?: InputMaybe<Team_Store_Bool_Exp>;
	team_store_email_log?: InputMaybe<Team_Store_Email_Log_Bool_Exp>;
	team_store_email_log_id?: InputMaybe<Uuid_Comparison_Exp>;
	team_store_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_store_order_email_log" */
export enum Team_Store_Order_Email_Log_Constraint {
	/** unique or primary key constraint on columns "id" */
	TeamStoreOrderEmailLogPkey = 'team_store_order_email_log_pkey'
}

/** input type for inserting data into table "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
	order_id?: InputMaybe<Scalars['uuid']>;
	team_store?: InputMaybe<Team_Store_Obj_Rel_Insert_Input>;
	team_store_email_log?: InputMaybe<Team_Store_Email_Log_Obj_Rel_Insert_Input>;
	team_store_email_log_id?: InputMaybe<Scalars['uuid']>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Team_Store_Order_Email_Log_Max_Fields = {
	__typename?: 'team_store_order_email_log_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	order_id?: Maybe<Scalars['uuid']>;
	team_store_email_log_id?: Maybe<Scalars['uuid']>;
	team_store_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	team_store_email_log_id?: InputMaybe<Order_By>;
	team_store_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Store_Order_Email_Log_Min_Fields = {
	__typename?: 'team_store_order_email_log_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	order_id?: Maybe<Scalars['uuid']>;
	team_store_email_log_id?: Maybe<Scalars['uuid']>;
	team_store_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order_id?: InputMaybe<Order_By>;
	team_store_email_log_id?: InputMaybe<Order_By>;
	team_store_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Mutation_Response = {
	__typename?: 'team_store_order_email_log_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Team_Store_Order_Email_Log>;
};

/** on_conflict condition type for table "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_On_Conflict = {
	constraint: Team_Store_Order_Email_Log_Constraint;
	update_columns?: Array<Team_Store_Order_Email_Log_Update_Column>;
	where?: InputMaybe<Team_Store_Order_Email_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "team_store_order_email_log". */
export type Team_Store_Order_Email_Log_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	order?: InputMaybe<Order_Order_By>;
	order_id?: InputMaybe<Order_By>;
	team_store?: InputMaybe<Team_Store_Order_By>;
	team_store_email_log?: InputMaybe<Team_Store_Email_Log_Order_By>;
	team_store_email_log_id?: InputMaybe<Order_By>;
	team_store_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_store_order_email_log */
export type Team_Store_Order_Email_Log_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "team_store_order_email_log" */
export enum Team_Store_Order_Email_Log_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	TeamStoreEmailLogId = 'team_store_email_log_id',
	/** column name */
	TeamStoreId = 'team_store_id'
}

/** input type for updating data in table "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	order_id?: InputMaybe<Scalars['uuid']>;
	team_store_email_log_id?: InputMaybe<Scalars['uuid']>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "team_store_order_email_log" */
export type Team_Store_Order_Email_Log_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Team_Store_Order_Email_Log_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Store_Order_Email_Log_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	order_id?: InputMaybe<Scalars['uuid']>;
	team_store_email_log_id?: InputMaybe<Scalars['uuid']>;
	team_store_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "team_store_order_email_log" */
export enum Team_Store_Order_Email_Log_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	OrderId = 'order_id',
	/** column name */
	TeamStoreEmailLogId = 'team_store_email_log_id',
	/** column name */
	TeamStoreId = 'team_store_id'
}

export type Team_Store_Order_Email_Log_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Team_Store_Order_Email_Log_Set_Input>;
	/** filter the rows which have to be updated */
	where: Team_Store_Order_Email_Log_Bool_Exp;
};

/** primary key columns input for table: team_store */
export type Team_Store_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Team_Store_Prepend_Input = {
	attribution_list?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "team_store" */
export enum Team_Store_Select_Column {
	/** column name */
	AttributionList = 'attribution_list',
	/** column name */
	BannerUrl = 'banner_url',
	/** column name */
	CloseDate = 'close_date',
	/** column name */
	CollectAddresses = 'collect_addresses',
	/** column name */
	CreateShipstationLabelOnOrder = 'create_shipstation_label_on_order',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	EnableCheckPayment = 'enable_check_payment',
	/** column name */
	EnableCreditCardPayment = 'enable_credit_card_payment',
	/** column name */
	EnableFreeShipping = 'enable_free_shipping',
	/** column name */
	EnableShipToStore = 'enable_ship_to_store',
	/** column name */
	EnableShippingOptions = 'enable_shipping_options',
	/** column name */
	EnableTaxCollection = 'enable_tax_collection',
	/** column name */
	FriendlyUrlKey = 'friendly_url_key',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	IsArchived = 'is_archived',
	/** column name */
	Name = 'name',
	/** column name */
	Notes = 'notes',
	/** column name */
	OpenDate = 'open_date',
	/** column name */
	Rep = 'rep',
	/** column name */
	Status = 'status',
	/** column name */
	StoreContact = 'store_contact',
	/** column name */
	StoreContactAddress = 'store_contact_address',
	/** column name */
	StoreContactAddress2 = 'store_contact_address2',
	/** column name */
	StoreContactCity = 'store_contact_city',
	/** column name */
	StoreContactEmail = 'store_contact_email',
	/** column name */
	StoreContactPostal = 'store_contact_postal',
	/** column name */
	StoreContactState = 'store_contact_state',
	/** column name */
	StoreDiscountRate = 'store_discount_rate',
	/** column name */
	StoreDiscountType = 'store_discount_type',
	/** column name */
	StoreTrackingNumber = 'store_tracking_number',
	/** column name */
	StoreUrl = 'store_url',
	/** column name */
	TeamId = 'team_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UseAsCatalog = 'use_as_catalog',
	/** column name */
	WelcomeMessage = 'welcome_message'
}

/** select "team_store_aggregate_bool_exp_bool_and_arguments_columns" columns of table "team_store" */
export enum Team_Store_Select_Column_Team_Store_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	CollectAddresses = 'collect_addresses',
	/** column name */
	CreateShipstationLabelOnOrder = 'create_shipstation_label_on_order',
	/** column name */
	EnableCheckPayment = 'enable_check_payment',
	/** column name */
	EnableCreditCardPayment = 'enable_credit_card_payment',
	/** column name */
	EnableFreeShipping = 'enable_free_shipping',
	/** column name */
	EnableShipToStore = 'enable_ship_to_store',
	/** column name */
	EnableShippingOptions = 'enable_shipping_options',
	/** column name */
	EnableTaxCollection = 'enable_tax_collection',
	/** column name */
	IsArchived = 'is_archived',
	/** column name */
	UseAsCatalog = 'use_as_catalog'
}

/** select "team_store_aggregate_bool_exp_bool_or_arguments_columns" columns of table "team_store" */
export enum Team_Store_Select_Column_Team_Store_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	CollectAddresses = 'collect_addresses',
	/** column name */
	CreateShipstationLabelOnOrder = 'create_shipstation_label_on_order',
	/** column name */
	EnableCheckPayment = 'enable_check_payment',
	/** column name */
	EnableCreditCardPayment = 'enable_credit_card_payment',
	/** column name */
	EnableFreeShipping = 'enable_free_shipping',
	/** column name */
	EnableShipToStore = 'enable_ship_to_store',
	/** column name */
	EnableShippingOptions = 'enable_shipping_options',
	/** column name */
	EnableTaxCollection = 'enable_tax_collection',
	/** column name */
	IsArchived = 'is_archived',
	/** column name */
	UseAsCatalog = 'use_as_catalog'
}

/** input type for updating data in table "team_store" */
export type Team_Store_Set_Input = {
	attribution_list?: InputMaybe<Scalars['jsonb']>;
	banner_url?: InputMaybe<Scalars['String']>;
	close_date?: InputMaybe<Scalars['date']>;
	collect_addresses?: InputMaybe<Scalars['Boolean']>;
	create_shipstation_label_on_order?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	enable_check_payment?: InputMaybe<Scalars['Boolean']>;
	enable_credit_card_payment?: InputMaybe<Scalars['Boolean']>;
	enable_free_shipping?: InputMaybe<Scalars['Boolean']>;
	enable_ship_to_store?: InputMaybe<Scalars['Boolean']>;
	enable_shipping_options?: InputMaybe<Scalars['Boolean']>;
	enable_tax_collection?: InputMaybe<Scalars['Boolean']>;
	friendly_url_key?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	is_archived?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	notes?: InputMaybe<Scalars['String']>;
	open_date?: InputMaybe<Scalars['date']>;
	rep?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	store_contact?: InputMaybe<Scalars['String']>;
	store_contact_address?: InputMaybe<Scalars['String']>;
	store_contact_address2?: InputMaybe<Scalars['String']>;
	store_contact_city?: InputMaybe<Scalars['String']>;
	store_contact_email?: InputMaybe<Scalars['String']>;
	store_contact_postal?: InputMaybe<Scalars['String']>;
	store_contact_state?: InputMaybe<Scalars['String']>;
	store_discount_rate?: InputMaybe<Scalars['Int']>;
	store_discount_type?: InputMaybe<Scalars['String']>;
	store_tracking_number?: InputMaybe<Scalars['String']>;
	store_url?: InputMaybe<Scalars['String']>;
	team_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	use_as_catalog?: InputMaybe<Scalars['Boolean']>;
	welcome_message?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Team_Store_Stddev_Fields = {
	__typename?: 'team_store_stddev_fields';
	store_discount_rate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "team_store" */
export type Team_Store_Stddev_Order_By = {
	store_discount_rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Team_Store_Stddev_Pop_Fields = {
	__typename?: 'team_store_stddev_pop_fields';
	store_discount_rate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "team_store" */
export type Team_Store_Stddev_Pop_Order_By = {
	store_discount_rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Team_Store_Stddev_Samp_Fields = {
	__typename?: 'team_store_stddev_samp_fields';
	store_discount_rate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "team_store" */
export type Team_Store_Stddev_Samp_Order_By = {
	store_discount_rate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "team_store" */
export type Team_Store_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Team_Store_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Store_Stream_Cursor_Value_Input = {
	attribution_list?: InputMaybe<Scalars['jsonb']>;
	banner_url?: InputMaybe<Scalars['String']>;
	close_date?: InputMaybe<Scalars['date']>;
	collect_addresses?: InputMaybe<Scalars['Boolean']>;
	create_shipstation_label_on_order?: InputMaybe<Scalars['Boolean']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	enable_check_payment?: InputMaybe<Scalars['Boolean']>;
	enable_credit_card_payment?: InputMaybe<Scalars['Boolean']>;
	enable_free_shipping?: InputMaybe<Scalars['Boolean']>;
	enable_ship_to_store?: InputMaybe<Scalars['Boolean']>;
	enable_shipping_options?: InputMaybe<Scalars['Boolean']>;
	enable_tax_collection?: InputMaybe<Scalars['Boolean']>;
	friendly_url_key?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	image_url?: InputMaybe<Scalars['String']>;
	is_archived?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	notes?: InputMaybe<Scalars['String']>;
	open_date?: InputMaybe<Scalars['date']>;
	rep?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Scalars['String']>;
	store_contact?: InputMaybe<Scalars['String']>;
	store_contact_address?: InputMaybe<Scalars['String']>;
	store_contact_address2?: InputMaybe<Scalars['String']>;
	store_contact_city?: InputMaybe<Scalars['String']>;
	store_contact_email?: InputMaybe<Scalars['String']>;
	store_contact_postal?: InputMaybe<Scalars['String']>;
	store_contact_state?: InputMaybe<Scalars['String']>;
	store_discount_rate?: InputMaybe<Scalars['Int']>;
	store_discount_type?: InputMaybe<Scalars['String']>;
	store_tracking_number?: InputMaybe<Scalars['String']>;
	store_url?: InputMaybe<Scalars['String']>;
	team_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	use_as_catalog?: InputMaybe<Scalars['Boolean']>;
	welcome_message?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Team_Store_Sum_Fields = {
	__typename?: 'team_store_sum_fields';
	store_discount_rate?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "team_store" */
export type Team_Store_Sum_Order_By = {
	store_discount_rate?: InputMaybe<Order_By>;
};

/** update columns of table "team_store" */
export enum Team_Store_Update_Column {
	/** column name */
	AttributionList = 'attribution_list',
	/** column name */
	BannerUrl = 'banner_url',
	/** column name */
	CloseDate = 'close_date',
	/** column name */
	CollectAddresses = 'collect_addresses',
	/** column name */
	CreateShipstationLabelOnOrder = 'create_shipstation_label_on_order',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	EnableCheckPayment = 'enable_check_payment',
	/** column name */
	EnableCreditCardPayment = 'enable_credit_card_payment',
	/** column name */
	EnableFreeShipping = 'enable_free_shipping',
	/** column name */
	EnableShipToStore = 'enable_ship_to_store',
	/** column name */
	EnableShippingOptions = 'enable_shipping_options',
	/** column name */
	EnableTaxCollection = 'enable_tax_collection',
	/** column name */
	FriendlyUrlKey = 'friendly_url_key',
	/** column name */
	Id = 'id',
	/** column name */
	ImageUrl = 'image_url',
	/** column name */
	IsArchived = 'is_archived',
	/** column name */
	Name = 'name',
	/** column name */
	Notes = 'notes',
	/** column name */
	OpenDate = 'open_date',
	/** column name */
	Rep = 'rep',
	/** column name */
	Status = 'status',
	/** column name */
	StoreContact = 'store_contact',
	/** column name */
	StoreContactAddress = 'store_contact_address',
	/** column name */
	StoreContactAddress2 = 'store_contact_address2',
	/** column name */
	StoreContactCity = 'store_contact_city',
	/** column name */
	StoreContactEmail = 'store_contact_email',
	/** column name */
	StoreContactPostal = 'store_contact_postal',
	/** column name */
	StoreContactState = 'store_contact_state',
	/** column name */
	StoreDiscountRate = 'store_discount_rate',
	/** column name */
	StoreDiscountType = 'store_discount_type',
	/** column name */
	StoreTrackingNumber = 'store_tracking_number',
	/** column name */
	StoreUrl = 'store_url',
	/** column name */
	TeamId = 'team_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UseAsCatalog = 'use_as_catalog',
	/** column name */
	WelcomeMessage = 'welcome_message'
}

export type Team_Store_Updates = {
	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?: InputMaybe<Team_Store_Append_Input>;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?: InputMaybe<Team_Store_Delete_At_Path_Input>;
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?: InputMaybe<Team_Store_Delete_Elem_Input>;
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?: InputMaybe<Team_Store_Delete_Key_Input>;
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Team_Store_Inc_Input>;
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?: InputMaybe<Team_Store_Prepend_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Team_Store_Set_Input>;
	/** filter the rows which have to be updated */
	where: Team_Store_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Team_Store_Var_Pop_Fields = {
	__typename?: 'team_store_var_pop_fields';
	store_discount_rate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "team_store" */
export type Team_Store_Var_Pop_Order_By = {
	store_discount_rate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Team_Store_Var_Samp_Fields = {
	__typename?: 'team_store_var_samp_fields';
	store_discount_rate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "team_store" */
export type Team_Store_Var_Samp_Order_By = {
	store_discount_rate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Team_Store_Variance_Fields = {
	__typename?: 'team_store_variance_fields';
	store_discount_rate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "team_store" */
export type Team_Store_Variance_Order_By = {
	store_discount_rate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "team" */
export type Team_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Team_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Stream_Cursor_Value_Input = {
	activity?: InputMaybe<Scalars['String']>;
	category?: InputMaybe<Scalars['String']>;
	city?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_category_type?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	state?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "team" */
export enum Team_Update_Column {
	/** column name */
	Activity = 'activity',
	/** column name */
	Category = 'category',
	/** column name */
	City = 'city',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	IsCategoryType = 'is_category_type',
	/** column name */
	Name = 'name',
	/** column name */
	State = 'state',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type Team_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Team_Set_Input>;
	/** filter the rows which have to be updated */
	where: Team_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['timestamptz']>;
	_gt?: InputMaybe<Scalars['timestamptz']>;
	_gte?: InputMaybe<Scalars['timestamptz']>;
	_in?: InputMaybe<Array<Scalars['timestamptz']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['timestamptz']>;
	_lte?: InputMaybe<Scalars['timestamptz']>;
	_neq?: InputMaybe<Scalars['timestamptz']>;
	_nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
	__typename?: 'user';
	created_at: Scalars['timestamptz'];
	email?: Maybe<Scalars['String']>;
	id: Scalars['uuid'];
	last_login_at?: Maybe<Scalars['timestamptz']>;
	name?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	picture?: Maybe<Scalars['String']>;
	updated_at: Scalars['timestamptz'];
	/** An array relationship */
	user_roles: Array<User_Roles>;
	/** An aggregate relationship */
	user_roles_aggregate: User_Roles_Aggregate;
	/** An array relationship */
	user_tokens: Array<User_Token>;
	/** An aggregate relationship */
	user_tokens_aggregate: User_Token_Aggregate;
};

/** columns and relationships of "user" */
export type UserUser_RolesArgs = {
	distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Roles_Order_By>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserUser_Roles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Roles_Order_By>>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserUser_TokensArgs = {
	distinct_on?: InputMaybe<Array<User_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Token_Order_By>>;
	where?: InputMaybe<User_Token_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserUser_Tokens_AggregateArgs = {
	distinct_on?: InputMaybe<Array<User_Token_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<User_Token_Order_By>>;
	where?: InputMaybe<User_Token_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
	__typename?: 'user_aggregate';
	aggregate?: Maybe<User_Aggregate_Fields>;
	nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
	__typename?: 'user_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<User_Max_Fields>;
	min?: Maybe<User_Min_Fields>;
};

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<User_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
	_and?: InputMaybe<Array<User_Bool_Exp>>;
	_not?: InputMaybe<User_Bool_Exp>;
	_or?: InputMaybe<Array<User_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	email?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	last_login_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	phone?: InputMaybe<String_Comparison_Exp>;
	picture?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user_roles?: InputMaybe<User_Roles_Bool_Exp>;
	user_roles_aggregate?: InputMaybe<User_Roles_Aggregate_Bool_Exp>;
	user_tokens?: InputMaybe<User_Token_Bool_Exp>;
	user_tokens_aggregate?: InputMaybe<User_Token_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
	/** unique or primary key constraint on columns "id" */
	UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	last_login_at?: InputMaybe<Scalars['timestamptz']>;
	name?: InputMaybe<Scalars['String']>;
	phone?: InputMaybe<Scalars['String']>;
	picture?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_roles?: InputMaybe<User_Roles_Arr_Rel_Insert_Input>;
	user_tokens?: InputMaybe<User_Token_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
	__typename?: 'user_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	email?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	last_login_at?: Maybe<Scalars['timestamptz']>;
	name?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	picture?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
	__typename?: 'user_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	email?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['uuid']>;
	last_login_at?: Maybe<Scalars['timestamptz']>;
	name?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	picture?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
	__typename?: 'user_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
	data: User_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
	constraint: User_Constraint;
	update_columns?: Array<User_Update_Column>;
	where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
	created_at?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	last_login_at?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	phone?: InputMaybe<Order_By>;
	picture?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_roles_aggregate?: InputMaybe<User_Roles_Aggregate_Order_By>;
	user_tokens_aggregate?: InputMaybe<User_Token_Aggregate_Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** columns and relationships of "user_roles" */
export type User_Roles = {
	__typename?: 'user_roles';
	created_at: Scalars['timestamptz'];
	id: Scalars['uuid'];
	/** An object relationship */
	role: Roles;
	role_id: Scalars['uuid'];
	updated_at: Scalars['timestamptz'];
	/** An object relationship */
	user: User;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
	__typename?: 'user_roles_aggregate';
	aggregate?: Maybe<User_Roles_Aggregate_Fields>;
	nodes: Array<User_Roles>;
};

export type User_Roles_Aggregate_Bool_Exp = {
	count?: InputMaybe<User_Roles_Aggregate_Bool_Exp_Count>;
};

export type User_Roles_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<User_Roles_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Roles_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
	__typename?: 'user_roles_aggregate_fields';
	count: Scalars['Int'];
	max?: Maybe<User_Roles_Max_Fields>;
	min?: Maybe<User_Roles_Min_Fields>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<User_Roles_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_roles" */
export type User_Roles_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<User_Roles_Max_Order_By>;
	min?: InputMaybe<User_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_roles" */
export type User_Roles_Arr_Rel_Insert_Input = {
	data: Array<User_Roles_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
	_and?: InputMaybe<Array<User_Roles_Bool_Exp>>;
	_not?: InputMaybe<User_Roles_Bool_Exp>;
	_or?: InputMaybe<Array<User_Roles_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	role?: InputMaybe<Roles_Bool_Exp>;
	role_id?: InputMaybe<Uuid_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user?: InputMaybe<User_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
	/** unique or primary key constraint on columns "id" */
	UserRolesPkey = 'user_roles_pkey'
}

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
	role_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user?: InputMaybe<User_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
	__typename?: 'user_roles_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	role_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_roles" */
export type User_Roles_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	role_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
	__typename?: 'user_roles_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	id?: Maybe<Scalars['uuid']>;
	role_id?: Maybe<Scalars['uuid']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_roles" */
export type User_Roles_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	role_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
	__typename?: 'user_roles_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<User_Roles>;
};

/** on_conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
	constraint: User_Roles_Constraint;
	update_columns?: Array<User_Roles_Update_Column>;
	where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
	created_at?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	role?: InputMaybe<Roles_Order_By>;
	role_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user?: InputMaybe<User_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_roles */
export type User_Roles_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	RoleId = 'role_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id'
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	role_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_roles" */
export type User_Roles_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: User_Roles_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Roles_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	id?: InputMaybe<Scalars['uuid']>;
	role_id?: InputMaybe<Scalars['uuid']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Id = 'id',
	/** column name */
	RoleId = 'role_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id'
}

export type User_Roles_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<User_Roles_Set_Input>;
	/** filter the rows which have to be updated */
	where: User_Roles_Bool_Exp;
};

/** select columns of table "user" */
export enum User_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Email = 'email',
	/** column name */
	Id = 'id',
	/** column name */
	LastLoginAt = 'last_login_at',
	/** column name */
	Name = 'name',
	/** column name */
	Phone = 'phone',
	/** column name */
	Picture = 'picture',
	/** column name */
	UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	last_login_at?: InputMaybe<Scalars['timestamptz']>;
	name?: InputMaybe<Scalars['String']>;
	phone?: InputMaybe<Scalars['String']>;
	picture?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: User_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['uuid']>;
	last_login_at?: InputMaybe<Scalars['timestamptz']>;
	name?: InputMaybe<Scalars['String']>;
	phone?: InputMaybe<Scalars['String']>;
	picture?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "user_token" */
export type User_Token = {
	__typename?: 'user_token';
	created_at: Scalars['timestamptz'];
	email: Scalars['String'];
	id: Scalars['Int'];
	name: Scalars['String'];
	picture: Scalars['String'];
	sub: Scalars['String'];
	token_type: Scalars['String'];
	updated_at: Scalars['timestamptz'];
	/** An object relationship */
	user: User;
	user_id: Scalars['uuid'];
};

/** aggregated selection of "user_token" */
export type User_Token_Aggregate = {
	__typename?: 'user_token_aggregate';
	aggregate?: Maybe<User_Token_Aggregate_Fields>;
	nodes: Array<User_Token>;
};

export type User_Token_Aggregate_Bool_Exp = {
	count?: InputMaybe<User_Token_Aggregate_Bool_Exp_Count>;
};

export type User_Token_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<User_Token_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<User_Token_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_token" */
export type User_Token_Aggregate_Fields = {
	__typename?: 'user_token_aggregate_fields';
	avg?: Maybe<User_Token_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<User_Token_Max_Fields>;
	min?: Maybe<User_Token_Min_Fields>;
	stddev?: Maybe<User_Token_Stddev_Fields>;
	stddev_pop?: Maybe<User_Token_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<User_Token_Stddev_Samp_Fields>;
	sum?: Maybe<User_Token_Sum_Fields>;
	var_pop?: Maybe<User_Token_Var_Pop_Fields>;
	var_samp?: Maybe<User_Token_Var_Samp_Fields>;
	variance?: Maybe<User_Token_Variance_Fields>;
};

/** aggregate fields of "user_token" */
export type User_Token_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<User_Token_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_token" */
export type User_Token_Aggregate_Order_By = {
	avg?: InputMaybe<User_Token_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<User_Token_Max_Order_By>;
	min?: InputMaybe<User_Token_Min_Order_By>;
	stddev?: InputMaybe<User_Token_Stddev_Order_By>;
	stddev_pop?: InputMaybe<User_Token_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<User_Token_Stddev_Samp_Order_By>;
	sum?: InputMaybe<User_Token_Sum_Order_By>;
	var_pop?: InputMaybe<User_Token_Var_Pop_Order_By>;
	var_samp?: InputMaybe<User_Token_Var_Samp_Order_By>;
	variance?: InputMaybe<User_Token_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_token" */
export type User_Token_Arr_Rel_Insert_Input = {
	data: Array<User_Token_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<User_Token_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Token_Avg_Fields = {
	__typename?: 'user_token_avg_fields';
	id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_token" */
export type User_Token_Avg_Order_By = {
	id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_token". All fields are combined with a logical 'AND'. */
export type User_Token_Bool_Exp = {
	_and?: InputMaybe<Array<User_Token_Bool_Exp>>;
	_not?: InputMaybe<User_Token_Bool_Exp>;
	_or?: InputMaybe<Array<User_Token_Bool_Exp>>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	email?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Int_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	picture?: InputMaybe<String_Comparison_Exp>;
	sub?: InputMaybe<String_Comparison_Exp>;
	token_type?: InputMaybe<String_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	user?: InputMaybe<User_Bool_Exp>;
	user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_token" */
export enum User_Token_Constraint {
	/** unique or primary key constraint on columns "id" */
	UserTokenPkey = 'user_token_pkey',
	/** unique or primary key constraint on columns "sub", "token_type" */
	UserTokenTokenTypeSubIndex = 'user_token_token_type_sub_index'
}

/** input type for incrementing numeric columns in table "user_token" */
export type User_Token_Inc_Input = {
	id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_token" */
export type User_Token_Insert_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Int']>;
	name?: InputMaybe<Scalars['String']>;
	picture?: InputMaybe<Scalars['String']>;
	sub?: InputMaybe<Scalars['String']>;
	token_type?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user?: InputMaybe<User_Obj_Rel_Insert_Input>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Token_Max_Fields = {
	__typename?: 'user_token_max_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	email?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
	picture?: Maybe<Scalars['String']>;
	sub?: Maybe<Scalars['String']>;
	token_type?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_token" */
export type User_Token_Max_Order_By = {
	created_at?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	picture?: InputMaybe<Order_By>;
	sub?: InputMaybe<Order_By>;
	token_type?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Token_Min_Fields = {
	__typename?: 'user_token_min_fields';
	created_at?: Maybe<Scalars['timestamptz']>;
	email?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
	picture?: Maybe<Scalars['String']>;
	sub?: Maybe<Scalars['String']>;
	token_type?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_token" */
export type User_Token_Min_Order_By = {
	created_at?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	picture?: InputMaybe<Order_By>;
	sub?: InputMaybe<Order_By>;
	token_type?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_token" */
export type User_Token_Mutation_Response = {
	__typename?: 'user_token_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<User_Token>;
};

/** on_conflict condition type for table "user_token" */
export type User_Token_On_Conflict = {
	constraint: User_Token_Constraint;
	update_columns?: Array<User_Token_Update_Column>;
	where?: InputMaybe<User_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "user_token". */
export type User_Token_Order_By = {
	created_at?: InputMaybe<Order_By>;
	email?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	picture?: InputMaybe<Order_By>;
	sub?: InputMaybe<Order_By>;
	token_type?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	user?: InputMaybe<User_Order_By>;
	user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_token */
export type User_Token_Pk_Columns_Input = {
	id: Scalars['Int'];
};

/** select columns of table "user_token" */
export enum User_Token_Select_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Email = 'email',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	Picture = 'picture',
	/** column name */
	Sub = 'sub',
	/** column name */
	TokenType = 'token_type',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id'
}

/** input type for updating data in table "user_token" */
export type User_Token_Set_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Int']>;
	name?: InputMaybe<Scalars['String']>;
	picture?: InputMaybe<Scalars['String']>;
	sub?: InputMaybe<Scalars['String']>;
	token_type?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Token_Stddev_Fields = {
	__typename?: 'user_token_stddev_fields';
	id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_token" */
export type User_Token_Stddev_Order_By = {
	id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Token_Stddev_Pop_Fields = {
	__typename?: 'user_token_stddev_pop_fields';
	id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_token" */
export type User_Token_Stddev_Pop_Order_By = {
	id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Token_Stddev_Samp_Fields = {
	__typename?: 'user_token_stddev_samp_fields';
	id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_token" */
export type User_Token_Stddev_Samp_Order_By = {
	id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_token" */
export type User_Token_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: User_Token_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Token_Stream_Cursor_Value_Input = {
	created_at?: InputMaybe<Scalars['timestamptz']>;
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Int']>;
	name?: InputMaybe<Scalars['String']>;
	picture?: InputMaybe<Scalars['String']>;
	sub?: InputMaybe<Scalars['String']>;
	token_type?: InputMaybe<Scalars['String']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Token_Sum_Fields = {
	__typename?: 'user_token_sum_fields';
	id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_token" */
export type User_Token_Sum_Order_By = {
	id?: InputMaybe<Order_By>;
};

/** update columns of table "user_token" */
export enum User_Token_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Email = 'email',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
	/** column name */
	Picture = 'picture',
	/** column name */
	Sub = 'sub',
	/** column name */
	TokenType = 'token_type',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UserId = 'user_id'
}

export type User_Token_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<User_Token_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<User_Token_Set_Input>;
	/** filter the rows which have to be updated */
	where: User_Token_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Token_Var_Pop_Fields = {
	__typename?: 'user_token_var_pop_fields';
	id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_token" */
export type User_Token_Var_Pop_Order_By = {
	id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Token_Var_Samp_Fields = {
	__typename?: 'user_token_var_samp_fields';
	id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_token" */
export type User_Token_Var_Samp_Order_By = {
	id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Token_Variance_Fields = {
	__typename?: 'user_token_variance_fields';
	id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_token" */
export type User_Token_Variance_Order_By = {
	id?: InputMaybe<Order_By>;
};

/** update columns of table "user" */
export enum User_Update_Column {
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	Email = 'email',
	/** column name */
	Id = 'id',
	/** column name */
	LastLoginAt = 'last_login_at',
	/** column name */
	Name = 'name',
	/** column name */
	Phone = 'phone',
	/** column name */
	Picture = 'picture',
	/** column name */
	UpdatedAt = 'updated_at'
}

export type User_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<User_Set_Input>;
	/** filter the rows which have to be updated */
	where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['uuid']>;
	_gt?: InputMaybe<Scalars['uuid']>;
	_gte?: InputMaybe<Scalars['uuid']>;
	_in?: InputMaybe<Array<Scalars['uuid']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['uuid']>;
	_lte?: InputMaybe<Scalars['uuid']>;
	_neq?: InputMaybe<Scalars['uuid']>;
	_nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type CreateDemoStoreMutationVariables = Exact<{
	storeLabel: Scalars['String'];
	bundleIds:
		| Array<InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>>
		| InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type CreateDemoStoreMutation = {
	__typename?: 'mutation_root';
	createDemoStore?: { __typename?: 'UrlOutput'; error?: string | null; urlRedirect: string } | null;
};

export type AuthLoginMutationVariables = Exact<{
	credentialResponse: Scalars['String'];
	loginType: Scalars['String'];
}>;

export type AuthLoginMutation = {
	__typename?: 'mutation_root';
	authLogin?: { __typename?: 'LoginOutput'; error?: string | null; token?: string | null } | null;
};

export type AuthSendMutationVariables = Exact<{
	email?: InputMaybe<Scalars['String']>;
}>;

export type AuthSendMutation = {
	__typename?: 'mutation_root';
	authSend?: { __typename?: 'SendOutput'; status: boolean; message?: string | null } | null;
};

export type AvailableOptionFragment = {
	__typename?: 'available_option';
	name: string;
	display_name?: string | null;
	id: any;
	ui_test_id?: string | null;
};

export type AvailableOptionValueFragment = {
	__typename?: 'available_option_value';
	id: any;
	label: string;
	price_modifier: number;
};

export type BundleProductFragment = {
	__typename?: 'product';
	id: any;
	label: string;
	base_price?: number | null;
	options?: any | null;
	public_product_image_url?: string | null;
};

export type ProductByIdFragment = {
	__typename?: 'store_product';
	custom_name_enabled: boolean;
	custom_name_price?: number | null;
	custom_number_enabled: boolean;
	custom_number_price?: number | null;
	custom_price_override?: number | null;
	display_photo?: string | null;
	description?: string | null;
	order: number;
	label?: string | null;
	bundle_only: boolean;
	id: any;
	friendly_url_key?: string | null;
	limit_order_qty: number;
	enforce_limit_qty: boolean;
	qty_purchased_calc: number;
	additional_image_list: any;
	additional_description?: string | null;
	custom_name_number_dropdown?: any | null;
	options: Array<{
		__typename?: 'store_product_has_options';
		available_option: { __typename?: 'available_option'; name: string; display_name?: string | null; id: any };
	}>;
	option_values: Array<{
		__typename?: 'store_product_has_options';
		id: any;
		image_url?: string | null;
		label_override?: string | null;
		price_modifier_override?: number | null;
		secondary_image_url?: string | null;
		order: number;
		available_option_id: any;
		available_option_value?: { __typename?: 'available_option_value'; id: any; label: string; price_modifier: number } | null;
	}>;
	product: {
		__typename?: 'product';
		base_price?: number | null;
		top_size_restriction: boolean;
		short_size_restriction: boolean;
		size_chart_url?: string | null;
	};
	team_store: {
		__typename?: 'team_store';
		id: any;
		name: string;
		friendly_url_key?: string | null;
		store_discount_rate?: number | null;
		store_discount_type?: string | null;
	};
};

export type PublicProductFragment = {
	__typename?: 'product';
	base_price?: number | null;
	description?: string | null;
	id: any;
	label: string;
	public_product: boolean;
	public_product_image_url?: string | null;
	public_product_description?: string | null;
	size_chart_url?: string | null;
	is_bundle: boolean;
	discounted_from_price?: number | null;
};

export type StorePackageFragment = {
	__typename?: 'store_package';
	id: any;
	photo_url?: string | null;
	label?: string | null;
	description?: string | null;
	custom_price: number;
	product_ids: any;
};

export type StoreProductFragment = {
	__typename?: 'store_product';
	custom_name_enabled: boolean;
	custom_name_price?: number | null;
	custom_number_enabled: boolean;
	custom_number_price?: number | null;
	custom_price_override?: number | null;
	display_photo?: string | null;
	description?: string | null;
	order: number;
	label?: string | null;
	bundle_only: boolean;
	id: any;
	friendly_url_key?: string | null;
	limit_order_qty: number;
	enforce_limit_qty: boolean;
	qty_purchased_calc: number;
	additional_image_list: any;
	store_product_has_options: Array<{
		__typename?: 'store_product_has_options';
		id: any;
		image_url?: string | null;
		label_override?: string | null;
		price_modifier_override?: number | null;
		secondary_image_url?: string | null;
		order: number;
		available_option_value?: { __typename?: 'available_option_value'; id: any; label: string; price_modifier: number } | null;
		available_option: {
			__typename?: 'available_option';
			name: string;
			display_name?: string | null;
			id: any;
			ui_test_id?: string | null;
		};
	}>;
	product: {
		__typename?: 'product';
		base_price?: number | null;
		top_size_restriction: boolean;
		short_size_restriction: boolean;
	};
};

export type StoreProductHasOptionsFragment = {
	__typename?: 'store_product_has_options';
	id: any;
	image_url?: string | null;
	label_override?: string | null;
	price_modifier_override?: number | null;
	secondary_image_url?: string | null;
	order: number;
	available_option_value?: { __typename?: 'available_option_value'; id: any; label: string; price_modifier: number } | null;
	available_option: {
		__typename?: 'available_option';
		name: string;
		display_name?: string | null;
		id: any;
		ui_test_id?: string | null;
	};
};

export type TeamStoreFragment = {
	__typename?: 'team_store';
	id: any;
	friendly_url_key?: string | null;
	name: string;
	status?: string | null;
	welcome_message?: string | null;
	image_url?: string | null;
	store_discount_rate?: number | null;
	store_discount_type?: string | null;
	enable_credit_card_payment: boolean;
	enable_check_payment: boolean;
	attribution_list?: any | null;
	use_as_catalog: boolean;
	banner_url?: string | null;
	store_packages: Array<{
		__typename?: 'store_package';
		id: any;
		photo_url?: string | null;
		label?: string | null;
		description?: string | null;
		custom_price: number;
		product_ids: any;
	}>;
	store_products: Array<{
		__typename?: 'store_product';
		custom_name_enabled: boolean;
		custom_name_price?: number | null;
		custom_number_enabled: boolean;
		custom_number_price?: number | null;
		custom_price_override?: number | null;
		display_photo?: string | null;
		description?: string | null;
		order: number;
		label?: string | null;
		bundle_only: boolean;
		id: any;
		friendly_url_key?: string | null;
		limit_order_qty: number;
		enforce_limit_qty: boolean;
		qty_purchased_calc: number;
		additional_image_list: any;
		store_product_has_options: Array<{
			__typename?: 'store_product_has_options';
			id: any;
			image_url?: string | null;
			label_override?: string | null;
			price_modifier_override?: number | null;
			secondary_image_url?: string | null;
			order: number;
			available_option_value?: {
				__typename?: 'available_option_value';
				id: any;
				label: string;
				price_modifier: number;
			} | null;
			available_option: {
				__typename?: 'available_option';
				name: string;
				display_name?: string | null;
				id: any;
				ui_test_id?: string | null;
			};
		}>;
		product: {
			__typename?: 'product';
			base_price?: number | null;
			top_size_restriction: boolean;
			short_size_restriction: boolean;
		};
	}>;
	store_is_showcasing_stores: Array<{
		__typename?: 'showcase';
		id: any;
		label: string;
		image_url: string;
		teamStoreByStoreShowcasingId: { __typename?: 'team_store'; id: any; name: string; welcome_message?: string | null };
	}>;
};

export type TeamStoreOrderFragment = {
	__typename?: 'order';
	id: any;
	customer_name?: string | null;
	updated_at: any;
	total?: number | null;
	order_items: Array<{
		__typename?: 'order_item';
		custom_name?: string | null;
		custom_number?: string | null;
		id: any;
		quantity: number;
		image_url?: string | null;
		total_price: number;
		order_for?: string | null;
		order_package_id?: any | null;
		order_package?: {
			__typename?: 'order_package';
			image_url?: string | null;
			store_package_id: any;
			total_price: number;
			store_package: { __typename?: 'store_package'; label?: string | null };
		} | null;
		order_item_options: Array<{
			__typename?: 'order_item_option';
			id: any;
			available_option_id: any;
			available_option_value_id: any;
			value: string;
			price_modifier: number;
			image_url?: string | null;
			available_option: { __typename?: 'available_option'; display_name?: string | null; name: string };
		}>;
		store_product: { __typename?: 'store_product'; id: any; label?: string | null };
	}>;
};

export type CreateNewLeadMutationVariables = Exact<{
	leadInput: LeadInput;
}>;

export type CreateNewLeadMutation = {
	__typename?: 'mutation_root';
	createNewLead?: {
		__typename?: 'LeadOutput';
		status: boolean;
		error?: string | null;
		design_file_signed_url?: string | null;
		design_file_two_signed_url?: string | null;
	} | null;
};

export type GetPartnerProductsQueryVariables = Exact<{
	stores?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
	_or?: InputMaybe<Array<Store_Product_Bool_Exp> | Store_Product_Bool_Exp>;
}>;

export type GetPartnerProductsQuery = {
	__typename?: 'query_root';
	store_product: Array<{
		__typename?: 'store_product';
		id: any;
		product_id: any;
		label?: string | null;
		display_photo?: string | null;
		custom_price_override?: number | null;
		product: { __typename?: 'product'; base_price?: number | null };
	}>;
};

export type GetStoresByActivityQueryVariables = Exact<{
	activity?: InputMaybe<Scalars['String']>;
}>;

export type GetStoresByActivityQuery = {
	__typename?: 'query_root';
	team_store: Array<{
		__typename?: 'team_store';
		id: any;
		friendly_url_key?: string | null;
		name: string;
		image_url?: string | null;
		store_is_showcasing_stores: Array<{
			__typename?: 'showcase';
			id: any;
			friendly_url_key?: string | null;
			label: string;
			order: number;
			banner_url?: string | null;
			image_url: string;
			search_sets?: any | null;
			teamStoreByStoreShowcasingId: {
				__typename?: 'team_store';
				id: any;
				name: string;
				friendly_url_key?: string | null;
				image_url?: string | null;
				banner_url?: string | null;
				status?: string | null;
			};
		}>;
	}>;
};

export type GetBundleBuilderProductsQueryVariables = Exact<{
	options?: InputMaybe<Jsonb_Comparison_Exp>;
}>;

export type GetBundleBuilderProductsQuery = {
	__typename?: 'query_root';
	product: Array<{
		__typename?: 'product';
		id: any;
		label: string;
		base_price?: number | null;
		options?: any | null;
		public_product_image_url?: string | null;
	}>;
};

export type GetPackageByPackageIdQueryVariables = Exact<{
	_eq?: InputMaybe<Scalars['uuid']>;
}>;

export type GetPackageByPackageIdQuery = {
	__typename?: 'query_root';
	store_package: Array<{
		__typename?: 'store_package';
		id: any;
		photo_url?: string | null;
		label?: string | null;
		description?: string | null;
		custom_price: number;
		product_ids: any;
	}>;
};

export type GetPackageProductsByIdQueryVariables = Exact<{
	_in?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetPackageProductsByIdQuery = {
	__typename?: 'query_root';
	store_product: Array<{
		__typename?: 'store_product';
		custom_name_enabled: boolean;
		custom_name_price?: number | null;
		custom_number_enabled: boolean;
		custom_number_price?: number | null;
		custom_price_override?: number | null;
		display_photo?: string | null;
		description?: string | null;
		order: number;
		label?: string | null;
		bundle_only: boolean;
		id: any;
		friendly_url_key?: string | null;
		limit_order_qty: number;
		enforce_limit_qty: boolean;
		qty_purchased_calc: number;
		additional_image_list: any;
		additional_description?: string | null;
		custom_name_number_dropdown?: any | null;
		options: Array<{
			__typename?: 'store_product_has_options';
			available_option: { __typename?: 'available_option'; name: string; display_name?: string | null; id: any };
		}>;
		option_values: Array<{
			__typename?: 'store_product_has_options';
			id: any;
			image_url?: string | null;
			label_override?: string | null;
			price_modifier_override?: number | null;
			secondary_image_url?: string | null;
			order: number;
			available_option_id: any;
			available_option_value?: {
				__typename?: 'available_option_value';
				id: any;
				label: string;
				price_modifier: number;
			} | null;
		}>;
		product: {
			__typename?: 'product';
			base_price?: number | null;
			top_size_restriction: boolean;
			short_size_restriction: boolean;
			size_chart_url?: string | null;
		};
		team_store: {
			__typename?: 'team_store';
			id: any;
			name: string;
			friendly_url_key?: string | null;
			store_discount_rate?: number | null;
			store_discount_type?: string | null;
		};
	}>;
};

export type GetProductByIdQueryVariables = Exact<{
	id?: InputMaybe<Scalars['uuid']>;
	key?: InputMaybe<Scalars['String']>;
}>;

export type GetProductByIdQuery = {
	__typename?: 'query_root';
	store_product: Array<{
		__typename?: 'store_product';
		custom_name_enabled: boolean;
		custom_name_price?: number | null;
		custom_number_enabled: boolean;
		custom_number_price?: number | null;
		custom_price_override?: number | null;
		display_photo?: string | null;
		description?: string | null;
		order: number;
		label?: string | null;
		bundle_only: boolean;
		id: any;
		friendly_url_key?: string | null;
		limit_order_qty: number;
		enforce_limit_qty: boolean;
		qty_purchased_calc: number;
		additional_image_list: any;
		additional_description?: string | null;
		custom_name_number_dropdown?: any | null;
		options: Array<{
			__typename?: 'store_product_has_options';
			available_option: { __typename?: 'available_option'; name: string; display_name?: string | null; id: any };
		}>;
		option_values: Array<{
			__typename?: 'store_product_has_options';
			id: any;
			image_url?: string | null;
			label_override?: string | null;
			price_modifier_override?: number | null;
			secondary_image_url?: string | null;
			order: number;
			available_option_id: any;
			available_option_value?: {
				__typename?: 'available_option_value';
				id: any;
				label: string;
				price_modifier: number;
			} | null;
		}>;
		product: {
			__typename?: 'product';
			base_price?: number | null;
			top_size_restriction: boolean;
			short_size_restriction: boolean;
			size_chart_url?: string | null;
		};
		team_store: {
			__typename?: 'team_store';
			id: any;
			name: string;
			friendly_url_key?: string | null;
			store_discount_rate?: number | null;
			store_discount_type?: string | null;
		};
	}>;
};

export type GetPublicProductByIdForBreakmarkQueryVariables = Exact<{
	productId: Scalars['uuid'];
}>;

export type GetPublicProductByIdForBreakmarkQuery = {
	__typename?: 'query_root';
	product: Array<{
		__typename?: 'product';
		base_price?: number | null;
		description?: string | null;
		id: any;
		label: string;
		public_product: boolean;
		public_product_image_url?: string | null;
		public_product_description?: string | null;
		size_chart_url?: string | null;
		is_bundle: boolean;
		discounted_from_price?: number | null;
	}>;
};

export type GetPublicProductsForBreakmarkQueryVariables = Exact<{ [key: string]: never }>;

export type GetPublicProductsForBreakmarkQuery = {
	__typename?: 'query_root';
	product: Array<{
		__typename?: 'product';
		base_price?: number | null;
		description?: string | null;
		id: any;
		label: string;
		public_product: boolean;
		public_product_image_url?: string | null;
		public_product_description?: string | null;
		size_chart_url?: string | null;
		is_bundle: boolean;
		discounted_from_price?: number | null;
	}>;
};

export type GetStoreContentsQueryVariables = Exact<{
	id?: InputMaybe<Scalars['uuid']>;
	key?: InputMaybe<Scalars['String']>;
}>;

export type GetStoreContentsQuery = {
	__typename?: 'query_root';
	team_store: Array<{
		__typename?: 'team_store';
		id: any;
		friendly_url_key?: string | null;
		name: string;
		status?: string | null;
		welcome_message?: string | null;
		image_url?: string | null;
		store_discount_rate?: number | null;
		store_discount_type?: string | null;
		enable_credit_card_payment: boolean;
		enable_check_payment: boolean;
		attribution_list?: any | null;
		use_as_catalog: boolean;
		banner_url?: string | null;
		store_packages: Array<{
			__typename?: 'store_package';
			id: any;
			photo_url?: string | null;
			label?: string | null;
			description?: string | null;
			custom_price: number;
			product_ids: any;
		}>;
		store_products: Array<{
			__typename?: 'store_product';
			custom_name_enabled: boolean;
			custom_name_price?: number | null;
			custom_number_enabled: boolean;
			custom_number_price?: number | null;
			custom_price_override?: number | null;
			display_photo?: string | null;
			description?: string | null;
			order: number;
			label?: string | null;
			bundle_only: boolean;
			id: any;
			friendly_url_key?: string | null;
			limit_order_qty: number;
			enforce_limit_qty: boolean;
			qty_purchased_calc: number;
			additional_image_list: any;
			store_product_has_options: Array<{
				__typename?: 'store_product_has_options';
				id: any;
				image_url?: string | null;
				label_override?: string | null;
				price_modifier_override?: number | null;
				secondary_image_url?: string | null;
				order: number;
				available_option_value?: {
					__typename?: 'available_option_value';
					id: any;
					label: string;
					price_modifier: number;
				} | null;
				available_option: {
					__typename?: 'available_option';
					name: string;
					display_name?: string | null;
					id: any;
					ui_test_id?: string | null;
				};
			}>;
			product: {
				__typename?: 'product';
				base_price?: number | null;
				top_size_restriction: boolean;
				short_size_restriction: boolean;
			};
		}>;
		store_is_showcasing_stores: Array<{
			__typename?: 'showcase';
			id: any;
			label: string;
			image_url: string;
			teamStoreByStoreShowcasingId: { __typename?: 'team_store'; id: any; name: string; welcome_message?: string | null };
		}>;
	}>;
};

export type GetTeamStoreOrderQueryVariables = Exact<{
	_eq?: InputMaybe<Scalars['uuid']>;
	orderIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetTeamStoreOrderQuery = {
	__typename?: 'query_root';
	order: Array<{
		__typename?: 'order';
		id: any;
		customer_name?: string | null;
		updated_at: any;
		total?: number | null;
		order_items: Array<{
			__typename?: 'order_item';
			custom_name?: string | null;
			custom_number?: string | null;
			id: any;
			quantity: number;
			image_url?: string | null;
			total_price: number;
			order_for?: string | null;
			order_package_id?: any | null;
			order_package?: {
				__typename?: 'order_package';
				image_url?: string | null;
				store_package_id: any;
				total_price: number;
				store_package: { __typename?: 'store_package'; label?: string | null };
			} | null;
			order_item_options: Array<{
				__typename?: 'order_item_option';
				id: any;
				available_option_id: any;
				available_option_value_id: any;
				value: string;
				price_modifier: number;
				image_url?: string | null;
				available_option: { __typename?: 'available_option'; display_name?: string | null; name: string };
			}>;
			store_product: { __typename?: 'store_product'; id: any; label?: string | null };
		}>;
	}>;
};

export type CreateCheckoutMutationVariables = Exact<{
	products?: InputMaybe<Scalars['String']>;
	storeId?: InputMaybe<Scalars['String']>;
	checkoutType?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	attributeTo?: InputMaybe<Scalars['String']>;
}>;

export type CreateCheckoutMutation = {
	__typename?: 'mutation_root';
	createCheckout?: { __typename?: 'UrlOutput'; urlRedirect: string; error?: string | null } | null;
};

export type GetAllStoreContentsQueryVariables = Exact<{
	ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type GetAllStoreContentsQuery = {
	__typename?: 'query_root';
	team_store: Array<{
		__typename?: 'team_store';
		id: any;
		friendly_url_key?: string | null;
		name: string;
		status?: string | null;
		welcome_message?: string | null;
		image_url?: string | null;
		store_discount_rate?: number | null;
		store_discount_type?: string | null;
		enable_credit_card_payment: boolean;
		enable_check_payment: boolean;
		attribution_list?: any | null;
		use_as_catalog: boolean;
		banner_url?: string | null;
		store_packages: Array<{
			__typename?: 'store_package';
			id: any;
			photo_url?: string | null;
			label?: string | null;
			description?: string | null;
			custom_price: number;
			product_ids: any;
		}>;
		store_products: Array<{
			__typename?: 'store_product';
			custom_name_enabled: boolean;
			custom_name_price?: number | null;
			custom_number_enabled: boolean;
			custom_number_price?: number | null;
			custom_price_override?: number | null;
			display_photo?: string | null;
			description?: string | null;
			order: number;
			label?: string | null;
			bundle_only: boolean;
			id: any;
			friendly_url_key?: string | null;
			limit_order_qty: number;
			enforce_limit_qty: boolean;
			qty_purchased_calc: number;
			additional_image_list: any;
			store_product_has_options: Array<{
				__typename?: 'store_product_has_options';
				id: any;
				image_url?: string | null;
				label_override?: string | null;
				price_modifier_override?: number | null;
				secondary_image_url?: string | null;
				order: number;
				available_option_value?: {
					__typename?: 'available_option_value';
					id: any;
					label: string;
					price_modifier: number;
				} | null;
				available_option: {
					__typename?: 'available_option';
					name: string;
					display_name?: string | null;
					id: any;
					ui_test_id?: string | null;
				};
			}>;
			product: {
				__typename?: 'product';
				base_price?: number | null;
				top_size_restriction: boolean;
				short_size_restriction: boolean;
			};
		}>;
		store_is_showcasing_stores: Array<{
			__typename?: 'showcase';
			id: any;
			label: string;
			image_url: string;
			teamStoreByStoreShowcasingId: { __typename?: 'team_store'; id: any; name: string; welcome_message?: string | null };
		}>;
	}>;
};

export type GetMeQueryVariables = Exact<{
	id?: InputMaybe<Scalars['uuid']>;
}>;

export type GetMeQuery = {
	__typename?: 'query_root';
	user_by_pk?: { __typename?: 'user'; email?: string | null; id: any; name?: string | null; picture?: string | null } | null;
};

export type GetMyStoreOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyStoreOrdersQuery = {
	__typename?: 'query_root';
	order: Array<{
		__typename?: 'order';
		id: any;
		customer_name?: string | null;
		customer_email?: string | null;
		created_at: any;
		checkout_type?: string | null;
		order_readable_column: number;
		status: string;
		stripe_tax_charged: number;
		stripe_total_charged?: number | null;
		total?: number | null;
		updated_at: any;
		order_items: Array<{ __typename?: 'order_item'; id: any; image_url?: string | null }>;
	}>;
};

export type GetOrderByPkQueryVariables = Exact<{
	id?: InputMaybe<Scalars['uuid']>;
}>;

export type GetOrderByPkQuery = {
	__typename?: 'query_root';
	products?: {
		__typename?: 'order';
		created_at: any;
		customer_name?: string | null;
		order_items: Array<{
			__typename?: 'order_item';
			id: any;
			custom_name?: string | null;
			total_price: number;
			custom_number?: string | null;
			image_url?: string | null;
			quantity: number;
			store_product: { __typename?: 'store_product'; label?: string | null; additional_image_list: any };
			order_item_options: Array<{
				__typename?: 'order_item_option';
				value: string;
				available_option: { __typename?: 'available_option'; name: string };
			}>;
		}>;
	} | null;
	packages?: {
		__typename?: 'order';
		team_store_id: any;
		created_at: any;
		customer_name?: string | null;
		order_items: Array<{
			__typename?: 'order_item';
			order_package?: {
				__typename?: 'order_package';
				total_price: number;
				image_url?: string | null;
				store_package: { __typename?: 'store_package'; label?: string | null };
				order_items: Array<{
					__typename?: 'order_item';
					custom_name?: string | null;
					custom_number?: string | null;
					image_url?: string | null;
					store_product: { __typename?: 'store_product'; label?: string | null };
					order_item_options: Array<{
						__typename?: 'order_item_option';
						value: string;
						available_option: { __typename?: 'available_option'; name: string };
					}>;
				}>;
			} | null;
		}>;
	} | null;
	details?: {
		__typename?: 'order';
		id: any;
		created_at: any;
		customer_name?: string | null;
		order_readable_column: number;
		total?: number | null;
	} | null;
};

export type InsertSupportMutationVariables = Exact<{
	first_name?: InputMaybe<Scalars['String']>;
	last_name?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['String']>;
	order_id?: InputMaybe<Scalars['String']>;
	order_item_id?: InputMaybe<Scalars['uuid']>;
	phone?: InputMaybe<Scalars['String']>;
	reason?: InputMaybe<Scalars['String']>;
	edits?: InputMaybe<Scalars['String']>;
	details?: InputMaybe<Scalars['String']>;
	user_id?: InputMaybe<Scalars['uuid']>;
	captcha_token?: InputMaybe<Scalars['String']>;
}>;

export type InsertSupportMutation = {
	__typename?: 'mutation_root';
	insert_support_one?: { __typename?: 'support'; id: number; created_at?: any | null } | null;
};

export type UpdateMeMutationVariables = Exact<{
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
}>;

export type UpdateMeMutation = { __typename?: 'mutation_root'; update_user_by_pk?: { __typename?: 'user'; id: any } | null };

export const BundleProductFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BundleProduct' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'product' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'base_price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'options' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'public_product_image_url' } }
				]
			}
		}
	]
} as unknown as DocumentNode<BundleProductFragment, unknown>;
export const ProductByIdFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ProductById' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'store_product' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_name_enabled' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_name_price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_number_enabled' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_number_price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_price_override' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'display_photo' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'order' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'bundle_only' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'friendly_url_key' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'limit_order_qty' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'enforce_limit_qty' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'qty_purchased_calc' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'additional_image_list' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'additional_description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_name_number_dropdown' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'options' },
						name: { kind: 'Name', value: 'store_product_has_options' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'available_option' },
											value: { kind: 'ObjectValue', fields: [] }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'distinct_on' },
								value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'available_option_id' }] }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'available_option' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'display_name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'option_values' },
						name: { kind: 'Name', value: 'store_product_has_options' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'available_option_value' },
											value: { kind: 'ObjectValue', fields: [] }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order_by' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'available_option_id' },
											value: { kind: 'EnumValue', value: 'asc' }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'order' },
											value: { kind: 'EnumValue', value: 'asc' }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'label_override' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'price_modifier_override' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'secondary_image_url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'order' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'available_option_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'available_option_value' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'price_modifier' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'product' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'base_price' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'top_size_restriction' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'short_size_restriction' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'size_chart_url' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team_store' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'friendly_url_key' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'store_discount_rate' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'store_discount_type' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ProductByIdFragment, unknown>;
export const PublicProductFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PublicProduct' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'product' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'base_price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'public_product' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'public_product_image_url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'public_product_description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'size_chart_url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'is_bundle' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'discounted_from_price' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PublicProductFragment, unknown>;
export const StorePackageFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'StorePackage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'store_package' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'photo_url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'product_ids' } }
				]
			}
		}
	]
} as unknown as DocumentNode<StorePackageFragment, unknown>;
export const AvailableOptionValueFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AvailableOptionValue' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'available_option_value' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'price_modifier' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AvailableOptionValueFragment, unknown>;
export const AvailableOptionFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AvailableOption' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'available_option' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'display_name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'ui_test_id' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AvailableOptionFragment, unknown>;
export const StoreProductHasOptionsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'StoreProductHasOptions' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'store_product_has_options' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'label_override' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'price_modifier_override' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'secondary_image_url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'order' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'available_option_value' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AvailableOptionValue' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'available_option' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AvailableOption' } }]
						}
					}
				]
			}
		},
		...AvailableOptionValueFragmentDoc.definitions,
		...AvailableOptionFragmentDoc.definitions
	]
} as unknown as DocumentNode<StoreProductHasOptionsFragment, unknown>;
export const StoreProductFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'StoreProduct' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'store_product' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_name_enabled' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_name_price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_number_enabled' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_number_price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'custom_price_override' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'display_photo' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'order' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'bundle_only' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'friendly_url_key' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'limit_order_qty' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'enforce_limit_qty' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'qty_purchased_calc' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'store_product_has_options' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'available_option_id' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_is_null' },
														value: { kind: 'BooleanValue', value: false }
													}
												]
											}
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order_by' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'order' },
											value: { kind: 'EnumValue', value: 'asc' }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreProductHasOptions' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'product' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'base_price' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'top_size_restriction' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'short_size_restriction' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'additional_image_list' } }
				]
			}
		},
		...StoreProductHasOptionsFragmentDoc.definitions
	]
} as unknown as DocumentNode<StoreProductFragment, unknown>;
export const TeamStoreFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamStore' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'team_store' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'friendly_url_key' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'welcome_message' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'store_discount_rate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'store_discount_type' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'enable_credit_card_payment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'enable_check_payment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'attribution_list' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'store_packages' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order_by' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'order' },
											value: { kind: 'EnumValue', value: 'asc' }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StorePackage' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'store_products' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order_by' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'order' },
											value: { kind: 'EnumValue', value: 'asc' }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'enabled' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_eq' },
														value: { kind: 'BooleanValue', value: true }
													}
												]
											}
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreProduct' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'use_as_catalog' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'banner_url' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'store_is_showcasing_stores' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'teamStoreByStoreShowcasingId' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'welcome_message' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		...StorePackageFragmentDoc.definitions,
		...StoreProductFragmentDoc.definitions
	]
} as unknown as DocumentNode<TeamStoreFragment, unknown>;
export const TeamStoreOrderFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamStoreOrder' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'order' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'customer_name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'order_items' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'custom_name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'custom_number' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total_price' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'order_for' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'order_package_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'order_package' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'store_package_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_price' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'store_package' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'label' } }]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'order_item_options' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'available_option_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'available_option_value_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'available_option' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'display_name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
													]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'value' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'price_modifier' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } }
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'store_product' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'label' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
				]
			}
		}
	]
} as unknown as DocumentNode<TeamStoreOrderFragment, unknown>;
export const CreateDemoStoreDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'CreateDemoStore' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeLabel' } },
					type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'bundleIds' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
						}
					}
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'createDemoStore' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'bundleIds' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'bundleIds' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'storeLabel' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'storeLabel' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'error' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'urlRedirect' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CreateDemoStoreMutation, CreateDemoStoreMutationVariables>;
export const AuthLoginDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'AuthLogin' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'credentialResponse' } },
					type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'loginType' } },
					type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'authLogin' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'credentialResponse' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'credentialResponse' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'loginType' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'loginType' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'error' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'token' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AuthLoginMutation, AuthLoginMutationVariables>;
export const AuthSendDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'AuthSend' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'authSend' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'email' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'message' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AuthSendMutation, AuthSendMutationVariables>;
export const CreateNewLeadDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'CreateNewLead' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'leadInput' } },
					type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'LeadInput' } } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'createNewLead' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'leadInput' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'leadInput' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'error' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'design_file_signed_url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'design_file_two_signed_url' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CreateNewLeadMutation, CreateNewLeadMutationVariables>;
export const GetPartnerProductsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetPartnerProducts' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'stores' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } } }
					},
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: '_or' } },
					type: {
						kind: 'ListType',
						type: {
							kind: 'NonNullType',
							type: { kind: 'NamedType', name: { kind: 'Name', value: 'store_product_bool_exp' } }
						}
					},
					defaultValue: { kind: 'ObjectValue', fields: [] }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'store_product' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'team_store' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: {
															kind: 'Name',
															value: 'storeIsShowcasingStoresByStoreShowcasingId'
														},
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'id' },
																	value: {
																		kind: 'ObjectValue',
																		fields: [
																			{
																				kind: 'ObjectField',
																				name: { kind: 'Name', value: '_in' },
																				value: {
																					kind: 'Variable',
																					name: { kind: 'Name', value: 'stores' }
																				}
																			}
																		]
																	}
																}
															]
														}
													}
												]
											}
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: '_or' },
											value: { kind: 'Variable', name: { kind: 'Name', value: '_or' } }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order_by' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'order' },
											value: { kind: 'EnumValue', value: 'asc' }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'product_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'display_photo' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'custom_price_override' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'product' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'base_price' } }]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GetPartnerProductsQuery, GetPartnerProductsQueryVariables>;
export const GetStoresByActivityDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetStoresByActivity' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'activity' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: 'Ultimate', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team_store' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'use_as_catalog' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_eq' },
														value: { kind: 'BooleanValue', value: true }
													}
												]
											}
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'status' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_eq' },
														value: { kind: 'StringValue', value: 'open', block: false }
													}
												]
											}
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'team' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'activity' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: '_eq' },
																	value: {
																		kind: 'Variable',
																		name: { kind: 'Name', value: 'activity' }
																	}
																}
															]
														}
													}
												]
											}
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'friendly_url_key' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'store_is_showcasing_stores' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'where' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'is_archived' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: '_eq' },
																	value: { kind: 'BooleanValue', value: false }
																}
															]
														}
													},
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'team_store' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'status' },
																	value: {
																		kind: 'ObjectValue',
																		fields: [
																			{
																				kind: 'ObjectField',
																				name: { kind: 'Name', value: '_eq' },
																				value: {
																					kind: 'StringValue',
																					value: 'open',
																					block: false
																				}
																			}
																		]
																	}
																},
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: 'team' },
																	value: {
																		kind: 'ObjectValue',
																		fields: [
																			{
																				kind: 'ObjectField',
																				name: { kind: 'Name', value: 'activity' },
																				value: {
																					kind: 'ObjectValue',
																					fields: [
																						{
																							kind: 'ObjectField',
																							name: { kind: 'Name', value: '_eq' },
																							value: {
																								kind: 'Variable',
																								name: {
																									kind: 'Name',
																									value: 'activity'
																								}
																							}
																						}
																					]
																				}
																			}
																		]
																	}
																}
															]
														}
													}
												]
											}
										},
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'order_by' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'order' },
														value: { kind: 'EnumValue', value: 'asc' }
													}
												]
											}
										}
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'friendly_url_key' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'order' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'banner_url' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'search_sets' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'teamStoreByStoreShowcasingId' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'friendly_url_key' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'banner_url' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'status' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GetStoresByActivityQuery, GetStoresByActivityQueryVariables>;
export const GetBundleBuilderProductsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetBundleBuilderProducts' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'jsonb_comparison_exp' } },
					defaultValue: { kind: 'ObjectValue', fields: [] }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'product' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'public_product' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_eq' },
														value: { kind: 'BooleanValue', value: true }
													}
												]
											}
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'options' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'options' } }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order_by' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'base_price' },
											value: { kind: 'EnumValue', value: 'desc' }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BundleProduct' } }]
						}
					}
				]
			}
		},
		...BundleProductFragmentDoc.definitions
	]
} as unknown as DocumentNode<GetBundleBuilderProductsQuery, GetBundleBuilderProductsQueryVariables>;
export const GetPackageByPackageIdDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetPackageByPackageId' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'store_package' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'id' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_eq' },
														value: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } }
													}
												]
											}
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StorePackage' } }]
						}
					}
				]
			}
		},
		...StorePackageFragmentDoc.definitions
	]
} as unknown as DocumentNode<GetPackageByPackageIdQuery, GetPackageByPackageIdQueryVariables>;
export const GetPackageProductsByIdDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetPackageProductsById' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: '_in' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } } }
					},
					defaultValue: { kind: 'StringValue', value: '', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'store_product' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'id' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_in' },
														value: { kind: 'Variable', name: { kind: 'Name', value: '_in' } }
													}
												]
											}
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductById' } }]
						}
					}
				]
			}
		},
		...ProductByIdFragmentDoc.definitions
	]
} as unknown as DocumentNode<GetPackageProductsByIdQuery, GetPackageProductsByIdQueryVariables>;
export const GetProductByIdDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetProductById' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'store_product' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: '_or' },
											value: {
												kind: 'ListValue',
												values: [
													{
														kind: 'ObjectValue',
														fields: [
															{
																kind: 'ObjectField',
																name: { kind: 'Name', value: 'id' },
																value: {
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: '_eq' },
																			value: {
																				kind: 'Variable',
																				name: { kind: 'Name', value: 'id' }
																			}
																		}
																	]
																}
															}
														]
													},
													{
														kind: 'ObjectValue',
														fields: [
															{
																kind: 'ObjectField',
																name: { kind: 'Name', value: 'friendly_url_key' },
																value: {
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: '_eq' },
																			value: {
																				kind: 'Variable',
																				name: { kind: 'Name', value: 'key' }
																			}
																		}
																	]
																}
															}
														]
													}
												]
											}
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductById' } }]
						}
					}
				]
			}
		},
		...ProductByIdFragmentDoc.definitions
	]
} as unknown as DocumentNode<GetProductByIdQuery, GetProductByIdQueryVariables>;
export const GetPublicProductByIdForBreakmarkDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetPublicProductByIdForBreakmark' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
					type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'product' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'id' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_eq' },
														value: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } }
													}
												]
											}
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PublicProduct' } }]
						}
					}
				]
			}
		},
		...PublicProductFragmentDoc.definitions
	]
} as unknown as DocumentNode<GetPublicProductByIdForBreakmarkQuery, GetPublicProductByIdForBreakmarkQueryVariables>;
export const GetPublicProductsForBreakmarkDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetPublicProductsForBreakmark' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'product' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'public_product' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_eq' },
														value: { kind: 'BooleanValue', value: true }
													}
												]
											}
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order_by' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'is_bundle' },
											value: { kind: 'EnumValue', value: 'desc' }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PublicProduct' } }]
						}
					}
				]
			}
		},
		...PublicProductFragmentDoc.definitions
	]
} as unknown as DocumentNode<GetPublicProductsForBreakmarkQuery, GetPublicProductsForBreakmarkQueryVariables>;
export const GetStoreContentsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetStoreContents' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team_store' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: '_or' },
											value: {
												kind: 'ListValue',
												values: [
													{
														kind: 'ObjectValue',
														fields: [
															{
																kind: 'ObjectField',
																name: { kind: 'Name', value: 'id' },
																value: {
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: '_eq' },
																			value: {
																				kind: 'Variable',
																				name: { kind: 'Name', value: 'id' }
																			}
																		}
																	]
																}
															}
														]
													},
													{
														kind: 'ObjectValue',
														fields: [
															{
																kind: 'ObjectField',
																name: { kind: 'Name', value: 'friendly_url_key' },
																value: {
																	kind: 'ObjectValue',
																	fields: [
																		{
																			kind: 'ObjectField',
																			name: { kind: 'Name', value: '_eq' },
																			value: {
																				kind: 'Variable',
																				name: { kind: 'Name', value: 'key' }
																			}
																		}
																	]
																}
															}
														]
													}
												]
											}
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamStore' } }]
						}
					}
				]
			}
		},
		...TeamStoreFragmentDoc.definitions
	]
} as unknown as DocumentNode<GetStoreContentsQuery, GetStoreContentsQueryVariables>;
export const GetTeamStoreOrderDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetTeamStoreOrder' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderIds' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } } }
					},
					defaultValue: { kind: 'StringValue', value: '', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'order' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'team_store_id' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_eq' },
														value: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } }
													}
												]
											}
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'id' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_in' },
														value: { kind: 'Variable', name: { kind: 'Name', value: 'orderIds' } }
													}
												]
											}
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order_by' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'updated_at' },
											value: { kind: 'EnumValue', value: 'desc' }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamStoreOrder' } }]
						}
					}
				]
			}
		},
		...TeamStoreOrderFragmentDoc.definitions
	]
} as unknown as DocumentNode<GetTeamStoreOrderQuery, GetTeamStoreOrderQueryVariables>;
export const CreateCheckoutDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'CreateCheckout' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'products' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutType' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'attributeTo' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'createCheckout' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'storeId' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'products' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'products' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'checkoutType' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutType' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'email' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'name' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'attributeTo' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'attributeTo' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'urlRedirect' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'error' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CreateCheckoutMutation, CreateCheckoutMutationVariables>;
export const GetAllStoreContentsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetAllStoreContents' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'ListType',
							type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } } }
						}
					}
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team_store' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'id' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: '_in' },
														value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } }
													}
												]
											}
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamStore' } }]
						}
					}
				]
			}
		},
		...TeamStoreFragmentDoc.definitions
	]
} as unknown as DocumentNode<GetAllStoreContentsQuery, GetAllStoreContentsQueryVariables>;
export const GetMeDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetMe' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user_by_pk' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'picture' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GetMeQuery, GetMeQueryVariables>;
export const GetMyStoreOrdersDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetMyStoreOrders' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'order' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'customer_name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'customer_email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'checkout_type' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'order_readable_column' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'order_items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'stripe_tax_charged' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'stripe_total_charged' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'updated_at' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GetMyStoreOrdersQuery, GetMyStoreOrdersQueryVariables>;
export const GetOrderByPkDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GetOrderByPK' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'products' },
						name: { kind: 'Name', value: 'order_by_pk' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'customer_name' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'order_items' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'where' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'order_package_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: '_is_null' },
																	value: { kind: 'BooleanValue', value: true }
																}
															]
														}
													}
												]
											}
										}
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'custom_name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'total_price' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'custom_number' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'store_product' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'label' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'additional_image_list' } }
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'order_item_options' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'available_option' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
																]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'value' } }
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'packages' },
						name: { kind: 'Name', value: 'order_by_pk' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'team_store_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'customer_name' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'order_items' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'where' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'order_package_id' },
														value: {
															kind: 'ObjectValue',
															fields: [
																{
																	kind: 'ObjectField',
																	name: { kind: 'Name', value: '_is_null' },
																	value: { kind: 'BooleanValue', value: false }
																}
															]
														}
													}
												]
											}
										},
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'distinct_on' },
											value: { kind: 'EnumValue', value: 'order_package_id' }
										}
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'order_package' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'total_price' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'store_package' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'label' } }
																]
															}
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'order_items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'custom_name' }
																	},
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'custom_number' }
																	},
																	{ kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'store_product' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'label' }
																				}
																			]
																		}
																	},
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'order_item_options' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'available_option'
																					},
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'Field',
																								name: {
																									kind: 'Name',
																									value: 'name'
																								}
																							}
																						]
																					}
																				},
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'value' }
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'details' },
						name: { kind: 'Name', value: 'order_by_pk' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'customer_name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'order_readable_column' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GetOrderByPkQuery, GetOrderByPkQueryVariables>;
export const InsertSupportDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'InsertSupport' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_item_id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'edits' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'details' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'captcha_token' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'insert_support_one' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'object' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'first_name' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'last_name' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'email' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'order_id' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'order_id' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'order_item_id' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'order_item_id' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'phone' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'reason' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'edits' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'edits' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'details' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'details' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'user_id' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'user_id' } }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'captcha_token' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'captcha_token' } }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'created_at' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<InsertSupportMutation, InsertSupportMutationVariables>;
export const UpdateMeDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateMe' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: '', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'update_user_by_pk' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'pk_columns' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'id' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: '_set' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'name' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UpdateMeMutation, UpdateMeMutationVariables>;
